import { Switch, switchTemplate as template } from '@microsoft/fast-foundation';
import { SwitchComponent } from './Switch';
import styles from './Switch.styles.scss';
export const switchDefinition = {
    baseName: 'switch',
    baseClass: Switch,
    template: template,
    styles,
};
export const chameleonSwitch = SwitchComponent.compose(switchDefinition);
