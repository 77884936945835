import { FC, useState } from 'react';
import { useIntl } from 'react-intl';
import { MoreMenuVerticalOutlinedIcon, StarOutlinedIcon, TrashOutlinedIcon } from '@getgo/chameleon-icons/react';
import { ChipV2, IconButton, Menu, MenuItem, PopoverV2, Typography } from '@getgo/chameleon-web-react-wrapper';

import { useAppDispatch } from 'hooks';
import { showErrorSnack } from 'modules/error';
import { postPaymentMethods, postPaymentMethodsDelete, postPaymentMethodsSetDefault } from 'modules/payment-methods';
import Track, { PaymentMethodDelete, PaymentMethodSetDefault } from 'modules/tracking';
import { PARTNER_TYPES, SEARCH_PARAMS } from 'utils/constants';
import st from 'utils/shared-translations';

import './default-delete-ctas.css';

interface DefaultDeleteCTAsProps {
  isDefault: boolean;
  isDeletable: boolean;
  paymentMethodKey: string;
  paymentType: string;
}

const DefaultDeleteCTAs: FC<DefaultDeleteCTAsProps> = ({ isDefault, isDeletable, paymentMethodKey, paymentType }) => {
  const intl = useIntl();
  const dispatch = useAppDispatch();

  const [isAPILoading, setAPILoading] = useState(false);

  const partnerType = localStorage.getItem(SEARCH_PARAMS.partnerType);

  /**
   * This method handles both API call (delete and set as default)
   * based on the passed action.
   */
  const handleAPICall = (action: Function, trackingEvent: string) => {
    const payload = { paymentMethodKey };
    Track(trackingEvent, { key: paymentMethodKey.slice(-5), paymentMethod: paymentType });

    setAPILoading(true);
    dispatch(action(payload))
      .unwrap()
      .then(() => {
        setAPILoading(false);
        dispatch(postPaymentMethods())
          .unwrap()
          .catch(() => dispatch(showErrorSnack(st['alert.error.general.refreshtryagain'])));
      })
      .catch(() => {
        setAPILoading(false);
        dispatch(showErrorSnack(st['alert.error.general.refreshtryagain']));
      });
  };

  const showMenu = isDeletable || !isDefault;

  return (
    <div className="default-delete-ctas">
      {isDefault && (
        <ChipV2 aria-label="primary bank" className="default-delete-ctas__primary" size="small">
          <Typography
            tag="span"
            className="default-delete-ctas__chip-text"
            variant="caption-medium-strong"
            color="type-color-inverted"
          >
            {intl.formatMessage(st['payment.methods.default'])}
          </Typography>
        </ChipV2>
      )}
      {showMenu && (
        <>
          <IconButton disabled={isAPILoading} labelId="Menu options" id="default-delete-ctas">
            <MoreMenuVerticalOutlinedIcon />
          </IconButton>
          <PopoverV2 menu triggerId="default-delete-ctas">
            <Menu labelId="default-delete-ctas__menu">
              {!isDefault && (
                <MenuItem
                  disabled={isAPILoading || partnerType?.toUpperCase() === PARTNER_TYPES.parent}
                  onClick={() => handleAPICall(postPaymentMethodsSetDefault, PaymentMethodSetDefault)}
                  leadingIcon={<StarOutlinedIcon />}
                  aria-label="Set as default"
                >
                  <Typography tag="span" className="default-delete-ctas__set-default" variant="caption-medium">
                    {intl.formatMessage(st['payment.methods.cta.setdefault'])}
                  </Typography>
                </MenuItem>
              )}
              {isDeletable && (
                <MenuItem
                  disabled={isAPILoading || partnerType === PARTNER_TYPES.parent}
                  onClick={() => handleAPICall(postPaymentMethodsDelete, PaymentMethodDelete)}
                  leadingIcon={<TrashOutlinedIcon />}
                  aria-label="Delete payment method"
                >
                  <Typography tag="span" variant="caption-medium">
                    {intl.formatMessage(st['payment.methods.cta.remove'])}
                  </Typography>
                </MenuItem>
              )}
            </Menu>
          </PopoverV2>
        </>
      )}
    </div>
  );
};

export default DefaultDeleteCTAs;
