import { __decorate } from "tslib";
import { Accordion, AccordionExpandMode } from '@microsoft/fast-foundation';
import { attr } from '@microsoft/fast-element';
import { ChameleonElementMixin } from '../common/mixin';
import { AccordionItemComponent } from './AccordionItem/AccordionItem';
export class AccordionComponent extends ChameleonElementMixin(Accordion) {
    constructor() {
        super(...arguments);
        this.chevronPosition = 'right';
    }
    connectedCallback() {
        super.connectedCallback();
        this.propagateSizeAttribute(this.getAttribute('size'));
        this.propagateChevronPositionAttribute(this.getAttribute('chevron-position'));
        this.propagateDividerAttribute(this.getAttribute('divider'));
        this.propagateOutlinedAttribute(this.hasAttribute('outlined'));
        this.addEventListener('expanded', (event) => {
            if (event.target instanceof AccordionItemComponent && this.expandmode == AccordionExpandMode.single) {
                const target = event.target;
                if (this.expandedItems.length > 1) {
                    const otherItem = this.expandedItems.find((item) => item.id !== target.id);
                    otherItem === null || otherItem === void 0 ? void 0 : otherItem.removeAttribute('expanded');
                }
            }
        });
    }
    get expandedItems() {
        return this.accordionItems.filter((item) => item.hasAttribute('expanded'));
    }
    sizeChanged(_oldValue, newValue) {
        this.propagateSizeAttribute(newValue);
    }
    chevronPositionChanged(_oldValue, newValue) {
        this.propagateChevronPositionAttribute(newValue);
    }
    dividerChanged(_oldValue, newValue) {
        this.propagateDividerAttribute(newValue);
    }
    outlinedChanged(_oldValue, newValue) {
        this.propagateOutlinedAttribute(newValue);
    }
    propagateSizeAttribute(newSize) {
        const accordionItems = this.querySelectorAll('chameleon-accordion-item');
        accordionItems === null || accordionItems === void 0 ? void 0 : accordionItems.forEach((accordionItem) => {
            if (newSize) {
                accordionItem.setAttribute('size', newSize);
            }
            else {
                accordionItem.removeAttribute('size');
            }
        });
    }
    propagateChevronPositionAttribute(newChevronPosition) {
        const accordionItems = this.querySelectorAll('chameleon-accordion-item');
        accordionItems === null || accordionItems === void 0 ? void 0 : accordionItems.forEach((accordionItem) => {
            if (newChevronPosition) {
                accordionItem.setAttribute('chevron-position', newChevronPosition);
            }
            else {
                accordionItem.removeAttribute('chevron-position');
            }
        });
    }
    propagateDividerAttribute(newDivider) {
        const accordionItems = this.querySelectorAll('chameleon-accordion-item');
        accordionItems === null || accordionItems === void 0 ? void 0 : accordionItems.forEach((accordionItem) => {
            if (newDivider) {
                accordionItem.setAttribute('divider', newDivider);
            }
            else {
                accordionItem.removeAttribute('divider');
            }
        });
    }
    propagateOutlinedAttribute(newOutlined) {
        const accordionItems = this.querySelectorAll('chameleon-accordion-item');
        accordionItems === null || accordionItems === void 0 ? void 0 : accordionItems.forEach((accordionItem) => {
            if (newOutlined) {
                accordionItem.setAttribute('outlined', '');
            }
            else {
                accordionItem.removeAttribute('outlined');
            }
        });
    }
}
__decorate([
    attr
], AccordionComponent.prototype, "size", void 0);
__decorate([
    attr({ attribute: 'chevron-position' })
], AccordionComponent.prototype, "chevronPosition", void 0);
__decorate([
    attr
], AccordionComponent.prototype, "divider", void 0);
__decorate([
    attr
], AccordionComponent.prototype, "outlined", void 0);
