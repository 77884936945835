import { __decorate } from "tslib";
import { attr, html } from '@microsoft/fast-element';
import { FoundationElement } from '@microsoft/fast-foundation';
import { ChameleonElementMixin } from '../common/mixin';
export const emptyStateTemplate = html `${(x) => x.getAttribute('size') === 'large' ? `` : html `<slot name="icon"></slot>`}
  ${(x) => (x.getAttribute('size') === 'small' ? `` : html `<slot name="title"></slot>`)}
  <div class="content">
    <slot></slot>
  </div>
  <slot name="actions"></slot>`;
export class EmptyStateComponent extends ChameleonElementMixin(FoundationElement) {
    constructor() {
        super(...arguments);
        this.size = 'medium';
    }
}
__decorate([
    attr
], EmptyStateComponent.prototype, "size", void 0);
