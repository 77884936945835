import { SVG_GOTO_BLACK_YELLOW, SVG_GOTO_MARK, SVG_GOTO_WHITE_YELLOW } from '@getgo/chameleon-icons';
import { html, ref } from '@microsoft/fast-element';
import { ChameleonRebrandingObserver } from '../Observers';
export const launchScreenComponentTemplate = html ` <chameleon-svg
    class="rebranding-logo-container"
    ${ref('rebrandingLogoContainer')}
  ></chameleon-svg>
  <div class="old-logo-container">
    <slot></slot>
  </div>`;
export class LaunchScreenComponent extends ChameleonRebrandingObserver {
    connectedCallback() {
        var _a;
        super.connectedCallback();
        const slot = (_a = this.shadowRoot) === null || _a === void 0 ? void 0 : _a.querySelector('slot');
        if (!(slot === null || slot === void 0 ? void 0 : slot.assignedNodes().length) && !(slot === null || slot === void 0 ? void 0 : slot.querySelectorAll('chameleon-svg').length)) {
            const svg = document.createElement('chameleon-svg');
            svg.innerHTML = SVG_GOTO_MARK;
            slot === null || slot === void 0 ? void 0 : slot.appendChild(svg);
        }
        this.observer.observe(this.getThemeProvider(), { attributes: true });
        this.updateVisibility();
    }
    disconnectedCallback() {
        super.disconnectedCallback();
    }
    rebrandingObserverHandler() {
        this.updateVisibility();
    }
    updateVisibility() {
        const themeProvider = this.getThemeProvider();
        if (!themeProvider) {
            console.error(`[@getgo/chameleon-web] - Couldn't find chameleon-theme-provider in the DOM for ${this}`);
            return;
        }
        if (themeProvider.hasAttribute('rebranding2021')) {
            this.updateIconBasedOnTheme();
            this.classList.add('rebranding');
        }
        else {
            this.classList.remove('rebranding');
        }
    }
    updateIconBasedOnTheme() {
        if (this.isDarkMode()) {
            this.rebrandingLogoContainer.innerHTML = SVG_GOTO_WHITE_YELLOW;
        }
        else {
            this.rebrandingLogoContainer.innerHTML = SVG_GOTO_BLACK_YELLOW;
        }
    }
    isDarkMode() {
        const themeProvider = this.getThemeProvider();
        const automaticAttr = themeProvider.getAttribute('automatic');
        const isAutomatic = automaticAttr === 'true' || automaticAttr == '';
        return isAutomatic
            ? window.matchMedia('(prefers-color-scheme: dark)').matches
            : themeProvider.getAttribute('theme') === 'dark';
    }
}
