import { assert } from './utils/assert';
import { redirect } from './utils/redirect';
import { stringify } from './utils/serialize';

export interface ILogoutOptions {
  client_id: string;
  onLogoutRedirect?: (url: string) => void;
  redirect_url: string;
  url: string;
  [key: string]: any;
}

export const logout = (options: ILogoutOptions) => {
  assert(options, ['client_id', 'url', 'redirect_url']);

  const params = {
    client_id: options.client_id,
    redirect_uri: options.redirect_url,
  };
  const url = `${options.url}/oauth/logout?${stringify(params)}`;
  const fn = options.onLogoutRedirect || redirect;

  return fn(url);
};
