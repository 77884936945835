import { BaseProgress, progressTemplate as template } from '@microsoft/fast-foundation';
import { progressStyles } from '@microsoft/fast-components';
import { css } from '@microsoft/fast-element';
import { LinearProgressComponent } from './LinearProgress';
import styles from './LinearProgress.styles.scss';
export const linearProgressDefinition = {
    baseName: 'linear-progress',
    baseClass: LinearProgressComponent,
    template: template,
    styles: (ctx, def) => css `
    ${progressStyles(ctx, def)}
    ${styles}
  `,
    indeterminateIndicator1: `
    <span class="indeterminate-indicator-1" part="indeterminate-indicator-1"></span>
`,
    indeterminateIndicator2: `
    <span class="indeterminate-indicator-1" part="indeterminate-indicator-1"></span>
`,
};
export const chameleonLinearProgress = BaseProgress.compose(linearProgressDefinition);
