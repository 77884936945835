import { __decorate } from "tslib";
import { attr, html, observable, when } from '@microsoft/fast-element';
import { SVG_COPY_OUTLINED } from '@getgo/chameleon-icons';
import { ChameleonElementMixin } from '../common/mixin';
import { FoundationElement } from '@microsoft/fast-foundation';
const copyButton = html `<chameleon-icon-button
  id="copy-button"
  size="large"
  slot="trigger"
  @click=${(x) => x.copyToClipboard()}
  label="Copy to clipboard"
>
  <chameleon-svg>${SVG_COPY_OUTLINED}</chameleon-svg>
</chameleon-icon-button>`;
const inlineTemplate = html `
  <chameleon-tooltip-v2
    style="${(x) => `--z-index: ${x.tooltipZIndex}`}"
    :hidden="${(x) => !x.showTooltip}"
    part="tooltip"
    position="top-center"
  >
    <pre @click=${(x) => x.copyToClipboard()} slot="trigger"><slot></slot></pre>
    <span slot="content">${(x) => (x.copyClicked ? x.copiedString : x.copyString)}</span>
  </chameleon-tooltip-v2>
`;
const defaultTemplate = html `
  <pre><slot></slot></pre>
  <chameleon-tooltip-v2 style="${(x) => `--z-index: ${x.tooltipZIndex}`}" part="tooltip" position="top-center">
    ${copyButton}
    <span slot="content"> ${(x) => (x.copyClicked ? x.copiedString : x.copyString)} </span>
  </chameleon-tooltip-v2>
`;
export const codeSnippetTemplate = html `
  ${when((x) => x.line !== 'inline', () => defaultTemplate)}
  ${when((x) => x.line === 'inline', () => inlineTemplate)}
`;
export class CodeSnippetComponent extends ChameleonElementMixin(FoundationElement) {
    constructor() {
        super(...arguments);
        this.copyString = 'Copy to clipboard';
        this.copiedString = 'Copied to clipboard';
        this.showTooltip = false;
        this.line = 'singleline';
        this.copyClicked = false;
    }
    getContentToCopy() {
        var _a;
        const slot = (_a = this.shadowRoot) === null || _a === void 0 ? void 0 : _a.querySelector('slot');
        const nodes = slot === null || slot === void 0 ? void 0 : slot.assignedNodes();
        return nodes === null || nodes === void 0 ? void 0 : nodes.map((node) => { var _a; return (_a = node.textContent) !== null && _a !== void 0 ? _a : ''; }).reverse().reduce((accumulator, currentValue) => (currentValue = accumulator !== '' ? `${currentValue}${accumulator}` : currentValue), '');
    }
    copyToClipboard() {
        if (this.copyClicked) {
            clearTimeout(this.copiedTimer);
        }
        const content = this.getContentToCopy();
        if (content) {
            navigator.clipboard
                .writeText(content)
                .then(() => {
                this.$emit('copied', content, { bubbles: false });
            })
                .then(() => {
                this.copyClicked = true;
                this.copiedTimer = setTimeout(() => {
                    this.copyClicked = false;
                }, 3000);
            });
        }
    }
}
__decorate([
    attr({ attribute: 'copy-string' })
], CodeSnippetComponent.prototype, "copyString", void 0);
__decorate([
    attr({ attribute: 'copied-string' })
], CodeSnippetComponent.prototype, "copiedString", void 0);
__decorate([
    attr({ attribute: 'show-tooltip', mode: 'boolean' })
], CodeSnippetComponent.prototype, "showTooltip", void 0);
__decorate([
    attr({ attribute: 'tooltip-z-index' })
], CodeSnippetComponent.prototype, "tooltipZIndex", void 0);
__decorate([
    attr()
], CodeSnippetComponent.prototype, "line", void 0);
__decorate([
    observable
], CodeSnippetComponent.prototype, "copyClicked", void 0);
__decorate([
    observable
], CodeSnippetComponent.prototype, "copiedTimer", void 0);
