import { SVG_LOADER } from '@getgo/chameleon-icons';
export function createLoadingSvg(size) {
    const svg = document.createElement('chameleon-svg');
    svg.innerHTML = SVG_LOADER;
    svg.ariaLabel = 'Loading icon';
    svg.classList.add('chameleon-loading-icon');
    addSvgIconSize(svg, size);
    return svg;
}
export function addHasAttributesToParent(component, ignoreStart = false, ignoreEnd = false) {
    const startSlot = component.querySelector('[slot="start"]');
    const endSlot = component.querySelector('[slot="end"]');
    if (startSlot && !ignoreStart) {
        component.setAttribute('has-start-slot', '');
    }
    if (endSlot && !ignoreEnd) {
        component.setAttribute('has-end-slot', '');
    }
}
export function addSvgIconSize(svg, size) {
    let iconSize;
    switch (size) {
        case 'xxsmall':
            iconSize = '16px';
            break;
        case 'xsmall':
            iconSize = '16px';
            break;
        case 'small':
            iconSize = '16px';
            break;
        case 'medium':
            iconSize = '16px';
            break;
        case 'large':
            iconSize = '20px';
            break;
        case 'xlarge':
            iconSize = '24px';
            break;
        case 'xxlarge':
            iconSize = '32px';
            break;
        case 'xxxlarge':
            iconSize = '24px';
            break;
        case 'fullScreen':
            iconSize = '24px';
            break;
        default:
            iconSize = '16px';
    }
    svg.style.setProperty('--goto-icon-size', iconSize);
    svg.style.width = iconSize;
    svg.setAttribute('width', iconSize);
    svg.setAttribute('height', iconSize);
}
