import { css } from "@microsoft/fast-element";
/**
 * Styles for AnchoredRegion
 * @public
 */
export const anchoredRegionStyles = (context, definition) => css `
    :host {
        contain: layout;
        display: block;
    }
`;
