import { forwardKeyboardEvent } from './../helpers';
import { ListboxOption } from '@microsoft/fast-foundation';
import { ChameleonElementMixin } from '../../common/mixin';
export class ListboxItemComponent extends ChameleonElementMixin(ListboxOption) {
    constructor() {
        super(...arguments);
        this.interactiveItems = [];
        this.handleKeyDown = (event) => {
            const { key } = event;
            const selectedInteractiveItemIndex = this.interactiveItems.findIndex((subItem) => subItem === null || subItem === void 0 ? void 0 : subItem.getAttribute('aria-current'));
            const selectedInteractiveItem = this.interactiveItems[selectedInteractiveItemIndex];
            if (!selectedInteractiveItem && (key === 'Enter' || key === ' ')) {
                this.click();
            }
            if (!(this.interactiveItems.length > 0)) {
                return;
            }
            if (key === 'ArrowRight') {
                event.preventDefault();
                this.classList.remove('selected');
                if (selectedInteractiveItem) {
                    const nextInteractiveSubItem = this.interactiveItems[selectedInteractiveItemIndex + 1];
                    if (nextInteractiveSubItem) {
                        this.updateSelectedInteractiveItem(nextInteractiveSubItem, selectedInteractiveItem);
                    }
                    else {
                        this.removeSelectedInteractiveItem(selectedInteractiveItem);
                        this.classList.add('selected');
                    }
                }
                else {
                    const firstInteractiveSubItem = this.interactiveItems[0];
                    this.updateSelectedInteractiveItem(firstInteractiveSubItem);
                }
            }
            if (key === 'ArrowLeft') {
                event.preventDefault();
                this.classList.remove('selected');
                if (selectedInteractiveItem) {
                    const previousInteractiveSubItem = this.interactiveItems[selectedInteractiveItemIndex - 1];
                    if (previousInteractiveSubItem) {
                        this.updateSelectedInteractiveItem(previousInteractiveSubItem, selectedInteractiveItem);
                    }
                    else {
                        this.removeSelectedInteractiveItem(selectedInteractiveItem);
                        this.classList.add('selected');
                    }
                }
                else {
                    const lastInteractiveSubItem = this.interactiveItems[this.interactiveItems.length - 1];
                    this.updateSelectedInteractiveItem(lastInteractiveSubItem);
                }
            }
            if (selectedInteractiveItem && (key === 'ArrowUp' || key === 'ArrowDown')) {
                this.removeSelectedInteractiveItem(selectedInteractiveItem);
            }
            if (selectedInteractiveItem && (key === 'Enter' || key === ' ')) {
                forwardKeyboardEvent(event, selectedInteractiveItem);
            }
        };
    }
    updateSelectedInteractiveItem(newInteractiveItem, previousInteractiveItem) {
        this.addSelectedInteractiveItem(newInteractiveItem);
        if (previousInteractiveItem) {
            this.removeSelectedInteractiveItem(previousInteractiveItem);
        }
    }
    addSelectedInteractiveItem(interactiveItem) {
        var _a, _b;
        const listbox = document.querySelector('chameleon-listbox');
        interactiveItem.setAttribute('aria-current', 'true');
        listbox === null || listbox === void 0 ? void 0 : listbox.setAttribute('aria-activedescendant', interactiveItem.id);
        (_a = interactiveItem.firstElementChild) === null || _a === void 0 ? void 0 : _a.classList.add('selected-interactive-item');
        const newTooltip = (_b = interactiveItem.shadowRoot) === null || _b === void 0 ? void 0 : _b.querySelector('chameleon-tooltip-v2');
        newTooltip === null || newTooltip === void 0 ? void 0 : newTooltip.setAttribute('is-open', 'true');
    }
    removeSelectedInteractiveItem(interactiveItem) {
        var _a, _b;
        const prevTooltip = (_a = interactiveItem.shadowRoot) === null || _a === void 0 ? void 0 : _a.querySelector('chameleon-tooltip-v2');
        prevTooltip === null || prevTooltip === void 0 ? void 0 : prevTooltip.removeAttribute('is-open');
        interactiveItem.removeAttribute('aria-current');
        (_b = interactiveItem.firstElementChild) === null || _b === void 0 ? void 0 : _b.classList.remove('selected-interactive-item');
    }
    getAllInteractiveItems() {
        const interactiveItems = new Set();
        let currentDepth = 1;
        const addInteractiveItem = (itemsArray) => {
            for (const item of itemsArray) {
                interactiveItems.add(item);
            }
        };
        const recursiveSearch = (parent) => {
            addInteractiveItem(Array.from(parent.querySelectorAll('chameleon-interactive-sub-item')));
            if (parent.shadowRoot) {
                currentDepth++;
                addInteractiveItem(Array.from(parent.shadowRoot.querySelectorAll('chameleon-interactive-sub-item')));
                for (const child of [...parent.shadowRoot.children]) {
                    if (currentDepth < 20) {
                        recursiveSearch(child);
                    }
                }
            }
            for (const child of [...parent.children]) {
                if (currentDepth < 20) {
                    recursiveSearch(child);
                }
            }
        };
        recursiveSearch(this);
        return Array.from(interactiveItems);
    }
    setInteractiveItems() {
        this.interactiveItems = this.getAllInteractiveItems();
    }
    connectedCallback() {
        super.connectedCallback();
        this.addEventListener('keydown', this.handleKeyDown);
        this.addEventListener('interactive-item-added', this.setInteractiveItems);
    }
    disconnectedCallback() {
        super.disconnectedCallback();
        this.removeEventListener('keydown', this.handleKeyDown);
        this.removeEventListener('interactive-item-added', this.setInteractiveItems);
    }
}
