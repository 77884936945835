import { BaseProgress as ProgressRing, progressRingTemplate as template, } from '@microsoft/fast-foundation';
import { progressRingStyles } from '@microsoft/fast-components';
import { css } from '@microsoft/fast-element';
import styles from './CircularProgress.styles.scss';
import { CircularProgressComponent } from './CircularProgress';
export const circularProgressDefinition = {
    baseName: 'circular-progress',
    baseClass: CircularProgressComponent,
    template: template,
    styles: (ctx, def) => css `
    ${progressRingStyles(ctx, def)}
    ${styles}
  `,
    indeterminateIndicator: `
      <slot name="indeterminate" slot="indeterminate">
          <svg class="progress" part="progress" viewBox="0 0 16 16">
              <circle class="background" part="background" cx="8px" cy="8px" r="7px" />
              <circle class="indeterminate-indicator-1" part="indeterminate-indicator-1" cx="8px" cy="8px" r="7px" />
          </svg>
      </slot>
    `,
};
export const chameleonCircularProgress = ProgressRing.compose(circularProgressDefinition);
