import { __rest } from "tslib";
import { createReactWrapper } from '../common/PrefixUtils';
import { handleForwardRef } from '../common/RefUtils';
import React, { useRef, forwardRef, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
export const LegacyPopoverV2 = forwardRef((_a, ref) => {
    var { triggerId, isOpen, triggerOn, minWidth, maxWidth, zIndex, labelId, children, onChange } = _a, props = __rest(_a, ["triggerId", "isOpen", "triggerOn", "minWidth", "maxWidth", "zIndex", "labelId", "children", "onChange"]);
    const popoverV2Ref = useRef();
    const handledRef = handleForwardRef(popoverV2Ref, ref);
    const [isContentAttachedAndOpened, setIsContentAttachedAndOpened] = useState(() => isOpen ? true : false);
    const [popoverContentEl, setPopoverContentEl] = useState(undefined);
    const handleChange = (event) => {
        setIsContentAttachedAndOpened(event.detail.isOpen);
        onChange === null || onChange === void 0 ? void 0 : onChange(event);
    };
    useEffect(() => {
        var _a;
        (_a = popoverV2Ref.current) === null || _a === void 0 ? void 0 : _a.addEventListener('popoverchange', handleChange);
        return () => {
            var _a;
            (_a = popoverV2Ref.current) === null || _a === void 0 ? void 0 : _a.removeEventListener('popoverchange', handleChange);
        };
    }, [ref, onChange]);
    useEffect(() => {
        var _a;
        /*
          Set the target after the chameleon-popover is loaded so we can have access to chameleon-popover-content.
          This will allow us to use the reactPortal to have events delegated to the proper element once chameleon-popover-content
          moves outside of chameleon-popover
        */
        // @ts-expect-error .contentEl property no longer exists so this error, but in older versions which this supports it's there.
        setPopoverContentEl((_a = popoverV2Ref.current) === null || _a === void 0 ? void 0 : _a.contentEl);
    }, [ref]);
    useEffect(() => {
        var _a, _b;
        if (((_a = popoverV2Ref.current) === null || _a === void 0 ? void 0 : _a.isOpen) === true) {
            (_b = popoverV2Ref.current) === null || _b === void 0 ? void 0 : _b.positionPopover();
        }
    }, [children]);
    return createReactWrapper({
        tagName: 'chameleon-popover-v2',
        props: Object.assign(Object.assign({}, props), { 'trigger-id': triggerId, 'is-open': isOpen, 'trigger-on': triggerOn, 'min-width': minWidth, 'max-width': maxWidth, 'z-index': zIndex, 'label-id': labelId, ref: handledRef }),
        children: React.createElement(React.Fragment, null, popoverContentEl && isContentAttachedAndOpened && createPortal(children, popoverContentEl)),
    });
});
