import { __decorate } from "tslib";
import { attr, html } from '@microsoft/fast-element';
import { FoundationElement } from '@microsoft/fast-foundation';
import { getChameleonElements, getElementsWithProperty } from '../../common/utils/DomUtil';
import { ChameleonElementMixin } from '../../common/mixin';
export const dataTableActionsV2Template = html `
  <div class="left">
    <slot name="search"></slot>
    <slot name="manage-selection"></slot>
    <slot name="selected-button"></slot>
  </div>
  <div class="right">
    <slot name="select"></slot>
    <div class="divider"></div>
    <slot name="actions"></slot>
  </div>
`;
export class DataTableActionsV2Component extends ChameleonElementMixin(FoundationElement) {
    constructor() {
        super(...arguments);
        this.condensed = false;
    }
    get buttonSize() {
        return this.condensed ? 'small' : 'medium';
    }
    connectedCallback() {
        super.connectedCallback();
        const chameleonElements = getElementsWithProperty(getChameleonElements(this), 'size');
        for (const el of chameleonElements) {
            if (el.tagName.toLowerCase().includes('search')) {
                el.setAttribute('fieldsize', this.condensed ? 'small' : 'medium');
            }
            else {
                el.setAttribute('size', this.condensed ? 'small' : 'medium');
            }
        }
    }
    condensedChanged(_, next) {
        const chameleonElements = getElementsWithProperty(getChameleonElements(this), 'size');
        for (const el of chameleonElements) {
            el.setAttribute('size', next ? 'small' : 'medium');
            if (el.tagName.toLowerCase().includes('search')) {
                el.setAttribute('fieldsize', this.condensed ? 'small' : 'medium');
            }
            else {
                el.setAttribute('size', this.condensed ? 'small' : 'medium');
            }
        }
    }
}
__decorate([
    attr({ mode: 'boolean' })
], DataTableActionsV2Component.prototype, "condensed", void 0);
