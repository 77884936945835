import { ILoginOptions, login, loginWithSSO, loginWithPKCE } from './login';
import { ILogoutOptions, logout } from './logout';
import { IAuthCodeTokenConfigPkce, requestPKCEToken } from './pkceToken';
import Token, { ITokenish } from './token';
import { assert } from './utils/assert';
import { redirect } from './utils/redirect';
import { randomString } from './utils/randomString';

export interface IAuthOptions extends ILoginOptions, ILogoutOptions, IAuthCodeTokenConfigPkce {
  enableTokenRefresh?: boolean;
  onTokenRefresh?: (token: Token) => void;
  tokenRefreshTimestamp?: number | ((token: Token) => number);
}

export const defaultOptions: IAuthOptions = {
  client_id: '',
  enableTokenRefresh: false,
  onLoginRedirect: redirect,
  onLogoutRedirect: redirect,
  redirect_url: '',
  url: '',
};

function clearRedirectResponse() {
  if (window.history && window.history.replaceState) {
    const url = window.location.href.replace(window.location.hash, '');
    window.history.replaceState(null, '', url);
  } else {
    window.location.hash = '';
  }
}

export default class Auth {
  private options: IAuthOptions;

  constructor(options: IAuthOptions) {
    assert(options, ['client_id', 'url', 'redirect_url']);
    this.options = { ...defaultOptions, ...options };
  }

  public init(tokenish?: ITokenish) {
    const token: Token | undefined = this.getRedirectResponse();
    if (token) {
      return token;
    }

    const convertedToken = tokenish && new Token(tokenish);
    if (convertedToken && convertedToken.isValid()) {
      return this.bindToken(convertedToken);
    }

    return undefined;
  }

  public getRedirectResponse(hash = window.location.hash) {
    if (hash) {
      const token = new Token(hash);
      if (token.isValid()) {
        clearRedirectResponse();
        return this.bindToken(token);
      }
    }

    return undefined;
  }

  public login(options: Partial<ILoginOptions> = {}) {
    return login({ ...this.options, ...options });
  }

  public loginWithSSO(options: Partial<ILoginOptions> = {}) {
    return loginWithSSO({ ...this.options, ...options });
  }

  public loginWithPKCE(options: Partial<ILoginOptions> = {}) {
    return loginWithPKCE({ ...this.options, ...options });
  }

  public requestPKCEToken(options: Partial<IAuthCodeTokenConfigPkce> = {}) {
    return requestPKCEToken({ ...this.options, ...options });
  }

  public storeTokenObject(token: Token) {
    if (token.isValid()) {
      return this.bindPKCEToken(token);
    }
    return undefined;
  }

  public logout(options: Partial<ILogoutOptions> = {}): void | Promise<void> {
    return logout({ ...this.options, ...options });
  }

  private bindToken(token: Token) {
    if (this.options.enableTokenRefresh) {
      token
        .startRefreshTimer(this.options.tokenRefreshTimestamp)
        .then(this.options.onTokenRefresh)
        .then(() => this.login());
    }

    this.bindLogoutToToken(token);

    return token;
  }

  private bindPKCEToken(token: Token) {
    if (this.options.enableTokenRefresh) {
      token
        .startRefreshTimer(this.options.tokenRefreshTimestamp)
        .then(this.options.onTokenRefresh)
        .then(() => this.loginWithPKCE());
    }

    this.bindLogoutToToken(token);

    return token;
  }

  private bindLogoutToToken(token: Token) {
    const tokenLogout = token.logout;

    Object.defineProperty(token, 'logout', {
      enumerable: false,
      value: () => {
        tokenLogout.call(token);
        this.logout();
      },
    });
  }

  public randomString(strLength: number) {
    return randomString(strLength);
  }
}
