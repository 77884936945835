import { __decorate } from "tslib";
import { SVG_COPY_OUTLINED } from '@getgo/chameleon-icons';
import { attr, html, when } from '@microsoft/fast-element';
import { FoundationElement } from '@microsoft/fast-foundation';
import { ChameleonElementMixin } from '../common/mixin';
const copyButton = html `<chameleon-icon-button
  id="copy-button"
  size="small"
  variant="primary"
  @click=${(x) => x.copyToClipboard()}
  ><chameleon-svg>${SVG_COPY_OUTLINED}</chameleon-svg></chameleon-icon-button
>`;
export const codeTemplate = html `<code><slot></slot></code>${when((x) => !x.inline, () => html `${(x) => !!x.copystring
    ? html `<chameleon-tooltip position="top" label="${x.copystring}"
              >${copyButton}</chameleon-tooltip
            >`
    : copyButton}`)}`;
export class CodeComponent extends ChameleonElementMixin(FoundationElement) {
    constructor() {
        super(...arguments);
        this.inline = false;
    }
    getContentToCopy() {
        var _a;
        const slot = (_a = this.shadowRoot) === null || _a === void 0 ? void 0 : _a.querySelector('slot');
        const nodes = slot === null || slot === void 0 ? void 0 : slot.assignedNodes();
        return nodes === null || nodes === void 0 ? void 0 : nodes.map((node) => { var _a; return (_a = node.textContent) !== null && _a !== void 0 ? _a : ''; }).reverse().reduce((accumulator, currentValue) => (currentValue = accumulator !== '' ? `${currentValue}${accumulator}` : currentValue), '');
    }
    copyToClipboard() {
        const content = this.getContentToCopy();
        if (content) {
            navigator.clipboard.writeText(content).then(() => {
                this.$emit('copied', content, { bubbles: false });
            });
        }
    }
}
__decorate([
    attr
], CodeComponent.prototype, "copystring", void 0);
__decorate([
    attr({ mode: 'boolean' })
], CodeComponent.prototype, "inline", void 0);
