import { FC } from 'react';

import AchForm from 'components/ach-form';
import ACHHeader from 'components/ach-header';

const AchAdd: FC = (): JSX.Element => (
  <>
    <ACHHeader />
    <AchForm />
  </>
);

export default AchAdd;
