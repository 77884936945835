import { createSelector } from '@reduxjs/toolkit';

import {
  AchDetails,
  CreditCard,
  DdDetails,
  PaymentMethodLimits,
  PaymentMethodsState,
  PaypalAgreements,
  SortedPaymentMethodList,
} from 'types/payment-method';
import { RootState } from 'types/root';
import { PAYMENT_METHODS_TYPES } from 'utils/constants';

export const paymentMethodsTree = (state: RootState): PaymentMethodsState => state.paymentMethods || {};

export const paymentMethodsIsLoading = (state: RootState): boolean => paymentMethodsTree(state).isLoading || false;

// ------------------------------------
// Payment lists
// ------------------------------------
export const creditCardList = (state: RootState): CreditCard[] => paymentMethodsTree(state).creditCards || [];

export const achList = (state: RootState): AchDetails[] => paymentMethodsTree(state).ach || [];

export const directDebitList = (state: RootState): DdDetails[] => paymentMethodsTree(state).directDebits || [];

export const paypalList = (state: RootState): PaypalAgreements[] => paymentMethodsTree(state).payPalAgreements || [];

export const sortedPaymentMethodList = createSelector(
  [creditCardList, achList, directDebitList, paypalList],
  (creditCards, ach, directDebits, payPalAgreements) => {
    const acc: SortedPaymentMethodList[] = [];

    creditCards.forEach((payment) => acc.push({ ...payment, paymentMethodType: PAYMENT_METHODS_TYPES.cc }));
    ach.forEach((payment) => acc.push({ ...payment, paymentMethodType: PAYMENT_METHODS_TYPES.ach }));
    directDebits.forEach((payment) => acc.push({ ...payment, paymentMethodType: PAYMENT_METHODS_TYPES.dd }));
    payPalAgreements.forEach((payment) => acc.push({ ...payment, paymentMethodType: PAYMENT_METHODS_TYPES.paypal }));

    return acc.sort((a, b) => (a.isDefault ? -1 : b.isDefault ? 1 : 0));
  },
);

// ------------------------------------
// Payment limits
// ------------------------------------
export const paymentMethodLimits = (state: RootState): PaymentMethodLimits =>
  paymentMethodsTree(state).paymentMethodLimits || {
    CREDIT_CARD: 2,
    DirectDebit: 1,
    ACH: 1,
    PayPal: 1,
  };

export const paymentMethodsAdditionCount = createSelector(
  [paymentMethodLimits, creditCardList, achList, directDebitList, paypalList],
  (paymentMethodLimits, creditCards, ach, directDebits, payPalAgreements) => ({
    [PAYMENT_METHODS_TYPES.cc]: paymentMethodLimits.CREDIT_CARD - creditCards.length,
    [PAYMENT_METHODS_TYPES.ach]: paymentMethodLimits.ACH - ach.length,
    [PAYMENT_METHODS_TYPES.dd]: paymentMethodLimits.DirectDebit - directDebits.length,
    [PAYMENT_METHODS_TYPES.paypal]: paymentMethodLimits.PayPal - payPalAgreements.length,
  }),
);

// ------------------------------------
// Loaders
// ------------------------------------
export const isDeletePaymentMethodLoading = (state: RootState): boolean =>
  paymentMethodsTree(state).isDeletePaymentMethodLoading || false;

export const isSetDefaultPaymentLoading = (state: RootState): boolean =>
  paymentMethodsTree(state).isSetDefaultPaymentLoading || false;

export const paymentTypesIsLoading = (state: RootState): boolean =>
  paymentMethodsTree(state).isPaymentTypeLoading || false;

// ------------------------------------
// Allowed payments
// ------------------------------------
export const paymentMethodTypes = (state: RootState): string[] => paymentMethodsTree(state).paymentMethodTypes || [];

export const isAddPaymentMethodSupported = (state: RootState): boolean => {
  const addPaymentMethodCount = paymentMethodsAdditionCount(state);
  return Object.values(addPaymentMethodCount).some((count) => count > 0);
};

// ------------------------------------
// UI state
// ------------------------------------
export const chosenPaymentMethodKey = (state: RootState): string =>
  paymentMethodsTree(state).chosenPaymentMethodKey || sortedPaymentMethodList(state)[0]?.paymentMethodKey || '';

export const payNowIntent = (state: RootState): string => paymentMethodsTree(state).payNowIntent || '';
