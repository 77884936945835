import { __decorate } from "tslib";
import { attr, html } from '@microsoft/fast-element';
import { FoundationElement } from '@microsoft/fast-foundation';
import { ChameleonElementMixin } from '../../common/mixin';
export const dataTableRowComponentTemplate = html `<template
  aria-disabled=${(x) => x.disabled}
  role="row"
  ><slot></slot
></template>`;
export class DataTableRowComponent extends ChameleonElementMixin(FoundationElement) {
}
__decorate([
    attr({ mode: 'boolean' })
], DataTableRowComponent.prototype, "disabled", void 0);
__decorate([
    attr({ mode: 'boolean' })
], DataTableRowComponent.prototype, "selected", void 0);
