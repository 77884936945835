import { html } from '@microsoft/fast-element';
import { ChameleonElementMixin } from '../../common/mixin';
import { FoundationElement } from '@microsoft/fast-foundation';
export const scrollBoxTemplate = html `<template>
  <slot></slot>
</template>`;
// This custom element reserves space for the scrollbar, so that the content doesn't jump around when the scrollbar appears.
// The scrollbar is hidden by default and only appears when the user hovers over the element.
// The component tries to achieve a right padding of --scrollbox-padding-right, but this is not possible in all browsers.
// Between content and scrollbar there is a minimum margin of --scrollbar-margin-left and to the right of the scrollbar is a margin of --scrollbar-margin-right.
// | content | --scrollbar-margin-left + make up | scrollbar | --scrollbar-margin-right |
// | content |                        --scrollbox-padding-right                         |
// Usage:
// Wrap your content in a copilot-scroll-box element.
// Use --scrollbox-padding-right instead of padding-right.
// Use --scrollbar-margin-left and --scrollbar-margin-right to influence the gaps around the scrollbar.
// Set --scrollbar-width-ff to auto or thin to influence the width of the scrollbar in Firefox.
export class ScrollBox extends ChameleonElementMixin(FoundationElement) {
}
