import { FC } from 'react';
import { useIntl } from 'react-intl';
import { BankAccountOutlinedIcon } from '@getgo/chameleon-icons/react';
import { Typography } from '@getgo/chameleon-web-react-wrapper';

import DefaultDeleteCTAs from 'components/default-delete-ctas';
import { AchDetails } from 'types/payment-method';
import { PAYMENT_METHODS_TYPES } from 'utils/constants';
import st from 'utils/shared-translations';

import './ach-info.css';

interface ACHInfoProps {
  achDetails: AchDetails;
}

const ACHInfo: FC<ACHInfoProps> = ({
  achDetails: { last4Digits, isDeletable, isDefault, paymentMethodKey },
}): JSX.Element => {
  const intl = useIntl();

  return (
    <section className="ach-info">
      <div className="ach-info__details">
        <BankAccountOutlinedIcon size="24px" />
        <Typography tag="h3" variant="body-small" className="ach-info__number">
          {intl.formatMessage(st['ach.bank.account.number.endingin'])} {last4Digits}
        </Typography>
      </div>
      <DefaultDeleteCTAs
        isDefault={isDefault}
        paymentMethodKey={paymentMethodKey}
        isDeletable={isDeletable}
        paymentType={PAYMENT_METHODS_TYPES.ach}
      />
    </section>
  );
};

export default ACHInfo;
