import { __decorate } from "tslib";
import { attr, html, observable, ref, slotted } from '@microsoft/fast-element';
import { FoundationElement } from '@microsoft/fast-foundation';
import { ChameleonElementMixin } from '../common/mixin';
import { SVG_MORE_HORIZONTAL } from '@getgo/chameleon-icons';
import { NavBarItemComponent } from './NavBarItem/NavBarItem';
export const chameleonNavBarTemplate = html `
  <template
    role="navigation"
    tabindex="0"
    aria-label="${(x) => x.label}"
    @keydown="${(x, c) => x.handleKeyDown(c.event)}"
  >
    <ul>
      <slot ${slotted('items')}></slot>
      <chameleon-button ${ref('more')} label="${(x) => x.moreLabel}" variant="tertiary" id="more">
        <chameleon-svg slot="start">${SVG_MORE_HORIZONTAL}</chameleon-svg>
        <span class="more-text">${(x) => x.moreLabel}</span>
      </chameleon-button>
      <chameleon-tooltip-v3 trigger-id="more" ?hidden="${(x) => !x.dataIsNarrow}">
        ${(x) => x.moreLabel}
      </chameleon-tooltip-v3>
      <chameleon-popover-v2
        ${ref('popoverEl')}
        @keydown="${(x, c) => x.handleDropdownKeyDown(c.event)}"
        position="bottom-start"
        trigger-id="more"
        menu
      >
        <ul class="more-list">
          <slot name="temp-more"></slot>
          <slot name="more"></slot>
        </ul>
      </chameleon-popover-v2>
    </ul>
  </template>
`;
export class NavBarComponent extends ChameleonElementMixin(FoundationElement) {
    constructor() {
        super(...arguments);
        this.moreLabel = 'More';
        /**
         * @internal
         *
         * This is set by the nav-bar element, should not be documented and should not
         * be set by end users.
         * */
        this.dataIsNarrow = false;
        this.items = [];
        this.itemsInMoreSlot = new Set();
        this.itemsInTempMoreSlot = new Set();
        this.resizeObserver = new ResizeObserver(this.resizeObserverCallback.bind(this));
        this.mutationObserver = new MutationObserver(this.mutationObserverCallback.bind(this));
    }
    connectedCallback() {
        super.connectedCallback();
        const moreItems = Array.from(this.querySelectorAll('[slot="more"]'));
        for (const item of moreItems) {
            this.itemsInMoreSlot.add(item);
        }
        this.resizeObserver.observe(this);
        this.mutationObserver.observe(this, { childList: true });
        this.addListenerToItems();
    }
    disconnectedCallback() {
        this.resizeObserver.disconnect();
        this.mutationObserver.disconnect();
    }
    itemClickHandler(e) {
        if (e.target.target === '_self') {
            e.preventDefault();
        }
        if (e.target.active !== true) {
            this.$emit('ch-nav-bar-change', { target: e.target }, { bubbles: true });
        }
    }
    resizeObserverCallback(entries) {
        const { contentRect } = entries[0];
        this.calculateItemPlacement(contentRect);
        if (contentRect.width < 500) {
            this.setAttribute('data-is-narrow', 'true');
            Array.from(this.children).forEach((item) => {
                item.setAttribute('data-is-narrow', 'true');
            });
        }
        else {
            this.removeAttribute('data-is-narrow');
            Array.from(this.children).forEach((item) => {
                item.removeAttribute('data-is-narrow');
            });
        }
    }
    mutationObserverCallback(entries) {
        const rect = this.getBoundingClientRect();
        this.calculateItemPlacement(rect);
        this.addListenerToItems();
        for (const entry of entries) {
            for (const removedNode of entry.removedNodes) {
                if (removedNode instanceof NavBarItemComponent) {
                    removedNode.removeEventListener('click', this.itemClickHandler);
                }
            }
        }
    }
    addListenerToItems() {
        const items = this.querySelectorAll('chameleon-nav-bar-item');
        for (const item of Array.from(items)) {
            item.addEventListener('click', this.itemClickHandler);
        }
    }
    calculateItemPlacement(navRailRect) {
        const items = Array.from(this.querySelectorAll('chameleon-nav-bar-item:not([slot="more"])'));
        let sumOfWidth = 0;
        let cutOffIndex = items.length;
        for (let i = 0; i < items.length; i++) {
            const itemWidth = items[i].hasAttribute('data-width')
                ? Number(items[i].getAttribute('data-width'))
                : items[i].getBoundingClientRect().width;
            sumOfWidth += itemWidth + 24; // The 24px is to account for the flex-gap between elements and padding in the item itself.
            if (sumOfWidth > navRailRect.width - this.more.getBoundingClientRect().width) {
                cutOffIndex = i;
                break;
            }
        }
        for (let i = 0; i < items.length; i++) {
            const item = items[i];
            if (i < cutOffIndex) {
                item.removeAttribute('data-width');
                item.removeAttribute('slot');
                this.itemsInTempMoreSlot.delete(item);
            }
            if (i >= cutOffIndex) {
                if (!item.hasAttribute('data-width')) {
                    item.setAttribute('data-width', item.getBoundingClientRect().width.toString());
                }
                item.setAttribute('slot', 'temp-more');
                this.itemsInTempMoreSlot.add(item);
            }
            if (this.itemsInTempMoreSlot.size > 0) {
                this.setAttribute('data-has-temp-more-items', 'true');
            }
            else {
                this.removeAttribute('data-has-temp-more-items');
            }
            if (this.itemsInMoreSlot.size === 0 && this.itemsInTempMoreSlot.size === 0) {
                this.removeAttribute('data-has-more-items');
            }
            else {
                this.setAttribute('data-has-more-items', 'true');
            }
        }
    }
    handleKeyDown(event) {
        var _a;
        if (event.key === 'ArrowRight') {
            // I want to determine which is the currently focused chameleon-nav-bar-item or chameleon-button and shift focus to the next element
            const focusedElement = document.activeElement;
            if (focusedElement === this) {
                const firstElement = Array.from(this.items)[0];
                firstElement.focus();
                return;
            }
            const nextElement = focusedElement.nextElementSibling;
            if (nextElement && !nextElement.hasAttribute('slot')) {
                nextElement.focus();
            }
            else {
                this.more.focus();
            }
        }
        if (event.key === 'ArrowLeft') {
            if (((_a = this.shadowRoot) === null || _a === void 0 ? void 0 : _a.activeElement) === this.more) {
                const lastElement = Array.from(this.items).pop();
                if (lastElement) {
                    lastElement.focus();
                }
                return true;
            }
            const focusedElement = document.activeElement;
            if (focusedElement === this) {
                this.more.focus();
                return true;
            }
            const prevElement = focusedElement.previousElementSibling;
            if (prevElement && !prevElement.hasAttribute('slot')) {
                prevElement.focus();
            }
            else {
                this.more.focus();
            }
        }
        return true;
    }
    handleDropdownKeyDown(event) {
        var _a;
        if (event.key === 'ArrowDown') {
            if (((_a = this.shadowRoot) === null || _a === void 0 ? void 0 : _a.activeElement) === this.popoverEl) {
                const firstElement = Array.from(this.querySelectorAll('chameleon-nav-bar-item[slot="more"],chameleon-nav-bar-item[slot="temp-more"]')).at(0);
                if (firstElement) {
                    firstElement.focus();
                }
                return true;
            }
            // Determine if the currently focused element is a chameleon-nav-bar-item with a slot attribute of "more" or "temp-more"
            // if it is, find the next item and focus it
            const focusedElement = document.activeElement;
            if (!focusedElement.tagName.includes('NAV-BAR-ITEM') || !focusedElement.hasAttribute('slot')) {
                return true;
            }
            const nextElement = focusedElement.nextElementSibling;
            if (!nextElement) {
                const firstElement = Array.from(this.querySelectorAll('chameleon-nav-bar-item[slot="more"],chameleon-nav-bar-item[slot="temp-more"]')).at(0);
                firstElement === null || firstElement === void 0 ? void 0 : firstElement.focus();
                return true;
            }
            nextElement.focus();
        }
        if (event.key === 'ArrowUp') {
            const focusedElement = document.activeElement;
            const prevElement = focusedElement.previousElementSibling;
            if (prevElement && (!prevElement.tagName.includes('NAV-BAR-ITEM') || !prevElement.hasAttribute('slot'))) {
                const lastElement = Array.from(this.querySelectorAll('chameleon-nav-bar-item[slot="more"],chameleon-nav-bar-item[slot="temp-more"]')).at(-1);
                lastElement === null || lastElement === void 0 ? void 0 : lastElement.focus();
                return true;
            }
            prevElement.focus();
        }
        return true;
    }
}
__decorate([
    attr({ attribute: 'more-label' })
], NavBarComponent.prototype, "moreLabel", void 0);
__decorate([
    attr
], NavBarComponent.prototype, "label", void 0);
__decorate([
    attr({ attribute: 'data-is-narrow', mode: 'boolean' })
], NavBarComponent.prototype, "dataIsNarrow", void 0);
__decorate([
    observable
], NavBarComponent.prototype, "items", void 0);
__decorate([
    observable
], NavBarComponent.prototype, "more", void 0);
__decorate([
    observable
], NavBarComponent.prototype, "popoverEl", void 0);
