import { __rest } from "tslib";
import React, { forwardRef } from 'react';
import { createReactWrapper } from '../common/PrefixUtils';
import { addExtraProps } from '../common/utils/AddExtraProps';
export const Link = forwardRef((_a, ref) => {
    var { children, leadingIcon, trailingIcon } = _a, props = __rest(_a, ["children", "leadingIcon", "trailingIcon"]);
    return createReactWrapper({
        tagName: 'chameleon-link',
        props: Object.assign(Object.assign({}, props), { ref }),
        children: (React.createElement(React.Fragment, null,
            leadingIcon && addExtraProps(leadingIcon, { slot: 'start' }),
            children,
            trailingIcon && addExtraProps(trailingIcon, { slot: 'end' }))),
    });
});
