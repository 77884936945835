import { __decorate } from "tslib";
import { attr, html, observable, ref } from '@microsoft/fast-element';
import { ChameleonElementMixin } from '../common/mixin';
import { FoundationElement } from '@microsoft/fast-foundation';
const MAX_ITEM_TO_SHOW = 4;
export const breadcrumbTemplate = html `
  <div role="list" class="list" part="list">
    <slot></slot>
    <chameleon-breadcrumb-item ${ref('menuBreadcrumbItem')} class="menu">
      <span id="trigger">...</span>
      <chameleon-popover-v2 trigger-id="trigger" menu>
        <chameleon-menu ${ref('menu')}></chameleon-menu>
      </chameleon-popover-v2>
    </chameleon-breadcrumb-item>
    <slot name="after"></slot>
  </div>
`;
export class BreadcrumbComponent extends ChameleonElementMixin(FoundationElement) {
    variantChanged(_, next) {
        if (!this.isConnected) {
            return;
        }
        const children = Array.from(this.children);
        if (next === 'overflow-content' && children.length > MAX_ITEM_TO_SHOW) {
            this.distributeItemsToSlots();
        }
    }
    constructor() {
        super();
        this.variant = 'default';
        this.items = [];
        this.childrenObserverCallback = (records) => {
            if (!this.isConnected) {
                return;
            }
            for (const record of records) {
                this.items = Array.from(record.target.children);
                this.distributeItemsToSlots();
            }
        };
        this.childrenObserver = new MutationObserver(this.childrenObserverCallback);
    }
    connectedCallback() {
        super.connectedCallback();
        this.childrenObserver.observe(this, { childList: true });
        this.items = Array.from(this.children);
        this.distributeItemsToSlots();
    }
    disconnectedCallback() {
        this.childrenObserver.disconnect();
    }
    distributeItemsToSlots() {
        var _a, _b;
        if (this.items.length <= MAX_ITEM_TO_SHOW) {
            this.menuBreadcrumbItem.classList.remove('visible');
            for (let i = 0; i < this.items.length; i++) {
                const breadcrumbItem = this.items[i];
                breadcrumbItem.separator = true;
                breadcrumbItem.removeAttribute('slot');
                if (breadcrumbItem.hasAttribute('data-href')) {
                    breadcrumbItem.setAttribute('href', breadcrumbItem.getAttribute('data-href'));
                    breadcrumbItem.removeAttribute('data-href');
                }
                if (i === this.items.length - 1) {
                    breadcrumbItem.separator = false;
                    if (breadcrumbItem.hasAttribute('href')) {
                        breadcrumbItem.setAttribute('data-href', (_a = breadcrumbItem.getAttribute('href')) !== null && _a !== void 0 ? _a : '');
                        breadcrumbItem.removeAttribute('href');
                    }
                }
            }
        }
        else {
            this.menuBreadcrumbItem.classList.add('visible');
            // we remove every element from the menu, because later we recreate them in the loop.
            while (this.menu.firstChild) {
                this.menu.removeChild(this.menu.firstChild);
            }
            for (let i = 0; i < this.items.length; i++) {
                const breadcrumbItem = this.items[i];
                if (i < this.items.length - 2) {
                    breadcrumbItem.removeAttribute('slot');
                }
                if (i > 0 && i < this.items.length - 2) {
                    const menuItem = document.createElement('chameleon-menu-item');
                    const slot = document.createElement('slot');
                    slot.setAttribute('name', `menu-item-${i}`);
                    menuItem.appendChild(slot);
                    this.menu.appendChild(menuItem);
                    breadcrumbItem.setAttribute('slot', `menu-item-${i}`);
                    breadcrumbItem.separator = false;
                }
                if (i >= this.items.length - 2) {
                    breadcrumbItem.setAttribute('slot', 'after');
                    breadcrumbItem.separator = true;
                    if (breadcrumbItem.hasAttribute('data-href')) {
                        breadcrumbItem.setAttribute('href', breadcrumbItem.getAttribute('data-href'));
                        breadcrumbItem.removeAttribute('data-href');
                    }
                }
                if (i === this.items.length - 1) {
                    breadcrumbItem.separator = false;
                    if (breadcrumbItem.hasAttribute('href')) {
                        breadcrumbItem.setAttribute('data-href', (_b = breadcrumbItem.getAttribute('href')) !== null && _b !== void 0 ? _b : '');
                        breadcrumbItem.removeAttribute('href');
                    }
                }
            }
        }
    }
}
__decorate([
    attr()
], BreadcrumbComponent.prototype, "variant", void 0);
__decorate([
    observable
], BreadcrumbComponent.prototype, "menuBreadcrumbItem", void 0);
__decorate([
    observable
], BreadcrumbComponent.prototype, "menu", void 0);
