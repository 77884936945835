import { FileThumbnailComponent, fileThumbnailTemplate } from './FileThumbnail';
import styles from './FileThumbnail.styles.scss';
export const fileThumbnail = FileThumbnailComponent.compose({
    baseName: 'file-thumbnail',
    template: fileThumbnailTemplate,
    styles,
    shadowOptions: {
        delegatesFocus: true,
    },
});
