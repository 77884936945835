import Token from './token';

interface StorageOptions {
  /** Key to store the token under in localStorage. Defaults to "auth-client-token". */
  key?: string;
  /** Logger instance to use for errors. Defaults to console. */
  logger?: {
    error: (...args: any[]) => any;
  };
}

const defaultOptions = {
  key: 'auth-client-token',
  logger: console,
};

/** Get token from localStorage. */
export const getTokenFromStorage = (options?: StorageOptions): Token | undefined => {
  const o = { ...defaultOptions, ...options };
  try {
    const stored = localStorage.getItem(o.key);
    if (!stored) {
      return;
    }
    const tokenish = JSON.parse(stored);
    const token = new Token(tokenish);
    return token;
  } catch (e) {
    o.logger.error(`Token could not be retrieved: ${e.message}`);
  }
};

/** Store token in localStorage. */
export const setTokenInStorage = (token: Token, options?: StorageOptions) => {
  if (!token) {
    return;
  }
  const o = { ...defaultOptions, ...options };
  try {
    const serialized = JSON.stringify(token);
    localStorage.setItem(o.key, serialized);
  } catch (e) {
    o.logger.error(`Token could not be stored: ${e.message}`);
  }
};

/** Removes token from localStorage. */
export const removeTokenFromStorage = (options?: StorageOptions) => {
  const o = { ...defaultOptions, ...options };
  try {
    localStorage.removeItem(o.key);
  } catch (e) {
    o.logger.error(`Token could not be removed: ${e.message}`);
  }
};
