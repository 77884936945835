import { MenuSectionComponent } from './MenuSection';
import { html } from '@microsoft/fast-element';
import styles from './MenuSection.styles.scss';
const template = html `<chameleon-typography
  variant="caption-medium-strong"
  color="type-color-secondary"
>
  <slot></slot>
</chameleon-typography>`;
export const menuSectionDefinition = {
    baseName: 'menu-section',
    template,
    styles,
};
export const chameleonMenuSection = MenuSectionComponent.compose(menuSectionDefinition);
