import { __decorate } from "tslib";
import { attr, html, when, ref } from '@microsoft/fast-element';
import { SVG_ADOBE, SVG_SMARTPHONE_OUTLINED, SVG_VIEW_SWITCH_EVERYONE_FILLED, SVG_PRESENTATION_FILLED, SVG_MUSIC_FILLED, SVG_SCREEN_MIRRORING_OUTLINED, SVG_PLAY_FILLED, SVG_SKETCH, SVG_PICTURE_FILLED, SVG_CODE_OUTLINED, SVG_CALENDAR_FILLED, SVG_PERSON_FILLED, SVG_FONT_OUTLINED, SVG_ZIP_OUTLINED, SVG_LOUDSPEAKER_OUTLINED, SVG_LINK_OUTLINED, SVG_NOTE_OUTLINED, SVG_MESSAGE_FILLED, SVG_EDUCATION_FILLED, SVG_PHONE_UP_FILLED, SVG_WEBCAM_ON_FILLED, SVG_ROOM_PHYSICAL_FILLED, SVG_HIGHLIGHTER_OUTLINED, SVG_CAMERA_ROOM_VIEW_OUTLINED, SVG_REMOTE_SUPPORT_OUTLINED, SVG_TICKET_OUTLINED, SVG_CAMERA_SHARE_OUTLINED, SVG_GOTO, SVG_VECTOR_OUTLINED, SVG_MOBILE_SUPPORT_OUTLINED, SVG_MOBILE_DEVICE_MANAGEMENT_OUTLINED, } from '@getgo/chameleon-icons';
import { FoundationElement } from '@microsoft/fast-foundation';
import { ChameleonElementMixin } from '../common/mixin';
import { DEV } from 'esm-env';
export const iconBadgeTemplate = html `${when((x) => x.type, () => html `<chameleon-svg>${(x) => html `${x.getIcon()}`}</chameleon-svg>`)}`;
export const iconBadgeTemplateV2 = html `${when((x) => x.colorToken, () => html `<slot ${ref('content')} @slotchange="${(x) => x.handleContentChange()}"></slot>`)}`;
export class IconBadgeComponent extends ChameleonElementMixin(FoundationElement) {
    constructor() {
        super(...arguments);
        this.size = 'medium';
    }
    getIcon() {
        switch (this.getAttribute('type')) {
            case 'assist':
                return SVG_SCREEN_MIRRORING_OUTLINED;
            case 'assistmobile':
                return SVG_SMARTPHONE_OUTLINED;
            case 'webinar':
                return SVG_LOUDSPEAKER_OUTLINED;
            case 'document':
                return SVG_NOTE_OUTLINED;
            case 'pdf':
                return SVG_ADOBE;
            case 'partner':
                return SVG_GOTO;
            case 'spreadsheet':
                return SVG_VIEW_SWITCH_EVERYONE_FILLED;
            case 'presentation':
                return SVG_PRESENTATION_FILLED;
            case 'audio':
                return SVG_MUSIC_FILLED;
            case 'video':
                return SVG_PLAY_FILLED;
            case 'sketch':
                return SVG_SKETCH;
            case 'image':
                return SVG_PICTURE_FILLED;
            case 'code':
                return SVG_CODE_OUTLINED;
            case 'calendar':
                return SVG_CALENDAR_FILLED;
            case 'contact':
                return SVG_PERSON_FILLED;
            case 'font':
                return SVG_FONT_OUTLINED;
            case 'zip':
                return SVG_ZIP_OUTLINED;
            case 'vector':
                return SVG_VECTOR_OUTLINED;
            case 'link':
                return SVG_LINK_OUTLINED;
            case 'messaging':
                return SVG_MESSAGE_FILLED;
            case 'call':
                return SVG_PHONE_UP_FILLED;
            case 'meeting':
                return SVG_WEBCAM_ON_FILLED;
            case 'training':
                return SVG_EDUCATION_FILLED;
            case 'room':
                return SVG_ROOM_PHYSICAL_FILLED;
            case 'highlighter':
                return SVG_HIGHLIGHTER_OUTLINED;
            case 'custom':
                return SVG_CAMERA_ROOM_VIEW_OUTLINED;
            case 'gotoresolveremotesupport':
                return SVG_REMOTE_SUPPORT_OUTLINED;
            case 'gotoresolvemobile':
                return SVG_MOBILE_SUPPORT_OUTLINED;
            case 'gotoresolvemdm':
                return SVG_MOBILE_DEVICE_MANAGEMENT_OUTLINED;
            case 'gotoresolvehelpdesk':
                return SVG_TICKET_OUTLINED;
            case 'gotoresolvecamerashare':
                return SVG_CAMERA_SHARE_OUTLINED;
            case 'gotoresolve':
                return SVG_GOTO;
        }
        return '';
    }
}
__decorate([
    attr
], IconBadgeComponent.prototype, "type", void 0);
__decorate([
    attr
], IconBadgeComponent.prototype, "size", void 0);
__decorate([
    attr({ mode: 'boolean' })
], IconBadgeComponent.prototype, "disabled", void 0);
export class IconBadgeComponentV2 extends ChameleonElementMixin(FoundationElement) {
    constructor() {
        super(...arguments);
        this.size = 'medium';
        this.border = 'none';
        this.variant = 'primary';
    }
    connectedCallback() {
        super.connectedCallback();
        this.setAccessibleLabel();
    }
    labelChanged() {
        this.setAccessibleLabel();
    }
    colorTokenChanged(_, newValue) {
        if (newValue) {
            this.style.setProperty('--goto-badge-bg-color', `var(--${newValue})`);
        }
        else {
            this.style.setProperty('--goto-badge-bg-color', ``);
        }
    }
    handleContentChange() {
        var _a;
        (_a = this.content.assignedNodes()[0].style) === null || _a === void 0 ? void 0 : _a.setProperty('--goto-icon-size', this.getIconSize(this.size));
    }
    getIconSize(size) {
        switch (size) {
            case 'small':
                return '16px';
            case 'xlarge':
                return '32px';
            default:
                return '24px';
        }
    }
    setAccessibleLabel() {
        if (this.label) {
            this.setAttribute('aria-label', this.label);
            return;
        }
        if (DEV) {
            this.validateAccessibleLabel();
        }
    }
    validateAccessibleLabel() {
        var _a;
        if (!((_a = this.getAttribute('aria-label')) === null || _a === void 0 ? void 0 : _a.length)) {
            console.error('CHAMELEON-A11Y: IconBadge component must have an accessible label provided by a non-empty label attribute to be used as aria-label.');
        }
    }
}
__decorate([
    attr
], IconBadgeComponentV2.prototype, "size", void 0);
__decorate([
    attr
], IconBadgeComponentV2.prototype, "border", void 0);
__decorate([
    attr()
], IconBadgeComponentV2.prototype, "variant", void 0);
__decorate([
    attr({ mode: 'boolean' })
], IconBadgeComponentV2.prototype, "disabled", void 0);
__decorate([
    attr({ attribute: 'color-token' })
], IconBadgeComponentV2.prototype, "colorToken", void 0);
__decorate([
    attr()
], IconBadgeComponentV2.prototype, "label", void 0);
