import { FC, useState } from 'react';
import { useIntl } from 'react-intl';
import { Button, MenuSeparator, TextField } from '@getgo/chameleon-web-react-wrapper';
import { Form, Formik } from 'formik';

import DirectDebitMandate from 'components/direct-debit-mandate';
import { useAppDispatch, useAppSelector } from 'hooks';
import { showErrorSnack } from 'modules/error';
import { postPaymentMethods, setChosenPaymentMethodKey, setPayNowIntent } from 'modules/payment-methods';
import { sessionIntent, sessionSuccessRedirectUrl } from 'modules/session-details';
import Track, { SEPASaveCTA } from 'modules/tracking';
import { postTransactionAddDD } from 'modules/transaction-dd';
import { AddDDPayload } from 'types/transaction-dd';
import { COPAS_PAYMENT_INTENT, COPAS_PAYMENT_STATUS, SEARCH_PARAMS, TRACKING_EVENT_STATUS } from 'utils/constants';
import { getSepaFormValidationSchema } from 'utils/direct-debit-utils';
import st from 'utils/shared-translations';

import './direct-debit-form.css';

const DirectDebitForm: FC = (): JSX.Element => {
  const intl = useIntl();
  const dispatch = useAppDispatch();

  const [isDDMandate, setDDMandate] = useState(false);
  const [isDefault, setDefault] = useState(false);

  const locale = localStorage.getItem(SEARCH_PARAMS.locale);

  const selectedSuccessRedirectUrl = useAppSelector(sessionSuccessRedirectUrl);
  const selectedSessionIntent = useAppSelector(sessionIntent);

  const handleOnSubmit = (values, { setSubmitting }) => {
    Track(SEPASaveCTA, { status: TRACKING_EVENT_STATUS.click, isDefault });

    const payload: AddDDPayload = {
      ...values,
      language: locale?.split('_')?.[0] || 'en',
      setAsDefault: isDefault,
    };

    dispatch(postTransactionAddDD(payload))
      .unwrap()
      .then((resp) => {
        if (resp.status === COPAS_PAYMENT_STATUS.FAILED) {
          Track(SEPASaveCTA, { status: TRACKING_EVENT_STATUS.copasFailure });
          setSubmitting(false);
          dispatch(showErrorSnack(st['alert.error.paymentmethod.general.failure']));
          return;
        }
        if (selectedSessionIntent === COPAS_PAYMENT_INTENT.PAYMENT_OR_PAYMENT_METHOD_CHANGE) {
          dispatch(postPaymentMethods()).then(() => {
            dispatch(setChosenPaymentMethodKey(resp.paymentMethodKey));
            dispatch(setPayNowIntent(''));
          });
        } else {
          localStorage.clear();
          window.location.assign(selectedSuccessRedirectUrl);
        }
      })
      .catch(() => {
        setDDMandate(false);
        setSubmitting(false);
        setDefault(false);
        dispatch(showErrorSnack(st['alert.error.paymentmethod.general.failure']));
      });
    return;
  };

  return (
    <Formik initialValues={{ iban: '' }} validationSchema={getSepaFormValidationSchema(intl)} onSubmit={handleOnSubmit}>
      {({ values, isValid, errors, handleChange, isSubmitting, dirty, touched, setFieldTouched }) => (
        <Form spellCheck={false}>
          <div className="direct-debit-form__iban">
            <TextField
              name="iban"
              className="direct-debit-form__input"
              fieldsize="medium"
              value={values.iban}
              onBlur={() => setFieldTouched('iban', true)}
              labelId="iban-number"
              onChange={handleChange}
              disabled={isDDMandate}
              helperText={touched.iban && errors.iban}
              error={touched.iban && !!errors.iban}
              fullwidth
            >
              {intl.formatMessage(st['dd.iban.account.number'])}
            </TextField>
            <Button
              className="direct-debit-form__cta"
              style={{ alignSelf: errors.iban && touched.iban ? 'center' : 'end' }}
              onClick={() => setDDMandate(true)}
              disabled={isDDMandate || !isValid || isSubmitting || !dirty}
            >
              {intl.formatMessage(st['dd.form.cta.validate'])}
            </Button>
          </div>
          {isDDMandate && (
            <>
              <MenuSeparator className="direct-debit-form__menu-separator" />
              <DirectDebitMandate
                handleCancelMandate={setDDMandate}
                ibanNumber={values.iban}
                isSubmitting={isSubmitting}
                isDefault={isDefault}
                setDefault={setDefault}
                isValid={isValid}
                dirty={dirty}
              />
            </>
          )}
        </Form>
      )}
    </Formik>
  );
};

export default DirectDebitForm;
