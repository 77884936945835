import { __decorate } from "tslib";
import { attr, html } from '@microsoft/fast-element';
import { FoundationElement } from '@microsoft/fast-foundation';
import { ChameleonElementMixin } from '../common/mixin';
export const contextualActionsTemplate = html `<slot></slot>`;
export class ContextualActionsComponent extends ChameleonElementMixin(FoundationElement) {
}
__decorate([
    attr
], ContextualActionsComponent.prototype, "variant", void 0);
