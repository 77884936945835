import { __decorate } from "tslib";
import { attr } from '@microsoft/fast-element';
import { TextArea } from '@microsoft/fast-foundation';
import { ChameleonElementMixin } from '../common/mixin';
import { uniqueId } from '@microsoft/fast-web-utilities';
import { DEV } from 'esm-env';
export class TextAreaComponent extends ChameleonElementMixin(TextArea) {
    constructor() {
        super(...arguments);
        this.success = false;
        this.error = false;
        this.fullwidth = false;
        this.hidecounter = false;
        this.optional = false;
        this.implicitSubmission = false;
        this.resize = 'both';
        this.infoContainer = document.createElement('div');
        this.counterEl = document.createElement('span');
        this.helperTextEl = document.createElement('slot');
        /**
         * By default textarea do not support implicit submission, but in FAST since the component inherit FormAssociated it does.
         * https://github.com/microsoft/fast/blob/b78c921ec4e49ec9d7ec980f079ec114045df42e/packages/web-components/fast-foundation/src/form-associated/form-associated.ts
         */
        this.handleImplicitSubmission = (e) => {
            if (e.key === 'Enter') {
                if (!this.implicitSubmission) {
                    e.stopPropagation();
                    return true;
                }
            }
        };
        this.handleInput = (e) => {
            const { value } = e === null || e === void 0 ? void 0 : e.target;
            if (this.maxlength && !this.hidecounter) {
                this.counterEl.textContent = `${value.length}/${this.maxlength}`;
            }
        };
        this.handleHelperTextSlotChange = () => {
            this.render();
            const helperText = this.helperTextEl.assignedElements()[0];
            if (!helperText || !helperText.textContent) {
                return;
            }
            this.control.setAttribute('aria-describedby', helperText.id);
        };
    }
    connectedCallback() {
        var _a;
        super.connectedCallback();
        this.control.addEventListener('input', this.handleInput);
        this.control.addEventListener('keypress', this.handleImplicitSubmission);
        this.infoContainer.classList.add('info');
        this.counterEl.classList.add('counter');
        (_a = this.shadowRoot) === null || _a === void 0 ? void 0 : _a.appendChild(this.infoContainer);
        this.addHelperText();
        this.addIdForLabel();
        this.setAccessibleLabel();
        this.render();
    }
    disconnectedCallback() {
        this.control.removeEventListener('input', this.handleInput);
        this.control.removeEventListener('keypress', this.handleImplicitSubmission);
        this.helperTextEl.removeEventListener('slotchange', this.handleHelperTextSlotChange);
    }
    // we cannot add a maxlengthChanged method since it's `private` in FAST and would throw several TS erros
    // this is a workaround for that.
    attributeChangedCallback(name, oldValue, newValue) {
        var _a, _b, _c, _d, _e;
        super.attributeChangedCallback(name, oldValue, newValue);
        if (name === 'readonly' && newValue) {
            this.setAttribute('aria-readonly', 'true');
        }
        else if (name === 'readonly') {
            this.removeAttribute('aria-readonly');
        }
        if (this.hasAttribute('disabled')) {
            requestAnimationFrame(() => {
                var _a;
                (_a = this.control) === null || _a === void 0 ? void 0 : _a.removeAttribute('disabled');
            });
        }
        (_a = this.control) === null || _a === void 0 ? void 0 : _a.setAttribute('aria-disabled', `${this.hasAttribute('disabled')}`);
        (_b = this.control) === null || _b === void 0 ? void 0 : _b.addEventListener('keydown', (event) => {
            if (this.hasAttribute('disabled') && event.key !== 'Tab') {
                event.preventDefault();
            }
        });
        if (this.optional || ((_c = this.textContent) === null || _c === void 0 ? void 0 : _c.includes('optional'))) {
            (_d = this.control) === null || _d === void 0 ? void 0 : _d.removeAttribute('aria-required');
        }
        else {
            (_e = this.control) === null || _e === void 0 ? void 0 : _e.setAttribute('aria-required', 'true');
        }
        this.render();
    }
    addIdForLabel() {
        var _a;
        const label = (_a = this.shadowRoot) === null || _a === void 0 ? void 0 : _a.querySelector('label');
        if (label) {
            label.id = uniqueId('label-');
            this.control.setAttribute('aria-labelledby', label.id);
        }
    }
    render() {
        if (this.maxlength && !this.hidecounter) {
            this.renderCounter();
        }
        else {
            this.removeCounter();
        }
    }
    hasHelperText() {
        const helperText = this.querySelector('[slot="helpertext"]');
        return helperText !== null;
    }
    addHelperText() {
        var _a, _b;
        this.helperTextEl.setAttribute('name', 'helpertext');
        this.helperTextEl.setAttribute('id', uniqueId('helpertext-'));
        this.helperTextEl.addEventListener('slotchange', this.handleHelperTextSlotChange);
        const helperText = (_a = this.shadowRoot) === null || _a === void 0 ? void 0 : _a.querySelector('[name="helpertext"]');
        if (!helperText) {
            (_b = this.infoContainer) === null || _b === void 0 ? void 0 : _b.prepend(this.helperTextEl);
        }
    }
    renderCounter() {
        var _a;
        if (!this.maxlength) {
            return;
        }
        if (!this.hasHelperText()) {
            this.infoContainer.style.justifyContent = 'flex-end';
        }
        else {
            this.infoContainer.style.justifyContent = 'space-between';
        }
        this.counterEl.textContent = `${this.value.length}/${this.maxlength}`;
        (_a = this.infoContainer) === null || _a === void 0 ? void 0 : _a.appendChild(this.counterEl);
    }
    removeCounter() {
        var _a;
        if ((_a = this.counterEl) === null || _a === void 0 ? void 0 : _a.isConnected) {
            this.infoContainer.style.justifyContent = '';
            this.counterEl.remove();
        }
    }
    setAccessibleLabel() {
        var _a, _b, _c;
        if (((_a = this.textContent) === null || _a === void 0 ? void 0 : _a.trim()) && ((_b = this.helperTextEl.assignedElements()[0]) === null || _b === void 0 ? void 0 : _b.textContent) !== ((_c = this.textContent) === null || _c === void 0 ? void 0 : _c.trim())) {
            return;
        }
        if (this.labelId) {
            this.setAttribute('aria-labelledby', this.labelId);
            return;
        }
        if (DEV) {
            this.validateAccessibleLabel();
        }
    }
    validateAccessibleLabel() {
        var _a;
        if (!((_a = this.getAttribute('aria-labelledby')) === null || _a === void 0 ? void 0 : _a.length)) {
            console.error(`CHAMELEON-A11Y: TextArea component must have an accessible label provided by either 
  1) text content 
  2) a visible label whose id is given as the attribute labelId
`);
        }
    }
}
__decorate([
    attr({ mode: 'boolean' })
], TextAreaComponent.prototype, "success", void 0);
__decorate([
    attr({ mode: 'boolean' })
], TextAreaComponent.prototype, "error", void 0);
__decorate([
    attr({ mode: 'boolean' })
], TextAreaComponent.prototype, "fullwidth", void 0);
__decorate([
    attr({ mode: 'boolean', attribute: 'hide-counter' })
], TextAreaComponent.prototype, "hidecounter", void 0);
__decorate([
    attr({ mode: 'boolean' })
], TextAreaComponent.prototype, "optional", void 0);
__decorate([
    attr({ mode: 'boolean', attribute: 'implicit-submission' })
], TextAreaComponent.prototype, "implicitSubmission", void 0);
__decorate([
    attr({ attribute: 'label-id' })
], TextAreaComponent.prototype, "labelId", void 0);
__decorate([
    attr
], TextAreaComponent.prototype, "resize", void 0);
