import { DesignSystemProvider } from '@microsoft/fast-components';
import { css, html } from '@microsoft/fast-element';
import { ThemeProviderComponent } from './ThemeProvider';
import styles from './ThemeProvider.styles.scss';
export const chameleonThemeProvider = ThemeProviderComponent.compose({
    baseName: 'theme-provider',
    baseClass: DesignSystemProvider,
    template: html `<slot></slot>`,
    styles: css `
    ${styles}
  `,
});
