import { provideChameleonDesignSystem } from '@getgo/chameleon-toolkit';
import { chameleonMenu } from './Menu.definition';
import { chameleonMenuItem } from './MenuItem/MenuItem.definition';
import { chameleonMenuSection } from './MenuSection/MenuSection.definition';
import { chameleonMenuSeparator } from './MenuSeparator/MenuSeparator.definition';
import { chameleonAnchorRegion } from '../AnchoredRegion';
import { register as registerTypography } from '@getgo/chameleon-typography/register';
export function registerCustom(prefix, node) {
    registerTypography({ prefix, node });
    return provideChameleonDesignSystem({ prefix, node }).register(chameleonMenu(), chameleonMenuItem(), chameleonMenuSeparator(), chameleonMenuSection(), chameleonAnchorRegion());
}
