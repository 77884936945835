import { __decorate } from "tslib";
import { attr } from '@microsoft/fast-element';
import { Anchor } from '@microsoft/fast-foundation';
import { ChameleonElementMixin } from '../common/mixin';
export class LinkComponent extends ChameleonElementMixin(Anchor) {
    constructor() {
        super(...arguments);
        this.appearance = 'link';
        this.size = 'medium';
        this.disabled = false;
        this.color = '';
    }
    colorChanged(_oldValue, newValue) {
        if (!!newValue && _oldValue !== newValue) {
            this.style.setProperty('--goto-link-color', `${this.color}`);
        }
    }
}
__decorate([
    attr
], LinkComponent.prototype, "variant", void 0);
__decorate([
    attr
], LinkComponent.prototype, "appearance", void 0);
__decorate([
    attr
], LinkComponent.prototype, "size", void 0);
__decorate([
    attr({ mode: 'boolean' })
], LinkComponent.prototype, "disabled", void 0);
__decorate([
    attr
], LinkComponent.prototype, "color", void 0);
