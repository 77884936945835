import { __rest } from "tslib";
import React, { forwardRef, useEffect, useRef } from 'react';
import { createReactWrapper } from '../common/PrefixUtils';
import { handleForwardRef } from '../common/RefUtils';
export const Dialog = forwardRef((_a, ref) => {
    var { actions, open, title, children, closeButtonLabel, onClosing, onClosed } = _a, props = __rest(_a, ["actions", "open", "title", "children", "closeButtonLabel", "onClosing", "onClosed"]);
    const dialogRef = useRef();
    const handledRef = handleForwardRef(dialogRef, ref);
    const handleClosingListener = () => {
        onClosing === null || onClosing === void 0 ? void 0 : onClosing();
    };
    const handleClosedListener = () => {
        onClosed === null || onClosed === void 0 ? void 0 : onClosed();
    };
    useEffect(() => {
        var _a, _b;
        (_a = dialogRef === null || dialogRef === void 0 ? void 0 : dialogRef.current) === null || _a === void 0 ? void 0 : _a.addEventListener('closing', handleClosingListener);
        (_b = dialogRef === null || dialogRef === void 0 ? void 0 : dialogRef.current) === null || _b === void 0 ? void 0 : _b.addEventListener('closed', handleClosedListener);
        return () => {
            var _a, _b;
            (_a = dialogRef === null || dialogRef === void 0 ? void 0 : dialogRef.current) === null || _a === void 0 ? void 0 : _a.removeEventListener('closing', handleClosingListener);
            (_b = dialogRef === null || dialogRef === void 0 ? void 0 : dialogRef.current) === null || _b === void 0 ? void 0 : _b.removeEventListener('closed', handleClosedListener);
        };
    }, [ref, onClosing, onClosed]);
    return createReactWrapper({
        tagName: 'chameleon-dialog',
        props: Object.assign(Object.assign({}, props), { ref: handledRef, open: !!open, 'close-button-label': closeButtonLabel }),
        children: (React.createElement(React.Fragment, null,
            React.createElement("span", { slot: "title" }, title),
            children,
            React.createElement("div", { slot: "actions" }, actions))),
    });
});
