import { __decorate } from "tslib";
import { attr, html, observable, ref, slotted } from '@microsoft/fast-element';
import { FoundationElement } from '@microsoft/fast-foundation';
import { ChameleonElementMixin } from '../../common/mixin';
import { SVG_EXTERNAL_LINK_OUTLINED } from '@getgo/chameleon-icons';
export const chameleonNavBarItemTemplate = html `
  <template role="listitem">
    <a id="anchor" part="anchor" ${ref('anchor')} href="${(x) => x.href}" target="${(x) => x.target}" aria-label="${(x) => x.label}" aria-current="${(x) => (x.active ? 'page' : null)}">
      <span class="icon">
        <slot name="icon"></slot>
      </span>
      <span class="default">
        <slot ${slotted('defaultSlot')}></slot>
      </span>
      <slot name="badge"></slot>
      <chameleon-svg widht="20" height="20" class="external">${SVG_EXTERNAL_LINK_OUTLINED}</chameleon-svg>
    </a>
    <chameleon-tooltip-v3 ?hidden="${(x) => !x.dataIsNarrow}" trigger-id="anchor">
      ${(x) => x.label}
    </chameleon-tooltip-v3>
  </template>
`;
/**
 * Takes an array of text nodes, trims their textContent and joins them together
 * */
function getCleanTextContent(nodes) {
    return nodes
        .filter((node) => node.nodeType === Node.TEXT_NODE) // Filter out only text nodes
        .map((node) => { var _a, _b; return (_b = (_a = node.textContent) === null || _a === void 0 ? void 0 : _a.trim()) !== null && _b !== void 0 ? _b : ''; }) // Trim whitespace from each text node
        .join(' '); // Join all text content with a space, preserving words separation
}
export class NavBarItemComponent extends ChameleonElementMixin(FoundationElement) {
    constructor() {
        super(...arguments);
        this.href = '';
        this.target = '';
        this.active = false;
        this.external = false;
        /**
         * @internal
         *
         * This is set by the nav-bar element, should not be documented and should not
         * be set by end users.
         * */
        this.dataIsNarrow = false;
        this.label = '';
        this.defaultSlot = [];
    }
    selectedChanged(_, newValue) {
        if (newValue) {
            this.setAttribute('aria-current', 'page');
        }
        else {
            this.removeAttribute('aria-current');
        }
    }
    defaultSlotChanged() {
        if (!this.isConnected) {
            return;
        }
        this.label = getCleanTextContent(this.defaultSlot);
    }
    connectedCallback() {
        super.connectedCallback();
        if (!this.target) {
            this.target = this.external ? '_blank' : '_self';
        }
    }
}
__decorate([
    attr
], NavBarItemComponent.prototype, "href", void 0);
__decorate([
    attr
], NavBarItemComponent.prototype, "target", void 0);
__decorate([
    attr({ mode: 'boolean' })
], NavBarItemComponent.prototype, "active", void 0);
__decorate([
    attr({ mode: 'boolean' })
], NavBarItemComponent.prototype, "external", void 0);
__decorate([
    attr({ attribute: 'data-is-narrow', mode: 'boolean' })
], NavBarItemComponent.prototype, "dataIsNarrow", void 0);
__decorate([
    observable
], NavBarItemComponent.prototype, "label", void 0);
__decorate([
    observable
], NavBarItemComponent.prototype, "anchor", void 0);
__decorate([
    observable
], NavBarItemComponent.prototype, "defaultSlot", void 0);
