import { MenuItem, menuItemTemplate as template } from '@microsoft/fast-foundation';
import { NavigationDrawerItemComponent } from './NavigationDrawerItem';
import styles from './NavigationDrawerItem.styles.scss';
export const navigationDrawerItemDefinition = {
    baseName: 'navigation-drawer-item',
    baseClass: MenuItem,
    template: template,
    styles,
};
export const chameleonNavigationDrawerItem = NavigationDrawerItemComponent.compose(navigationDrawerItemDefinition);
