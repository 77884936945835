import { parse, stringify } from './utils/serialize';

/** Valid properties to read from a tokenish object into a Token object. */
const tokenProps = [
  '_expires_in', // restore value after serialized to storage
  'access_token',
  'client_authorities',
  'client_id',
  'expires_in',
  'expires',
  'id_token',
  'issued',
  'loa',
  'principal',
  'refresh_token',
  'scope',
  'state',
  'token_type',
  'user_account_authorities',
  'user_authorities',
  'user_id',
];

/**
 * Properties of a successful OAuth response.
 * @see https://www.oauth.com/oauth2-servers/access-tokens/access-token-response/
 */
export interface IToken extends IValidateToken {
  /** Token type. Currently the only supported type is "Bearer". */
  token_type?: string;
  /** Access token string. */
  access_token?: string;
  /**
   * Time in seconds until the token expires.
   * Used to calculate `expires` when token response is received, use `expires` instead.
   */
  expires_in?: number;
  /** Permissions scope for this token defined by the auth server. */
  scope?: string;
  /**
   * The principal (if any) associated with the token. For ex: the user email in case of the Resource Owner Password Grant.
   * @deprecated
   */
  principal?: string;
  /** Refresh token to request an updated access token after it expires. Not available for implicit grant flow. */
  refresh_token?: string;
  /** Optional state values to be passed along with the token. */
  state?: any;
}

/**
 * Extended token properties set by PKCE validate tokens.
 * @see https://confluence.ops.expertcity.com/pages/viewpage.action?spaceKey=SOA&title=OAuth+Service+API#OAuthServiceAPI-ValidateToken
 */
export interface IValidateToken {
  client_authorities?: string;
  user_authorities?: string[];
  user_account_authorities?: string[];
  client_id?: string[];
  id_token?: string;
  loa?: number;
  user_id?: string;
}

export type ITokenish = string | IToken | Token;

export default class Token implements IToken {
  public access_token?: string;
  /** Timestamp for when this token expires based on the original `expired_in` value. */
  public expires?: number;
  /** Timestamp for when this token was issued. */
  public issued?: number;
  public refresh_token?: string;
  public refresh?: number;
  public refreshPromise?: Promise<Token>;
  public refreshTimeout?: number;
  public scope?: string;
  public state?: any;
  public token_type = 'Bearer';

  private _expires_in?: number;

  constructor(tokenish?: ITokenish) {
    let obj: any;

    if (tokenish instanceof Token) {
      return tokenish;
    } else if (typeof tokenish === 'string') {
      obj = parse(tokenish);
    } else if (typeof tokenish === 'object' && tokenish) {
      obj = tokenish;
    } else {
      obj = {};
    }

    // Transfer known properties of a tokenish object into the class fields
    if (typeof obj === 'object') {
      tokenProps.forEach((prop) => {
        if (prop in obj) {
          this[prop] = obj[prop];
        }
      });
    }

    // Set properties for refresh logic so they will not be serialized to JSON
    ['refresh', 'refreshPromise', 'refreshTimeout'].forEach((prop) => {
      Object.defineProperty(this, prop, {
        configurable: false,
        enumerable: false,
        writable: true,
      });
    });
  }

  get expires_in() {
    return this._expires_in;
  }

  set expires_in(value: number | undefined) {
    // When given `expires_in` calculate `expires` and `issued`
    if (value && !Number.isNaN(Number(value))) {
      this._expires_in = Number(value);
      this.expires = Date.now() + 1000 * this._expires_in;
      this.issued = Date.now();
    } else {
      this._expires_in = undefined;
      this.expires = undefined;
    }
  }

  public isValid(buffer = 0) {
    return !!this.access_token && Number(this.expires) > Date.now() + buffer;
  }

  public logout() {
    clearTimeout(this.refreshTimeout);
    this.refresh = undefined;
    this.refreshPromise = undefined;
    this.refreshTimeout = undefined;
  }

  public startRefreshTimer(refreshTimestamp?: number | ((token: Token) => number)) {
    clearTimeout(this.refreshTimeout);

    if (typeof refreshTimestamp === 'function') {
      this.refresh = refreshTimestamp(this);
    } else {
      this.refresh = this.expires;
    }

    this.refreshPromise = new Promise((resolve) => {
      if (this.needsToRefresh()) {
        resolve(this);
      }
      this.refreshTimeout = setInterval(() => {
        if (this.needsToRefresh()) {
          clearInterval(this.refreshTimeout);
          resolve(this);
        }
      }, 5000) as unknown as number;
    });
    return this.refreshPromise;
  }

  public toString() {
    return stringify(this);
  }

  private needsToRefresh() {
    return (this.refresh || 0) - Date.now() < 0;
  }
}
