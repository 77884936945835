import styles from './NavBarItem.styles.scss';
import { NavBarItemComponent, chameleonNavBarItemTemplate } from './NavBarItem';
export const chameleonNavBarItemDefinition = {
    baseName: 'nav-bar-item',
    template: chameleonNavBarItemTemplate,
    styles,
    shadowOptions: {
        delegatesFocus: true,
    },
};
export const chameleonNavBarItem = NavBarItemComponent.compose(chameleonNavBarItemDefinition);
