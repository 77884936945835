import { CalendarComponent, calendarTemplate } from './Calendar';
import styles from './Calendar.styles.scss';
export const chameleonCalendar = CalendarComponent.compose({
    baseName: 'calendar',
    template: calendarTemplate,
    styles,
    shadowOptions: {
        delegatesFocus: true,
    },
});
