import { FC } from 'react';
import { Route, Routes } from 'react-router-dom';

import ErrorSnackbar from 'components/error-snackbar';
import PaymentFailure from 'components/feedback/payment-failure';
import PaymentSuccess from 'components/feedback/payment-success';
import ProcessQuoteLoader from 'components/process-quote-loader';
import PayNow from 'routes/pay-now';
import PaymentMethods from 'routes/payment-methods';
import { PAYMENT_METHODS_TYPES } from 'utils/constants';

const CoreView: FC = (): JSX.Element => (
  <>
    <ErrorSnackbar />
    <PaymentSuccess />
    <PaymentFailure />
    <ProcessQuoteLoader />
    <Routes>
      <Route
        path="/:accountKey/:sessionId/paymentmethod/cc"
        element={<PaymentMethods paymentMethodType={PAYMENT_METHODS_TYPES.cc} />}
      />
      <Route
        path="/:accountKey/:sessionId/paymentmethod/dd"
        element={<PaymentMethods paymentMethodType={PAYMENT_METHODS_TYPES.dd} />}
      />
      <Route
        path="/:accountKey/:sessionId/paymentmethod/ach"
        element={<PaymentMethods paymentMethodType={PAYMENT_METHODS_TYPES.ach} />}
      />
      <Route
        path="/:accountKey/:sessionId/paymentmethod/paypal"
        element={<PaymentMethods paymentMethodType={PAYMENT_METHODS_TYPES.paypal} />}
      />
      <Route path="/:accountKey/:sessionId/pay" element={<PayNow />} />
    </Routes>
  </>
);
export default CoreView;
