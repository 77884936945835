import { __decorate } from "tslib";
import { attr } from '@microsoft/fast-element';
import { ChameleonElementMixin } from '../common/mixin';
import { BaseProgress } from '@microsoft/fast-foundation';
export class CircularProgressComponent extends ChameleonElementMixin(BaseProgress) {
    constructor() {
        super(...arguments);
        this.size = 'large';
        this.inverse = false;
    }
}
__decorate([
    attr()
], CircularProgressComponent.prototype, "size", void 0);
__decorate([
    attr({ mode: 'boolean' })
], CircularProgressComponent.prototype, "inverse", void 0);
