import { __decorate } from "tslib";
import { Menu } from '@microsoft/fast-foundation';
import { ChameleonElementMixin } from '../common/mixin';
import { attr } from '@microsoft/fast-element';
import { DEV } from 'esm-env';
export class MenuComponent extends ChameleonElementMixin(Menu) {
    connectedCallback() {
        super.connectedCallback();
        this.setAccessibleLabel();
    }
    labelChanged() {
        if (this.isConnected) {
            this.setAccessibleLabel();
        }
    }
    labelIdChanged() {
        if (this.isConnected) {
            this.setAccessibleLabel();
        }
    }
    isSubmenu() {
        return this.getAttribute('slot') === 'submenu';
    }
    setAccessibleLabel() {
        if (this.labelId) {
            this.setAttribute('aria-labelledby', this.labelId);
            return;
        }
        if (this.label) {
            this.setAttribute('aria-label', this.label);
            return;
        }
        if (DEV) {
            this.validateAccessibleLabel();
        }
    }
    validateAccessibleLabel() {
        var _a, _b;
        if (!((_a = this.getAttribute('aria-label')) === null || _a === void 0 ? void 0 : _a.length) && !((_b = this.getAttribute('aria-labelledby')) === null || _b === void 0 ? void 0 : _b.length)) {
            if (this.isSubmenu()) {
                console.error(`CHAMELEON-A11Y: SubMenu component must have an accessible label provided by either 
    1) a visible label whose id is given as the attribute label-id/labelId
    2) a non-empty label attribute to be used as aria-label.
    `);
                return;
            }
            console.error(`CHAMELEON-A11Y: Menu component must have an accessible label provided by either 
    1) a visible label whose id is given as the attribute label-id/labelId
    2) a non-empty label attribute to be used as aria-label.
    `);
        }
    }
}
__decorate([
    attr
], MenuComponent.prototype, "label", void 0);
__decorate([
    attr({ attribute: 'label-id' })
], MenuComponent.prototype, "labelId", void 0);
