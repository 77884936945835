import { __decorate } from "tslib";
import { attr, html, observable, ref, slotted, when } from '@microsoft/fast-element';
import { FoundationElement } from '@microsoft/fast-foundation';
import { ChameleonElementMixin } from '../../common/mixin';
import { debounce } from 'lodash-es';
export const chatBubbleTemplate = html `<template tabindex="0">
  <div class="container">
    <slot name="contextual-actions"></slot>
    <div class="contents" ${ref('contents')}>
      <slot name="attachments"></slot>
      <span class="content-text"><slot ${slotted('defaultSlot')}></slot></span>
    </div>

    ${when((x) => x.unclamp === false && x.showTruncateAction, html `<chameleon-button @click=${(x) => x.clickTruncateHandler()} class="truncate-action" variant="tertiary">
        ${(x) => (x.expanded ? x.seeLess : x.seeMore)}
      </chameleon-button>`)}
  </div>
  <div class="reactions" ${ref('reactionsContainer')}>
    <slot name="reactions" ${slotted('reactions')}></slot>
  </div>
</template>`;
export class ChatBubbleComponent extends ChameleonElementMixin(FoundationElement) {
    constructor() {
        super();
        this.variant = 'default';
        this.type = 'inbound';
        this.first = false;
        this.clickable = false;
        this.shortMessage = false;
        this.expanded = false;
        this.unclamp = false;
        this.showTruncateAction = false;
        this.seeMore = 'See more';
        this.seeLess = 'See less';
        this.lineClamp = 15;
        this.observerCallback = debounce((entries) => {
            entries.forEach((entry) => {
                this.shortMessage = entry.contentRect.width < 100;
                this.handleShowTruncateAction(this.mainTextNode);
                this.reactionsContainer.style.minWidth = `min(100%, calc(${Math.ceil(entry.contentRect.width)}px + 2*var(--goto-spacing-03)))`;
            });
        }, 100);
        this.defaultSlot = [];
        this.handleKeyDown = (e) => {
            const key = e.key;
            const activeElements = document.activeElement;
            switch (key) {
                case 'Enter': {
                    if (this === activeElements) {
                        this.click();
                    }
                    break;
                }
                default: {
                    return true;
                }
            }
            e.stopPropagation();
        };
        this.handleMouseUp = (e) => {
            this.removeAttribute('data-pressed');
            e.stopPropagation();
        };
        this.observer = new ResizeObserver(this.observerCallback);
    }
    reactionsChanged() {
        var _a;
        if (!this.isConnected) {
            return;
        }
        if (((_a = this.reactions) === null || _a === void 0 ? void 0 : _a.length) === 0) {
            this.setAttribute('data-no-reactions', 'true');
        }
        else {
            this.removeAttribute('data-no-reactions');
        }
    }
    clickableChanged(_, newValue) {
        newValue ? this.bindClickableEvents() : this.unbindClickableEvents();
    }
    unclampChanged(_, newValue) {
        if (newValue) {
            this.handleShowTruncateAction(this.mainTextNode);
        }
    }
    connectedCallback() {
        super.connectedCallback();
        this.observer.observe(this.contents);
    }
    disconnectedCallback() {
        this.observer.disconnect();
    }
    clickTruncateHandler() {
        this.expanded = !this.expanded;
    }
    get mainTextNode() {
        var _a;
        return (_a = this.defaultSlot.find((node) => { var _a; return (_a = node.textContent) === null || _a === void 0 ? void 0 : _a.trim().length; })) !== null && _a !== void 0 ? _a : this.defaultSlot[0];
    }
    handleShowTruncateAction(textNode) {
        if (this.unclamp) {
            return;
        }
        const range = document === null || document === void 0 ? void 0 : document.createRange();
        range.selectNodeContents(textNode);
        const rect = range === null || range === void 0 ? void 0 : range.getClientRects();
        this.showTruncateAction = rect.length > this.lineClamp;
    }
    defaultSlotChanged(_, changed) {
        changed.forEach((entry) => {
            var _a, _b;
            this.shortMessage = ((_a = entry.parentElement) === null || _a === void 0 ? void 0 : _a.offsetWidth) && ((_b = entry.parentElement) === null || _b === void 0 ? void 0 : _b.offsetWidth) < 100 ? true : false;
            this.handleShowTruncateAction(this.mainTextNode);
        });
    }
    bindClickableEvents() {
        this.addEventListener('mousedown', this.handleMouseDown);
        this.addEventListener('keydown', this.handleKeyDown);
    }
    unbindClickableEvents() {
        this.removeEventListener('mousedown', this.handleMouseDown);
        document.removeEventListener('mouseup', this.handleMouseUp);
        this.removeEventListener('keydown', this.handleKeyDown);
    }
    handleMouseDown(e) {
        if (e.target === this) {
            this.setAttribute('data-pressed', 'true');
            document.addEventListener('mouseup', this.handleMouseUp, { once: true });
        }
        e.stopPropagation();
    }
}
__decorate([
    observable
], ChatBubbleComponent.prototype, "reactions", void 0);
__decorate([
    attr
], ChatBubbleComponent.prototype, "variant", void 0);
__decorate([
    attr
], ChatBubbleComponent.prototype, "type", void 0);
__decorate([
    attr({ mode: 'boolean' })
], ChatBubbleComponent.prototype, "first", void 0);
__decorate([
    attr({ mode: 'boolean' })
], ChatBubbleComponent.prototype, "clickable", void 0);
__decorate([
    attr({ mode: 'boolean', attribute: 'short-message' })
], ChatBubbleComponent.prototype, "shortMessage", void 0);
__decorate([
    attr({ mode: 'boolean', attribute: 'expanded' })
], ChatBubbleComponent.prototype, "expanded", void 0);
__decorate([
    attr({ mode: 'boolean', attribute: 'unclamp' })
], ChatBubbleComponent.prototype, "unclamp", void 0);
__decorate([
    attr({ mode: 'boolean' })
], ChatBubbleComponent.prototype, "showTruncateAction", void 0);
__decorate([
    attr
], ChatBubbleComponent.prototype, "seeMore", void 0);
__decorate([
    attr
], ChatBubbleComponent.prototype, "seeLess", void 0);
__decorate([
    observable
], ChatBubbleComponent.prototype, "defaultSlot", void 0);
