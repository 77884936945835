import { FC } from 'react';

import CCBuyNow from 'components/credit-card-buy-now';
import ElectronicBuyNow from 'components/electronic-buy-now';
import { useAppSelector } from 'hooks';
import { chosenPaymentMethodKey, sortedPaymentMethodList } from 'modules/payment-methods';
import { CreditCard } from 'types/payment-method';
import { PAYMENT_METHODS_TYPES } from 'utils/constants';

const BuyNow: FC = (): JSX.Element => {
  const selectedSortedPaymentMethodList = useAppSelector(sortedPaymentMethodList);
  const selectedChosenPaymentMethodKey = useAppSelector(chosenPaymentMethodKey);

  const paymentDetails = selectedSortedPaymentMethodList.find(
    (paymentMethod) => paymentMethod.paymentMethodKey === selectedChosenPaymentMethodKey,
  )!;

  return (
    <>
      {paymentDetails?.paymentMethodType === PAYMENT_METHODS_TYPES.cc ? (
        <CCBuyNow currentCreditCard={paymentDetails as CreditCard} />
      ) : (
        <ElectronicBuyNow
          paymentMethodKey={paymentDetails?.paymentMethodKey}
          paymentType={paymentDetails?.paymentMethodType}
        />
      )}
    </>
  );
};

export default BuyNow;
