import { FC } from 'react';
import { useIntl } from 'react-intl';
import { TimeLimitFilledIcon } from '@getgo/chameleon-icons/react';
import { Button, Dialog, Typography } from '@getgo/chameleon-web-react-wrapper';

import { useAppSelector } from 'hooks';
import { sesssionCancelRedirectUrl } from 'modules/session-details';
import st from 'utils/shared-translations';

import './session-expired-modal.css';

interface SessionExpiredModalProps {
  redirectType: 'PaymentMethod' | 'Checkout';
}

const SessionExpiredModal: FC<SessionExpiredModalProps> = ({ redirectType }) => {
  const intl = useIntl();

  const selectedCancelRedirectUrl = useAppSelector(sesssionCancelRedirectUrl);

  const redirectMethod = () => {
    localStorage.clear();
    window.location.assign(selectedCancelRedirectUrl);
  };

  return (
    <Dialog
      open
      actions={
        <>
          <Button onClick={redirectMethod}>{intl.formatMessage(st['back.to.previous.page'])}</Button>
        </>
      }
    >
      <div className="session-expired-modal">
        <TimeLimitFilledIcon size="75px" color="var(--goto-icon-color-warning)" />
        <Typography variant="heading-small" color="type-color-default" className="session-expired-modal__header">
          {intl.formatMessage(st['session.timeout.dialog.header'])}
        </Typography>
        {redirectType === 'Checkout' && (
          <Typography variant="body-small" color="type-color-secondary" className="session-expired-modal__message">
            {intl.formatMessage(st['session.timeout.dialog.checkout.message'])}
          </Typography>
        )}
      </div>
    </Dialog>
  );
};

export default SessionExpiredModal;
