import { css } from '@microsoft/fast-element';
import { radioStyles } from '@microsoft/fast-components';
import { Radio, radioTemplate as template } from '@microsoft/fast-foundation';
import { RadioButtonComponent } from './RadioButton';
import styles from './RadioButton.styles.scss';
export const radioButtonDefinition = {
    baseName: 'radio-button',
    baseClass: Radio,
    template: template,
    styles: (ctx, def) => css `
    ${radioStyles(ctx, def)}
    ${styles}
  `,
    checkedIndicator: `<div part="checked-indicator" class="checked-indicator"></div>`,
};
export const chameleonRadioButton = RadioButtonComponent.compose(radioButtonDefinition);
