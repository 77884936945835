import { __rest } from "tslib";
import React, { forwardRef, useEffect, useRef } from 'react';
import { createReactWrapper } from '../common/PrefixUtils';
import { handleForwardRef } from '../common/RefUtils';
import { ChangeSyntheticEvent } from '../common/SyntheticEvent';
import { addExtraProps } from '../common/utils/AddExtraProps';
export const Select = forwardRef((_a, ref) => {
    var { children, onChange, leadingIcon, helperText, selectedValue, value, inDialog, noBorder, fitContent } = _a, props = __rest(_a, ["children", "onChange", "leadingIcon", "helperText", "selectedValue", "value", "inDialog", "noBorder", "fitContent"]);
    const selectRef = useRef();
    const handledRef = handleForwardRef(selectRef, ref);
    const handleChange = (event) => {
        onChange === null || onChange === void 0 ? void 0 : onChange(new ChangeSyntheticEvent(event));
    };
    useEffect(() => {
        var _a;
        (_a = selectRef.current) === null || _a === void 0 ? void 0 : _a.addEventListener('change', handleChange);
        return () => {
            var _a;
            (_a = selectRef.current) === null || _a === void 0 ? void 0 : _a.removeEventListener('change', handleChange);
        };
    }, [ref, onChange]);
    useEffect(() => {
        if (selectRef.current && value !== undefined) {
            selectRef.current.value = value;
        }
    }, [value, selectRef]);
    return createReactWrapper({
        tagName: 'chameleon-select',
        props: Object.assign(Object.assign({}, props), { ref: handledRef, 'helper-text': helperText, 'in-dialog': inDialog, 'no-border': noBorder, 'fit-content': fitContent }),
        children: (React.createElement(React.Fragment, null,
            leadingIcon && addExtraProps(leadingIcon, { slot: 'start' }),
            children,
            selectedValue && addExtraProps(selectedValue, { slot: 'selected-value' }))),
    });
});
