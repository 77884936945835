import { FC } from 'react';
import { useIntl } from 'react-intl';
import { Typography } from '@getgo/chameleon-web-react-wrapper';

import DefaultDeleteCTAs from 'components/default-delete-ctas';
import { CreditCard } from 'types/payment-method';
import { PAYMENT_METHODS_TYPES } from 'utils/constants';
import { getSupportedCCInfo } from 'utils/credit-card-utils';

import './credit-card-info.css';

interface CreditCardInfoProps {
  ccDetails: CreditCard;
}

const CreditCardInfo: FC<CreditCardInfoProps> = ({ ccDetails }): JSX.Element => {
  const intl = useIntl();

  const { type, last4Digits, expirationMonth, expirationYear } = ccDetails;

  const cardInfo = getSupportedCCInfo(type.toUpperCase(), intl);

  return (
    <>
      <section className="card-info">
        <div className="card-info__icon-num">
          {cardInfo.cardIcon}
          <Typography variant="body-small">
            {cardInfo.ccDigitLength === 15 ? <>**** ****** *{last4Digits}</> : <>**** **** **** {last4Digits}</>}
          </Typography>
        </div>
        <DefaultDeleteCTAs
          isDefault={ccDetails.isDefault}
          isDeletable={ccDetails.isDeletable}
          paymentMethodKey={ccDetails.paymentMethodKey}
          paymentType={PAYMENT_METHODS_TYPES.cc}
        />
      </section>
      <Typography
        variant="caption-small"
        color="type-color-secondary"
      >{`${expirationMonth} / ${expirationYear}`}</Typography>
    </>
  );
};

export default CreditCardInfo;
