import { selectStyles } from '@microsoft/fast-components';
import { css } from '@microsoft/fast-element';
import { Select, selectTemplate as template } from '@microsoft/fast-foundation';
import { SelectComponent } from './Select';
import styles from './Select.styles.scss';
export const selectDefinition = {
    baseName: 'select',
    baseClass: Select,
    template: template,
    styles: (ctx, def) => css `
    ${selectStyles(ctx, def)}
    ${styles}
  `,
};
export const chameleonSelect = SelectComponent.compose(selectDefinition);
