import { RootState } from 'types/root';
import {
  AddPaypalStep1Resp,
  AddPaypalStep2Resp,
  TransactionsPaypalState,
  UsePaypalPayResp,
} from 'types/transaction-paypal';
import { COPAS_PAYMENT_STATUS } from 'utils/constants';

export const transactionsPaypalTree = (state: RootState): TransactionsPaypalState => state.transactionsPaypal || {};

export const transactionsPaypalIsLoading = (state: RootState): boolean =>
  transactionsPaypalTree(state).isLoading || false;

export const addPaypalStep1Resp = (state: RootState): AddPaypalStep1Resp =>
  transactionsPaypalTree(state).addPaypalStep1Resp || {};

export const addPaypalStep2Resp = (state: RootState): AddPaypalStep2Resp =>
  transactionsPaypalTree(state).addPaypalStep2Resp || {};

export const usePaypalPayResp = (state: RootState): UsePaypalPayResp =>
  transactionsPaypalTree(state).usePaypalPayResp || {};

export const usePaypalPayFailed = (state: RootState): boolean =>
  usePaypalPayResp(state).status === COPAS_PAYMENT_STATUS.FAILED || false;

export const usePaypalPaySuccess = (state: RootState): boolean =>
  usePaypalPayResp(state).status === COPAS_PAYMENT_STATUS.SUCCESS || false;

export const usePaypalPayPending = (state: RootState): boolean =>
  usePaypalPayResp(state).status === COPAS_PAYMENT_STATUS.PENDING || false;
