/* https://stackblitz.com/edit/typescript-sha256-base64-encoded */

/**
 * Returns an array of characters mapped from the Uint8Array object's values passed to it.
 * @param {Uint8Array} arr: The Uint8Array to be used for mapping the characters to.
 */
const toCharCodes = (arr: Uint8Array) => {
  const validChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  return arr.map((x) => validChars.charCodeAt(x % validChars.length));
};

/**
 * Returns a string of specified-length random characters.
 *   - Use for creating the random values for "state" (32 chars) and "code_verifier" (64 chars) for the client side application.
 * @param {number} len: The random string length to be returned
 */
export const randomString = (len: number) => {
  const arr = new Uint8Array(len);
  window.crypto.getRandomValues(arr);
  return String.fromCharCode(...toCharCodes(arr));
};
