import { createSelector } from '@reduxjs/toolkit';
import sortBy from 'lodash/sortBy';

import { ErrorMessage, ErrorsState } from 'types/errors';
import { RootState } from 'types/root';

export const errorsTree = (state: RootState): ErrorsState => state.errors || {};

export const errorsMessages = (state: RootState): { [key: string]: ErrorMessage } =>
  errorsTree(state).errorAlerts || {};

export const errorsMessagesSorted = createSelector([errorsMessages], (alerts) =>
  sortBy(
    Object.keys(alerts).map((alertMessageKey) => alerts[alertMessageKey]),
    ['timestamp'],
  ).reverse(),
);
