import { Anchor, anchorTemplate as template } from '@microsoft/fast-foundation';
import { ButtonLinkComponent } from './ButtonLink';
import { html } from '@microsoft/fast-element';
import { SVG_EXTERNAL_LINK_OUTLINED } from '@getgo/chameleon-icons';
import styles from './ButtonLink.styles.scss';
export const buttonLinkDefinition = {
    baseName: 'button-link',
    baseClass: Anchor,
    template: template,
    styles,
    shadowOptions: {
        delegatesFocus: true,
    },
    end: html `<chameleon-svg>${SVG_EXTERNAL_LINK_OUTLINED}</chameleon-svg>`,
};
export const chameleonButtonLink = ButtonLinkComponent.compose(buttonLinkDefinition);
