import { __decorate } from "tslib";
import { Presence } from '@getgo/chameleon-core';
import { SVG_CLOCK_FILLED, SVG_CLOSE_CIRCLE_OUTLINED, SVG_PRESENCE_AVAILABLE, SVG_PRESENCE_BUSY_ACTIVE, SVG_PRESENCE_DND, SVG_PRESENCE_OFFLINE, } from '@getgo/chameleon-icons';
import { attr, html } from '@microsoft/fast-element';
import { FoundationElement } from '@microsoft/fast-foundation';
import { ChameleonElementMixin } from '../common/mixin';
import { DEV } from 'esm-env';
const icon = (presence) => {
    switch (presence) {
        case Presence.AVAILABLE:
            return html `${SVG_PRESENCE_AVAILABLE}`;
        case Presence.BUSY:
            return html `${SVG_PRESENCE_BUSY_ACTIVE}`;
        case Presence.AWAY:
            return html `${SVG_CLOCK_FILLED}`;
        case Presence.DND:
            return html `${SVG_PRESENCE_DND}`;
        case Presence.DO_NOT_DISTURB:
            return html `${SVG_PRESENCE_DND}`;
        case Presence.OFFLINE:
            return html `${SVG_PRESENCE_OFFLINE}`;
        case Presence.DISCONNECTED:
            return html `${SVG_CLOSE_CIRCLE_OUTLINED}`;
    }
};
export const presenceIndicatorTemplate = html `<div
  class="goto-presence-indicator goto-presence-indicator--${(x) => x.presence} ${(x) => x.hasAttribute('achromatic') ? 'goto-presence-indicator--achromatic' : ''}"
>
  <chameleon-svg>${(x) => icon(x.presence)}</chameleon-svg>
</div>`;
export class PresenceIndicatorComponent extends ChameleonElementMixin(FoundationElement) {
    constructor() {
        super(...arguments);
        this.presence = Presence.OFFLINE;
        this.achromatic = false;
    }
    connectedCallback() {
        super.connectedCallback();
        this.setAccessibleLabel();
    }
    labelChanged() {
        this.setAccessibleLabel();
    }
    setAccessibleLabel() {
        if (this.label) {
            this.setAttribute('aria-label', this.label);
            return;
        }
        if (DEV) {
            this.validateAccessibleLabel();
        }
    }
    validateAccessibleLabel() {
        var _a;
        if (!((_a = this.getAttribute('aria-label')) === null || _a === void 0 ? void 0 : _a.length)) {
            console.error('CHAMELEON-A11Y: Presence Indicator component must have an accessible label provided by a non-empty label attribute to be used as aria-label.');
        }
    }
}
__decorate([
    attr
], PresenceIndicatorComponent.prototype, "presence", void 0);
__decorate([
    attr
], PresenceIndicatorComponent.prototype, "achromatic", void 0);
__decorate([
    attr
], PresenceIndicatorComponent.prototype, "label", void 0);
