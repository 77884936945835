import { provideChameleonDesignSystem } from '@getgo/chameleon-toolkit';
import { fileThumbnail } from './FileThumbnail.definition';
import { chameleonSvg } from '../Svg';
import { chameleonIconBadgeV2 } from '../IconBadge/IconBadge.definition';
import { chameleonCircularProgress } from '../CircularProgress/CircularProgress.definition';
import { register as registerTypography } from '@getgo/chameleon-typography/register';
export function registerCustom(prefix, node) {
    registerTypography({ prefix, node });
    return provideChameleonDesignSystem({ prefix, node }).register(fileThumbnail(), chameleonSvg(), chameleonIconBadgeV2(), chameleonCircularProgress());
}
