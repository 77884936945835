import { Tooltip, tooltipTemplate as template } from '@microsoft/fast-foundation';
import { css } from '@microsoft/fast-element';
import { tooltipStyles } from '@microsoft/fast-components';
import { InternalTooltipComponent } from './InternalTooltip';
import styles from './InternalTooltip.styles.scss';
export const internalTooltipDefinition = {
    baseName: 'internal-tooltip',
    baseClass: Tooltip,
    template: template,
    styles: (ctx, def) => css `
    ${tooltipStyles(ctx, def)}
    ${styles}
  `,
};
export const chameleonInternalTooltip = InternalTooltipComponent.compose(internalTooltipDefinition);
