import Amplitude from 'lib/amplitude';
import { AmplitudeRevenue, AmplitudeRevenueProductDetails } from 'types/tracking';
import {
  ACHCancelCTA,
  ACHSaveCTA,
  CreditCardCancelCTA,
  CreditCardSaveCTA,
  OnePayAPIError,
  OnePayLanding,
  PaymentMethodBackToG2A,
  PaymentMethodDelete,
  PaymentMethodLanding,
  PaymentMethodSetDefault,
  PaynowAddNewMethod,
  PaynowBackToCheckout,
  PaynowContinueAddNewMethod,
  PaynowCTA,
  PaynowLanding,
  PaynowSaveAndPay,
  PaypalSaveRedirect,
  PaypalSaveStep1,
  Revenue,
  SEPASaveCTA,
} from './tracking-constants';

export const trackPageView = (eventName: string, payload: object): void => {
  let amplitudeEventName: string;
  let amplitudePayload = payload;

  switch (eventName) {
    case OnePayLanding:
      amplitudeEventName = 'OnePay - PageView';
      break;

    case OnePayAPIError:
      amplitudeEventName = 'OnePay - APIError';
      break;

    case PaymentMethodLanding:
      amplitudeEventName = 'Payment methods - PageView';
      break;

    case PaymentMethodBackToG2A:
      amplitudeEventName = 'Payment methods - BackToG2AClick';
      break;

    case PaymentMethodDelete:
      amplitudeEventName = 'PaymentMethods - DeleteClick';
      break;

    case PaymentMethodSetDefault:
      amplitudeEventName = 'PaymentMethods - SetDefaultClick';
      break;

    case CreditCardSaveCTA:
      amplitudeEventName = 'Credit Card - SaveClick';
      break;

    case CreditCardCancelCTA:
      amplitudeEventName = 'Credit Card - CancelClick';
      break;

    case ACHSaveCTA:
      amplitudeEventName = 'ACH - SaveClick';
      break;

    case ACHCancelCTA:
      amplitudeEventName = 'ACH - CancelClick';
      break;

    case SEPASaveCTA:
      amplitudeEventName = 'SEPA - SaveClick';
      break;

    case PaypalSaveStep1:
      amplitudeEventName = 'Paypal - SaveStep1';
      break;

    case PaypalSaveRedirect:
      amplitudeEventName = 'Paypal - SaveRedirect';
      break;

    case PaynowLanding:
      amplitudeEventName = 'Paynow - PageView';
      break;

    case PaynowBackToCheckout:
      amplitudeEventName = 'Paynow - BackToCheckoutClick';
      break;

    case PaynowAddNewMethod:
      amplitudeEventName = 'Paynow - AddNewPaymentMethodClick';
      break;

    case PaynowContinueAddNewMethod:
      amplitudeEventName = 'Paynow - ContinueAddNewMethodClick';
      break;

    case PaynowCTA:
      amplitudeEventName = 'Paynow - PayClick';
      break;

    case PaynowSaveAndPay:
      amplitudeEventName = 'Paynow - SaveAndPayClick';
      break;

    default:
      amplitudeEventName = eventName;
      amplitudePayload = payload;
  }
  return Amplitude.track(amplitudeEventName, amplitudePayload);
};

interface OrderItem {
  productName: string;
  amount: number;
  quantity: number;
  productGroup: string;
  planTermType: string;
}

export const trackingRevenueAmplitudeInfo = (orderItem: OrderItem): AmplitudeRevenueProductDetails => {
  const productId = orderItem.productName;
  const orderItemAmount = orderItem.amount;
  let orderType = '';

  if (orderItemAmount > 0) {
    orderType = 'purchase';
  } else if (orderItemAmount < 0) {
    orderType = 'refund';
  } else {
    orderType = 'free';
  }

  const productDetails: AmplitudeRevenueProductDetails = {
    orderItemAmount: Number(orderItemAmount.toFixed(2)),
    orderType,
    productId,
    quantity: orderItem.quantity,
    productFamilyKey: orderItem.productGroup,
    paymentPlan: orderItem.planTermType,
  };
  return productDetails;
};

export const trackRevenueAmplitude = (payload: {
  currencyCode: string;
  orderList: [];
  purchaseFlow: string;
  paymentMethod: string;
  quoteId: string;
}): void => {
  const { orderList, currencyCode, purchaseFlow, paymentMethod, quoteId } = payload;

  if (Array.isArray(payload.orderList)) {
    orderList.forEach((orderItem) => {
      const productDetails = trackingRevenueAmplitudeInfo(orderItem);
      const revenuePayload: AmplitudeRevenue = {
        ...productDetails,
        quoteId,
        purchaseFlow,
        currencyCode,
        paymentMethod,
      };
      return Amplitude.trackRevenue(revenuePayload);
    });
  }
};

export default (eventName: string, event: any): void => {
  switch (eventName) {
    case OnePayLanding:
    case OnePayAPIError:
    case PaymentMethodLanding:
    case PaymentMethodBackToG2A:
    case PaymentMethodDelete:
    case PaymentMethodSetDefault:
    case CreditCardSaveCTA:
    case CreditCardCancelCTA:
    case ACHSaveCTA:
    case ACHCancelCTA:
    case SEPASaveCTA:
    case PaypalSaveStep1:
    case PaypalSaveRedirect:
    case PaynowLanding:
    case PaynowBackToCheckout:
    case PaynowAddNewMethod:
    case PaynowContinueAddNewMethod:
    case PaynowCTA:
    case PaynowSaveAndPay: {
      trackPageView(eventName, event);
      break;
    }
    case Revenue: {
      trackRevenueAmplitude(event);
      break;
    }
    default: {
      break;
    }
  }
};
