import { treeItemStyles } from '@microsoft/fast-components';
import { css } from '@microsoft/fast-element';
import { TreeItem, treeItemTemplate as template } from '@microsoft/fast-foundation';
import { TreeItemComponent } from './TreeItem';
import styles from './TreeItem.styles.scss';
export const treeItemDefinition = {
    baseName: 'tree-item',
    baseClass: TreeItem,
    template: template,
    styles: (ctx, def) => css `
    ${treeItemStyles(ctx, def)}
    ${styles}
  `,
};
export const chameleonTreeItem = TreeItemComponent.compose(treeItemDefinition);
