import { Menu, menuTemplate as template } from '@microsoft/fast-foundation';
import { NavigationDrawerComponent } from './NavigationDrawer';
import styles from './NavigationDrawer.styles.scss';
export const navigationDrawerDefinition = {
    baseName: 'navigation-drawer',
    baseClass: Menu,
    template: template,
    styles,
};
export const chameleonNavigationDrawer = NavigationDrawerComponent.compose(navigationDrawerDefinition);
