import { AvatarComponent, avatarTemplate, AvatarV2Component, avatarV2Template } from './Avatar';
import styles from './Avatar.styles.scss';
import oldStyles from './OldAvatar.styles.scss';
export const avatarDefinition = {
    template: avatarTemplate,
    styles: oldStyles,
};
export const chameleonAvatar = AvatarComponent.compose(Object.assign(Object.assign({}, avatarDefinition), { baseName: 'avatar' }));
export const avatarV2Definition = {
    template: avatarV2Template,
    styles,
};
export const chameleonAvatarV2 = AvatarV2Component.compose(Object.assign(Object.assign({}, avatarV2Definition), { baseName: 'avatar-v2' }));
