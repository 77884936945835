import { __decorate } from "tslib";
import { attr } from '@microsoft/fast-element';
import { BadgeBase } from './badge.common';
export class BadgeComponent extends BadgeBase {
    constructor() {
        super(...arguments);
        this.variant = 'neutral';
    }
}
__decorate([
    attr
], BadgeComponent.prototype, "variant", void 0);
