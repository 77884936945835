import { __decorate } from "tslib";
import { SVG_ALERT_OUTLINED, SVG_CHECKBOX_CHECK } from '@getgo/chameleon-icons';
import { attr, html, observable, slotted, when } from '@microsoft/fast-element';
import { FoundationElement } from '@microsoft/fast-foundation';
import { ChameleonElementMixin } from '../../common/mixin';
export const progressStepTemplate = html `<template slot="step">
  <span class="badge">
    ${when((x) => x.shouldShowDangerState(), html `<chameleon-svg>${SVG_ALERT_OUTLINED}</chameleon-svg>`)}
    ${when((x) => x.shouldShowSuccessState(), html `<chameleon-svg>${SVG_CHECKBOX_CHECK}</chameleon-svg>`)}
    ${when((x) => x.shouldShowBadgeValue(), html `<span>${(x) => x.badgeValue}</span>`)}
  </span>
  <chameleon-tooltip-v2 position="bottom-center" hidden="${(x) => !x.showTooltip}">
    <div class="step-text" slot="trigger">
      <chameleon-typography class="step-title" part="title" nowrap color="inherit">
        ${when((x) => !x.editable, html `<slot ${slotted('defaultSlot')}></slot>`)}
        ${when((x) => x.editable && !x.disabled, html `
            <chameleon-link @click="${(x) => x.onStepClick()}">
              <slot ${slotted('defaultSlot')}></slot>
            </chameleon-link>
          `)}
      </chameleon-typography>
      <div class="step-description">
        <slot name="description" ${slotted('descriptionSlot')}></slot>
      </div>
    </div>
    <span slot="content">
      <chameleon-typography tag="b" nowrap color="inherit">${(x) => x.titlePopoverContent}</chameleon-typography>
      ${(x) => x.descriptionPopoverContent}
    </span>
  </chameleon-tooltip-v2>
</template>`;
export class ProgressStepComponent extends ChameleonElementMixin(FoundationElement) {
    constructor() {
        super(...arguments);
        this.disabled = false;
        this.badgeValue = '';
        this.editable = false;
        this.optional = false;
        this.visited = false;
        this.showTooltip = true;
        this.titlePopoverContent = '';
        this.descriptionPopoverContent = '';
        this.selected = false;
        this.defaultSlot = [];
        this.descriptionSlot = [];
    }
    selectedChanged(_oldValue, newValue) {
        if (newValue) {
            this.$emit('change', null, { bubbles: false });
        }
    }
    defaultSlotChanged(_, changed) {
        var _a, _b;
        this.titlePopoverContent = (_b = (_a = changed[0]) === null || _a === void 0 ? void 0 : _a.textContent) !== null && _b !== void 0 ? _b : '';
    }
    descriptionSlotChanged(_, changed) {
        var _a, _b;
        this.descriptionPopoverContent = (_b = (_a = changed[0]) === null || _a === void 0 ? void 0 : _a.textContent) !== null && _b !== void 0 ? _b : '';
    }
    shouldShowDangerState() {
        return this.variant === 'danger';
    }
    shouldShowSuccessState() {
        return !this.selected && this.variant === 'success';
    }
    shouldShowBadgeValue() {
        return (this.selected || this.variant !== 'success') && !this.shouldShowDangerState();
    }
    onStepClick() {
        this.$emit('step-clicked', null, { bubbles: false });
    }
}
__decorate([
    attr
], ProgressStepComponent.prototype, "variant", void 0);
__decorate([
    attr({ mode: 'boolean' })
], ProgressStepComponent.prototype, "disabled", void 0);
__decorate([
    attr({ attribute: 'badgevalue' })
], ProgressStepComponent.prototype, "badgeValue", void 0);
__decorate([
    attr({ mode: 'boolean' })
], ProgressStepComponent.prototype, "editable", void 0);
__decorate([
    attr({ mode: 'boolean' })
], ProgressStepComponent.prototype, "optional", void 0);
__decorate([
    attr({ mode: 'boolean' })
], ProgressStepComponent.prototype, "visited", void 0);
__decorate([
    attr({ mode: 'boolean', attribute: 'show-tooltip' })
], ProgressStepComponent.prototype, "showTooltip", void 0);
__decorate([
    observable
], ProgressStepComponent.prototype, "titlePopoverContent", void 0);
__decorate([
    observable
], ProgressStepComponent.prototype, "descriptionPopoverContent", void 0);
__decorate([
    attr({ mode: 'boolean' })
], ProgressStepComponent.prototype, "selected", void 0);
__decorate([
    observable
], ProgressStepComponent.prototype, "defaultSlot", void 0);
__decorate([
    observable
], ProgressStepComponent.prototype, "descriptionSlot", void 0);
