import { FC, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { ChevronLeftOutlinedIcon, GotoAdminPartnerFaviconIcon } from '@getgo/chameleon-icons/react';
import { Button, GotoLogo } from '@getgo/chameleon-web-react-wrapper';

import SessionExpiredModal from 'components/session-expired-modal';
import SessionTimer from 'components/session-timer';
import { useAppSelector } from 'hooks';
import { accountKey } from 'modules/global-wrapper';
import { isSessionExpired, sesssionCancelRedirectUrl } from 'modules/session-details';
import Track, { PaymentMethodBackToG2A, PaymentMethodLanding } from 'modules/tracking';
import { PAYMENT_METHODS_TYPES } from 'utils/constants';
import st from 'utils/shared-translations';
import ACHMethod from './ach-method';
import CreditCardMethod from './credit-card-method';
import DirectDebitMethod from './direct-debit-method';
import PaypalMethod from './paypal-method';

import './payment-methods.css';

interface PaymentMethodsProps {
  paymentMethodType: string;
}

const PaymentMethods: FC<PaymentMethodsProps> = ({ paymentMethodType }) => {
  const intl = useIntl();

  const selectedAccountKey = useAppSelector(accountKey);
  const selectedSessionIsExpired = useAppSelector(isSessionExpired);
  const selectedCancelRedirectUrl = useAppSelector(sesssionCancelRedirectUrl);

  useEffect(() => {
    if (selectedAccountKey) {
      Track(PaymentMethodLanding, { paymentMethodType });
    }
  }, [selectedAccountKey, paymentMethodType]);

  const redirectToPaymentMethod = () => {
    Track(PaymentMethodBackToG2A, {});
    localStorage.clear();
    window.location.assign(selectedCancelRedirectUrl);
  };

  return (
    <div className="payment-methods" data-testid="payment-methods">
      {selectedSessionIsExpired && <SessionExpiredModal redirectType="PaymentMethod" />}
      <nav className="payment-methods__nav" data-testid="payment-methods__nav">
        <Button
          size="medium"
          variant="neutral"
          leadingIcon={<ChevronLeftOutlinedIcon />}
          onClick={redirectToPaymentMethod}
          data-testid="payment-methods__back"
        >
          {intl.formatMessage(st['back.to.payment.methods'])}
        </Button>
      </nav>
      <header className="payment-methods__header" data-testid="payment-methods__header">
        <SessionTimer />
        <GotoLogo data-testid="payment-methods__logo">
          <GotoAdminPartnerFaviconIcon />
        </GotoLogo>
      </header>
      <main className="payment-methods__main" data-testid="payment-methods__main">
        {
          {
            [PAYMENT_METHODS_TYPES.ach]: <ACHMethod />,
            [PAYMENT_METHODS_TYPES.cc]: <CreditCardMethod />,
            [PAYMENT_METHODS_TYPES.dd]: <DirectDebitMethod />,
            [PAYMENT_METHODS_TYPES.paypal]: <PaypalMethod />,
          }[paymentMethodType]
        }
      </main>
    </div>
  );
};

export default PaymentMethods;
