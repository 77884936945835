
import React from 'react';
const createIconComponent = ({ content, height, width }) => ({ size, color, style = {}, ...props}) => {
  const resolvedColor = style?.color || color || '';
  const resolvedSize = size ? size : '';

  const allProps = {
    ...props,
    style: {
      ...style,
      '--goto-icon-color': resolvedColor,
      '--goto-icon-size': resolvedSize,
    },
    width,
    height,
  };

  return React.createElement('chameleon-svg', allProps, content);
};

export default createIconComponent;
