import { NavigationRailLinkComponent } from './NavigationRailLink';
import styles from './NavigationRailLink.styles.scss';
import { html, slotted } from '@microsoft/fast-element';
export const chameleonNavigationRailLinkTemplate = html `
  <template
    @keydown="${(x, c) => x.handleMenuKeyDown(c.event)}"
    @click="${(x, c) => x.handleItemClick(c.event)}"
    @mouseover="${(x, c) => x.handleMouseOver(c.event)}"
    @mouseout="${(x, c) => x.handleMouseOut(c.event)}"
    role="menuitem"
  >
    <chameleon-tooltip-v2
      position="right-center"
      z-index="100"
      tabindex="-1"
      :hidden="${(x) => x.size !== 'small'}"
      style="--ADDITIONAL-ARROW-OFFSET: 7;"
    >
      <chameleon-base-navigation-rail-link
        slot="trigger"
        :selected=${(x) => x.selected}
        :disabled=${(x) => x.disabled}
        :sticky=${(x) => x.sticky}
        :collapse=${(x) => x.collapse}
        :size=${(x) => x.size}
        :href=${(x) => x.href}
        :target=${(x) => x.target}
        :appearance=${(x) => x.appearance}
      >
        <slot ${slotted('defaultSlottedNodes')}></slot>
        <slot slot="start" name="start"></slot>
        <slot slot="end" name="end" ${slotted('endSlottedNodes')}></slot>
      </chameleon-base-navigation-rail-link>
      <span slot="content"></span>
    </chameleon-tooltip-v2>
  </template>
`;
export const chameleonNavigationRailLinkDefinition = {
    baseName: 'navigation-rail-link',
    template: chameleonNavigationRailLinkTemplate,
    styles,
};
export const chameleonNavigationRailLink = NavigationRailLinkComponent.compose(chameleonNavigationRailLinkDefinition);
