import { __decorate } from "tslib";
import { attr, html } from '@microsoft/fast-element';
import { FoundationElement } from '@microsoft/fast-foundation';
import { uniqueId as fastUniqueId } from '@microsoft/fast-web-utilities';
import { ChameleonElementMixin } from '../common/mixin';
const DEFAULT_Z_INDEX = 1;
export const tooltipTemplate = html `${(x) => x.label || x.slottedTooltip()
    ? html `<chameleon-internal-tooltip
          position=${(x) => x.position}
          visible=${(x) => x.visible}
          anchor=${(x) => x.uniqueId}
          style="${(x) => `z-index: ${x.zIndex}`}"
          >${(x) => x.label}<slot name="tooltip"></slot></chameleon-internal-tooltip
        ><slot class="content" id=${(x) => x.uniqueId}></slot>`
    : html `<slot></slot>`}`;
export class TooltipComponent extends ChameleonElementMixin(FoundationElement) {
    constructor() {
        super(...arguments);
        this.uniqueId = fastUniqueId();
        this.label = '';
        this.zIndex = DEFAULT_Z_INDEX;
    }
    slottedTooltip() {
        return !!this.querySelector('[slot="tooltip"]');
    }
    zIndexChanged(_oldValue, newValue) {
        if (newValue) {
            this.zIndex = newValue;
        }
        else {
            this.zIndex = DEFAULT_Z_INDEX;
        }
    }
}
__decorate([
    attr
], TooltipComponent.prototype, "uniqueId", void 0);
__decorate([
    attr
], TooltipComponent.prototype, "label", void 0);
__decorate([
    attr
], TooltipComponent.prototype, "position", void 0);
__decorate([
    attr
], TooltipComponent.prototype, "visible", void 0);
__decorate([
    attr({ attribute: 'z-index' })
], TooltipComponent.prototype, "zIndex", void 0);
