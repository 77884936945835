import { FoundationElement } from '@microsoft/fast-foundation';
import { ChameleonElementMixin } from '../common/mixin';
import { findClosestThemeProvider } from '../common/utils/DomUtil';
export class ChameleonRebrandingObserver extends ChameleonElementMixin(FoundationElement) {
    constructor() {
        super();
        this.observer = new MutationObserver((mutations, obsersver) => this.rebrandingObserverHandler(mutations, obsersver));
    }
    disconnectedCallback() {
        this.observer.disconnect();
    }
    handler(mutations, observer) {
        this.rebrandingObserverHandler(mutations, observer);
    }
    getThemeProvider() {
        const themeProvider = findClosestThemeProvider(this);
        if (!themeProvider) {
            throw new Error('chameleon-goto-logo require a chameleon-theme-provider element');
        }
        return themeProvider;
    }
}
