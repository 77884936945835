import Token from './token';
import { stringify } from './utils/serialize';

export interface IAuthCodeTokenConfigPkce {
  client_id: string;
  state?: {
    [key: string]: any;
  };
  code?: string;
  code_verifier?: string;
  redirect_url: string;
  url: string;
}

export const requestPKCEToken = (options: IAuthCodeTokenConfigPkce) =>
  fetch(`${options.url}/oauth/token`, {
    body: stringify({
      grant_type: 'authorization_code',
      code: options.code,
      client_id: options.client_id,
      redirect_uri: options.redirect_url,
      state: options.state,
      code_verifier: options.code_verifier,
    }),
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      Accept: 'application/json',
    },
    method: 'POST',
  })
    .then((res) => {
      if (res.ok) {
        return res.json();
      }

      return res
        .json()
        .then((json) => {
          throw new Error(`${res.status} ${res.statusText}: ${json.error_description}`);
        })
        .catch(() => {
          throw new Error(`${res.status} ${res.statusText}`);
        });
    })
    .then((json) => new Token(json));
