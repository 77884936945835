import { __decorate } from "tslib";
import { attr } from '@microsoft/fast-element';
import { AccordionItem } from '@microsoft/fast-foundation';
import { ChameleonElementMixin } from '../../common/mixin';
import { changeIconToOppositeType } from '../../common/utils/changeIconToOppositeType';
import { DEV } from 'esm-env';
export class AccordionItemComponent extends ChameleonElementMixin(AccordionItem) {
    constructor() {
        super(...arguments);
        this.chevronPosition = 'right';
    }
    expandedChanged(_, newValue) {
        if (this.isConnected) {
            changeIconToOppositeType(this.querySelector('[slot=start]'), newValue);
            this.$emit('expanded');
        }
    }
    disabledChanged() {
        if (this.isConnected) {
            this.propagateDisabledState();
        }
    }
    propagateDisabledState() {
        var _a, _b;
        if (this.disabled) {
            (_a = this.getInternalButtonElement()) === null || _a === void 0 ? void 0 : _a.setAttribute('disabled', '');
        }
        else {
            (_b = this.getInternalButtonElement()) === null || _b === void 0 ? void 0 : _b.removeAttribute('disabled');
        }
    }
    getHeadingSlot() {
        return this.querySelector('[slot="heading"]');
    }
    getInternalButtonElement() {
        var _a;
        return (_a = this.shadowRoot) === null || _a === void 0 ? void 0 : _a.querySelector('.button');
    }
    setAccessibleLabel() {
        var _a, _b, _c;
        if ((_b = (_a = this.getHeadingSlot()) === null || _a === void 0 ? void 0 : _a.textContent) === null || _b === void 0 ? void 0 : _b.length) {
            return;
        }
        if (this.label) {
            (_c = this.getInternalButtonElement()) === null || _c === void 0 ? void 0 : _c.setAttribute('aria-label', this.label);
            return;
        }
        if (DEV) {
            this.validateAccessibleLabel();
        }
    }
    validateAccessibleLabel() {
        var _a, _b;
        if (!((_b = (_a = this.getInternalButtonElement()) === null || _a === void 0 ? void 0 : _a.getAttribute('aria-label')) === null || _b === void 0 ? void 0 : _b.length)) {
            console.error(`CHAMELEON-A11Y: Accordion Item component must have an accessible label provided by either 
  1) text content in the heading slot 
  2) a non-empty label attribute to be used as aria-label.`);
        }
    }
    connectedCallback() {
        super.connectedCallback();
        this.setAccessibleLabel();
        this.propagateDisabledState();
    }
}
__decorate([
    attr({ mode: 'boolean' })
], AccordionItemComponent.prototype, "disabled", void 0);
__decorate([
    attr({ attribute: 'chevron-position' })
], AccordionItemComponent.prototype, "chevronPosition", void 0);
__decorate([
    attr({ mode: 'boolean', attribute: 'full-width' })
], AccordionItemComponent.prototype, "fullWidth", void 0);
__decorate([
    attr
], AccordionItemComponent.prototype, "divider", void 0);
__decorate([
    attr
], AccordionItemComponent.prototype, "label", void 0);
