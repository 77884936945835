import { __decorate } from "tslib";
import { SVG_CHEVRON_DOWN_OUTLINED, SVG_CHEVRON_UP_OUTLINED } from '@getgo/chameleon-icons';
import { attr, html, observable, ref, when } from '@microsoft/fast-element';
import { assertSlotNonNull } from '../common/asserts';
import { ChameleonElementMixin } from '../common/mixin';
import { getHostChildrenFromEvent } from '../common/utils/DomUtil';
import { DEV } from 'esm-env';
import { FoundationElement } from '@microsoft/fast-foundation';
var InternalButtonType;
(function (InternalButtonType) {
    InternalButtonType["Main"] = "main-button";
    InternalButtonType["Menu"] = "menu-button";
})(InternalButtonType || (InternalButtonType = {}));
export const squircleButtonTemplate = html `<div
    @click=${(x, { event }) => x.clickHandler(event)}
  >
    <chameleon-button
      ${ref('actionButton')}
      id="main-button"
      variant="${(x) => x.variant}"
      size="${(x) => x.size}"
      disabled="${(x) => x.disabled}"
      is-loading="${(x) => x.isLoading}"
    >
      <slot ${ref('iconSlot')} class="icon" name="icon"></slot>
    </chameleon-button>
    ${when((x) => x.split, html `<chameleon-popover-v2
          class="popover"
          ${ref('popoverButton')}
          position="${(x) => x.position}"
          width="${(x) => x.popoverWidth}"
          trigger-id="menu-button"
          menu
          label="popover"
        >
          <slot ${ref('actionsSlot')} name="actions"></slot>
        </chameleon-popover-v2>
        <chameleon-button
          id="menu-button"
          variant="${(x) => x.variant}"
          size="${(x) => x.size}"
          disabled=${(x) => x.disabled || x.isLoading}
        >
          ${when((x) => x.shouldShowChevronBottom(), html `<chameleon-svg>${SVG_CHEVRON_DOWN_OUTLINED}</chameleon-svg>`)}
          ${when((x) => !x.shouldShowChevronBottom(), html `<chameleon-svg>${SVG_CHEVRON_UP_OUTLINED}</chameleon-svg>`)}
        </chameleon-button>`)}
  </div>
  <span class="label" slot="label">${(x) => x.label}</span>`;
export class SquircleButtonComponent extends ChameleonElementMixin(FoundationElement) {
    constructor() {
        super(...arguments);
        this.size = 'large';
        this.variant = 'primary';
        this.position = 'top-center';
        this.split = false;
        this.disabled = false;
        this.isLoading = false;
        this.popoverWidth = undefined;
        this.displayLabel = true;
        this.popoverChangeHandler = (e) => this.$emit('popoverchange', e.detail, { bubbles: false });
        this.shouldShowChevronBottom = () => {
            if (this.position.includes('bottom')) {
                return true;
            }
            return false;
        };
    }
    connectedCallback() {
        var _a;
        super.connectedCallback();
        this.assertForIconSlot();
        this.setMainLabel();
        this.setMenuLabel();
        (_a = this.popoverButton) === null || _a === void 0 ? void 0 : _a.addEventListener('popoverchange', this.popoverChangeHandler);
    }
    disconnectedCallback() {
        var _a;
        super.disconnectedCallback();
        (_a = this.popoverButton) === null || _a === void 0 ? void 0 : _a.removeEventListener('popoverchange', this.popoverChangeHandler);
    }
    labelChanged() {
        requestAnimationFrame(() => {
            this.setMainLabel();
        });
    }
    labelIdChanged() {
        requestAnimationFrame(() => {
            this.setMainLabel();
        });
    }
    menuLabelChanged() {
        requestAnimationFrame(() => {
            this.setMenuLabel();
        });
    }
    clickHandler(e) {
        if (this.actionButton) {
            const childs = getHostChildrenFromEvent(e, this.actionButton);
            if (!childs.length) {
                e.stopImmediatePropagation();
            }
        }
    }
    getInternalButtonElement(type) {
        var _a, _b, _c;
        return (_c = (_b = (_a = this.shadowRoot) === null || _a === void 0 ? void 0 : _a.getElementById(type)) === null || _b === void 0 ? void 0 : _b.shadowRoot) === null || _c === void 0 ? void 0 : _c.querySelector('button');
    }
    get mainButton() {
        return this.getInternalButtonElement(InternalButtonType.Main);
    }
    get menuButton() {
        return this.getInternalButtonElement(InternalButtonType.Menu);
    }
    validateMenuLabel() {
        var _a, _b;
        if (this.split && !this.menuLabel && !((_b = (_a = this.menuButton) === null || _a === void 0 ? void 0 : _a.getAttribute('aria-label')) === null || _b === void 0 ? void 0 : _b.length)) {
            console.error('CHAMELEON-A11Y: Squircle Button component must have an accessible label for the Menu Button provided by a non-empty menuLabel/menu-label attribute to be used as aria-label.');
        }
    }
    validateMainLabel() {
        var _a, _b, _c;
        if (!((_b = (_a = this.mainButton) === null || _a === void 0 ? void 0 : _a.getAttribute('aria-label')) === null || _b === void 0 ? void 0 : _b.length) && !((_c = this.getAttribute('aria-labelledby')) === null || _c === void 0 ? void 0 : _c.length)) {
            console.error(`CHAMELEON-A11Y: Squircle Button component must have an accessible label provided by either
  1) a visible label whose id is given as the attribute labelId
  2) a non-empty label attribute to be used as aria-label.`);
        }
    }
    setMenuLabel() {
        if (this.split && this.menuButton && this.menuLabel) {
            this.menuButton.setAttribute('aria-label', this.menuLabel);
            return;
        }
        if (DEV) {
            this.validateMenuLabel();
        }
    }
    setMainLabel() {
        var _a;
        if (this.labelId) {
            this.setAttribute('aria-labelledby', this.labelId);
            return;
        }
        if (this.label) {
            (_a = this.mainButton) === null || _a === void 0 ? void 0 : _a.setAttribute('aria-label', this.label);
            return;
        }
        if (DEV)
            this.validateMainLabel();
    }
    assertForIconSlot() {
        const iconElement = this.iconSlot.assignedElements()[0];
        if (!iconElement)
            assertSlotNonNull(iconElement, 'SquircleButtonComponent', 'icon');
    }
}
__decorate([
    attr
], SquircleButtonComponent.prototype, "size", void 0);
__decorate([
    attr
], SquircleButtonComponent.prototype, "variant", void 0);
__decorate([
    attr
], SquircleButtonComponent.prototype, "position", void 0);
__decorate([
    attr({ mode: 'boolean' })
], SquircleButtonComponent.prototype, "split", void 0);
__decorate([
    attr({ mode: 'boolean' })
], SquircleButtonComponent.prototype, "disabled", void 0);
__decorate([
    attr({ mode: 'boolean' })
], SquircleButtonComponent.prototype, "isLoading", void 0);
__decorate([
    attr({ attribute: 'popover-width' })
], SquircleButtonComponent.prototype, "popoverWidth", void 0);
__decorate([
    attr
], SquircleButtonComponent.prototype, "label", void 0);
__decorate([
    attr({ attribute: 'display-label' })
], SquircleButtonComponent.prototype, "displayLabel", void 0);
__decorate([
    attr({ attribute: 'label-id' })
], SquircleButtonComponent.prototype, "labelId", void 0);
__decorate([
    attr({ attribute: 'menu-label' })
], SquircleButtonComponent.prototype, "menuLabel", void 0);
__decorate([
    observable
], SquircleButtonComponent.prototype, "mainButton", null);
__decorate([
    observable
], SquircleButtonComponent.prototype, "menuButton", null);
