import { DatePickerComponent, datePickerTemplate } from './DatePicker';
import styles from './DatePicker.styles.scss';
export const chameleonDatePicker = DatePickerComponent.compose({
    baseName: 'date-picker',
    template: datePickerTemplate,
    styles,
    shadowOptions: {
        delegatesFocus: true,
    },
});
