import { provideChameleonDesignSystem } from '@getgo/chameleon-toolkit';
import { chameleonNavigationRail } from './NavigationRail.definition';
import { chameleonNavigationRailItem } from './NavigationRailItem/NavigationRailItem.definition';
import { chameleonNavigationRailLink } from './NavigationRailLink/NavigationRailLink.definition';
import { chameleonBaseNavigationRailItem } from './BaseNavigationRailItem/BaseNavigationRailItem.definition';
import { chameleonBaseNavigationRailLink } from './BaseNavigationRailLink/BaseNavigationRailLink.definition';
import { chameleonTooltipV2 } from '../TooltipV2';
export function registerCustom(prefix, node) {
    return provideChameleonDesignSystem({ prefix, node }).register(chameleonNavigationRail(), chameleonNavigationRailItem(), chameleonBaseNavigationRailItem(), chameleonNavigationRailLink(), chameleonBaseNavigationRailLink(), chameleonTooltipV2());
}
