import { FC } from 'react';

import CreditCardForm from 'components/credit-card-form';
import CreditCardHeader from 'components/credit-card-header';
import { CREDIT_CARD_ADD_PAY, CREDIT_CARD_SAVE } from 'utils/constants';

interface CreditCardAddProps {
  intent: typeof CREDIT_CARD_SAVE | typeof CREDIT_CARD_ADD_PAY;
}

const CreditCardAdd: FC<CreditCardAddProps> = ({ intent }): JSX.Element => (
  <>
    <CreditCardHeader />
    <CreditCardForm intent={intent} />
  </>
);

export default CreditCardAdd;
