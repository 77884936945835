import { autoUpdate, computePosition, flip, offset } from '@floating-ui/dom';
import { convertStylePropertyPixelsToNumber } from '@microsoft/fast-web-utilities';
const MIN_WIDTH = 56;
const LARGE_WIDTH = 300;
const MARGIN_TO_TRIGGER = 4;
const DEFAULT_SINGLE_LINE_CONTENT_HEIGHT = 30;
export function updatePopoverContentStyles(popover, content) {
    content.inverted = popover.inverted;
    content.compact = popover.compact;
    content.hidden = popover.hidden;
    content.arrow = popover.arrow;
    content.menu = popover.menu;
}
function chameleonPlacementToFloatingUIPlacement(position) {
    if (!position.includes('center')) {
        return position;
    }
    const [placement] = position.split('-');
    return placement;
}
function floatingUIPlacementToChameleonPosition(placement) {
    if (!placement.includes('-')) {
        return `${placement}-center`;
    }
    return placement;
}
const reactToPlacementChange = (content) => {
    let previousPlacement;
    return {
        name: 'reactToPlacementChange',
        fn({ x, y, initialPlacement, placement }) {
            if (initialPlacement !== placement || placement !== previousPlacement) {
                const position = floatingUIPlacementToChameleonPosition(placement);
                content.setAttribute('position', position);
            }
            previousPlacement = placement;
            return {
                x,
                y,
            };
        },
    };
};
export function calculatePopoverPosition(popover, content, triggerBounds) {
    return new Promise((resolve) => {
        const positionFallbackPlacements = calculateFallbackPlacements(popover.position);
        requestAnimationFrame(() => {
            const middlewares = [
                flip({
                    fallbackPlacements: positionFallbackPlacements,
                }),
            ];
            if (popover.arrow) {
                middlewares.push(offset(12));
            }
            else {
                middlewares.push(offset(MARGIN_TO_TRIGGER));
            }
            middlewares.push(reactToPlacementChange(content));
            const position = calculateSingleLinePositioning(popover.position, content);
            if (position !== popover.position) {
                content.setAttribute('position', position);
            }
            const cleanup = autoUpdate(popover, content, () => {
                computePosition(popover, content, {
                    placement: chameleonPlacementToFloatingUIPlacement(position),
                    middleware: middlewares,
                }).then(({ x, y }) => {
                    const contentRect = content.getBoundingClientRect();
                    if (popover.width && popover.width >= MIN_WIDTH) {
                        content.style.setProperty('--content-width', `${popover.width}px`);
                        content.setAttribute('custom-width', '');
                    }
                    else {
                        if (popover.menu) {
                            content.style.setProperty('--content-width', `240px`);
                        }
                        else {
                            content.style.setProperty('--content-width', `${contentRect.width}px`);
                        }
                    }
                    const additionalOffset = popover.style.getPropertyValue('--ADDITIONAL-ARROW-OFFSET');
                    if (additionalOffset) {
                        content.style.setProperty('--trigger-x', `${x + +additionalOffset}px`);
                    }
                    else {
                        content.style.setProperty('--trigger-x', `${x}px`);
                    }
                    content.style.setProperty('--trigger-y', `${y}px`);
                    content.style.setProperty('--trigger-width', `${triggerBounds.width}px`);
                    content.style.setProperty('--trigger-height', `${triggerBounds.height}px`);
                    // If the popover is a menu, min-width: 240px otherwise min-width is the minimum of the possible icon size + padding around it (56).
                    // Width of the content should be always be fit content, except the menu, the menu always forced to be 240px width.
                    // If the 'width' is defined for the component then the width should be that number
                    // content-width is used for calculations! ALWAYS NEEDS TO BE IN PIXEL FORMAT !
                    // Max-content is always the maximum of the viewport - the margins
                    if (popover.maxWidth) {
                        content.style.setProperty('--max-width', `${popover.maxWidth}px`);
                    }
                    if (popover.zIndex) {
                        content.style.setProperty('--z-index', `${popover.zIndex}`);
                    }
                    if (contentRect.width >= LARGE_WIDTH) {
                        content.setAttribute('large', '');
                    }
                    content.style.setProperty('--content-height', `${contentRect.height}px`);
                });
            });
            resolve(cleanup);
        });
    });
}
function calculateSingleLinePositioning(position, content) {
    const isSingleLine = convertStylePropertyPixelsToNumber(getComputedStyle(content), 'height') <=
        DEFAULT_SINGLE_LINE_CONTENT_HEIGHT;
    if (isSingleLine && (position.includes('left') || position.includes('right'))) {
        const [primaryPosition] = position.split('-');
        return `${primaryPosition}-center`;
    }
    return position;
}
/**
 * This function determines the correct fallback position if the popover cannot fit into its original position
 * The logic is that first we should try to place it in its original primary position with a different secondary position, if
 * that fails then we try the opposite primary position with the same secondary position first and the other secondary positions after that.
 * If the opposite primary positions fail as well, we shift to the other two primary positions.
 *
 * Example: If the original position is `top-start`, we should try `top` and `top-end`, if those fail as well we go to the `bottom-start` position
 * and then rotate through the rest of the bottom positions. If those fail we visit `right` and `left` positions.
 */
function calculateFallbackPlacements(position) {
    const basePlacementList = [
        'bottom',
        'bottom-start',
        'bottom-end',
        'top',
        'top-start',
        'top-end',
        'right',
        'right-start',
        'right-end',
        'left',
        'left-start',
        'left-end',
    ];
    const oppositePrimaryPositionPairs = {
        top: 'bottom',
        bottom: 'top',
        left: 'right',
        right: 'left',
    };
    const oppositeSecondaryPositionsPairs = {
        end: 'start',
        start: 'end',
        center: 'center',
    };
    const [primaryPlacement, secondaryPlacement] = position.split('-');
    const samePrimaryOptions = [`${primaryPlacement}`, `${primaryPlacement}-start`, `${primaryPlacement}-end`].filter((placement) => placement !== position);
    const oppositePrimaryOptions = [
        `${oppositePrimaryPositionPairs[primaryPlacement]}-${secondaryPlacement}`,
        `${oppositePrimaryPositionPairs[primaryPlacement]}`,
        `${oppositePrimaryPositionPairs[primaryPlacement]}-${oppositeSecondaryPositionsPairs[secondaryPlacement]}`,
    ];
    const rest = basePlacementList.filter((placement) => !samePrimaryOptions.includes(placement) || !oppositePrimaryOptions.includes(placement));
    return [...samePrimaryOptions, ...oppositePrimaryOptions, ...rest];
}
