import { __decorate } from "tslib";
import { animations } from '@getgo/chameleon-core';
import { SVG_CLOSE_OUTLINED } from '@getgo/chameleon-icons';
import { attr, html, when } from '@microsoft/fast-element';
import { DEV } from 'esm-env';
import { FASTElementHeightOverflowObserver } from '../Observers/FastElementHeightOverflowObserver';
export const dialogTemplate = html `
  <template role="dialog">
    <div part="overlay" class="goto-dialog-wrapper">
      <div part="content" :className="goto-dialog goto-dialog--${(x) => x.size}">
        <div part="heading" role="heading" aria-level="1">
          <slot name="title"></slot>
          ${when((x) => x.closable, html `<chameleon-icon-button
              @click="${(x) => x.close()}"
              label="${(x) => x.closeButtonLabel}"
              size="small"
              variant="primary"
              class="close-button"
            >
              <chameleon-svg>${SVG_CLOSE_OUTLINED}</chameleon-svg>
            </chameleon-icon-button>`)}
        </div>
        <div part="body" class="content">
          <slot></slot>
        </div>
        <div part="actions">
          <slot name="actions"></slot>
        </div>
      </div>
    </div>
  </template>
`;
export class DialogComponent extends FASTElementHeightOverflowObserver {
    constructor() {
        super(...arguments);
        this.open = false;
        this.closable = false;
        this.size = 'medium';
        this.closeButtonLabel = undefined;
        this.wrapperElement = null;
        this.handleBackgroundClick = (e) => {
            if (e.target === this.wrapperElement && this.closable) {
                this.close();
            }
        };
        this.handleEscapeKey = (e) => {
            if (e.key === 'Escape' && this.closable) {
                this.close();
            }
        };
    }
    openChanged(_oldValue, newValue) {
        this.refreshVisibleState(newValue);
    }
    connectedCallback() {
        var _a, _b, _c;
        super.connectedCallback();
        const title = this.querySelector('[slot="title"]');
        if (title) {
            this.setAttribute('aria-label', (_a = title.textContent) !== null && _a !== void 0 ? _a : '');
        }
        this.wrapperElement = (_b = this.shadowRoot) === null || _b === void 0 ? void 0 : _b.querySelector('.goto-dialog-wrapper');
        const content = (_c = this.wrapperElement) === null || _c === void 0 ? void 0 : _c.querySelector('.content');
        this.observer.observe(content);
        this.refreshVisibleState(this.hasAttribute('open'));
        if (DEV && this.closable && !this.closeButtonLabel) {
            console.error('CHAMELEON-A11Y: Closable dialog component must have an accessible label for the X icon provided via the close-button-label attribute.');
        }
    }
    disconnectedCallback() {
        super.disconnectedCallback();
        clearTimeout(this.closeTimeout);
        this.unbindEvents();
    }
    show() {
        this.open = true;
        this.bindEvents();
    }
    close() {
        this.open = false;
        this.$emit('closing', null, { bubbles: false });
        this.delayedClosedEvent();
        this.unbindEvents();
    }
    handleElementHeightOverflow(el) {
        if (el) {
            const isElementOverflow = el.clientHeight < el.scrollHeight;
            el.classList.toggle('content--overflow', isElementOverflow);
        }
    }
    bindEvents() {
        var _a;
        (_a = this.wrapperElement) === null || _a === void 0 ? void 0 : _a.addEventListener('mousedown', this.handleBackgroundClick);
        document.addEventListener('keydown', this.handleEscapeKey);
    }
    unbindEvents() {
        var _a;
        (_a = this.wrapperElement) === null || _a === void 0 ? void 0 : _a.removeEventListener('mousedown', this.handleBackgroundClick);
        document.removeEventListener('keydown', this.handleEscapeKey);
    }
    delayedClosedEvent() {
        this.closeTimeout = window.setTimeout(() => {
            this.$emit('closed', null, { bubbles: false });
        }, animations.transitionDelay250);
    }
    refreshVisibleState(isOpen) {
        var _a;
        (_a = this.wrapperElement) === null || _a === void 0 ? void 0 : _a.classList.toggle('visible', isOpen);
        if (isOpen) {
            this.bindEvents();
        }
        else {
            this.unbindEvents();
        }
    }
}
__decorate([
    attr({ mode: 'boolean' })
], DialogComponent.prototype, "open", void 0);
__decorate([
    attr({ mode: 'boolean' })
], DialogComponent.prototype, "closable", void 0);
__decorate([
    attr
], DialogComponent.prototype, "size", void 0);
__decorate([
    attr({ attribute: 'close-button-label' })
], DialogComponent.prototype, "closeButtonLabel", void 0);
