import { AnchoredRegion, anchoredRegionTemplate as template } from '@microsoft/fast-foundation';
import { anchoredRegionStyles } from '@microsoft/fast-components';
import { css } from '@microsoft/fast-element';
import { AnchoredRegionComponent } from './AnchoredRegion';
import styles from './AnchoredRegion.styles.scss';
export const chameleonAnchorRegion = AnchoredRegionComponent.compose({
    baseName: 'anchor-region',
    baseClass: AnchoredRegion,
    template: template,
    styles: (ctx, def) => css `
    ${anchoredRegionStyles(ctx, def)}
    ${styles}
  `,
});
