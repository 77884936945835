import { provideChameleonDesignSystem } from '@getgo/chameleon-toolkit';
import { chameleonChatInput } from './ChatInput.definition';
import { chameleonIconButton } from '../IconButton/IconButton.definition';
import { chameleonSvg } from '../Svg';
import { chameleonScrollBox } from './ScrollBox/ScrollBox.definition';
import { chameleonCircularProgress } from '../CircularProgress/CircularProgress.definition';
import { chameleonTooltipV3 } from '../TooltipV3/TooltipV3.definition';
export function registerCustom(prefix, node) {
    return provideChameleonDesignSystem({ prefix, node }).register(chameleonChatInput(), chameleonIconButton(), chameleonSvg(), chameleonScrollBox(), chameleonCircularProgress(), chameleonTooltipV3());
}
