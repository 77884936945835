import { __decorate } from "tslib";
import { attr, DOM, html, Observable, observable, ref, repeat, slotted, volatile, when } from '@microsoft/fast-element';
import { SVG_CHEVRON_DOWN_FILLED, SVG_CHEVRON_UP_FILLED, SVG_CLOSE_CIRCLE_FILLED } from '@getgo/chameleon-icons';
import { FoundationElement } from '@microsoft/fast-foundation';
import { uniqueId } from '@microsoft/fast-web-utilities';
import { autoUpdate, computePosition, flip } from '@floating-ui/dom';
function optionFilter(node) {
    return node.nodeName === 'CHAMELEON-OPTION';
}
export const autocompleteTemplate = html `
  <template @click="${(el, ctx) => el.handleClick(ctx.event)}">
    <label class="label" part="label" for="${(x) => x.controlId}">
      <slot name="label"></slot>
    </label>
    <div
      ${ref('controlBox')}
      part="control"
      class="control"
      @ch-chip-close="${(el, ctx) => el.handleChipClick(ctx.event)}"
    >
      <div class="control-area">
        <slot name="start"></slot>
        ${when((x) => x.multiple, html `<slot name="chips" ${slotted('slottedChips')}
            >${repeat((x) => x.selectedOptions, html `<chameleon-chip-v2
                close-label="Remove option ${(o) => o.label}"
                size="small"
                data-option-id="${(o) => o.id}"
                closable
                >${(o) => o.label}</chameleon-chip-v2
              >`)}</slot
          >`)}
        <slot name="control">
          <input
            part="input-text"
            id="${(x) => x.controlId}"
            type="text"
            value="${(x) => x.value}"
            placeholder="${(x) => x.displayedPlaceholder}"
            class="selected-value"
            ?disabled="${(x) => x.disabled}"
            ${ref('control')}
            @input="${(element, context) => element.handleInput(context.event)}"
            @blur="${(el, context) => el.handleBlur(context.event)}"
            @keydown="${(x, ctx) => x.keydownHandler(ctx.event)}"
            role="combobox"
            aria-autocomplete="list"
            aria-controls="${(el) => el.listboxId}"
            aria-expanded="${(el) => el.open}"
            aria-haspopup="listbox"
            aria-activedescendant="${(el) => el.ariaActiveDescandant}"
          />
        </slot>
      </div>
      <div class="buttons" part="buttons">
        <chameleon-icon-button
          tabindex="-1"
          size="${(el) => el.size}"
          class="clear-btn"
          label="${(el) => el.clearButtonLabel}"
          has-selected-option="${(el) => el.hasSelectedOption}"
          @click="${(el, ctx) => el.handleClearButtonClick(ctx.event)}"
        >
          <chameleon-svg ${ref('closeButton')}>${SVG_CLOSE_CIRCLE_FILLED}</chameleon-svg>
        </chameleon-icon-button>
        ${when((x) => x.variant === 'select', html `<chameleon-icon-button
            tabindex="-1"
            size="${(el) => el.size}"
            class="chevron"
            @click="${(el, ctx) => el.handleChevronClick(ctx.event)}"
            aria-controls="${(el) => el.listboxId}"
            aria-expanded="${(el) => el.open}"
            label="${(el) => el.toggleButtonLabel}"
          >
            <chameleon-svg ${ref('chevron')}
              >${(x) => (x.open ? SVG_CHEVRON_UP_FILLED : SVG_CHEVRON_DOWN_FILLED)}</chameleon-svg
            >
          </chameleon-icon-button>`)}
      </div>
    </div>
    <div id="${(el) => el.listboxId}" ${ref('listbox')} part="listbox" class="listbox ${(x) => (x.open ? 'open' : '')}">
      <slot ${slotted({ property: 'slottedOptions', filter: optionFilter })}></slot>
      <chameleon-option ${ref('noOptionsElement')} ?hidden=${(x) => !x.shouldShowNoOption} disabled
        >${(x) => x.noOptionsText}</chameleon-option
      >
      <chameleon-option ${ref('loaderOption')} hidden><chameleon-skeleton></chameleon-skeleton></chameleon-option>
    </div>
    <slot name="helper-text"></slot>
  </template>
`;
export class AutocompleteComponent extends FoundationElement {
    constructor() {
        super(...arguments);
        this.variant = 'select';
        this.size = 'medium';
        this.disabled = false;
        this.multiple = false;
        this.fullWidth = false;
        this.noOptionsText = 'No Options';
        this.clearButtonLabel = '';
        this.toggleButtonLabel = '';
        this.placeholder = undefined;
        this.loading = false;
        /** Users can set this to true to disable the built-in filtering of options.
         * Useful when the options are rendered as a result of an async call.
         * */
        this.disableFiltering = false;
        this.position = 'bottom';
        this._value = '';
        this._options = [];
        /** The current value of the filter */
        this.filter = '';
        this.listboxId = uniqueId('listbox');
        /** Marks which option the user is on when moving with keyboard */
        this.currentIndex = -1;
        this.controlId = uniqueId('chameleon-autocomplete-control-');
        this.open = false;
        this.selectedOptions = [];
        this.slottedOptions = [];
        this.filteredOptions = [];
    }
    loadingChanged(_, next) {
        var _a, _b, _c, _d;
        if (next && this.options) {
            for (const option of this.options) {
                option.setAttribute('hidden', 'true');
            }
            (_a = this.noOptionsElement) === null || _a === void 0 ? void 0 : _a.setAttribute('hidden', 'true');
            (_b = this.loaderOption) === null || _b === void 0 ? void 0 : _b.removeAttribute('hidden');
        }
        if (next === false && this.options) {
            for (const option of this.options) {
                option.removeAttribute('hidden');
            }
            (_c = this.loaderOption) === null || _c === void 0 ? void 0 : _c.setAttribute('hidden', 'true');
            if (this.options.length === 0) {
                (_d = this.noOptionsElement) === null || _d === void 0 ? void 0 : _d.removeAttribute('hidden');
            }
        }
    }
    positionChanged() {
        this.setPositioning();
    }
    slottedOptionsChanged(_prev, next) {
        var _a;
        this.options = next.filter((o) => optionFilter(o));
        const setSize = this.options.length;
        this.options.forEach((option, index) => {
            if (!option.id) {
                option.id = uniqueId('option-');
            }
            option.ariaPosInSet = `${index + 1}`;
            option.ariaSetSize = setSize.toString();
            if (option.selected) {
                this.selectOption(option);
            }
            const selectedOptionIndex = this.selectedOptions.findIndex((o) => option.value === o.value);
            if (selectedOptionIndex > -1 && !this.selectedOptions[selectedOptionIndex].selected) {
                this.selectedOptions[selectedOptionIndex].selected = true;
            }
        });
        if (this.options.length === 0) {
            (_a = this.noOptionsElement) === null || _a === void 0 ? void 0 : _a.setAttribute('hidden', 'true');
        }
        this.filterOptions(this.filter);
    }
    slottedChipsChanged(_prev, next) {
        if (next && next.length > 0) {
            for (const chip of next) {
                if (chip.getAttribute('size') !== 'small') {
                    chip.setAttribute('size', 'small');
                }
            }
        }
    }
    get shouldShowNoOption() {
        var _a, _b;
        return (this.loading !== true &&
            ((!this.disableFiltering && ((_a = this.filteredOptions) === null || _a === void 0 ? void 0 : _a.length) === 0) ||
                (this.disableFiltering && ((_b = this.slottedOptions) === null || _b === void 0 ? void 0 : _b.length) === 0)));
    }
    get displayedPlaceholder() {
        return this.selectedOptions.length === 0 ? this.placeholder : undefined;
    }
    get firstOption() {
        return this.disableFiltering ? this.slottedOptions[0] : this.filteredOptions[0];
    }
    get isSearchType() {
        return this.variant === 'search';
    }
    get hasSelectedOption() {
        Observable.track(this, 'hasSelectedOption');
        return this.selectedOptions.length > 0;
    }
    get options() {
        var _a;
        Observable.track(this, 'value');
        return ((_a = this.filteredOptions) === null || _a === void 0 ? void 0 : _a.length) > 0 ? this.filteredOptions : this._options;
    }
    set options(next) {
        this._options = next;
        Observable.notify(this, 'options');
    }
    get value() {
        Observable.track(this, 'value');
        return this._value;
    }
    set value(next) {
        this._value = next;
        Observable.notify(this, 'value');
    }
    get ariaActiveDescandant() {
        return this.open && this.currentIndex > -1 ? this.options[this.currentIndex].id : null;
    }
    filterFunction(inputValue, option) {
        var _a;
        return (_a = this.getOptionText(option).toLowerCase().startsWith(inputValue.toLowerCase())) !== null && _a !== void 0 ? _a : false;
    }
    disconnectedCallback() {
        var _a;
        (_a = this.autoPositioningCleanup) === null || _a === void 0 ? void 0 : _a.call(this);
    }
    handleClick(e) {
        if (this.disabled || e.defaultPrevented) {
            return;
        }
        if (this.open) {
            const capturedOption = e.target.closest('option, [role=option]');
            const isAlreadySelected = this.selectedOptions.findIndex((o) => this.getOptionText(o) === this.getOptionText(capturedOption)) > -1;
            if (!capturedOption || capturedOption.disabled) {
                return;
            }
            if (isAlreadySelected && this.multiple && this.variant !== 'select') {
                this.deselectOption(capturedOption);
            }
            else {
                this.selectOption(capturedOption);
            }
        }
        if (!this.open && !this.isSearchType) {
            this.openListbox();
        }
        else {
            this.closeListbox();
        }
        if (this.open) {
            this.control.focus();
        }
        return true;
    }
    handleChipClick(event) {
        event.preventDefault();
        const optionId = event.target.getAttribute('data-option-id');
        if (!optionId) {
            console.warn('Chip element inside Autocomplete must have a data-option-id attribute set to the id of the option element it refers to for the close functionality to work.');
            return;
        }
        const optionToRemove = this.selectedOptions.find((option) => option.id === optionId);
        if (!optionToRemove) {
            return;
        }
        this.deselectOption(optionToRemove);
        this.control.focus();
    }
    handleInput(e) {
        e.stopPropagation();
        if (this.currentIndex > 0 && !this.isOptionSelected(this.options[this.currentIndex])) {
            this.options[this.currentIndex].selected = false;
        }
        this.currentIndex = -1;
        if (this.isSearchType && e.target.value === '') {
            this.closeListbox();
        }
        else {
            this.openListbox();
        }
        this.filter = e.target.value;
        this.filterOptions(e.target.value);
        this.$emit('input', { value: e.target.value }, { bubbles: false });
    }
    handleBlur(e) {
        var _a;
        const chevron = (_a = this.shadowRoot) === null || _a === void 0 ? void 0 : _a.querySelector('.chevron');
        // The chevron is always rendered so if this can never be true, but we have to satisfy TS
        if (!chevron) {
            return;
        }
        // This branch is for handling the case where there's something written in the input
        // but there's no selected option. In this case we just set the input back to an empty string.
        if (!this.multiple && this.control.value && !this.hasSelectedOption && !this.firstOption) {
            this.filter = '';
            this.control.value = '';
            this.filterOptions(this.filter);
            this.$emit('input', { value: '' });
        }
        // Handles the scenario where the user deletes the selected value from the input completely,
        // in this case we should remove the selection.
        if (!this.multiple && this.control.value === '') {
            this.deselectOptions();
        }
        // This branch handles when there's a selected option, but the text in the input does not
        // match the label of the selected option
        if (!this.multiple &&
            this.hasSelectedOption &&
            this.control.value !== '' &&
            this.control.value !== this.getOptionText(this.selectedOptions[0])) {
            this.control.value = this.getOptionText(this.selectedOptions[0]);
            this.filter = this.getOptionText(this.selectedOptions[0]);
            this.filterOptions(this.filter);
            this.$emit('input', { value: this.getOptionText(this.selectedOptions[0]) });
        }
        // This branch handles when the focus leaves a multiple autocomoplete.
        // The logic is if there's something in the input and there's an option matching that
        // we select it, otherwiswe we clear the input
        if (this.multiple && this.control.value && this.firstOption) {
            this.selectOption(this.firstOption);
            this.filter = '';
            this.control.value = '';
            this.filterOptions(this.filter);
            this.$emit('input', { value: '' });
        }
        else if (!this.firstOption) {
            this.filter = '';
            this.control.value = '';
            this.filterOptions(this.filter);
            this.$emit('input', { value: '' });
        }
        if (e.relatedTarget === chevron) {
            return;
        }
        this.closeListbox();
    }
    keydownHandler(e) {
        var _a;
        const key = e.key;
        e.stopPropagation();
        if (e.ctrlKey || e.shiftKey) {
            return true;
        }
        switch (key) {
            case 'Enter': {
                // if we cannot find the option based on the currentIndex, for example if the user has not moved in the list with the arrow keys
                // then we select the first matching option from the filteredOptions
                const optionToSelect = (_a = this.options[this.currentIndex]) !== null && _a !== void 0 ? _a : this.filteredOptions[0];
                const isAlreadySelected = this.selectedOptions.findIndex((o) => this.getOptionText(o) === this.getOptionText(optionToSelect)) > -1;
                if (this.open && optionToSelect && !isAlreadySelected) {
                    this.selectOption(optionToSelect);
                }
                if (!this.multiple || this.isSearchType) {
                    this.closeListbox();
                }
                break;
            }
            case 'Escape': {
                if (this.open) {
                    this.closeListbox();
                    break;
                }
                this.value = '';
                this.control.value = '';
                this.filter = '';
                this.filterOptions(this.filter);
                this.control.focus();
                break;
            }
            case 'Tab': {
                const optionToSelect = this.options[this.currentIndex];
                const isAlreadySelected = this.selectedOptions.findIndex((o) => this.getOptionText(o) === this.getOptionText(optionToSelect)) > -1;
                if (optionToSelect && !isAlreadySelected) {
                    this.selectOption(optionToSelect);
                }
                if (!this.open) {
                    return true;
                }
                this.closeListbox();
                return true;
            }
            case 'ArrowUp':
            case 'ArrowDown': {
                if (!this.open && !this.isSearchType) {
                    this.openListbox();
                }
                if (e.altKey) {
                    break;
                }
                if (this.filteredOptions.length > 0 || (this.disableFiltering && this.slottedOptions.length > 0)) {
                    if (e.key === 'ArrowUp') {
                        this.moveToPreviousOption();
                    }
                    if (e.key === 'ArrowDown') {
                        this.moveToNextOption();
                    }
                }
                break;
            }
            case 'Home': {
                this.control.selectionStart = 0;
                this.control.selectionEnd = 0;
                e.preventDefault();
                break;
            }
            case 'End': {
                this.control.selectionStart = this.control.value.length;
                this.control.selectionEnd = this.control.value.length;
                e.preventDefault();
                break;
            }
            default: {
                return true;
            }
        }
    }
    handleChevronClick(e) {
        if (this.disabled) {
            return;
        }
        if (this.open) {
            this.closeListbox();
        }
        else {
            this.openListbox();
        }
        this.control.focus();
        e.stopPropagation();
    }
    handleClearButtonClick(e) {
        this.deselectOptions();
        this.$emit('clear-click');
        this.control.focus();
        e.stopPropagation();
    }
    setPositioning() {
        var _a;
        if (!(this instanceof HTMLElement) || !this.isConnected) {
            return;
        }
        (_a = this.autoPositioningCleanup) === null || _a === void 0 ? void 0 : _a.call(this);
        DOM.queueUpdate(() => {
            this.autoPositioningCleanup = autoUpdate(this.controlBox, this.listbox, () => {
                computePosition(this.controlBox, this.listbox, { placement: this.position, middleware: [flip()] }).then(({ y, placement }) => {
                    if (this.position !== placement) {
                        this.position = placement;
                    }
                    // we have to offset the y value by 2 pixels to account for the border and the focus ring of the control box
                    const offsetPosition = placement === 'bottom' ? y + 2 : y - 2;
                    this.style.setProperty('--autocomplete-listbox-position', `${offsetPosition}px`);
                });
            });
        });
    }
    filterOptions(filterValue) {
        var _a, _b, _c;
        if (this.disableFiltering) {
            return;
        }
        this.filteredOptions = this.slottedOptions.filter((option) => this.filterFunction(filterValue, option));
        (_a = this.slottedOptions) === null || _a === void 0 ? void 0 : _a.forEach((option) => {
            if (!this.filterFunction(filterValue !== null && filterValue !== void 0 ? filterValue : '', option)) {
                option.setAttribute('hidden', 'true');
                option.classList.add('hidden');
            }
            else {
                option.removeAttribute('hidden');
                option.classList.remove('hidden');
            }
        });
        if (this.filteredOptions.length === 0) {
            (_b = this.noOptionsElement) === null || _b === void 0 ? void 0 : _b.removeAttribute('hidden');
        }
        else {
            (_c = this.noOptionsElement) === null || _c === void 0 ? void 0 : _c.setAttribute('hidden', 'true');
        }
        if (!this.filteredOptions && !this.filter) {
            this.filteredOptions = this.slottedOptions;
        }
    }
    openListbox() {
        this.setPositioning();
        this.open = true;
    }
    closeListbox() {
        this.open = false;
        this.currentIndex = -1;
        // Remove selected styling from the element tha the currentIndex was pointing to
        // but not the ones that are truly selected.
        for (const option of this.options) {
            if (!this.isOptionSelected(option)) {
                option.selected = false;
            }
        }
    }
    moveToNextOption() {
        let next = -1;
        const totalOptions = this.options.length;
        // Determine the starting index for the loop
        const startIndex = this.currentIndex === -1 ? -1 : this.currentIndex;
        // Loop through the list starting from the startIndex
        for (let i = 1; i <= totalOptions; i++) {
            const index = (startIndex + i) % totalOptions;
            if (!this.options[index].disabled) {
                next = index;
                break;
            }
        }
        if (next >= 0) {
            if (this.options[this.currentIndex] && !this.isOptionSelected(this.options[this.currentIndex])) {
                this.options[this.currentIndex].selected = false;
            }
            this.currentIndex = next;
            this.options[next].selected = true;
            this.focusAndScrollOptionIntoView(this.options[next]);
        }
    }
    moveToPreviousOption() {
        let prev = -1;
        const totalOptions = this.options.length;
        // If no option is currently selected, set it to the last option.
        if (this.currentIndex === -1) {
            this.currentIndex = totalOptions;
        }
        // Loop through the list starting from the currentIndex
        for (let i = 1; i < totalOptions; i++) {
            const index = (this.currentIndex - i + totalOptions) % totalOptions;
            if (!this.options[index].disabled) {
                prev = index;
                break;
            }
        }
        if (prev >= 0) {
            if (this.options[this.currentIndex] && !this.isOptionSelected(this.options[this.currentIndex])) {
                this.options[this.currentIndex].selected = false;
            }
            this.currentIndex = prev;
            this.options[prev].selected = true;
            this.focusAndScrollOptionIntoView(this.options[prev]);
        }
    }
    selectOption(option) {
        if (this.selectedOptions.includes(option)) {
            return;
        }
        option.selected = true;
        if (this.multiple) {
            this.selectedOptions = [...this.selectedOptions, option];
            this.control.value = '';
            this.filter = '';
            this.filterOptions(this.filter);
            this.$emit('input', { value: '' });
        }
        else {
            if (this.hasSelectedOption) {
                this.selectedOptions[0].selected = false;
            }
            this.selectedOptions = [option];
            this.value = this.getOptionText(option);
            this.filter = '';
            this.control.value = this.getOptionText(option);
            this.filterOptions(this.filter);
            this.$emit('input', { value: this.getOptionText(this.selectedOptions[0]) });
        }
        this.$emit('change', { selectedOptions: this.selectedOptions });
    }
    deselectOption(option) {
        option.selected = false;
        if (this.multiple) {
            this.selectedOptions = this.selectedOptions.filter((o) => this.getOptionText(o) !== this.getOptionText(option));
            this.value = '';
        }
        else {
            this.selectedOptions = [];
            this.value = '';
            this.filter = '';
            this.filterOptions(this.filter);
            this.control.value = '';
            this.$emit('input', { value: '' });
        }
        this.$emit('change', { selectedOptions: this.selectedOptions }, { bubbles: true });
    }
    deselectOptions() {
        for (const option of this.options) {
            option.selected = false;
        }
        this.selectedOptions = [];
        this.value = '';
        this.filter = '';
        this.control.value = '';
        this.filterOptions(this.filter);
        this.$emit('input', { value: '' });
        this.$emit('change', { selectedOptions: this.selectedOptions }, { bubbles: true });
    }
    isOptionSelected(option) {
        return this.selectedOptions.findIndex((o) => this.getOptionText(o) === this.getOptionText(option)) > -1;
    }
    focusAndScrollOptionIntoView(option) {
        requestAnimationFrame(() => {
            option.scrollIntoView({ block: 'nearest' });
        });
    }
    getOptionText(option) {
        return option === null || option === void 0 ? void 0 : option.label;
    }
}
__decorate([
    attr()
], AutocompleteComponent.prototype, "variant", void 0);
__decorate([
    attr()
], AutocompleteComponent.prototype, "size", void 0);
__decorate([
    attr({ mode: 'boolean' })
], AutocompleteComponent.prototype, "disabled", void 0);
__decorate([
    attr({ mode: 'boolean' })
], AutocompleteComponent.prototype, "multiple", void 0);
__decorate([
    attr({ mode: 'boolean', attribute: 'full-width' })
], AutocompleteComponent.prototype, "fullWidth", void 0);
__decorate([
    attr({ mode: 'fromView', attribute: 'no-options-text' })
], AutocompleteComponent.prototype, "noOptionsText", void 0);
__decorate([
    attr({ attribute: 'clear-button-label' })
], AutocompleteComponent.prototype, "clearButtonLabel", void 0);
__decorate([
    attr({ attribute: 'toggle-button-label' })
], AutocompleteComponent.prototype, "toggleButtonLabel", void 0);
__decorate([
    attr()
], AutocompleteComponent.prototype, "placeholder", void 0);
__decorate([
    attr({ mode: 'boolean' })
], AutocompleteComponent.prototype, "loading", void 0);
__decorate([
    attr({ mode: 'boolean', attribute: 'disable-filtering' })
], AutocompleteComponent.prototype, "disableFiltering", void 0);
__decorate([
    attr
], AutocompleteComponent.prototype, "position", void 0);
__decorate([
    observable
], AutocompleteComponent.prototype, "filter", void 0);
__decorate([
    observable
], AutocompleteComponent.prototype, "currentIndex", void 0);
__decorate([
    observable
], AutocompleteComponent.prototype, "controlId", void 0);
__decorate([
    observable
], AutocompleteComponent.prototype, "open", void 0);
__decorate([
    observable
], AutocompleteComponent.prototype, "selectedOptions", void 0);
__decorate([
    observable
], AutocompleteComponent.prototype, "slottedOptions", void 0);
__decorate([
    observable
], AutocompleteComponent.prototype, "slottedChips", void 0);
__decorate([
    observable
], AutocompleteComponent.prototype, "filteredOptions", void 0);
__decorate([
    observable
], AutocompleteComponent.prototype, "shouldShowNoOption", null);
__decorate([
    volatile
], AutocompleteComponent.prototype, "displayedPlaceholder", null);
__decorate([
    volatile
], AutocompleteComponent.prototype, "ariaActiveDescandant", null);
