import { __rest } from "tslib";
import React, { forwardRef } from 'react';
import { createReactWrapper } from '../common/PrefixUtils';
export const ButtonLink = forwardRef((_a, ref) => {
    var { children } = _a, props = __rest(_a, ["children"]);
    return createReactWrapper({
        tagName: 'chameleon-button-link',
        props: Object.assign(Object.assign({}, props), { ref }),
        children: React.createElement(React.Fragment, null, children),
    });
});
