import { Tab } from '@microsoft/fast-foundation';
import { ChameleonElementMixin } from '../../common/mixin';
export class TabComponent extends ChameleonElementMixin(Tab) {
    connectedCallback() {
        super.connectedCallback();
        this.updateDisabledClass(this.hasAttribute('disabled'));
    }
    disabledChanged(oldValue, newValue) {
        this.updateDisabledClass(newValue);
    }
    updateDisabledClass(disabled) {
        this.classList.toggle('disabled', disabled);
    }
}
