import { __decorate } from "tslib";
import { attr, html, observable, slotted } from '@microsoft/fast-element';
import { FoundationElement } from '@microsoft/fast-foundation';
import { ChameleonElementMixin } from '../common/mixin';
import { DEV } from 'esm-env';
export const badgeCounterTemplate = html `<template role="status">
  <slot ${slotted('defaultSlot')}></slot>
</template>`;
export class BadgeCounterComponent extends ChameleonElementMixin(FoundationElement) {
    defaultSlotChanged(_, newValue) {
        if (this.isConnected) {
            if (newValue) {
                this.normalizeMoreThanHundred();
            }
        }
    }
    labelChanged() {
        this.setAccessibleLabel();
    }
    constructor() {
        super();
        this.size = 'medium';
        this.variant = 'primary';
        this.border = 'none';
        this.defaultSlot = [];
        this.handleContentMutation = (mutationsList) => {
            for (const mutation of mutationsList) {
                if (mutation.type === 'childList' || mutation.type === 'characterData') {
                    this.normalizeMoreThanHundred();
                    this.classList.toggle('dot', !this.textContent);
                }
            }
        };
        this.contentObserver = new MutationObserver(this.handleContentMutation);
    }
    connectedCallback() {
        super.connectedCallback();
        this.classList.toggle('dot', !this.textContent);
        this.setAccessibleLabel();
        this.contentObserver.observe(this, { childList: true, subtree: true, characterData: true });
    }
    disconnectedCallback() {
        super.disconnectedCallback();
        this.contentObserver.disconnect();
    }
    normalizeMoreThanHundred() {
        if (+this.innerText.trim() >= 100) {
            this.value = +this.innerText.trim();
            this.textContent = '99+';
        }
    }
    setAccessibleLabel() {
        if (this.label) {
            this.setAttribute('aria-label', `${this.label}`);
            return;
        }
        if (DEV) {
            this.validateAccessibleLabel();
        }
    }
    validateAccessibleLabel() {
        var _a;
        if (!((_a = this.getAttribute('aria-label')) === null || _a === void 0 ? void 0 : _a.length)) {
            console.error('CHAMELEON-A11Y: BadgeCounter component must have an accessible label provided by a non-empty label attribute to be used as aria-label.');
        }
    }
}
__decorate([
    attr()
], BadgeCounterComponent.prototype, "size", void 0);
__decorate([
    attr()
], BadgeCounterComponent.prototype, "variant", void 0);
__decorate([
    attr()
], BadgeCounterComponent.prototype, "border", void 0);
__decorate([
    attr
], BadgeCounterComponent.prototype, "value", void 0);
__decorate([
    attr({ mode: 'boolean' })
], BadgeCounterComponent.prototype, "counter", void 0);
__decorate([
    attr()
], BadgeCounterComponent.prototype, "label", void 0);
__decorate([
    observable
], BadgeCounterComponent.prototype, "defaultSlot", void 0);
