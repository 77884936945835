import { __rest } from "tslib";
import React, { forwardRef } from 'react';
import { createReactWrapper } from '../common/PrefixUtils';
import { addExtraProps } from '../common/utils/AddExtraProps';
export const Button = forwardRef((_a, ref) => {
    var { labelId, isLoading, children, leadingIcon, trailingIcon, autoFocus } = _a, props = __rest(_a, ["labelId", "isLoading", "children", "leadingIcon", "trailingIcon", "autoFocus"]);
    return createReactWrapper({
        tagName: 'chameleon-button',
        props: Object.assign(Object.assign({}, props), { 'is-loading': isLoading, 'label-id': labelId, autofocus: autoFocus, ref }),
        children: (React.createElement(React.Fragment, null,
            leadingIcon && addExtraProps(leadingIcon, { slot: 'start' }),
            children,
            trailingIcon && addExtraProps(trailingIcon, { slot: 'end' }))),
    });
});
