import { __rest } from "tslib";
import { forwardRef } from 'react';
import { createReactWrapper } from '../common/PrefixUtils';
export const Skeleton = forwardRef((_a, ref) => {
    var { children } = _a, props = __rest(_a, ["children"]);
    return createReactWrapper({
        tagName: 'chameleon-skeleton',
        props: Object.assign(Object.assign({}, props), { ref }),
        children,
    });
});
