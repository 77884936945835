import { __decorate } from "tslib";
import { attr } from '@microsoft/fast-element';
import { MenuItem } from '@microsoft/fast-foundation';
import { badgeCounterDefinition } from '../../BadgeCounter/BadgeCounter.definition';
import { ChameleonElementMixin } from '../../common/mixin';
import { DEV } from 'esm-env';
export class MenuItemComponent extends ChameleonElementMixin(MenuItem) {
    constructor() {
        super(...arguments);
        this.size = 'medium';
        this.handleChangeEvent = (_e) => {
            this.$emit('menuitemclick', null, { bubbles: true, cancelable: true });
        };
        // eslint-disable-next-line inclusive-language/use-inclusive-words
        /**
         * Overwrite the original keyDown handler from FAST, because we do **not** want
         * to call `.preventDefault()` on the event if the menu-item's parent element is an `a` tag
         * because that would prevent navigation.
         * https://github.com/microsoft/fast/blob/master/packages/web-components/fast-foundation/src/menu-item/menu-item.ts#L180
         *
         * UPDATE: Need to pass the original method to a function because calling with super throws error and here is the explanation:
         * https://stackoverflow.com/questions/64498584/typescript-calling-super-method-from-extended-class-gives-type-error-interme
         * */
        this.fastHandleMenuItemKeyDown = this.handleMenuItemKeyDown;
        this.handleMenuItemKeyDown = (e) => {
            var _a;
            const result = this.fastHandleMenuItemKeyDown(e);
            if (((_a = this.parentElement) === null || _a === void 0 ? void 0 : _a.tagName) === 'A') {
                return true;
            }
            return result;
        };
        // eslint-disable-next-line inclusive-language/use-inclusive-words
        /**
         * Overwrite the original click handler from FAST, because we do **not** want
         * to call `.preventDefault()` on the event if the menu-item's parent element is an `a` tag
         * because that would prevent navigation.
         * https://github.com/microsoft/fast/blob/master/packages/web-components/fast-foundation/src/menu-item/menu-item.ts#L220
         * */
        this.handleMenuItemClick = (e) => {
            var _a;
            if (e.defaultPrevented || this.disabled) {
                return false;
            }
            if (((_a = this.parentElement) === null || _a === void 0 ? void 0 : _a.tagName) === 'A') {
                return true;
            }
            this['invoke']();
            return false;
        };
    }
    connectedCallback() {
        super.connectedCallback();
        this.setAccessibleLabel();
        this.addEventListener('change', this.handleChangeEvent);
        if (this.size === 'small') {
            this.setBadgeCounterSize();
        }
    }
    disconnectedCallback() {
        super.disconnectedCallback();
        this.removeEventListener('change', this.handleChangeEvent);
    }
    /**
     * If a BadgeCounter is placed in the `end` slot of a MenuItem, we need to
     * set it's size to `small` if the MenuItem's size is small as well for visual consistency.
     */
    setBadgeCounterSize() {
        const trailingElements = this.end.assignedElements();
        if (trailingElements.length !== 0) {
            for (const element of trailingElements) {
                if (element.tagName.toLocaleLowerCase().includes(badgeCounterDefinition.baseName)) {
                    element.setAttribute('size', this.size);
                }
            }
        }
    }
    getInternalTextContent() {
        var _a;
        // Parse text content to remove the svg code portion of the string for leading and/or trailing icons, if they exist. Replace is run twice, in case both are present.
        // Remaining string represents visible text content provided to the button
        const regex = new RegExp(`<svg(.*?)<\/svg>`, 'gi');
        return (_a = this.textContent) === null || _a === void 0 ? void 0 : _a.replaceAll(regex, '');
    }
    setAccessibleLabel() {
        var _a;
        if (this.getInternalTextContent()) {
            return;
        }
        if (DEV && !((_a = this.getInternalTextContent()) === null || _a === void 0 ? void 0 : _a.length)) {
            console.error('CHAMELEON-A11Y: MenuItem component must have an accessible label provided by text content.');
        }
    }
}
__decorate([
    attr
], MenuItemComponent.prototype, "size", void 0);
