import { treeViewStyles } from '@microsoft/fast-components';
import { css } from '@microsoft/fast-element';
import { TreeView, treeViewTemplate as template } from '@microsoft/fast-foundation';
import { TreeViewComponent } from './TreeView';
import styles from './TreeView.styles.scss';
export const treeViewDefinition = {
    baseName: 'tree-view',
    baseClass: TreeView,
    template: template,
    styles: (ctx, def) => css `
    ${treeViewStyles(ctx, def)}
    ${styles}
  `,
};
export const chameleonTreeView = TreeViewComponent.compose(treeViewDefinition);
