import { FC } from 'react';
import { useIntl } from 'react-intl';
import { CheckmarkCircleOutlinedIcon } from '@getgo/chameleon-icons/react';
import { Button, ButtonLink, Dialog, MenuSeparator, Typography } from '@getgo/chameleon-web-react-wrapper';

import config from 'config';
import { useAppSelector } from 'hooks';
import { isProcessRenewalSuccessful } from 'modules/cog/cog-selector';
import { accountKey } from 'modules/global-wrapper';
import { sessionProducts, sessionSuccessRedirectUrl, sesssionCancelRedirectUrl } from 'modules/session-details';
import { isProcessQuoteSuccessful } from 'modules/ucs/ucs-selector';
import { classicProductFamilyKey, PARTNER_TYPES, SEARCH_PARAMS } from 'utils/constants';
import st from 'utils/shared-translations';

import './feedback.css';

const PaymentSuccess: FC = (): JSX.Element => {
  const intl = useIntl();

  const partnerType = localStorage.getItem(SEARCH_PARAMS.partnerType);
  const selectedAccountKey = useAppSelector(accountKey);

  const selectedIsProcessQuoteSucceed = useAppSelector(isProcessQuoteSuccessful);
  const selectedIsProcessRenewalSucceed = useAppSelector(isProcessRenewalSuccessful);

  const selectedSuccessRedirectUrl = useAppSelector(sessionSuccessRedirectUrl);
  const selectedCancelRedirectUrl = useAppSelector(sesssionCancelRedirectUrl);
  const selectedQuotePriceList = useAppSelector(sessionProducts);

  const isClassicProductFamily = selectedQuotePriceList.some((item) =>
    classicProductFamilyKey.includes(item?.productGroup),
  );

  const goToSubscriptions = () => {
    localStorage.clear();
    window.location.assign(selectedSuccessRedirectUrl);
  };

  const hasGrantPartnerAccessPrivilege =
    selectedCancelRedirectUrl.includes('quotes') && partnerType?.toUpperCase() === PARTNER_TYPES.child;

  return (
    <Dialog
      open={selectedIsProcessQuoteSucceed || selectedIsProcessRenewalSucceed}
      closable={false}
      size="large"
      actions={
        <div className="feedback__ctas">
          {isClassicProductFamily && !hasGrantPartnerAccessPrivilege && (
            <ButtonLink variant="tertiary" size="medium" href={config.CLASSIC_PRODUCTS_URL}>
              {intl.formatMessage(st['feedback.success.to.product'])}
            </ButtonLink>
          )}
          {hasGrantPartnerAccessPrivilege && (
            <ButtonLink
              variant="tertiary"
              size="medium"
              href={`${config.ADMIN_URL}/${selectedAccountKey}/settings/partner`}
              onClick={() => localStorage.clear()}
            >
              {intl.formatMessage(st['feedback.success.partner.grant.cta'])}
            </ButtonLink>
          )}
          <Button size="medium" variant="primary" onClick={goToSubscriptions}>
            {intl.formatMessage(st['feedback.success.back.to.subscriptions'])}
          </Button>
        </div>
      }
    >
      <div className="feedback__body">
        <CheckmarkCircleOutlinedIcon size="80px" color="var(--goto-icon-color-success)" />
        <Typography tag="h3" variant="heading-small" className="feedback__body-info" gutterBottom>
          {intl.formatMessage(st['feedback.success.order.purchase.successful'])}
        </Typography>
        <MenuSeparator className="feedback__menu-separator" />
      </div>
    </Dialog>
  );
};

export default PaymentSuccess;
