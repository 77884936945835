import { __rest } from "tslib";
import { forwardRef, useEffect, useRef } from 'react';
import { createReactWrapper } from '../common/PrefixUtils';
import { handleForwardRef } from '../common/RefUtils';
import { ChangeSyntheticEvent } from '../common/SyntheticEvent';
export const RadioButton = forwardRef((_a, ref) => {
    var { children, onChange, labelId, errorText } = _a, otherProps = __rest(_a, ["children", "onChange", "labelId", "errorText"]);
    const radioButtonRef = useRef();
    const handledRef = handleForwardRef(radioButtonRef, ref);
    const handleChange = (event) => onChange === null || onChange === void 0 ? void 0 : onChange(new ChangeSyntheticEvent(event));
    useEffect(() => {
        var _a;
        (_a = radioButtonRef.current) === null || _a === void 0 ? void 0 : _a.addEventListener('change', handleChange);
        return () => {
            var _a;
            (_a = radioButtonRef.current) === null || _a === void 0 ? void 0 : _a.removeEventListener('change', handleChange);
        };
    }, [ref, onChange]);
    return createReactWrapper({
        tagName: 'chameleon-radio-button',
        props: Object.assign(Object.assign({}, otherProps), { 'label-id': labelId, 'error-text': errorText, ref: handledRef }),
        children,
    });
});
