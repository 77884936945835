import { __decorate } from "tslib";
import { attr, DOM, html, observable, slotted, when } from '@microsoft/fast-element';
import { ChameleonElementMixin } from '../common/mixin';
import { SVG_ALERT_OUTLINED, SVG_CLOSE_OUTLINED, SVG_PERSON_OUTLINED } from '@getgo/chameleon-icons';
import { FoundationElement } from '@microsoft/fast-foundation';
import { uniqueId } from '@microsoft/fast-web-utilities';
import { DEV } from 'esm-env';
export const chipV2Template = html ` ${when((x) => x.type === 'default', html `<template>
    ${when((x) => x.person && !x.error, () => html `<chameleon-svg class="person-icon">${SVG_PERSON_OUTLINED}</chameleon-svg>`)}
    ${when((x) => !x.person && !x.error, () => html `<slot name="icon"></slot>`)}
    ${when((x) => x.error, () => html `<chameleon-svg class="error-icon">${SVG_ALERT_OUTLINED}</chameleon-svg>`)}
    <slot ${slotted('slottedAvatar')} name="avatar"></slot>
    <slot></slot>
    ${when((x) => x.closable, () => html `<chameleon-icon-button
          class="close"
          @click=${(x, { event }) => x.close(event)}
          size="small"
          label="${(x) => x.closeLabel}"
          ><chameleon-svg>${SVG_CLOSE_OUTLINED}</chameleon-svg></chameleon-icon-button
        >`)}
  </template>`)}
${when((x) => x.type === 'button', html `<button type="button" class="button-chip" @click=${(x, { event }) => x.action(event)}>
    ${when((x) => x.person && !x.error, () => html `<chameleon-svg class="person-icon">${SVG_PERSON_OUTLINED}</chameleon-svg>`)}
    ${when((x) => !x.person && !x.error, () => html `<slot name="icon"></slot>`)}
    ${when((x) => x.error, () => html `<chameleon-svg class="error-icon">${SVG_ALERT_OUTLINED}</chameleon-svg>`)}
    <slot ${slotted('slottedAvatar')} name="avatar"></slot>
    <slot></slot>
    ${when((x) => x.closable, () => html `<chameleon-icon-button
          class="close"
          @click=${(x, { event }) => x.close(event)}
          size="small"
          label="${(x) => x.closeLabel}"
          ><chameleon-svg>${SVG_CLOSE_OUTLINED}</chameleon-svg></chameleon-icon-button
        >`)}
  </button>`)}
${when((x) => x.type === 'checkbox', html `<label for="${(x) => x.uniqueId}">
      <input
        id="${(x) => x.uniqueId}"
        type="checkbox"
        aria-label="${(x) => x.textContent}"
        checked="${(x) => x.active}"
        aria-checked="${(x) => x.active}"
        @click=${(x, { event }) => {
    if (!x.disabled) {
        x.active = !x.active;
        x.action(event);
    }
}}
      />
    </label>
    ${when((x) => x.person && !x.error, () => html `<chameleon-svg class="person-icon">${SVG_PERSON_OUTLINED}</chameleon-svg>`)}
    ${when((x) => !x.person && !x.error, () => html `<slot name="icon"></slot>`)}
    ${when((x) => x.error, () => html `<chameleon-svg class="error-icon">${SVG_ALERT_OUTLINED}</chameleon-svg>`)}
    <slot ${slotted('slottedAvatar')} name="avatar"></slot>
    <slot></slot>
    ${when((x) => x.closable, () => html `<chameleon-icon-button
          class="close"
          @click=${(x, { event }) => x.close(event)}
          size="small"
          label="${(x) => x.closeLabel}"
          ><chameleon-svg>${SVG_CLOSE_OUTLINED}</chameleon-svg></chameleon-icon-button
        >`)} `)}`;
export class ChipV2Component extends ChameleonElementMixin(FoundationElement) {
    constructor() {
        super(...arguments);
        this.size = 'medium';
        this.type = 'default';
        this.uniqueId = uniqueId('chip-id-');
        this.handleKeyDown = (event) => {
            const { key } = event;
            if (key === ' ' || key === 'enter') {
                this.action(event);
                event.preventDefault();
            }
            if (event.key === ' ' && this.type === 'checkbox' && !this.disabled) {
                this.active = !this.active;
            }
        };
    }
    colorTokenChanged(_, newValue) {
        this.changeColorToken(newValue);
    }
    sizeChanged(_, newValue) {
        var _a;
        if (this.slottedAvatar && ((_a = this.slottedAvatar) === null || _a === void 0 ? void 0 : _a.length) > 0) {
            this.slottedAvatar.forEach((avatar) => {
                avatar.setAttribute('size', `${newValue}`);
                if (newValue === null) {
                    avatar.setAttribute('size', `medium`);
                }
            });
        }
    }
    disabledChanged(_, newValue) {
        requestAnimationFrame(() => {
            var _a, _b, _c, _d, _e, _f;
            if (this.slottedAvatar && ((_a = this.slottedAvatar) === null || _a === void 0 ? void 0 : _a.length) > 0) {
                this.slottedAvatar.forEach((avatar) => {
                    avatar.setAttribute('disabled', `${newValue}`);
                    if (newValue === null) {
                        avatar.setAttribute('disabled', `false`);
                    }
                });
            }
            if (this.closable) {
                (_b = this.getInternalCloseElement()) === null || _b === void 0 ? void 0 : _b.setAttribute('disabled', 'true');
            }
            if (this.type === 'button') {
                (_c = this.getInternalButtonElement()) === null || _c === void 0 ? void 0 : _c.removeAttribute('disabled');
                (_d = this.getInternalButtonElement()) === null || _d === void 0 ? void 0 : _d.setAttribute('aria-disabled', `${newValue}`);
            }
            if (this.type === 'checkbox') {
                (_e = this.getInternalCheckboxElement()) === null || _e === void 0 ? void 0 : _e.removeAttribute('disabled');
                (_f = this.getInternalCheckboxElement()) === null || _f === void 0 ? void 0 : _f.setAttribute('aria-disabled', `${newValue}`);
            }
        });
    }
    errorChanged(_, newValue) {
        requestAnimationFrame(() => {
            if (newValue === true) {
                this.setAttribute('aria-label', 'error');
            }
            else {
                this.removeAttribute('aria-label');
            }
        });
    }
    labelChanged() {
        this.setAccessibleLabel();
    }
    labelIdChanged() {
        this.setAccessibleLabel();
    }
    connectedCallback() {
        super.connectedCallback();
        this.addEventListener('keydown', this.handleKeyDown);
        this.setAccessibleLabel();
    }
    disconnectedCallback() {
        super.disconnectedCallback();
        this.removeEventListener('keydown', this.handleKeyDown);
    }
    changeColorToken(token) {
        DOM.queueUpdate(() => {
            if (token) {
                this.style.setProperty('--goto-chip-bg-color', `var(--goto-${token})`);
            }
            else {
                this.style.removeProperty('--goto-chip-bg-color');
            }
        });
    }
    slottedAvatarChanged(_, newValue) {
        // TODO : [Backward compatibility] remove tagname check in next major version, should use icon slot
        if (newValue && newValue.length > 0 && newValue.some((element) => element.tagName.includes('AVATAR'))) {
            this.setAttribute('has-avatar', 'true');
            newValue.forEach((avatar) => {
                avatar.setAttribute('size', `${this.size}`);
                if (this.getAttribute('disabled')) {
                    avatar.setAttribute('disabled', '');
                }
            });
        }
        else {
            this.removeAttribute('has-avatar');
        }
    }
    action(event) {
        var _a;
        if (this.disabled)
            return;
        event.stopPropagation();
        this.$emit('chip-action', null, { bubbles: true });
        // When the chips are used as links, we want to trigger the default click event so the redirect happens
        if (((_a = this.parentElement) === null || _a === void 0 ? void 0 : _a.tagName) === 'A') {
            return true;
        }
    }
    close(event) {
        if (this.disabled)
            return;
        event.stopPropagation();
        this.$emit('close', null, { bubbles: false });
        this.$emit('ch-chip-close', null, { bubbles: true }); // Needed for the autocomplete multiple
    }
    getInternalButtonElement() {
        var _a;
        return (_a = this.shadowRoot) === null || _a === void 0 ? void 0 : _a.querySelector('.button-chip');
    }
    getInternalCheckboxElement() {
        var _a;
        return (_a = this.shadowRoot) === null || _a === void 0 ? void 0 : _a.querySelector('input');
    }
    getInternalCloseElement() {
        var _a;
        return (_a = this.shadowRoot) === null || _a === void 0 ? void 0 : _a.querySelector('.close');
    }
    setAccessibleLabel() {
        var _a;
        if (this.closable && DEV) {
            this.validateAccessibleCloseLabel();
        }
        if ((_a = this.innerText) === null || _a === void 0 ? void 0 : _a.length) {
            return;
        }
        if (this.labelId) {
            this.setAttribute('aria-labelledby', this.labelId);
            return;
        }
        if (this.label) {
            this.setAttribute('aria-label', this.label);
            return;
        }
        if (DEV) {
            this.validateAccessibleLabel();
        }
    }
    validateAccessibleCloseLabel() {
        var _a, _b;
        if (!this.closeLabel && !((_b = (_a = this.getInternalCloseElement()) === null || _a === void 0 ? void 0 : _a.getAttribute('aria-label')) === null || _b === void 0 ? void 0 : _b.length)) {
            console.error('CHAMELEON-A11Y: Closable chip component must have an accessible label for the X icon provided via the closeLabel attribute.');
        }
    }
    validateAccessibleLabel() {
        var _a, _b;
        if (!((_a = this.getAttribute('aria-labelledby')) === null || _a === void 0 ? void 0 : _a.length) && !((_b = this.getAttribute('aria-label')) === null || _b === void 0 ? void 0 : _b.length)) {
            console.error(`CHAMELEON-A11Y: Chip component must have an accessible label provided by either 
  1) text content 
  2) a visible label whose id is given as the attribute labelId 
  3) a non-empty label attribute to be used as aria-label.
`);
        }
    }
}
__decorate([
    attr({ mode: 'boolean' })
], ChipV2Component.prototype, "disabled", void 0);
__decorate([
    attr({ mode: 'boolean' })
], ChipV2Component.prototype, "active", void 0);
__decorate([
    attr({ mode: 'boolean' })
], ChipV2Component.prototype, "error", void 0);
__decorate([
    attr({ mode: 'boolean' })
], ChipV2Component.prototype, "closable", void 0);
__decorate([
    attr({ mode: 'boolean' })
], ChipV2Component.prototype, "person", void 0);
__decorate([
    attr({ attribute: 'label-id' })
], ChipV2Component.prototype, "labelId", void 0);
__decorate([
    attr({ attribute: 'close-label' })
], ChipV2Component.prototype, "closeLabel", void 0);
__decorate([
    attr
], ChipV2Component.prototype, "label", void 0);
__decorate([
    attr
], ChipV2Component.prototype, "size", void 0);
__decorate([
    attr
], ChipV2Component.prototype, "type", void 0);
__decorate([
    attr
], ChipV2Component.prototype, "uniqueId", void 0);
__decorate([
    attr({ attribute: 'color-token' })
], ChipV2Component.prototype, "colorToken", void 0);
__decorate([
    observable
], ChipV2Component.prototype, "slottedAvatar", void 0);
