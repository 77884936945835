var _SyntheticEvent_isPropagationStopped;
import { __classPrivateFieldGet, __classPrivateFieldSet } from "tslib";
export class SyntheticEvent {
    get defaultPrevented() {
        return this.nativeEvent.defaultPrevented;
    }
    get eventPhase() {
        return this.nativeEvent.eventPhase;
    }
    get isTrusted() {
        return this.nativeEvent.isTrusted;
    }
    get timeStamp() {
        return this.nativeEvent.timeStamp;
    }
    constructor(event) {
        _SyntheticEvent_isPropagationStopped.set(this, false);
        this.nativeEvent = event;
        this.target = event.target;
        this.currentTarget = event.currentTarget;
        this.bubbles = event.bubbles;
        this.cancelable = event.cancelable;
    }
    preventDefault() {
        this.nativeEvent.preventDefault();
    }
    isDefaultPrevented() {
        return this.nativeEvent.defaultPrevented;
    }
    stopPropagation() {
        __classPrivateFieldSet(this, _SyntheticEvent_isPropagationStopped, true, "f");
        this.nativeEvent.stopPropagation();
    }
    isPropagationStopped() {
        return __classPrivateFieldGet(this, _SyntheticEvent_isPropagationStopped, "f");
    }
    persist() {
        // Modern event system doesn't use pooling.
    }
}
_SyntheticEvent_isPropagationStopped = new WeakMap();
export class ChangeSyntheticEvent extends SyntheticEvent {
    constructor() {
        super(...arguments);
        this.type = 'onChange';
    }
}
export class InputSyntheticEvent extends SyntheticEvent {
    constructor() {
        super(...arguments);
        this.type = 'onInput';
    }
}
