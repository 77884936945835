import { __rest } from "tslib";
import React, { forwardRef, useEffect, useRef } from 'react';
import { createReactWrapper } from '../common/PrefixUtils';
import { handleForwardRef } from '../common/RefUtils';
import { addExtraProps } from '../common/utils/AddExtraProps';
export const Alert = forwardRef((_a, ref) => {
    var { icon, action, onClose, ariaCloseLabel, children } = _a, props = __rest(_a, ["icon", "action", "onClose", "ariaCloseLabel", "children"]);
    const alertRef = useRef();
    const handledRef = handleForwardRef(alertRef, ref);
    const handleClose = () => {
        onClose === null || onClose === void 0 ? void 0 : onClose();
    };
    useEffect(() => {
        var _a;
        (_a = alertRef.current) === null || _a === void 0 ? void 0 : _a.addEventListener('close', handleClose);
        return () => {
            var _a;
            (_a = alertRef.current) === null || _a === void 0 ? void 0 : _a.removeEventListener('close', handleClose);
        };
    }, [ref, onClose]);
    return createReactWrapper({
        tagName: 'chameleon-alert',
        props: Object.assign(Object.assign({}, props), { ref: handledRef, 'close-aria-label': ariaCloseLabel }),
        children: (React.createElement(React.Fragment, null,
            icon && addExtraProps(icon, { slot: 'icon' }),
            action && addExtraProps(action, { slot: 'action' }),
            children)),
    });
});
export const AlertV2 = forwardRef((_a, ref) => {
    var { action, onClose, ariaCloseLabel, children, icon } = _a, props = __rest(_a, ["action", "onClose", "ariaCloseLabel", "children", "icon"]);
    const alertRef = useRef();
    const handledRef = handleForwardRef(alertRef, ref);
    const handleClose = () => {
        onClose === null || onClose === void 0 ? void 0 : onClose();
    };
    useEffect(() => {
        var _a;
        (_a = alertRef.current) === null || _a === void 0 ? void 0 : _a.addEventListener('close', handleClose);
        return () => {
            var _a;
            (_a = alertRef.current) === null || _a === void 0 ? void 0 : _a.removeEventListener('close', handleClose);
        };
    }, [ref, onClose]);
    return createReactWrapper({
        tagName: 'chameleon-alert-v2',
        props: Object.assign(Object.assign({}, props), { ref: handledRef, 'close-aria-label': ariaCloseLabel }),
        children: (React.createElement(React.Fragment, null,
            action && addExtraProps(action, { slot: 'action' }),
            icon && addExtraProps(icon, { slot: 'icon' }),
            children)),
    });
});
