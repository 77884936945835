import { textFieldStyles } from '@microsoft/fast-components';
import { css } from '@microsoft/fast-element';
import { TextField, textFieldTemplate as template } from '@microsoft/fast-foundation';
import { SearchFieldComponent } from './SearchField';
import styles from './SearchField.styles.scss';
export const searchFieldDefinition = {
    baseName: 'search-field',
    baseClass: TextField,
    template: template,
    styles: (ctx, def) => css `
    ${textFieldStyles(ctx, def)}
    ${styles}
  `,
    shadowOptions: {
        delegatesFocus: true,
    },
};
export const chameleonSearchField = SearchFieldComponent.compose(searchFieldDefinition);
