import { __decorate } from "tslib";
import { attr, html } from '@microsoft/fast-element';
import { FoundationElement } from '@microsoft/fast-foundation';
import { getChameleonElements, getElementsWithProperty } from '../common/utils/DomUtil';
import { ChameleonElementMixin } from '../common/mixin';
export const dataTableV2Template = html `
  <template role="table">
    <slot></slot>
  </template>
`;
export class DataTableV2Component extends ChameleonElementMixin(FoundationElement) {
    constructor() {
        super(...arguments);
        this.condensed = false;
    }
    condensedChanged(_, next) {
        if (this.children.length >= 0) {
            const chameleonElements = getElementsWithProperty(getChameleonElements(this), 'condensed');
            for (const el of chameleonElements) {
                el.setAttribute('condensed', next ? 'true' : 'false');
            }
        }
    }
    connectedCallback() {
        super.connectedCallback();
        const chameleonElements = getElementsWithProperty(getChameleonElements(this), 'condensed');
        for (const el of chameleonElements) {
            el.setAttribute('condensed', this.condensed ? 'true' : 'false');
        }
    }
}
__decorate([
    attr({ mode: 'boolean' })
], DataTableV2Component.prototype, "condensed", void 0);
