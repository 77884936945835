declare const Buffer: any;

export const encode = (s: string) => {
  if (typeof btoa !== 'undefined') {
    return btoa(s);
  } else if (typeof Buffer !== 'undefined') {
    return Buffer.from(s).toString('base64');
  }

  return s;
};

export const decode = (s: string) => {
  if (typeof atob !== 'undefined') {
    return atob(s);
  } else if (typeof Buffer !== 'undefined') {
    return Buffer.from(s, 'base64').toString('utf8');
  }

  return s;
};
