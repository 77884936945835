export function stringify(obj: Record<string, any> | string) {
  if (typeof obj !== 'object') {
    return encodeURIComponent(obj);
  }

  const str: string[] = [];
  Object.keys(obj || {}).forEach((key) => {
    let value = obj[key];
    if (value !== '' && value !== undefined) {
      if (typeof value === 'object') {
        value = JSON.stringify(value);
      }
      str.push([key, value].map(encodeURIComponent).join('='));
    }
  });

  return str.join('&');
}

export function parse(input: string) {
  let str: string = input;

  if (['?', '#'].indexOf(str.substr(0, 1)) !== -1) {
    str = str.substr(1);
  }

  if (str.indexOf('=') === -1) {
    return str;
  }

  return str.split('&').reduce((obj, set) => {
    const [key, value] = set.split('=').map(decodeURIComponent);
    let parsed: any;

    try {
      parsed = JSON.parse(value);
    } catch (err) {
      parsed = value;
    }

    obj[key] = parsed;

    return obj;
  }, {});
}
