import { __decorate } from "tslib";
import { attr, html, ref, slotted, when, observable, DOM } from '@microsoft/fast-element';
import { FoundationElement } from '@microsoft/fast-foundation';
import { ChameleonElementMixin } from '../common/mixin';
import { SVG_PERSON_OUTLINED } from '@getgo/chameleon-icons';
import { DEV } from 'esm-env';
const BADGE_ALLOWED_SIZES = ['xlarge'];
export const avatarTemplate = html `<template
  style="${(x) => { var _a; return (_a = x.primaryBackgroundColor) !== null && _a !== void 0 ? _a : ''; }}"
  tabindex="${(x) => (x.isActiveButton() ? '0' : null)}"
>
  <slot ${ref('content')} @slotchange="${(x) => x.handleContentChange()}"></slot>
  ${when((x) => x.canHostABadge(), html `<slot name="badge"></slot>`)}
  ${when((x) => x.size !== 'xxxlarge', html `<slot name="presence"></slot>`)}
</template>`;
export const avatarV2Template = html ` ${when((x) => x.type === 'button', html `<button @click=${(x, { event }) => x.onAction(event)}>
    <slot ${slotted('content')}></slot>
    ${when((x) => { var _a, _b; return ((_a = x.content) === null || _a === void 0 ? void 0 : _a.length) === 0 || ((_b = x.content) === null || _b === void 0 ? void 0 : _b.length) === undefined; }, html `<chameleon-svg>${SVG_PERSON_OUTLINED}</chameleon-svg>`)}
    ${when((x) => x.canHostABadge(), html `<slot name="badge" ${slotted('slottedBadge')}></slot>`)}
    ${when((x) => x.size !== 'xxxxlarge', html `<slot name="presence"></slot>`)}
  </button>`)}
${when((x) => x.type === 'default', html `<template>
    <slot ${slotted('content')}></slot>
    ${when((x) => { var _a, _b; return ((_a = x.content) === null || _a === void 0 ? void 0 : _a.length) === 0 || ((_b = x.content) === null || _b === void 0 ? void 0 : _b.length) === undefined; }, html `<chameleon-svg>${SVG_PERSON_OUTLINED}</chameleon-svg>`)}
    ${when((x) => x.canHostABadge(), html `<slot name="badge" ${slotted('slottedBadge')}></slot>`)}
    ${when((x) => x.size !== 'xxxxlarge', html `<slot name="presence"></slot>`)}
  </template>`)}`;
export class AvatarComponent extends ChameleonElementMixin(FoundationElement) {
    constructor() {
        super(...arguments);
        this.variant = 'neutral';
        this.disabled = false;
        this.hidden = false;
    }
    handleContentChange() {
        const nodes = this.content.assignedNodes().reduce((acc, el) => {
            var _a;
            if (el.nodeType === Node.TEXT_NODE && ((_a = el.textContent) === null || _a === void 0 ? void 0 : _a.trim().length) === 0) {
                return acc;
            }
            return [...acc, el];
        }, []);
        if (nodes.length > 1) {
            this.removeInactiveIcon();
        }
        if (nodes.length === 0) {
            this.renderInactiveIcon();
        }
        this.verifyImageChild();
    }
    variantChanged(_oldValue, newValue) {
        if (newValue === 'primary') {
            this.primaryBackgroundColor = `--goto-avatar-bg-color: var(--goto-avatar-color-00)`;
        }
    }
    connectedCallback() {
        super.connectedCallback();
        this.render();
        this.renderInactiveIcon();
    }
    attributeChangedCallback(name, oldValue, newValue) {
        super.attributeChangedCallback(name, oldValue, newValue);
        this.render();
    }
    canHostABadge() {
        const allowedSizes = ['large', 'xlarge', 'xxlarge'];
        return allowedSizes.find((size) => this.size === size);
    }
    isActiveButton() {
        return this.type === 'button' && !this.disabled;
    }
    hasChildImage() {
        return !!this.querySelector('img');
    }
    render() {
        var _a;
        const slot = (_a = this.shadowRoot) === null || _a === void 0 ? void 0 : _a.querySelector('slot');
        if (!slot) {
            return;
        }
        this.renderButtonOverlay();
    }
    verifyImageChild() {
        this.classList.toggle('with-image', this.hasChildImage());
    }
    renderInactiveIcon() {
        const svg = document.createElement('chameleon-svg');
        svg.classList.add('inactive-icon');
        svg.innerHTML = SVG_PERSON_OUTLINED;
        this.appendChild(svg);
    }
    removeInactiveIcon() {
        var _a;
        (_a = this.querySelector('.inactive-icon')) === null || _a === void 0 ? void 0 : _a.remove();
    }
    isTypeButton() {
        return this.type === 'button';
    }
    isButtonOvelayRendered() {
        return this.querySelector('.button-overlay');
    }
    renderButtonOverlay() {
        if (this.isTypeButton() && !this.isButtonOvelayRendered()) {
            const overlay = document.createElement('span');
            overlay.classList.add('button-overlay');
            this.appendChild(overlay);
        }
    }
}
__decorate([
    attr
], AvatarComponent.prototype, "size", void 0);
__decorate([
    attr
], AvatarComponent.prototype, "variant", void 0);
__decorate([
    attr
], AvatarComponent.prototype, "type", void 0);
__decorate([
    attr({ mode: 'boolean' })
], AvatarComponent.prototype, "disabled", void 0);
__decorate([
    attr
], AvatarComponent.prototype, "primaryBackgroundColor", void 0);
export class AvatarV2Component extends ChameleonElementMixin(FoundationElement) {
    constructor() {
        super(...arguments);
        this.size = 'medium';
        this.variant = 'primary';
        this.type = 'default';
        this.disabled = false;
        this.hidden = false;
        this.handleKeyDown = (event) => {
            const { key } = event;
            if (key === ' ' || key === 'enter') {
                this.onAction(event);
                event.preventDefault();
            }
        };
    }
    colorTokenChanged(_, newValue) {
        this.changeColorToken(newValue);
    }
    contentChanged() {
        this.verifyContent();
        this.verifyText();
        this.verifyImage();
    }
    slottedBadgeChanged(_, newValue) {
        if (newValue && newValue.length > 0) {
            newValue.forEach((badge) => {
                badge.setAttribute('size', 'small');
                badge.setAttribute('border', 'fill');
            });
        }
    }
    connectedCallback() {
        super.connectedCallback();
        this.setAccessibleLabel();
        this.addEventListener('keydown', this.handleKeyDown);
    }
    disconnectedCallback() {
        super.disconnectedCallback();
        this.removeEventListener('keydown', this.handleKeyDown);
    }
    labelChanged() {
        this.setAccessibleLabel();
    }
    disabledChanged(_, newValue) {
        requestAnimationFrame(() => {
            var _a, _b;
            (_a = this.getInternalButtonElement()) === null || _a === void 0 ? void 0 : _a.removeAttribute('disabled');
            (_b = this.getInternalButtonElement()) === null || _b === void 0 ? void 0 : _b.setAttribute('aria-disabled', `${newValue}`);
        });
    }
    getInternalButtonElement() {
        var _a;
        return (_a = this.shadowRoot) === null || _a === void 0 ? void 0 : _a.querySelector('button');
    }
    changeColorToken(token) {
        DOM.queueUpdate(() => {
            if (token) {
                this.style.setProperty('--goto-avatar-bg-color', `var(--${token})`);
            }
            else {
                this.style.removeProperty('--goto-avatar-bg-color');
            }
        });
    }
    canHostABadge() {
        return BADGE_ALLOWED_SIZES.includes(this.size);
    }
    isActiveButton() {
        return this.type === 'button' && !this.disabled;
    }
    hasContent() {
        return this.content.length > 0;
    }
    isContentText() {
        return !!this.content.find((contentElement) => contentElement.nodeName === '#text');
    }
    isContentImage() {
        return !!this.content.find((contentElement) => contentElement.nodeName === 'IMG');
    }
    onAction(event) {
        if (this.isActiveButton()) {
            this.$emit('avatar-action', event, { bubbles: true });
        }
    }
    verifyContent() {
        this.classList.toggle('no-content', !this.hasContent());
    }
    verifyText() {
        this.classList.toggle('text-content', this.isContentText());
    }
    verifyImage() {
        this.classList.toggle('img-content', this.isContentImage());
    }
    getChildImage() {
        return this.querySelector('img');
    }
    setAccessibleLabel() {
        var _a;
        if (this.label) {
            if (!!this.getChildImage()) {
                (_a = this.getChildImage()) === null || _a === void 0 ? void 0 : _a.setAttribute('alt', `${this.label}`);
            }
            else {
                this.setAttribute('aria-label', `${this.label}`);
            }
            return;
        }
        if (DEV) {
            this.validateAccessibleLabel();
        }
    }
    validateAccessibleLabel() {
        var _a, _b, _c;
        if (!((_a = this.getAttribute('aria-label')) === null || _a === void 0 ? void 0 : _a.length) && !((_c = (_b = this.getChildImage()) === null || _b === void 0 ? void 0 : _b.alt) === null || _c === void 0 ? void 0 : _c.length)) {
            console.error(`CHAMELEON-A11Y: Avatar component must have an accessible label provided by a non-empty label attribute to be used as 
  1) alt-text if the avatar has an image 
  2) aria-label if the avatar has initials or an icon`);
        }
    }
}
__decorate([
    attr
], AvatarV2Component.prototype, "size", void 0);
__decorate([
    attr
], AvatarV2Component.prototype, "variant", void 0);
__decorate([
    attr
], AvatarV2Component.prototype, "type", void 0);
__decorate([
    attr({ mode: 'boolean' })
], AvatarV2Component.prototype, "disabled", void 0);
__decorate([
    attr({ attribute: 'color-token' })
], AvatarV2Component.prototype, "colorToken", void 0);
__decorate([
    attr({ mode: 'boolean' })
], AvatarV2Component.prototype, "hidden", void 0);
__decorate([
    attr
], AvatarV2Component.prototype, "label", void 0);
__decorate([
    observable
], AvatarV2Component.prototype, "content", void 0);
__decorate([
    observable
], AvatarV2Component.prototype, "slottedBadge", void 0);
