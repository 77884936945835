import { __decorate } from "tslib";
import { attr } from '@microsoft/fast-element';
import { ChameleonElementMixin } from '../common/mixin';
import { FoundationElement } from '@microsoft/fast-foundation';
export class TextBadgeComponent extends ChameleonElementMixin(FoundationElement) {
    constructor() {
        super(...arguments);
        this.variant = 'neutral';
        this.border = 'none';
    }
}
__decorate([
    attr
], TextBadgeComponent.prototype, "variant", void 0);
__decorate([
    attr
], TextBadgeComponent.prototype, "border", void 0);
