import { __decorate } from "tslib";
import { attr } from '@microsoft/fast-element';
import { Button } from '@microsoft/fast-foundation';
import { uniqueId } from '@microsoft/fast-web-utilities';
import { ChameleonElementMixin } from './mixin';
import { addHasAttributesToParent, addSvgIconSize, createLoadingSvg } from './LoadingIconUtils';
export class LoadingButton extends ChameleonElementMixin(Button) {
    constructor() {
        super(...arguments);
        this.isLoading = false;
        this.buttonLoadingIndicatorId = uniqueId('button-loading-indicator-');
    }
    isLoadingChanged(_) {
        if (this.isConnected)
            addHasAttributesToParent(this);
    }
    sizeChanged(_, next) {
        if (!next) {
            return;
        }
        if (this.loadingIcon) {
            addSvgIconSize(this.loadingIcon, next);
        }
    }
    disabledChanged(oldValue, newValue) {
        var _a;
        (_a = super.disabledChanged) === null || _a === void 0 ? void 0 : _a.call(this, oldValue, newValue);
        if (!newValue && !!this.isLoading) {
            this.disabled = newValue;
        }
    }
    placeSVGToCorrectPlaceInDOM() {
        var _a, _b, _c, _d, _e, _f;
        if (this.loadingIcon) {
            return;
        }
        this.loadingIcon = createLoadingSvg(this.size);
        this.loadingIcon.id = this.buttonLoadingIndicatorId;
        const contentNode = (_b = (_a = this.shadowRoot) === null || _a === void 0 ? void 0 : _a.querySelector('[part="control"]')) === null || _b === void 0 ? void 0 : _b.querySelector('[part="content"]');
        const endSlot = this.querySelector('[slot="end"]');
        if (endSlot) {
            (_d = (_c = this.shadowRoot) === null || _c === void 0 ? void 0 : _c.querySelector('[part="control"]')) === null || _d === void 0 ? void 0 : _d.appendChild(this.loadingIcon);
            return;
        }
        (_f = (_e = this.shadowRoot) === null || _e === void 0 ? void 0 : _e.querySelector('[part="control"]')) === null || _f === void 0 ? void 0 : _f.insertBefore(this.loadingIcon, contentNode);
    }
    connectedCallback() {
        super.connectedCallback();
        this.placeSVGToCorrectPlaceInDOM();
        addHasAttributesToParent(this);
    }
}
__decorate([
    attr({ mode: 'boolean', attribute: 'is-loading' })
], LoadingButton.prototype, "isLoading", void 0);
__decorate([
    attr
], LoadingButton.prototype, "size", void 0);
