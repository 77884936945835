import { FC } from 'react';
import { useIntl } from 'react-intl';
import { Typography } from '@getgo/chameleon-web-react-wrapper';

import st from 'utils/shared-translations';

import './direct-debit-header.css';

const DirectDebitHeader: FC = (): JSX.Element => {
  const intl = useIntl();

  return (
    <header className="direct-debit-header">
      <section className="direct-debit-header__label">
        <Typography tag="h1" variant="heading-medium">
          {intl.formatMessage(st['dd.form.header'])}
        </Typography>
      </section>
      <Typography variant="body-small">{intl.formatMessage(st['dd.form.header.caption'])}</Typography>
    </header>
  );
};

export default DirectDebitHeader;
