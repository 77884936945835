/* istanbul ignore file */
export var Presence;
(function (Presence) {
    Presence["AVAILABLE"] = "available";
    Presence["AWAY"] = "away";
    Presence["BUSY"] = "busy";
    Presence["DND"] = "dnd";
    Presence["DO_NOT_DISTURB"] = "do-not-disturb";
    Presence["OFFLINE"] = "offline";
    Presence["DISCONNECTED"] = "disconnected";
})(Presence || (Presence = {}));
