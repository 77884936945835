import { autoUpdate, computePosition, flip } from '@floating-ui/dom';
import { convertStylePropertyPixelsToNumber } from '@microsoft/fast-web-utilities';
const LARGE_WIDTH = 300;
const DEFAULT_SINGLE_LINE_CONTENT_HEIGHT = 30;
function chameleonPlacementToFloatingUIPlacement(position) {
    if (!position.includes('center')) {
        return position;
    }
    const [placement] = position.split('-');
    return placement;
}
function floatingUIPlacementToChameleonPosition(placement) {
    if (!placement.includes('-')) {
        return `${placement}-center`;
    }
    return placement;
}
const reactToPlacementChange = (popover) => {
    let previousPlacement;
    return {
        name: 'reactToPlacementChange',
        fn({ x, y, initialPlacement, placement }) {
            if (initialPlacement !== placement || placement !== previousPlacement) {
                const position = floatingUIPlacementToChameleonPosition(placement);
                popover.setAttribute('data-rendered-position', position);
                popover.$emit('position-changed');
            }
            previousPlacement = placement;
            return {
                x,
                y,
            };
        },
    };
};
/**
 *
 * @param customElement The custom element itself that implements the floating behaviour
 * @param positionedElement The element that is actually positioned, can be same as the custom element
 * @param trigger The trigger element that should serve as an anchor
 * */
export function calculatePopoverPosition(customElement, positionedElement, trigger) {
    return new Promise((resolve) => {
        const positionFallbackPlacements = calculateFallbackPlacements(customElement.position);
        const triggerBounds = trigger.getBoundingClientRect();
        requestAnimationFrame(() => {
            const middlewares = [
                flip({
                    fallbackPlacements: positionFallbackPlacements,
                }),
            ];
            middlewares.push(reactToPlacementChange(customElement));
            const position = calculateSingleLinePositioning(customElement.position, positionedElement);
            if (position !== customElement.position) {
                positionedElement.setAttribute('data-single-line', 'true');
                positionedElement.setAttribute('data-rendered-position', position);
            }
            const contentRect = positionedElement.getBoundingClientRect();
            positionedElement.style.setProperty('--content-height', `${contentRect.height}px`);
            positionedElement.style.setProperty('--content-width', `${contentRect.width}px`);
            if (contentRect.width >= LARGE_WIDTH && !positionedElement.hasAttribute('large')) {
                positionedElement.setAttribute('large', '');
                // The reason we have to calculate the popover position again here is because by setting the `large`
                // attribute, we are modifying the dimesnions of the popover (large variant has bigger padding) which
                // will result in the first position calculation being incorrent.
                calculatePopoverPosition(customElement, positionedElement, trigger).then((cleanup) => resolve(cleanup));
            }
            positionedElement.style.setProperty('--trigger-width', `${triggerBounds.width}px`);
            positionedElement.style.setProperty('--trigger-height', `${triggerBounds.height}px`);
            if (customElement.zIndex) {
                positionedElement.style.setProperty('--z-index', `${customElement.zIndex}`);
            }
            const cleanup = autoUpdate(trigger, positionedElement, () => {
                computePosition(trigger, positionedElement, {
                    placement: chameleonPlacementToFloatingUIPlacement(position),
                    strategy: 'fixed',
                    middleware: middlewares,
                }).then(({ x, y }) => {
                    /**
                     * DO NOT SET THE WIDTH OR HEIGHT OF THE CONTENT ELEMENT HERE.
                     * Messing with those properties here will mean the popover will render in the wrong place,
                     * as we're resetting the content elements clientRect AFTER its position was calculated.
                     * If you need to modify these, always do so *BEFORE* this function is called.
                     * */
                    const additionalOffsetX = customElement.style.getPropertyValue('--additional-offset-x');
                    const additionalOffsetY = customElement.style.getPropertyValue('--additional-offset-y');
                    if (additionalOffsetX) {
                        positionedElement.style.setProperty('--trigger-x', `calc(${x}px + ${additionalOffsetX})`);
                    }
                    else {
                        positionedElement.style.setProperty('--trigger-x', `${x}px`);
                    }
                    if (additionalOffsetY) {
                        positionedElement.style.setProperty('--trigger-y', `calc(${y}px + ${additionalOffsetY})`);
                    }
                    else {
                        positionedElement.style.setProperty('--trigger-y', `${y}px`);
                    }
                });
            });
            resolve(cleanup);
        });
    });
}
function calculateSingleLinePositioning(position, content) {
    const isSingleLine = convertStylePropertyPixelsToNumber(getComputedStyle(content), 'height') <=
        DEFAULT_SINGLE_LINE_CONTENT_HEIGHT;
    if (isSingleLine && (position.includes('left') || position.includes('right'))) {
        const [primaryPosition] = position.split('-');
        return `${primaryPosition}-center`;
    }
    return position;
}
/**
 * This function determines the correct fallback position if the popover cannot fit into its original position
 * The logic is that first we should try to place it in its original primary position with a different secondary position, if
 * that fails then we try the opposite primary position with the same secondary position first and the other secondary positions after that.
 * If the opposite primary positions fail as well, we shift to the other two primary positions.
 *
 * Example: If the original position is `top-start`, we should try `top` and `top-end`, if those fail as well we go to the `bottom-start` position
 * and then rotate through the rest of the bottom positions. If those fail we visit `right` and `left` positions.
 */
function calculateFallbackPlacements(position) {
    const basePlacementList = [
        'bottom',
        'bottom-start',
        'bottom-end',
        'top',
        'top-start',
        'top-end',
        'right',
        'right-start',
        'right-end',
        'left',
        'left-start',
        'left-end',
    ];
    const oppositePrimaryPositionPairs = {
        top: 'bottom',
        bottom: 'top',
        left: 'right',
        right: 'left',
    };
    const oppositeSecondaryPositionsPairs = {
        end: 'start',
        start: 'end',
        center: 'center',
    };
    const [primaryPlacement, secondaryPlacement] = position.split('-');
    const samePrimaryOptions = [`${primaryPlacement}`, `${primaryPlacement}-start`, `${primaryPlacement}-end`].filter((placement) => placement !== position);
    const oppositePrimaryOptions = [
        `${oppositePrimaryPositionPairs[primaryPlacement]}-${secondaryPlacement}`,
        `${oppositePrimaryPositionPairs[primaryPlacement]}`,
        `${oppositePrimaryPositionPairs[primaryPlacement]}-${oppositeSecondaryPositionsPairs[secondaryPlacement]}`,
    ];
    const rest = basePlacementList.filter((placement) => !samePrimaryOptions.includes(placement) || !oppositePrimaryOptions.includes(placement));
    return [...samePrimaryOptions, ...oppositePrimaryOptions, ...rest];
}
