import { Menu, menuTemplate as template } from '@microsoft/fast-foundation';
import { MenuComponent } from './Menu';
import styles from './Menu.styles.scss';
export const menuDefinition = {
    baseName: 'menu',
    baseClass: Menu,
    template: template,
    styles,
};
export const chameleonMenu = MenuComponent.compose(menuDefinition);
