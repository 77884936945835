import { createSlice } from '@reduxjs/toolkit';

import { GlobalWrapperState } from 'types/global-wrapper';

const globalWrapperSlice = createSlice({
  name: 'globalWrapper',

  initialState: {
    isLoading: true,
    accountKey: '',
    sessionId: '',
  } as GlobalWrapperState,

  reducers: {
    hideGlobalLoading(state) {
      state.isLoading = false;
    },
    setGlobalAccountKey(state, action) {
      state.accountKey = action.payload;
    },
    setGlobalSessionId(state, action) {
      state.sessionId = action.payload;
    },
  },
});

export const { hideGlobalLoading, setGlobalAccountKey, setGlobalSessionId } = globalWrapperSlice.actions;

export default globalWrapperSlice.reducer;
