import { __decorate } from "tslib";
import { attr, html, nullableNumberConverter } from '@microsoft/fast-element';
import { FoundationElement } from '@microsoft/fast-foundation';
import { ChameleonElementMixin } from '../../common/mixin';
export const popoverContentTemplate = html `<slot name="content"></slot>`;
export class PopoverContentComponent extends ChameleonElementMixin(FoundationElement) {
    constructor() {
        super(...arguments);
        this.inactive = true;
        this.compact = false;
        this.inverted = false;
        this.hidden = false;
        this.arrow = false;
        this.menu = false;
        this.large = false;
        this.customWidth = false;
    }
    zIndexChanged(_, next) {
        if (next) {
            this.style.setProperty('--z-index', next.toString());
        }
    }
}
__decorate([
    attr({ mode: 'boolean' })
], PopoverContentComponent.prototype, "inactive", void 0);
__decorate([
    attr({ mode: 'boolean' })
], PopoverContentComponent.prototype, "compact", void 0);
__decorate([
    attr({ mode: 'boolean' })
], PopoverContentComponent.prototype, "inverted", void 0);
__decorate([
    attr({ mode: 'boolean' })
], PopoverContentComponent.prototype, "hidden", void 0);
__decorate([
    attr({ mode: 'boolean' })
], PopoverContentComponent.prototype, "arrow", void 0);
__decorate([
    attr({ mode: 'boolean' })
], PopoverContentComponent.prototype, "menu", void 0);
__decorate([
    attr({ mode: 'boolean' })
], PopoverContentComponent.prototype, "large", void 0);
__decorate([
    attr({ mode: 'boolean', attribute: 'custom-width' })
], PopoverContentComponent.prototype, "customWidth", void 0);
__decorate([
    attr()
], PopoverContentComponent.prototype, "position", void 0);
__decorate([
    attr({ attribute: 'trigger' })
], PopoverContentComponent.prototype, "trigger", void 0);
__decorate([
    attr({ attribute: 'z-index', converter: nullableNumberConverter })
], PopoverContentComponent.prototype, "zIndex", void 0);
