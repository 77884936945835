import { html } from '@microsoft/fast-element';
import { FoundationElement } from '@microsoft/fast-foundation';
import { ChameleonElementMixin } from '../common/mixin';
const defaultTemplate = html `<slot></slot>`;
export const badgeBaseDefinition = (baseName, styles, template = defaultTemplate) => ({
    baseName,
    template,
    styles,
});
export class BadgeBase extends ChameleonElementMixin(FoundationElement) {
}
