import { __decorate } from "tslib";
import { TextField } from '@microsoft/fast-foundation';
import { attr } from '@microsoft/fast-element';
import { uniqueId } from '@microsoft/fast-web-utilities';
import { ChameleonElementMixin } from '../common/mixin';
import { DEV } from 'esm-env';
export class InputFieldComponent extends ChameleonElementMixin(TextField) {
    constructor() {
        super(...arguments);
        this.fieldsize = 'medium';
        this.success = false;
        this.error = false;
        this.fullwidth = false;
        this.optional = false;
        this.infoContainer = document.createElement('div');
        this.helperTextEl = document.createElement('slot');
        this.handleRootClick = (e) => {
            if (!e.defaultPrevented) {
                this.control.focus();
            }
        };
        this.handleHelperTextSlotChange = () => {
            const helperText = this.helperTextEl.assignedElements()[0];
            if (!helperText || !helperText.textContent) {
                return;
            }
            if (this.querySelector('[slot="helpertext"]')) {
                this.control.setAttribute('aria-describedby', this.querySelector('[slot="helpertext"]').id);
            }
        };
    }
    autocompleteChanged(_, next) {
        var _a, _b;
        if (next) {
            (_a = this.control) === null || _a === void 0 ? void 0 : _a.setAttribute('autocomplete', next);
        }
        else {
            (_b = this.control) === null || _b === void 0 ? void 0 : _b.removeAttribute('autocomplete');
        }
    }
    connectedCallback() {
        var _a, _b, _c;
        super.connectedCallback();
        (_b = (_a = this.shadowRoot) === null || _a === void 0 ? void 0 : _a.querySelector('.root')) === null || _b === void 0 ? void 0 : _b.addEventListener('click', this.handleRootClick);
        this.infoContainer.classList.add('info');
        (_c = this.shadowRoot) === null || _c === void 0 ? void 0 : _c.appendChild(this.infoContainer);
        this.addHelperText();
        this.addIdForLabel();
        this.setAccessibleLabel();
    }
    disconnectedCallback() {
        var _a, _b;
        super.disconnectedCallback();
        (_b = (_a = this.shadowRoot) === null || _a === void 0 ? void 0 : _a.querySelector('.root')) === null || _b === void 0 ? void 0 : _b.removeEventListener('click', this.handleRootClick);
        this.helperTextEl.removeEventListener('slotchange', this.handleHelperTextSlotChange);
    }
    // we cannot add a maxlengthChanged method since it's `private` in FAST and would throw several TS errors
    // this is a workaround for that.
    attributeChangedCallback(name, oldValue, newValue) {
        var _a, _b;
        super.attributeChangedCallback(name, oldValue, newValue);
        if (name === 'readonly' && this.hasAttribute('readonly')) {
            this.setAttribute('aria-readonly', 'true');
        }
        else if (name === 'readonly' && !this.hasAttribute('readonly')) {
            this.removeAttribute('aria-readonly');
        }
        this.addAllRelevantAttributes();
        if (this.hasAttribute('disabled')) {
            requestAnimationFrame(() => {
                var _a;
                (_a = this.control) === null || _a === void 0 ? void 0 : _a.removeAttribute('disabled');
            });
        }
        (_a = this.control) === null || _a === void 0 ? void 0 : _a.setAttribute('aria-disabled', `${this.hasAttribute('disabled')}`);
        (_b = this.control) === null || _b === void 0 ? void 0 : _b.addEventListener('keydown', (event) => {
            if (this.hasAttribute('disabled') && event.key !== 'Tab') {
                event.preventDefault();
            }
        });
    }
    addIdForLabel() {
        if (this.labelId) {
            this.control.setAttribute('aria-labelledby', this.labelId);
        }
    }
    addAllRelevantAttributes() {
        var _a, _b, _c;
        const attributes = this.getAttributeNames();
        attributes.forEach((attribute) => {
            var _a, _b, _c;
            if (Object.keys(HTMLInputElement.prototype).includes(attribute)) {
                (_a = this.control) === null || _a === void 0 ? void 0 : _a.setAttribute(attribute, this.getAttribute(attribute));
                if (attribute === 'disabled') {
                    (_b = this.control) === null || _b === void 0 ? void 0 : _b.removeAttribute('disabled');
                    (_c = this.control) === null || _c === void 0 ? void 0 : _c.setAttribute('aria-disabled', `${this.hasAttribute('disabled')}`);
                }
            }
        });
        if (this.optional || ((_a = this.textContent) === null || _a === void 0 ? void 0 : _a.includes('(optional)'))) {
            (_b = this.control) === null || _b === void 0 ? void 0 : _b.removeAttribute('aria-required');
        }
        else {
            (_c = this.control) === null || _c === void 0 ? void 0 : _c.setAttribute('aria-required', 'true');
        }
    }
    addHelperText() {
        var _a, _b, _c;
        this.helperTextEl.setAttribute('name', 'helpertext');
        if (this.querySelector('[slot="helpertext"]')) {
            (_a = this.querySelector('[slot="helpertext"]')) === null || _a === void 0 ? void 0 : _a.setAttribute('id', uniqueId('helpertext-'));
        }
        this.helperTextEl.addEventListener('slotchange', this.handleHelperTextSlotChange);
        const helperText = (_b = this.shadowRoot) === null || _b === void 0 ? void 0 : _b.querySelector('[name="helpertext"]');
        if (!helperText) {
            (_c = this.infoContainer) === null || _c === void 0 ? void 0 : _c.prepend(this.helperTextEl);
        }
    }
    setAccessibleLabel() {
        var _a, _b, _c;
        if (((_a = this.textContent) === null || _a === void 0 ? void 0 : _a.trim()) && ((_b = this.helperTextEl.assignedElements()[0]) === null || _b === void 0 ? void 0 : _b.textContent) !== ((_c = this.textContent) === null || _c === void 0 ? void 0 : _c.trim())) {
            return;
        }
        if (this.labelId) {
            this.setAttribute('aria-labelledby', this.labelId);
            return;
        }
        if (DEV) {
            this.validateAccessibleLabel();
        }
    }
    validateAccessibleLabel() {
        var _a;
        if (!((_a = this.getAttribute('aria-labelledby')) === null || _a === void 0 ? void 0 : _a.length)) {
            console.error(`CHAMELEON-A11Y: InputField component must have an accessible label provided by either 
  1) text content 
  2) a visible label whose id is given as the attribute labelId
`);
        }
    }
}
__decorate([
    attr
], InputFieldComponent.prototype, "fieldsize", void 0);
__decorate([
    attr({ mode: 'boolean' })
], InputFieldComponent.prototype, "success", void 0);
__decorate([
    attr({ mode: 'boolean' })
], InputFieldComponent.prototype, "error", void 0);
__decorate([
    attr({ mode: 'boolean' })
], InputFieldComponent.prototype, "fullwidth", void 0);
__decorate([
    attr({ mode: 'boolean' })
], InputFieldComponent.prototype, "optional", void 0);
__decorate([
    attr({ attribute: 'label-id' })
], InputFieldComponent.prototype, "labelId", void 0);
__decorate([
    attr
], InputFieldComponent.prototype, "autocomplete", void 0);
