export function changeIconToOppositeType(component, condition) {
    if (!component)
        return;
    const text = component.textContent;
    if (!text)
        return;
    if (condition) {
        component.textContent = text === null || text === void 0 ? void 0 : text.replace('OUTLINED', 'FILLED');
    }
    else {
        component.textContent = text === null || text === void 0 ? void 0 : text.replace('FILLED', 'OUTLINED');
    }
}
