import { __rest } from "tslib";
import { forwardRef } from 'react';
import { createReactWrapper } from '../common/PrefixUtils';
export const IconButton = forwardRef((_a, ref) => {
    var { isLoading, labelId, badgeLabel, children } = _a, props = __rest(_a, ["isLoading", "labelId", "badgeLabel", "children"]);
    return createReactWrapper({
        tagName: 'chameleon-icon-button',
        props: Object.assign(Object.assign({}, props), { 'is-loading': isLoading, 'label-id': labelId, 'badge-label': badgeLabel, ref }),
        children,
    });
});
