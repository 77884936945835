// OnePay events
export const OnePayLanding = 'OnePayLanding';
export const OnePayAPIError = 'OnePayAPIError';

// Payment methods events
export const PaymentMethodLanding = 'PaymentMethodLanding';
export const PaymentMethodBackToG2A = 'PaymentMethodBackToG2A';
export const PaymentMethodSetDefault = 'PaymentMethodSetDefault';
export const PaymentMethodDelete = 'PaymentMethodDelete';

// Credit card events
export const CreditCardSaveCTA = 'CreditCardSaveCTA';
export const CreditCardCancelCTA = 'CreditCardCancelCTA';

// ACH events
export const ACHCancelCTA = 'ACHCancelCTA';
export const ACHSaveCTA = 'ACHSaveCTA';

// Direct debit events
export const SEPASaveCTA = 'SEPASaveCTA';

// Direct debit events
export const PaypalSaveStep1 = 'PaypalSaveStep1';
export const PaypalSaveRedirect = 'PaypalSaveRedirect';

// Paynow events
export const PaynowLanding = 'PaynowLanding';
export const PaynowBackToCheckout = 'PaynowBackToCheckout';
export const PaynowAddNewMethod = 'PaynowAddNewMethod';
export const PaynowContinueAddNewMethod = 'PaynowContinueAddNewMethod';
export const PaynowCTA = 'PaynowCTA';
export const PaynowSaveAndPay = 'PaynowSaveAndPay';

export const Revenue = 'Revenue';
