import { NavigationRailComponent } from './NavigationRail';
import { SVG_DOUBLE_CHEVRON_RIGHT_OUTLINED, SVG_DOUBLE_CHEVRON_LEFT_OUTLINED } from '@getgo/chameleon-icons';
import styles from './NavigationRail.styles.scss';
import customItemStyles from './defaultNavigationRailItemStyling.styles.scss';
import { html, slotted, when } from '@microsoft/fast-element';
//TODO: add logic with state on the SVG part of collapse button
export const chameleonNavigationRailTemplate = html `
  <template
    @keydown="${(x, c) => x.handleMenuKeyDown(c.event)}"
    @focusout="${(x, c) => x.handleFocusOut(c.event)}"
    role="menu"
  >
    <div class="items">
      <slot ${slotted('items')}></slot>
    </div>
    <div style="display: ${(x) => (x.shouldDisplaySecondaryItems ? 'flex' : 'none')}" class="secondary-items">
      <slot name="secondary-items" ${slotted('secondaryItems')}></slot>
    </div>
    <chameleon-navigation-rail-item
      part="toggle-button"
      slot="toggle-button"
      tabindex="0"
      aria-label="${(x) => x.toggleLabel}"
      :size="${(x) => x.size}"
      :state="${(x) => x.state}"
      @click="${(x) => x.toggle()}"
      @keyup="${(x, c) => x.handleCollapseButtonKeyup(c.event)}"
    >
      <chameleon-svg slot="start" style="--goto-icon-size: 24px;">
        ${when((x) => x.size === 'large', html `${SVG_DOUBLE_CHEVRON_LEFT_OUTLINED}`)}
        ${when((x) => x.size !== 'large', html `${SVG_DOUBLE_CHEVRON_RIGHT_OUTLINED}`)}
      </chameleon-svg>
      ${(x) => x.toggleLabel}
    </chameleon-navigation-rail-item>
  </template>
`;
export const chameleonNavigationRailDefinition = {
    baseName: 'navigation-rail',
    template: chameleonNavigationRailTemplate,
    styles: `${styles}${customItemStyles}`,
    shadowOptions: {
        delegatesFocus: true,
    },
};
export const chameleonNavigationRail = NavigationRailComponent.compose(chameleonNavigationRailDefinition);
