import { __decorate } from "tslib";
import { ChameleonElementMixin } from '../../common/mixin';
import { attr } from '@microsoft/fast-element';
import { Anchor } from '@microsoft/fast-foundation';
export class BaseNavigationRailLinkComponent extends ChameleonElementMixin(Anchor) {
    constructor() {
        super(...arguments);
        this.disabled = false;
        this.appearance = 'navrailitem';
    }
    connectedCallback() {
        var _a;
        super.connectedCallback();
        // This is a temporary fix. We have to set the --goto-icon-size variable
        // on the svg element explicitly, because in some cases the element cannot
        // read the property if it's set somewhere up the tree on the parent.
        for (const svg of this.querySelectorAll('chameleon-svg').values()) {
            svg.style.setProperty('--goto-icon-size', '24px');
        }
        if (((_a = this.querySelector('[slot="end"]')) === null || _a === void 0 ? void 0 : _a.assignedNodes().length) <= 0) {
            this.removeAttribute('data-has-end-slot');
        }
        else {
            this.setAttribute('data-has-end-slot', '');
        }
    }
}
__decorate([
    attr({ mode: 'boolean' })
], BaseNavigationRailLinkComponent.prototype, "selected", void 0);
__decorate([
    attr({ mode: 'boolean' })
], BaseNavigationRailLinkComponent.prototype, "disabled", void 0);
__decorate([
    attr
], BaseNavigationRailLinkComponent.prototype, "appearance", void 0);
