import { __decorate } from "tslib";
import { FoundationElement } from '@microsoft/fast-foundation';
import { ChameleonElementMixin } from '../common/mixin';
import { DOM, attr, html, observable, slotted, when } from '@microsoft/fast-element';
import { SVG_ALERT_OUTLINED } from '@getgo/chameleon-icons';
const errorTemplate = () => html `
  <div>
    <div>
      <chameleon-icon-badge-v2
        size="${(x) => x.badgeSize()}"
        variant="secondary"
        style="--goto-icon-color: ${(x) => (x.error ? 'var(--goto-icon-color-danger)' : `var(--${x.iconColorToken})`)}"
        color-token="${(x) => (x.error ? 'goto-surface-color-secondary' : x.badgeColorToken)}"
      >
        <chameleon-svg>${SVG_ALERT_OUTLINED}</chameleon-svg>
      </chameleon-icon-badge-v2>
      <div>
        <chameleon-typography color="type-color-default" variant="${(x) => x.titleFont()}">
          <slot name="title"></slot>
        </chameleon-typography>
        ${when((x) => x.size !== 'small', html `
            <chameleon-typography tag="p" color="type-color-secondary" variant="${(x) => x.metaFont()}">
              <slot name="meta"></slot>
            </chameleon-typography>
          `)}
      </div>
    </div>
    <div class="actions">
      <slot name="actions" ${slotted('actions')}></slot>
    </div>
  </div>
`;
const normalTemplate = () => html `
  <button type="button">
    <div>
      <slot name="media">
        <chameleon-icon-badge-v2
          label="${(x) => x.badgeLabel}"
          title="${(x) => x.size}"
          size="${(x) => x.badgeSize()}"
          variant="secondary"
          style="--goto-icon-color: ${(x) => x.error ? 'var(--goto-icon-color-danger)' : `var(--${x.iconColorToken})`}"
          color-token="${(x) => (x.error ? 'goto-surface-color-secondary' : x.badgeColorToken)}"
        >
          ${when((x) => !x.loading, html `<slot name="icon"></slot>`)}
          ${when((x) => x.loading, html `<chameleon-circular-progress
              value="${(x) => x.loadingProgress}"
              size="${(x) => x.progressSize()}"
            ></chameleon-circular-progress>`)}
        </chameleon-icon-badge-v2>
      </slot>
      <div>
        <chameleon-typography color="type-color-default" variant="${(x) => x.titleFont()}">
          <slot name="title" ${slotted('titleSlot')}></slot>
        </chameleon-typography>
        ${when((x) => x.size !== 'small', html `
            <chameleon-typography tag="p" color="type-color-secondary" variant="${(x) => x.metaFont()}">
              <slot name="meta"></slot>
            </chameleon-typography>
          `)}
      </div>
    </div>
  </button>
`;
export const fileThumbnailTemplate = html `
  ${when((x) => !x.error, normalTemplate())} ${when((x) => x.error, errorTemplate())}
  <slot name="dismiss" ${slotted('dismiss')}></slot>
`;
export class FileThumbnailComponent extends ChameleonElementMixin(FoundationElement) {
    constructor() {
        super(...arguments);
        this.size = 'medium';
        this.badgeColorToken = '';
        this.iconColorToken = '';
        this.loading = false;
        this.error = false;
        this.fullWidth = false;
        this.loadingProgress = undefined;
        this.actions = [];
        this.dismiss = [];
        this.titleSlot = [];
    }
    sizeChanged() {
        this.setRoundButtonSize();
    }
    actionsChanged() {
        this.setRoundButtonSize();
    }
    dismissChanged() {
        this.setRoundButtonSize();
    }
    titleSlotChanged(_, next) {
        var _a;
        if (next.length) {
            this.badgeLabel = (_a = next[0]) === null || _a === void 0 ? void 0 : _a.textContent;
        }
    }
    // Setting the width here is necessary fo the overflow calculations to work in full-width mode as well.
    fullWidthChanged(_, next) {
        if (next) {
            DOM.queueUpdate(() => {
                this.style.setProperty('--width', `${this.clientWidth}px`);
            });
        }
        else {
            this.style.removeProperty('--width');
        }
    }
    titleFont() {
        switch (this.size) {
            case 'small':
            case 'medium':
                return 'caption-medium-strong';
            case 'large':
                return 'body-small-strong';
            default:
                return 'caption-medium-strong';
        }
    }
    metaFont() {
        switch (this.size) {
            case 'small':
            case 'medium':
                return 'caption-small';
            case 'large':
                return 'caption-medium';
            default:
                return 'caption-small';
        }
    }
    badgeSize() {
        switch (this.size) {
            case 'small':
                return 'small';
            case 'medium':
                return 'medium';
            case 'large':
                return 'xlarge';
            default:
                return 'medium';
        }
    }
    progressSize() {
        switch (this.size) {
            case 'small':
                return 'small';
            case 'medium':
                return 'medium';
            case 'large':
                return 'large';
            default:
                return 'medium';
        }
    }
    setRoundButtonSize() {
        const roundButtons = this.querySelectorAll('chameleon-round-button');
        for (const roundButton of roundButtons) {
            roundButton.setAttribute('size', 'small');
        }
    }
}
__decorate([
    attr
], FileThumbnailComponent.prototype, "size", void 0);
__decorate([
    attr({ attribute: 'badge-color-token' })
], FileThumbnailComponent.prototype, "badgeColorToken", void 0);
__decorate([
    attr({ attribute: 'icon-color-token' })
], FileThumbnailComponent.prototype, "iconColorToken", void 0);
__decorate([
    attr({ mode: 'boolean' })
], FileThumbnailComponent.prototype, "loading", void 0);
__decorate([
    attr({ mode: 'boolean' })
], FileThumbnailComponent.prototype, "error", void 0);
__decorate([
    attr({ mode: 'boolean', attribute: 'full-width' })
], FileThumbnailComponent.prototype, "fullWidth", void 0);
__decorate([
    attr({ attribute: 'loading-progress' })
], FileThumbnailComponent.prototype, "loadingProgress", void 0);
__decorate([
    observable
], FileThumbnailComponent.prototype, "actions", void 0);
__decorate([
    observable
], FileThumbnailComponent.prototype, "dismiss", void 0);
__decorate([
    observable
], FileThumbnailComponent.prototype, "titleSlot", void 0);
__decorate([
    observable
], FileThumbnailComponent.prototype, "badgeLabel", void 0);
__decorate([
    observable
], FileThumbnailComponent.prototype, "titleFont", null);
__decorate([
    observable
], FileThumbnailComponent.prototype, "metaFont", null);
__decorate([
    observable
], FileThumbnailComponent.prototype, "badgeSize", null);
__decorate([
    observable
], FileThumbnailComponent.prototype, "progressSize", null);
