import { __decorate } from "tslib";
import { attr, observable } from '@microsoft/fast-element';
import { ChameleonElementMixin } from '../../common/mixin';
import { NavigationRailComponent } from '../NavigationRail';
import { MenuItem } from '@microsoft/fast-foundation';
export class NavigationRailItemComponent extends ChameleonElementMixin(MenuItem) {
    constructor() {
        super(...arguments);
        /*
         *   @deprecated
         *   Will be removed in upcoming major update
         * */
        this.size = 'small';
        this.state = 'expanded';
        this.disabled = false;
        this.appearance = 'navrailitem';
        this.wrapperElement = null;
    }
    stateChanged() {
        this.assignText();
    }
    selectedChanged(_, newValue) {
        if (newValue) {
            this.setAttribute('aria-current', 'page');
        }
        else {
            this.removeAttribute('aria-current');
        }
    }
    attributeChangedCallback(propertyName, oldValue, newValue) {
        super.attributeChangedCallback(propertyName, oldValue, newValue);
        if (propertyName === 'selected') {
            const svgComponent = this.querySelector('chameleon-svg');
            if (svgComponent) {
                svgComponent.style.setProperty('--goto-icon-size', '24px');
            }
        }
    }
    connectedCallback() {
        var _a, _b, _c, _d;
        super.connectedCallback();
        this.toggleSlots();
        this.wrapperElement = this.parentNode;
        if (this.wrapperElement instanceof NavigationRailComponent) {
            this.size = (_a = this.wrapperElement) === null || _a === void 0 ? void 0 : _a.size;
            this.state = (_b = this.wrapperElement) === null || _b === void 0 ? void 0 : _b.state;
        }
        this.contentElement = (_c = this.shadowRoot) === null || _c === void 0 ? void 0 : _c.querySelector('[slot="content"]');
        this.tooltipElement = (_d = this.shadowRoot) === null || _d === void 0 ? void 0 : _d.querySelector('chameleon-tooltip-v2');
        this.addEventListener('focus', this.toggleTooltipOnFocusBlur);
        this.addEventListener('blur', this.toggleTooltipOnFocusBlur);
    }
    /* 'defaultNode' is the text, what the user typed in to the component
     * and we assign it to the content slot of the tooltip v2
     */
    defaultSlottedNodesChanged() {
        this.assignText();
        this.toggleSlots();
    }
    assignText() {
        if (this.defaultSlottedNodes && this.defaultSlottedNodes.length > 0) {
            const textNodes = this.defaultSlottedNodes.filter((defaultNode) => defaultNode.nodeName === '#text');
            const trueTextNode = textNodes.find((textNode) => textNode.textContent && !textNode.textContent.startsWith('\n'));
            if (this.contentElement && !!(trueTextNode === null || trueTextNode === void 0 ? void 0 : trueTextNode.textContent)) {
                this.contentElement.textContent = trueTextNode.textContent;
            }
            if (this.className === 'more-items-container') {
                this.contentElement.textContent = this.textContent;
            }
        }
    }
    toggleTooltipOnFocusBlur() {
        if (this.slot === 'toggle-button' &&
            (this.size === 'small' || this.state === 'collapsed') &&
            !this.tooltipElement.isOpen) {
            this.tooltipElement.open();
        }
        else {
            if (document.activeElement === this && (this.size === 'small' || this.state === 'collapsed')) {
                this.tooltipElement.open();
            }
            else {
                this.tooltipElement.close();
            }
        }
    }
    toggleSlots() {
        var _a, _b, _c, _d;
        if (!this.querySelector('[slot="start"]')) {
            this.style.setProperty('--start-slot-display', 'none');
        }
        else {
            this.style.setProperty('--start-slot-display', 'contents');
        }
        if (!this.querySelector('[slot="end"]')) {
            (_b = (_a = this.shadowRoot) === null || _a === void 0 ? void 0 : _a.querySelector('chameleon-base-navigation-rail-item')) === null || _b === void 0 ? void 0 : _b.removeAttribute('data-has-end-slot');
            this.style.setProperty('--end-slot-display', 'none');
        }
        else {
            (_d = (_c = this.shadowRoot) === null || _c === void 0 ? void 0 : _c.querySelector('chameleon-base-navigation-rail-item')) === null || _d === void 0 ? void 0 : _d.setAttribute('data-has-end-slot', '');
            this.style.setProperty('--end-slot-display', 'contents');
        }
    }
    endSlottedNodesChanged() {
        this.setBadgeCounter();
    }
    setBadgeCounter() {
        if (this.endSlottedNodes && this.endSlottedNodes.length > 0) {
            const textNodes = this.endSlottedNodes[0];
            if (this.endSlottedNodes[0] instanceof Element) {
                textNodes.setAttribute('size', 'small');
            }
        }
    }
}
__decorate([
    attr
], NavigationRailItemComponent.prototype, "size", void 0);
__decorate([
    attr
], NavigationRailItemComponent.prototype, "state", void 0);
__decorate([
    attr({ mode: 'boolean' })
], NavigationRailItemComponent.prototype, "disabled", void 0);
__decorate([
    attr({ mode: 'boolean' })
], NavigationRailItemComponent.prototype, "selected", void 0);
__decorate([
    attr({ mode: 'boolean' })
], NavigationRailItemComponent.prototype, "sticky", void 0);
__decorate([
    attr({ mode: 'boolean' })
], NavigationRailItemComponent.prototype, "collapse", void 0);
__decorate([
    attr
], NavigationRailItemComponent.prototype, "appearance", void 0);
__decorate([
    observable
], NavigationRailItemComponent.prototype, "defaultSlottedNodes", void 0);
__decorate([
    observable
], NavigationRailItemComponent.prototype, "endSlottedNodes", void 0);
