import { chameleonCalendar } from '../Calendar/Calendar.definition';
import { chameleonIconButton } from '../IconButton/IconButton.definition';
import { chameleonSvg } from '../Svg';
import { provideChameleonDesignSystem } from '@getgo/chameleon-toolkit';
import { chameleonDatePicker } from './DatePicker.definition';
import { chameleonTextField } from '../TextField/TextField.definition';
import { chameleonButton } from '../Button/Button.definition';
export function registerCustom(prefix, node) {
    return provideChameleonDesignSystem({ prefix, node }).register(chameleonDatePicker(), chameleonCalendar(), chameleonIconButton(), chameleonSvg(), chameleonTextField(), chameleonButton());
}
