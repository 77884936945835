import { __decorate } from "tslib";
import { Menu } from '@microsoft/fast-foundation';
import { attr } from '@microsoft/fast-element';
import { ChameleonElementMixin } from '../common/mixin';
import { DEV } from 'esm-env';
export class NavigationDrawerComponent extends ChameleonElementMixin(Menu) {
    connectedCallback() {
        super.connectedCallback();
        this.propagateSizeAttribute(this.getAttribute('size'));
        this.setAccessibleLabel();
    }
    sizeChanged(_oldValue, newValue) {
        this.propagateSizeAttribute(newValue);
    }
    propagateSizeAttribute(newSize) {
        const items = this.querySelectorAll('chameleon-navigation-drawer-item, chameleon-navigation-drawer-link');
        items === null || items === void 0 ? void 0 : items.forEach((item) => {
            if (newSize) {
                item.setAttribute('size', newSize);
            }
            else {
                item.removeAttribute('size');
            }
        });
    }
    getNavDrawerHeaderTextContent() {
        const navDrawerHeader = this.querySelector('chameleon-navigation-drawer-header');
        return navDrawerHeader === null || navDrawerHeader === void 0 ? void 0 : navDrawerHeader.textContent;
    }
    setAccessibleLabel() {
        var _a, _b;
        if (this.labelId) {
            this.setAttribute('aria-labelledby', this.labelId);
            return;
        }
        if ((_a = this.getNavDrawerHeaderTextContent()) === null || _a === void 0 ? void 0 : _a.length) {
            this.setAttribute('aria-label', (_b = this.getNavDrawerHeaderTextContent()) !== null && _b !== void 0 ? _b : '');
            return;
        }
        if (DEV) {
            this.validateAccessibleLabel();
        }
    }
    validateAccessibleLabel() {
        var _a, _b;
        if (!((_a = this.getAttribute('aria-label')) === null || _a === void 0 ? void 0 : _a.length) && !((_b = this.getAttribute('aria-labelledby')) === null || _b === void 0 ? void 0 : _b.length)) {
            console.error(`CHAMELEON-A11Y: Navigation Drawer component must have an accessible label provided by either 
  1) text content in a Navigation Header component 
  2) a visible label whose id is given as the attribute labelId
`);
        }
    }
}
__decorate([
    attr
], NavigationDrawerComponent.prototype, "size", void 0);
__decorate([
    attr({ attribute: 'label-id' })
], NavigationDrawerComponent.prototype, "labelId", void 0);
