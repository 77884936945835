import { __decorate } from "tslib";
import { FoundationElement } from '@microsoft/fast-foundation';
import { ChameleonElementMixin } from '../../common/mixin';
import { attr, html, when } from '@microsoft/fast-element';
import { SVG_ARROW_DOWN, SVG_ARROW_UP } from '@getgo/chameleon-icons';
export const templateDataTableHeaderCellV2 = html `<template role="columnheader">
  <button
    class="cell"
    @keydown="${(el, c) => el.handleKeyDown(c.event)}"
    @click="${(el) => el.handleClick()}"
    ?disabled="${(el) => el.disabled}"
  >
    <chameleon-typography
      variant="caption-medium-strong"
      color="${(el) => (el.disabled ? 'type-color-disabled' : 'type-color-secondary')}"
    >
      <slot></slot>
    </chameleon-typography>
    ${when((el) => el.sort === 'asc', html ` <chameleon-svg>${SVG_ARROW_UP}</chameleon-svg> `)}
    ${when((el) => el.sort === 'desc', html ` <chameleon-svg>${SVG_ARROW_DOWN}</chameleon-svg> `)}
  </button>
</template>`;
export class DataTableHeaderCellV2Component extends ChameleonElementMixin(FoundationElement) {
    constructor() {
        super(...arguments);
        this.condensed = false;
        this.disabled = false;
        this.handleHostClick = (e) => {
            if (this.disabled) {
                e.preventDefault();
                e.stopImmediatePropagation();
            }
        };
    }
    connectedCallback() {
        super.connectedCallback();
        this.addEventListener('click', this.handleHostClick);
    }
    disconnectedCallback() {
        this.removeEventListener('click', this.handleHostClick);
    }
    handleClick() {
        if (this.disabled) {
            return;
        }
        this.$emit('sort', undefined, { bubbles: true });
    }
    handleKeyDown(e) {
        if (e.key === 'Enter' || e.key === 'Space') {
            this.$emit('sort', undefined, { bubbles: true });
        }
        return true;
    }
}
__decorate([
    attr
], DataTableHeaderCellV2Component.prototype, "sort", void 0);
__decorate([
    attr({ mode: 'boolean' })
], DataTableHeaderCellV2Component.prototype, "condensed", void 0);
__decorate([
    attr({ mode: 'boolean' })
], DataTableHeaderCellV2Component.prototype, "disabled", void 0);
