import { __decorate } from "tslib";
import { SVG_FILE } from '@getgo/chameleon-icons';
import { attr, html, when } from '@microsoft/fast-element';
import { FoundationElement } from '@microsoft/fast-foundation';
import { ChameleonElementMixin } from '../common/mixin';
export const thumbnailTemplate = html `
  <div class="icon">
      ${when((x) => !x.fileType, html `<slot name="image"></slot>`)}
      ${when((x) => x.fileType, html `<chameleon-svg>${SVG_FILE}</chameleon-svg> ${when((x) => x.fileType !== 'blank', html `<chameleon-icon-badge
              size="${(x) => x.computeIconBadgeSize(x.size)}"
              type="${(x) => x.fileType}"
            ></chameleon-icon-badge>`)}`)}
    </div>
    <div class="content">
      <chameleon-typography
        variant="caption-medium-strong"
        class="title"
        color="type-color-default"
        ?nowrap=${(x) => x.size !== 'large'}
        ><slot></slot
      ></chameleon-typography>
      ${when((x) => x.size !== 'small', html `<chameleon-typography
          variant="caption-medium"
          color="type-color-secondary"
          ?nowrap=${(x) => x.size !== 'large'}
          ><slot name="description"></slot
        ></chameleon-typography>`)}
    </div></ThumbnailComponent
  >`;
export class ThumbnailComponent extends ChameleonElementMixin(FoundationElement) {
    constructor() {
        super(...arguments);
        this.size = 'medium';
    }
    computeIconBadgeSize(size) {
        if (!size) {
            return 'medium';
        }
        if (size === 'large') {
            return 'xlarge';
        }
        return size === 'medium' ? 'small' : size;
    }
}
__decorate([
    attr
], ThumbnailComponent.prototype, "size", void 0);
__decorate([
    attr({ attribute: 'file-type' })
], ThumbnailComponent.prototype, "fileType", void 0);
