import { css } from '@microsoft/fast-element';
import { popoverTemplate } from '../Popover';
import styles from '../Popover/Popover.styles.scss';
import { TooltipV2Component } from './TooltipV2';
import tooltipStyles from './TooltipV2.styles.scss';
export const tooltipV2Definition = {
    baseName: 'tooltip-v2',
    styles: css `
    ${styles}
    ${tooltipStyles}
  `,
    template: popoverTemplate,
};
export const chameleonTooltipV2 = TooltipV2Component.compose(tooltipV2Definition);
