import { __decorate } from "tslib";
import { attr } from '@microsoft/fast-element';
import { FoundationElement } from '@microsoft/fast-foundation';
import { ChameleonElementMixin } from './mixin';
export const DEFAULT_CLOSE_ARIA_LABEL = 'Close';
export class AlertBase extends ChameleonElementMixin(FoundationElement) {
    constructor() {
        super(...arguments);
        this.closeLabel = DEFAULT_CLOSE_ARIA_LABEL;
    }
    close() {
        this.$emit('close', null, { bubbles: false });
    }
}
__decorate([
    attr
], AlertBase.prototype, "variant", void 0);
__decorate([
    attr({ mode: 'boolean' })
], AlertBase.prototype, "closable", void 0);
__decorate([
    attr({ attribute: 'close-aria-label' })
], AlertBase.prototype, "closeLabel", void 0);
