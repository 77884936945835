import { Button, buttonTemplate as template } from '@microsoft/fast-foundation';
import { IconButtonComponent } from './IconButton';
import styles from './IconButton.styles.scss';
export const iconButtonDefinition = {
    baseName: 'icon-button',
    baseClass: Button,
    template: template,
    styles,
};
export const chameleonIconButton = IconButtonComponent.compose(iconButtonDefinition);
