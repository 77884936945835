import { __decorate } from "tslib";
import { attr, html } from '@microsoft/fast-element';
import { FoundationElement } from '@microsoft/fast-foundation';
import { ChameleonElementMixin } from '../common/mixin';
export const snackbarTemplate = html `
  <div class="message">
    <slot name="title"></slot>
    <slot name="message"></slot>
    <slot name="progress"></slot>
  </div>
  <div class="actions">
    <slot name="action"></slot>
  </div>
`;
export class SnackbarComponent extends ChameleonElementMixin(FoundationElement) {
    constructor() {
        super(...arguments);
        this.stacked = false;
        this.centered = false;
    }
}
__decorate([
    attr({ mode: 'boolean' })
], SnackbarComponent.prototype, "stacked", void 0);
__decorate([
    attr({ mode: 'boolean' })
], SnackbarComponent.prototype, "centered", void 0);
