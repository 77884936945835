import { FC } from 'react';

import DirectDebitForm from 'components/direct-debit-form';
import DirectDebitHeader from 'components/direct-debit-header';

const DirectDebitAdd: FC = (): JSX.Element => (
  <>
    <DirectDebitHeader />
    <DirectDebitForm />
  </>
);

export default DirectDebitAdd;
