import { __decorate } from "tslib";
import { Button } from '@microsoft/fast-foundation';
import { attr } from '@microsoft/fast-element';
import { ChameleonElementMixin } from '../../common/mixin';
export class ToolbarButtonComponent extends ChameleonElementMixin(Button) {
    defaultSlottedContentChanged() {
        this.classList.toggle('empty-content', !this.defaultSlottedContent.length);
    }
}
__decorate([
    attr({ mode: 'boolean' })
], ToolbarButtonComponent.prototype, "selected", void 0);
