import { useIntl } from 'react-intl';
import { Button, Snackbar } from '@getgo/chameleon-web-react-wrapper';

import { useAppDispatch, useAppSelector } from 'hooks';
import { dismissErrorSnack, errorsMessagesSorted } from 'modules/error';
import st from 'utils/shared-translations';

import './error-snackbar.css';

const ErrorSnackbar = () => {
  const intl = useIntl();
  const dispatch = useAppDispatch();

  const selectedErrorMsgs = useAppSelector(errorsMessagesSorted);

  const handleClose = (id: string) => dispatch(dismissErrorSnack(id));

  return (
    <div id="error-snackbar">
      {selectedErrorMsgs.map((msg) => (
        <Snackbar
          key={msg.key}
          className="error-snackbar"
          title={intl.formatMessage(st[msg.key])}
          actions={
            <Button onClick={() => handleClose(msg.key)} size="small" variant="inverted">
              {intl.formatMessage(st['alert.error.dismiss'])}
            </Button>
          }
        />
      ))}
    </div>
  );
};

export default ErrorSnackbar;
