/* https://stackblitz.com/edit/typescript-sha256-base64-encoded */

import { encode } from './base64';

export const sha256 = (message: string) => {
  const encoder = new TextEncoder();
  const data = encoder.encode(message);
  return window.crypto.subtle.digest('SHA-256', data);
};

export const urlEncodeBase64 = (input: string) => {
  const chars = { '+': '-', '/': '_', '=': '' };
  return input.replace(/[+/=]/g, (m) => chars[m]);
};

export const bufferToBase64UrlEncoded = (input: ArrayBuffer) => {
  const bytes = new Uint8Array(input);
  return urlEncodeBase64(encode(String.fromCharCode(...bytes)));
};

export const getBase64UrlEncodedCodeVerifier = async (verifierStr = '') => {
  const shaBuffer = await sha256(verifierStr);
  return bufferToBase64UrlEncoded(shaBuffer);
};
