import { __decorate } from "tslib";
import { FoundationElement } from '@microsoft/fast-foundation';
import { attr, html } from '@microsoft/fast-element';
import { ChameleonElementMixin } from '../../common/mixin';
import { uniqueId } from '@microsoft/fast-web-utilities';
export const interactiveSubItemTemplate = html ` <template
  tabIndex="-1"
  aria-label=${(x) => x.label}
  id=${(x) => x.uniqueId}
  ><chameleon-tooltip-v2
    ><div slot="trigger">
      <slot tabindex="-1"></slot>
    </div>
    <span slot="content">${(x) => x.label}</span>
  </chameleon-tooltip-v2></template
>`;
export class InteractiveSubItemComponent extends ChameleonElementMixin(FoundationElement) {
    constructor() {
        super(...arguments);
        this.label = '';
        this.uniqueId = uniqueId('interactive-sub-item-');
        this.handleKeyDown = (event) => {
            const { key } = event;
            const selectedChildInteractiveItem = this.firstElementChild;
            if (selectedChildInteractiveItem &&
                selectedChildInteractiveItem instanceof HTMLElement &&
                (key === 'Enter' || key === ' ')) {
                selectedChildInteractiveItem.click();
            }
        };
    }
    connectedCallback() {
        super.connectedCallback();
        this.addEventListener('keydown', this.handleKeyDown);
        this.$emit('interactive-item-added');
    }
    disconnectedCallback() {
        this.removeEventListener('keydown', this.handleKeyDown);
    }
}
__decorate([
    attr
], InteractiveSubItemComponent.prototype, "label", void 0);
__decorate([
    attr
], InteractiveSubItemComponent.prototype, "uniqueId", void 0);
