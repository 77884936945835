import { __decorate } from "tslib";
import { attr, html, observable, slotted } from '@microsoft/fast-element';
import { FoundationElement } from '@microsoft/fast-foundation';
import { ChameleonElementMixin } from '../../common/mixin';
export const dataTableRowTemplate = html `
  <template role="row">
    <slot name="drag" ${slotted('drag')}></slot>
    <slot ${slotted('cells')}></slot>
  </template>
`;
export class DataTableRowV2Component extends ChameleonElementMixin(FoundationElement) {
    constructor() {
        super(...arguments);
        this.selected = false;
        this.disabled = false;
        this.header = false;
        this.condensed = false;
        this.indented = false;
    }
    cellsChanged(_, next) {
        if (!next) {
            return;
        }
        if (this.condensed) {
            for (const cell of next) {
                cell.setAttribute('condensed', 'true');
            }
        }
        if (this.disabled) {
            for (const cell of next) {
                cell.setAttribute('disabled', 'true');
            }
        }
    }
    dragChanged(_, next) {
        if (!next) {
            return;
        }
        if (next.length > 0) {
            this.setAttribute('draggable', 'true');
        }
        else {
            this.removeAttribute('draggable');
        }
        if (this.condensed) {
            for (const cell of next) {
                cell.setAttribute('condensed', 'true');
            }
        }
    }
}
__decorate([
    attr({ mode: 'boolean' })
], DataTableRowV2Component.prototype, "selected", void 0);
__decorate([
    attr({ mode: 'boolean' })
], DataTableRowV2Component.prototype, "disabled", void 0);
__decorate([
    attr({ mode: 'boolean' })
], DataTableRowV2Component.prototype, "header", void 0);
__decorate([
    attr({ mode: 'boolean' })
], DataTableRowV2Component.prototype, "condensed", void 0);
__decorate([
    attr({ mode: 'boolean' })
], DataTableRowV2Component.prototype, "indented", void 0);
__decorate([
    observable
], DataTableRowV2Component.prototype, "cells", void 0);
__decorate([
    observable
], DataTableRowV2Component.prototype, "drag", void 0);
