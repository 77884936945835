import { __decorate } from "tslib";
import { attr } from '@microsoft/fast-element';
import { LoadingButton } from '../common/LoadingButton';
import { DEV } from 'esm-env';
export class ButtonComponent extends LoadingButton {
    constructor() {
        super(...arguments);
        this.size = 'medium';
        this.variant = 'primary';
        this.fullwidth = false;
        this.disabled = false;
        this.type = 'button';
    }
    connectedCallback() {
        super.connectedCallback();
        this.setAccessibleLabel();
        // This line is necessary to make it compatible with Button before the audit
        if (this.variant === 'tertiary-neutral') {
            this.variant = 'tertiary';
            if (DEV) {
                console.warn('Chameleon Button: The variant `tertiary-neutral` is deprecated. Use `tertiary` instead');
            }
        }
        if (this.variant === 'neutral-inverse') {
            this.variant = 'inverted';
            if (DEV) {
                console.warn('Chameleon Button: The variant `neutral-inverse` is deprecated. Use `inverted` instead');
            }
        }
    }
    getInternalButtonElement() {
        var _a;
        return (_a = this.shadowRoot) === null || _a === void 0 ? void 0 : _a.querySelector('button');
    }
    disabledChanged(oldValue, newValue) {
        super.disabledChanged(oldValue, newValue);
        requestAnimationFrame(() => {
            var _a, _b;
            (_a = this.getInternalButtonElement()) === null || _a === void 0 ? void 0 : _a.removeAttribute('disabled');
            (_b = this.getInternalButtonElement()) === null || _b === void 0 ? void 0 : _b.setAttribute('aria-disabled', `${newValue}`);
        });
    }
    labelChanged() {
        this.setAccessibleLabel();
    }
    labelIdChanged() {
        this.setAccessibleLabel();
    }
    // We need to overwrite the internal keypress handler because FAST uses this to add the "submit on Enter press when inside a form"
    // behaviour to custom input fields. However in the case of the `button`, since we render actual HTML button elements inside our web-components
    // this only causes buggy behaviour like `type="button"` submitting the form on `Enter` or a `type="submit"` submitting the form twice.
    // source: https://github.com/microsoft/fast/blob/f05058084183576b641d33b18e0cdd205814ffdf/packages/web-components/fast-foundation/src/form-associated/form-associated.ts#L660
    _keypressHandler(event) {
        var _a;
        if (((_a = this.getInternalButtonElement()) === null || _a === void 0 ? void 0 : _a.getAttribute('aria-disabled')) === 'true') {
            event.preventDefault();
        }
        // We don't do anything here because buttons automatically handle form submissions on keypress
    }
    getInternalTextContent() {
        var _a, _b;
        // Parse text content to remove the svg code portion of the string for leading and/or trailing icons, if they exist. Replace is run twice, in case both are present.
        // Remaining string represents visible text content provided to the button
        const regex = new RegExp(`<svg(.*?)<\/svg>`);
        return (_b = (_a = this.textContent) === null || _a === void 0 ? void 0 : _a.replace(regex, '')) === null || _b === void 0 ? void 0 : _b.replace(regex, '');
    }
    setAccessibleLabel() {
        var _a;
        if (this.getInternalTextContent()) {
            return;
        }
        if (this.labelId) {
            this.setAttribute('aria-labelledby', this.labelId);
            return;
        }
        if (this.label) {
            (_a = this.getInternalButtonElement()) === null || _a === void 0 ? void 0 : _a.setAttribute('aria-label', this.label);
            return;
        }
        if (DEV) {
            this.validateAccessibleLabel();
        }
    }
    validateAccessibleLabel() {
        var _a, _b, _c;
        if (!((_b = (_a = this.getInternalButtonElement()) === null || _a === void 0 ? void 0 : _a.getAttribute('aria-label')) === null || _b === void 0 ? void 0 : _b.length) &&
            !((_c = this.getAttribute('aria-labelledby')) === null || _c === void 0 ? void 0 : _c.length)) {
            console.error(`CHAMELEON-A11Y: Button component must have an accessible label provided by either 
  1) text content 
  2) a visible label whose id is given as the attribute labelId 
  3) a non-empty label attribute to be used as aria-label.
`);
        }
    }
}
__decorate([
    attr
], ButtonComponent.prototype, "size", void 0);
__decorate([
    attr
], ButtonComponent.prototype, "variant", void 0);
__decorate([
    attr({ mode: 'boolean' })
], ButtonComponent.prototype, "fullwidth", void 0);
__decorate([
    attr({ mode: 'boolean' })
], ButtonComponent.prototype, "disabled", void 0);
__decorate([
    attr
], ButtonComponent.prototype, "type", void 0);
__decorate([
    attr({ attribute: 'label-id' })
], ButtonComponent.prototype, "labelId", void 0);
__decorate([
    attr
], ButtonComponent.prototype, "label", void 0);
