import { __decorate } from "tslib";
import { Switch } from '@microsoft/fast-foundation';
import { ChameleonElementMixin } from '../common/mixin';
import { attr } from '@microsoft/fast-element';
import { DEV } from 'esm-env';
export class SwitchComponent extends ChameleonElementMixin(Switch) {
    connectedCallback() {
        super.connectedCallback();
        this.setAccessibleLabel();
    }
    labelIdChanged() {
        this.setAccessibleLabel();
    }
    setAccessibleLabel() {
        var _a;
        if ((_a = this.textContent) === null || _a === void 0 ? void 0 : _a.length) {
            return;
        }
        if (this.labelId) {
            this.setAttribute('aria-labelledby', this.labelId);
            return;
        }
        if (DEV) {
            this.validateAccessibleLabel();
        }
    }
    validateAccessibleLabel() {
        var _a;
        if (!((_a = this.getAttribute('aria-labelledby')) === null || _a === void 0 ? void 0 : _a.length)) {
            console.error(`CHAMELEON-A11Y: Switch component must have an accessible label provided by either 
  1) visible text content 
  2) a visible label referenced by id in the attribute labelId
`);
        }
    }
}
__decorate([
    attr({ attribute: 'label-id' })
], SwitchComponent.prototype, "labelId", void 0);
