import { __decorate } from "tslib";
import { attr, observable, html, ref, DOM, repeat, when } from '@microsoft/fast-element';
import { FoundationElement } from '@microsoft/fast-foundation';
import { NumberParser } from '@internationalized/number';
import { ChameleonElementMixin } from '../common/mixin';
import { getLocalTimeZone, parseDate, today, getMinimumMonthInYear, getMinimumDayInMonth, } from '@internationalized/date';
import { SVG_CALENDAR_OUTLINED } from '@getgo/chameleon-icons';
const POPOVER_OFFSET_TOP_WITH_LABEL = '25px';
const POPOVER_OFFSET_TOP_WITHOUT_LABEL = '3px';
const POPOVER_OFFSET_BOTTOM_WITH_HELPER = '-27px';
const POPOVER_OFFSET_BOTTOM_WITHOUT_HELPER = '-8px';
const EDITABLE_SEGMENTS = ['year', 'month', 'day'];
function isEditableSegment(segment) {
    return EDITABLE_SEGMENTS.includes(segment);
}
export const datePickerTemplate = html `<template>
  <chameleon-typography variant="caption-medium-strong" color="type-color-default"
    >${(x) => x.inputLabel}</chameleon-typography
  >
  <div tabindex="-1" ${ref('field')} class="input-container">
    <div>
      ${repeat((x) => x.dateParts, html `
          ${when((x) => x.type === 'literal', html `<span>${(x) => x.text}</span>`)}
          ${when((x) => x.type !== 'literal', html `<div
              id="${(x) => x.type}"
              contenteditable
              autocapitalize="off"
              spellcheck="false"
              autocorrect="off"
              enteykeyhint="next"
              inputmode="numeric"
              role="spinbutton"
              tabindex="0"
              aria-label="${(x) => x.type}"
              aria-valuenow="${(x) => (!x.isPlaceholder ? x.value : null)}"
              aria-valuetext="${(x) => (!x.isPlaceholder ? x.text : null)}"
              aria-valuemin="${(x) => x.minValue}"
              aria-valuemax="${(x) => x.maxValue}"
              @keydown=${(x, c) => c.parent.spinButtonKeyDown(x, c.event)}
              @focus=${(x, c) => c.parent.handleSpinButtonFocus(x)}
            >
              ${(x) => {
    return x.isPlaceholder ? x.placeholder : x.text;
}}
            </div>`)}
        `)}
    </div>
    <chameleon-icon-button
      label="${(x) => x.calendarToggleLabel}"
      id="trigger"
      slot="end"
      disabled="${(x) => x.disabled}"
      :anchorElement="${(x) => x}"
    >
      <chameleon-svg>${SVG_CALENDAR_OUTLINED}</chameleon-svg>
    </chameleon-icon-button>
  </div>
  <chameleon-popover-v2
    trigger-id="trigger"
    slot="end"
    :position="${(x) => x.position}"
    ${ref('popoverElement')}
    :anchorElement=${(el) => el.field}
    @popoverchange=${(x) => x.calculatePopoverOffset()}
    @position-changed=${(x) => x.calculatePopoverOffset()}
  >
    <div role="rowgroup">
      <slot name="header"></slot>
      <chameleon-calendar
        ${ref('calendar')}
        locale="${(x) => x.locale}"
        timezone="${(x) => x.timezone}"
        error="${(x) => x.error}"
        min="${(x) => x.min}"
        max="${(x) => x.max}"
        @ch-calendar-change="${(x, c) => x.handleCalendarChange(c.event)}"
      ></chameleon-calendar>
    </div>
  </chameleon-popover-v2>
</template>`;
export class DatePickerComponent extends ChameleonElementMixin(FoundationElement) {
    constructor() {
        super();
        this.timezone = getLocalTimeZone();
        /* The selected value of the DatePicker. This should always be in ISO-8601 format **/
        this.value = '';
        this.defaultValue = '';
        this.disabled = false;
        this.error = false;
        this.placeholder = 'MM-DD-YYYY';
        this.position = 'bottom-start';
        this.locale = navigator.language;
        this.calendarToggleLabel = '';
        this.dateParts = [];
        /**
         * This date is used initially when there are no default date set as
         * base value for when the user tries to cycle through the date segments
         * */
        this.placeholderDate = today(this.timezone);
        // This is used to get the default browser validation messages for certain invalid states.
        this.validationInput = document.createElement('input');
        this.internals = this.attachInternals();
    }
    reportValidity() {
        return this.internals.reportValidity();
    }
    get validationMessage() {
        return this.internals.validationMessage;
    }
    checkValidity() {
        return this.internals.checkValidity();
    }
    get validity() {
        return this.internals.validity;
    }
    willValidate() {
        return this.internals.willValidate;
    }
    localeChanged() {
        if (!this.isConnected || !this.isLocaleValid(this.locale)) {
            return;
        }
        this.dateParts = this.calculateDateSegments();
    }
    timezoneChanged() {
        if (!this.isConnected || !this.isTimezoneValid(this.timezone)) {
            return;
        }
        this.dateParts = this.calculateDateSegments();
    }
    inputLabelChanged() {
        var _a;
        this.internals.ariaLabel = (_a = this.inputLabel) !== null && _a !== void 0 ? _a : null;
    }
    valueChanged() {
        if (!this.isConnected || !this.internals) {
            return;
        }
        if (this.hasAttribute('required') && !this.value) {
            this.validationInput.setAttribute('required', 'true');
            const message = this.validationInput.validationMessage;
            this.internals.setValidity({ valueMissing: true }, message, this.field);
        }
        else if (this.hasAttribute('required')) {
            this.internals.setValidity({});
            this.validationInput.removeAttribute('required');
            this.validationInput.value = '';
        }
        this.internals.setFormValue(this.value);
        const date = this.value !== '' ? parseDate(this.value).toDate(this.timezone) : '';
        this.$emit('change', { date });
        this.validateMinAndMax();
    }
    spinButtonKeyDown(segment, event) {
        const parser = new NumberParser(this.locale, { maximumFractionDigits: 0 });
        switch (true) {
            case event.key === 'ArrowUp':
                if (segment.isPlaceholder) {
                    this.revealSegment(segment);
                }
                else {
                    this.increment(segment);
                }
                break;
            case event.key === 'ArrowDown':
                if (segment.isPlaceholder) {
                    this.revealSegment(segment);
                }
                else {
                    this.decrement(segment);
                }
                break;
            case event.key === 'Backspace':
                this.handleBackSpace(segment);
                break;
            case parser.isValidPartialNumber(event.key, segment.minValue, segment.maxValue): {
                this.updateSegment(segment, event.key);
                break;
            }
            // On tab we want it to work us usual so we return true to prevent fast from calling
            // .preventDefault on th event
            case event.key === 'Tab':
                return true;
        }
    }
    handleSpinButtonFocus(segment) {
        segment.enteredKeys = '';
    }
    handleBackSpace(segment) {
        const parser = new NumberParser(this.locale, { maximumFractionDigits: 0 });
        if (!parser.isValidPartialNumber(segment.text) || segment.isPlaceholder) {
            return;
        }
        const newValue = segment.text.slice(0, -1);
        const parsed = parser.parse(newValue);
        segment.enteredKeys = '';
        if (newValue.length === 0 || parsed === 0) {
            this.clearSegment(segment);
        }
        else {
            const dateToUse = this.value ? parseDate(this.value) : this.placeholderDate;
            const newDate = dateToUse.set({ [segment.type]: parsed });
            const newSegment = this.createSegment(segment, newDate);
            this.setSegment(segment, newSegment, newDate);
        }
    }
    createSegment(segment, newDate, isPlaceholder = false) {
        return Object.assign(Object.assign(Object.assign({}, segment), { isPlaceholder, value: newDate[segment.type], text: this.getTextForPart(segment.type, newDate) }), this.getSegmentLimits(newDate, segment.type));
    }
    clearSegment(segment) {
        const segmentIndex = this.dateParts.indexOf(segment);
        this.placeholderDate = today(this.timezone);
        const newSegment = this.createSegment(segment, this.placeholderDate, true);
        this.dateParts.splice(segmentIndex, 1, newSegment);
        this.value = '';
    }
    updateSegment(segment, value) {
        const parser = new NumberParser(this.locale, { maximumFractionDigits: 0 });
        const newValue = segment.enteredKeys + value;
        const numberValue = parser.parse(newValue);
        let valueToSet;
        if (numberValue <= segment.maxValue && numberValue >= segment.minValue) {
            valueToSet = numberValue;
        }
        else {
            valueToSet = parser.parse(value);
        }
        // create a variable that's the inverse of the following experssion: valueToSet === 0 && segment.minValue !== 0
        const shouldUpdate = valueToSet !== 0 || segment.minValue === 0;
        if (!shouldUpdate) {
            return;
        }
        let newSegment;
        if (this.value) {
            const newDate = parseDate(this.value).set({ [segment.type]: valueToSet });
            newSegment = this.createSegment(segment, newDate);
            if (newValue.length >= String(segment.maxValue).length || Number(numberValue + '0') > segment.maxValue) {
                newSegment.enteredKeys = '';
                if (shouldUpdate) {
                    DOM.queueUpdate(() => {
                        this.focusNextSegment(newSegment);
                    });
                }
            }
            else {
                newSegment.enteredKeys = newValue;
            }
            this.setSegment(segment, newSegment, newDate);
        }
        else {
            this.placeholderDate = this.placeholderDate.set({ [segment.type]: valueToSet });
            newSegment = this.createSegment(segment, this.placeholderDate);
            if (newValue.length >= String(segment.maxValue).length || Number(numberValue + '0') > segment.maxValue) {
                newSegment.enteredKeys = '';
                if (shouldUpdate) {
                    DOM.queueUpdate(() => {
                        this.focusNextSegment(newSegment);
                    });
                }
            }
            else {
                newSegment.enteredKeys = newValue;
            }
            this.setSegment(segment, newSegment, this.placeholderDate);
        }
    }
    focusNextSegment(segment) {
        var _a;
        const segmentIndex = this.dateParts.findIndex((part) => part.type === segment.type);
        const nextSegment = this.dateParts.find((part, index) => index > segmentIndex && part.type !== 'literal');
        if (!nextSegment) {
            return;
        }
        const elementToFocus = (_a = this.shadowRoot) === null || _a === void 0 ? void 0 : _a.querySelector(`#${nextSegment.type}`);
        console.log('focusing next element', elementToFocus);
        elementToFocus === null || elementToFocus === void 0 ? void 0 : elementToFocus.focus();
    }
    setSegment(oldSegment, newSegment, newDate) {
        const segmentIndex = this.dateParts.indexOf(oldSegment);
        this.dateParts.splice(segmentIndex, 1, newSegment);
        this.dateParts = this.dateParts.map((segment) => {
            if (segment.type === 'literal') {
                return segment;
            }
            const newLimits = this.getSegmentLimits(newDate, segment.type);
            if (segment.value > newLimits.maxValue) {
                return Object.assign(Object.assign(Object.assign({}, segment), newLimits), { value: newLimits.maxValue, text: this.getTextForPart(segment.type, newDate) });
            }
            if (segment.value < newLimits.minValue) {
                return Object.assign(Object.assign(Object.assign({}, segment), newLimits), { value: newLimits.minValue, text: this.getTextForPart(segment.type, newDate) });
            }
            return Object.assign(Object.assign({}, segment), newLimits);
        });
        if (this.isDateValid(this.dateParts)) {
            this.value = newDate.toString();
            this.calendar.setAttribute('value', this.value.toString());
        }
        else {
            this.placeholderDate = newDate;
        }
    }
    // Takes an array of DateSegments, return true if all of them has the isPlaceholder property set to false, false otherwise
    isDateValid(segments) {
        for (const segment of segments) {
            if (segment.type !== 'literal' && segment.isPlaceholder) {
                return false;
            }
        }
        return true;
    }
    revealSegment(segment) {
        const segmentIndex = this.dateParts.findIndex((part) => part.type === segment.type);
        const newSegment = this.createSegment(segment, this.placeholderDate);
        this.dateParts.splice(segmentIndex, 1, newSegment);
        if (this.isDateValid(this.dateParts)) {
            this.value = this.placeholderDate.toString();
        }
    }
    increment(segment) {
        segment.enteredKeys = '';
        if (!isEditableSegment(segment.type)) {
            return;
        }
        if (this.value) {
            const newDate = parseDate(this.value).cycle(segment.type, 1);
            const newSegment = this.createSegment(segment, newDate);
            this.setSegment(segment, newSegment, newDate);
        }
        else {
            this.placeholderDate = this.placeholderDate.cycle(segment.type, 1);
            const newSegment = this.createSegment(segment, this.placeholderDate);
            this.setSegment(segment, newSegment, this.placeholderDate);
        }
    }
    decrement(segment) {
        segment.enteredKeys = '';
        if (!isEditableSegment(segment.type)) {
            return;
        }
        if (this.value) {
            const newDate = parseDate(this.value).cycle(segment.type, -1);
            const newSegment = this.createSegment(segment, newDate);
            this.setSegment(segment, newSegment, newDate);
        }
        else {
            const newDate = this.placeholderDate.cycle(segment.type, -1);
            const newSegment = this.createSegment(segment, newDate);
            this.setSegment(segment, newSegment, newDate);
        }
    }
    connectedCallback() {
        super.connectedCallback();
        if (!this.isLocaleValid(this.locale) && !this.isTimezoneValid(this.locale)) {
            return;
        }
        this.dateParts = this.calculateDateSegments();
        if (this.defaultValue) {
            this.value = this.defaultValue;
            this.calendar.setAttribute('value', this.defaultValue);
        }
        else {
            this.internals.setFormValue('');
        }
        if (this.hasAttribute('required') && !this.value) {
            this.validationInput.setAttribute('required', 'true');
            const message = this.validationInput.validationMessage;
            this.internals.setValidity({ valueMissing: true }, message, this.field);
        }
    }
    // TODO: reimplement this correctly
    handleCalendarChange(e) {
        const newDate = e.detail.customDate;
        this.value = newDate.toString();
        this.dateParts = this.calculateDateSegments();
        // We delay closing here to prevent the popover automatically reopening when the date
        // is selected using a keyboard.
        DOM.queueUpdate(() => {
            this.popoverElement.close();
        });
    }
    get localizedParts() {
        const dateFormat = new Intl.DateTimeFormat(this.locale, {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
            timeZone: this.timezone,
        });
        return dateFormat.formatToParts(today(this.timezone).toDate(this.timezone));
    }
    calculateDateSegments() {
        const dateFormat = new Intl.DateTimeFormat(this.locale, {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
            timeZone: this.timezone,
        });
        const parts = dateFormat.formatToParts(today(this.timezone).toDate(this.timezone));
        const valueSet = this.value ? parseDate(this.value) : this.defaultValue ? parseDate(this.defaultValue) : null;
        const value = valueSet ? valueSet : this.placeholderDate;
        // Add minValue and maxValue to make key inputs works later
        return parts.map((part) => (Object.assign({ enteredKeys: '', type: part.type, isPlaceholder: !valueSet, placeholder: part.type === 'literal' ? undefined : this.getPlaceholderForPart(part), isEditable: part.type !== 'literal', text: value && part.type !== 'literal' ? this.getTextForPart(part.type, value) : part.value, value: part.type !== 'literal' ? value[part.type] : part.value }, this.getSegmentLimits(value, part.type))));
    }
    getSegmentLimits(date, type) {
        switch (type) {
            case 'year': {
                return {
                    minValue: 1,
                    maxValue: date.calendar.getYearsInEra(date),
                };
            }
            case 'month': {
                return {
                    minValue: getMinimumMonthInYear(date),
                    maxValue: date.calendar.getMonthsInYear(date),
                };
            }
            case 'day': {
                return {
                    minValue: getMinimumDayInMonth(date),
                    maxValue: date.calendar.getDaysInMonth(date),
                };
            }
        }
    }
    getPlaceholderForPart(part) {
        const localizedPartNames = new Intl.DisplayNames(this.locale, { type: 'dateTimeField' });
        return localizedPartNames.of(part.type).slice(0, 1).toUpperCase().repeat(part.value.length);
    }
    getTextForPart(type, value) {
        const formatter = new Intl.DateTimeFormat(this.locale, {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            timeZone: this.timezone,
        });
        const parts = formatter.formatToParts(value.toDate(this.timezone));
        const res = parts
            .filter((part) => part.type === type)
            .map((part) => part.value)
            .join('');
        return res;
    }
    calculatePopoverOffset() {
        var _a, _b;
        if (!this.popoverElement.isOpen) {
            return;
        }
        const position = (_b = (_a = this.popoverElement) === null || _a === void 0 ? void 0 : _a.dataset.renderedPosition) !== null && _b !== void 0 ? _b : this.position;
        const isBottom = position.includes('bottom');
        const hasHelperText = this.helperText && this.helperText.length > 0;
        const hasLabel = this.inputLabel && this.inputLabel.length > 0;
        if (isBottom && hasHelperText) {
            this.popoverOffset = POPOVER_OFFSET_BOTTOM_WITH_HELPER;
        }
        if (isBottom && !hasHelperText) {
            this.popoverOffset = POPOVER_OFFSET_BOTTOM_WITHOUT_HELPER;
        }
        if (!isBottom && hasLabel) {
            this.popoverOffset = POPOVER_OFFSET_TOP_WITH_LABEL;
        }
        if (!isBottom && !hasLabel) {
            this.popoverOffset = POPOVER_OFFSET_TOP_WITHOUT_LABEL;
        }
    }
    isLocaleValid(locale) {
        try {
            // @ts-expect-error For some reason this is not included in typescript's type definitions
            Intl.getCanonicalLocales(locale);
            return true;
        }
        catch (e) {
            return false;
        }
    }
    isTimezoneValid(timezone) {
        try {
            Intl.DateTimeFormat(undefined, { timeZone: timezone });
            return true;
        }
        catch (e) {
            return false;
        }
    }
    validateMinAndMax() {
        if (!this.value) {
            return;
        }
        const date = parseDate(this.value);
        const isBeforeMin = !this.min ? false : parseDate(this.min).compare(date) > 0;
        const isAfterMax = !this.max ? false : parseDate(this.max).compare(date) < 0;
        if (isBeforeMin && this.min) {
            this.validationInput.setAttribute('type', 'date');
            this.validationInput.setAttribute('min', this.min);
            this.validationInput.value = this.value;
            const message = this.validationInput.validationMessage;
            this.internals.setValidity({ rangeUnderflow: true }, message, this.field);
        }
        if (isAfterMax) {
            this.validationInput.setAttribute('type', 'number');
            this.validationInput.setAttribute('max', '10');
            this.validationInput.value = '11';
            const message = this.validationInput.validationMessage;
            this.internals.setValidity({ rangeOverflow: true }, message, this.field);
        }
        this.validationInput.removeAttribute('min');
        this.validationInput.removeAttribute('max');
    }
}
DatePickerComponent.formAssociated = true;
__decorate([
    attr({ attribute: 'input-label' })
], DatePickerComponent.prototype, "inputLabel", void 0);
__decorate([
    attr({ attribute: 'helper-text' })
], DatePickerComponent.prototype, "helperText", void 0);
__decorate([
    attr
], DatePickerComponent.prototype, "timezone", void 0);
__decorate([
    attr
], DatePickerComponent.prototype, "value", void 0);
__decorate([
    attr({ attribute: 'default-value' })
], DatePickerComponent.prototype, "defaultValue", void 0);
__decorate([
    attr
], DatePickerComponent.prototype, "disabled", void 0);
__decorate([
    attr
], DatePickerComponent.prototype, "error", void 0);
__decorate([
    attr
], DatePickerComponent.prototype, "placeholder", void 0);
__decorate([
    attr
], DatePickerComponent.prototype, "position", void 0);
__decorate([
    attr
], DatePickerComponent.prototype, "locale", void 0);
__decorate([
    attr({ attribute: 'error-text' })
], DatePickerComponent.prototype, "errorText", void 0);
__decorate([
    attr
], DatePickerComponent.prototype, "min", void 0);
__decorate([
    attr
], DatePickerComponent.prototype, "max", void 0);
__decorate([
    attr({ attribute: 'calendar-toggle-label' })
], DatePickerComponent.prototype, "calendarToggleLabel", void 0);
__decorate([
    observable
], DatePickerComponent.prototype, "field", void 0);
__decorate([
    observable
], DatePickerComponent.prototype, "popoverElement", void 0);
__decorate([
    observable
], DatePickerComponent.prototype, "calendar", void 0);
__decorate([
    observable
], DatePickerComponent.prototype, "popoverOffset", void 0);
__decorate([
    observable
], DatePickerComponent.prototype, "dateParts", void 0);
