import { Tabs, tabsTemplate as template } from '@microsoft/fast-foundation';
import { tabsStyles } from '@microsoft/fast-components';
import { css } from '@microsoft/fast-element';
import { TabsComponent } from './Tabs';
import styles from './Tabs.styles.scss';
export const tabsDefinition = {
    baseName: 'tabs',
    baseClass: Tabs,
    template: template,
    styles: (ctx, def) => css `
    ${tabsStyles(ctx, def)}
    ${styles}
  `,
};
export const chameleonTabs = TabsComponent.compose(tabsDefinition);
