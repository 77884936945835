import { tabPanelTemplate as template, TabPanel } from '@microsoft/fast-foundation';
import { tabsStyles } from '@microsoft/fast-components';
import { css } from '@microsoft/fast-element';
import { TabPanelComponent } from './TabPanel';
import styles from './TabPanel.styles.scss';
export const tabPanelDeffinition = {
    baseName: 'tab-panel',
    baseClass: TabPanel,
    template: template,
};
export const chameleonTabPanel = TabPanelComponent.compose(Object.assign(Object.assign({}, tabPanelDeffinition), { styles: (ctx, def) => css `
    ${tabsStyles(ctx, def)}
    ${styles}
  ` }));
