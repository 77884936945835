import { FC } from 'react';
import { useIntl } from 'react-intl';
import { Typography } from '@getgo/chameleon-web-react-wrapper';

import st from 'utils/shared-translations';

import './ach-header.css';

const ACHHeader: FC = (): JSX.Element => {
  const intl = useIntl();

  return (
    <header className="ach-header">
      <section className="ach-header__label">
        <Typography tag="h1" variant="heading-medium">
          {intl.formatMessage(st['ach.enter.bank.account.info'])}
        </Typography>
      </section>
    </header>
  );
};

export default ACHHeader;
