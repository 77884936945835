/**
 * This function can be used in `scroll` event handlers to throttle the handler and prevent
 * jank when scrolling.
 * inspired by: https://developer.mozilla.org/en-US/docs/Web/API/Document/scroll_event#scroll_event_throttling
 */
export function highRateFriendly(callback) {
    let animating = false;
    return () => {
        if (!animating) {
            animating = true;
            window.requestAnimationFrame(() => {
                callback();
                animating = false;
            });
        }
    };
}
