import { __decorate } from "tslib";
import { attr, html } from '@microsoft/fast-element';
import { FoundationElement } from '@microsoft/fast-foundation';
import { getChameleonElements, getElementsWithProperty } from '../../common/utils/DomUtil';
import { ChameleonElementMixin } from '../../common/mixin';
export const dataTablePaginationV2Template = html `
  <div>
    <slot name="select"></slot>
  </div>
  <div class="right">
    <div class="counter">${(x) => (x.counterText ? x.counterText : html `<slot name="counter"></slot>`)}</div>
    <div class="actions">
      <slot name="actions"></slot>
    </div>
  </div>
`;
export class DataTablePaginationV2Component extends ChameleonElementMixin(FoundationElement) {
    constructor() {
        super(...arguments);
        this.counterText = '';
        this.condensed = false;
    }
    condensedChanged(_, next) {
        if (this.children.length >= 0) {
            const chameleonElements = getElementsWithProperty(getChameleonElements(this), 'size');
            for (const el of chameleonElements) {
                el.setAttribute('size', next ? 'small' : 'medium');
            }
        }
    }
    connectedCallback() {
        super.connectedCallback();
        const chameleonElements = getElementsWithProperty(getChameleonElements(this), 'size');
        for (const el of chameleonElements) {
            el.setAttribute('size', this.condensed ? 'small' : 'medium');
        }
    }
}
__decorate([
    attr({ attribute: 'counter-text' })
], DataTablePaginationV2Component.prototype, "counterText", void 0);
__decorate([
    attr({ mode: 'boolean' })
], DataTablePaginationV2Component.prototype, "condensed", void 0);
