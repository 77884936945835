import { buttonTemplate as template, Button } from '@microsoft/fast-foundation';
import { css } from '@microsoft/fast-element';
import { TileComponent } from './Tile';
import buttonStyles from '../Button/Button.styles.scss';
import styles from './Tile.styles.scss';
export const tileDefinition = {
    baseName: 'tile',
    baseClass: Button,
    template: template,
    styles: css `
    ${buttonStyles}
    ${styles}
  `,
    shadowOptions: {
        delegatesFocus: true,
    },
};
export const chameleonTile = TileComponent.compose(tileDefinition);
