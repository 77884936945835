import { __decorate } from "tslib";
import { attr } from '@microsoft/fast-element';
import { ChameleonElementMixin } from '../../common/mixin';
import { Anchor } from '@microsoft/fast-foundation';
import { DEV } from 'esm-env';
export class NavigationDrawerLinkComponent extends ChameleonElementMixin(Anchor) {
    connectedCallback() {
        super.connectedCallback();
        this.setAttribute('role', 'menuitem');
        this.setAccessibleLabel();
    }
    labelChanged() {
        this.setAccessibleLabel();
    }
    setAccessibleLabel() {
        if (this.textContent) {
            return;
        }
        if (this.label) {
            this.setAttribute('aria-label', this.label);
            return;
        }
        if (DEV) {
            this.validateAccessibleLabel();
        }
    }
    validateAccessibleLabel() {
        var _a;
        if (!((_a = this.getAttribute('aria-label')) === null || _a === void 0 ? void 0 : _a.length)) {
            console.error(`CHAMELEON-A11Y: Navigation Drawer Link component must have an accessible label provided by either 
  1) text content 
  2) a non-empty label attribute to be used as aria-label.
`);
        }
    }
}
__decorate([
    attr({ mode: 'boolean' })
], NavigationDrawerLinkComponent.prototype, "selected", void 0);
__decorate([
    attr
], NavigationDrawerLinkComponent.prototype, "label", void 0);
