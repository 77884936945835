import { __decorate } from "tslib";
import { attr, observable } from '@microsoft/fast-element';
import { ChameleonElementMixin } from '../../common/mixin';
import { MenuItem } from '@microsoft/fast-foundation';
import { changeIconToOppositeType } from '../../common/utils/changeIconToOppositeType';
export class NavigationRailLinkComponent extends ChameleonElementMixin(MenuItem) {
    constructor() {
        super(...arguments);
        /*
         *   @deprecated
         *   Will be removed in upcoming major update
         * */
        this.size = 'small';
        this.state = 'expanded';
        this.disabled = false;
        this.target = '_blank';
        this.appearance = 'navrailitem';
        this.navigationRailElement = null;
        this.handleMenuKeyDown = (event) => {
            this.handleMenuItemKeyDown(event);
            if (event.key === 'Enter') {
                window.open(this.href, this.target);
            }
            return true;
        };
    }
    attributeChangedCallback(propertyName, oldValue, newValue) {
        super.attributeChangedCallback(propertyName, oldValue, newValue);
        if (propertyName === 'selected') {
            const svgComponent = this.querySelector('chameleon-svg');
            if (svgComponent) {
                changeIconToOppositeType(svgComponent, newValue === 'true');
            }
        }
    }
    connectedCallback() {
        var _a, _b, _c;
        super.connectedCallback();
        this.toggleSlots();
        this.navigationRailElement = this.parentNode;
        this.size = this.navigationRailElement.size;
        this.state = (_a = this.navigationRailElement) === null || _a === void 0 ? void 0 : _a.state;
        this.contentElement = (_b = this.shadowRoot) === null || _b === void 0 ? void 0 : _b.querySelector('[slot="content"]');
        this.tooltipElement = (_c = this.shadowRoot) === null || _c === void 0 ? void 0 : _c.querySelector('chameleon-tooltip-v2');
        this.addEventListener('focus', this.toggleTooltipOnFocusBlur);
        this.addEventListener('blur', this.toggleTooltipOnFocusBlur);
    }
    disconnectedCallback() {
        this.removeEventListener('focus', this.toggleTooltipOnFocusBlur);
        this.removeEventListener('blur', this.toggleTooltipOnFocusBlur);
    }
    /* 'defaultNode' is the text, what the user typed in to the component
     * and we assign it to the content slot of the tooltip v2
     */
    defaultSlottedNodesChanged() {
        if (this.defaultSlottedNodes.length > 0) {
            this.toggleSlots();
            const textNodes = this.defaultSlottedNodes.filter((defaultNode) => defaultNode.nodeName === '#text');
            const trueTextNode = textNodes.find((textNode) => textNode.textContent && !textNode.textContent.startsWith('\n'));
            if (this.contentElement && !!(trueTextNode === null || trueTextNode === void 0 ? void 0 : trueTextNode.textContent)) {
                this.contentElement.textContent = trueTextNode.textContent;
            }
        }
    }
    handleItemClick(event) {
        this.handleMenuItemClick(event);
        return true;
    }
    toggleTooltipOnFocusBlur() {
        if ((document.activeElement === this || this.slot === 'toggle-button') &&
            (this.size === 'small' || this.state === 'collapsed')) {
            this.tooltipElement.open();
        }
        else {
            this.tooltipElement.close();
        }
    }
    toggleSlots() {
        var _a, _b, _c, _d;
        if (!this.querySelector('[slot="start"]')) {
            this.style.setProperty('--start-slot-display', 'none');
        }
        else {
            this.style.setProperty('--start-slot-display', 'contents');
        }
        if (!this.querySelector('[slot="end"]')) {
            (_b = (_a = this.shadowRoot) === null || _a === void 0 ? void 0 : _a.querySelector('chameleon-base-navigation-rail-item')) === null || _b === void 0 ? void 0 : _b.removeAttribute('data-has-end-slot');
            this.style.setProperty('--end-slot-display', 'none');
        }
        else {
            (_d = (_c = this.shadowRoot) === null || _c === void 0 ? void 0 : _c.querySelector('chameleon-base-navigation-rail-item')) === null || _d === void 0 ? void 0 : _d.setAttribute('data-has-end-slot', '');
            this.style.setProperty('--end-slot-display', 'contents');
        }
    }
    endSlottedNodesChanged() {
        this.setBadgeCounter();
    }
    setBadgeCounter() {
        if (this.endSlottedNodes && this.endSlottedNodes.length > 0) {
            const textNodes = this.endSlottedNodes[0];
            if (this.endSlottedNodes[0] instanceof Element) {
                textNodes.setAttribute('size', 'small');
            }
        }
    }
}
__decorate([
    attr
], NavigationRailLinkComponent.prototype, "size", void 0);
__decorate([
    attr
], NavigationRailLinkComponent.prototype, "state", void 0);
__decorate([
    attr({ mode: 'boolean' })
], NavigationRailLinkComponent.prototype, "disabled", void 0);
__decorate([
    attr({ mode: 'boolean' })
], NavigationRailLinkComponent.prototype, "selected", void 0);
__decorate([
    attr({ mode: 'boolean' })
], NavigationRailLinkComponent.prototype, "sticky", void 0);
__decorate([
    attr({ mode: 'boolean' })
], NavigationRailLinkComponent.prototype, "collapse", void 0);
__decorate([
    attr()
], NavigationRailLinkComponent.prototype, "href", void 0);
__decorate([
    attr()
], NavigationRailLinkComponent.prototype, "target", void 0);
__decorate([
    attr
], NavigationRailLinkComponent.prototype, "appearance", void 0);
__decorate([
    observable
], NavigationRailLinkComponent.prototype, "defaultSlottedNodes", void 0);
__decorate([
    observable
], NavigationRailLinkComponent.prototype, "endSlottedNodes", void 0);
