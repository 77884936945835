import { __decorate } from "tslib";
import { attr } from '@microsoft/fast-element';
import { PopoverComponent, PopoverTriggerOn } from '../Popover';
const MIN_WIDTH = 30;
const MAX_WIDTH = 224;
export class TooltipV2Component extends PopoverComponent {
    constructor() {
        super(...arguments);
        this.arrow = true;
        this.compact = true;
        this.inverted = true;
        this.minWidth = MIN_WIDTH;
        this.maxWidth = MAX_WIDTH;
        this.triggerOn = PopoverTriggerOn.mouseover;
    }
}
__decorate([
    attr({ mode: 'boolean' })
], TooltipV2Component.prototype, "arrow", void 0);
__decorate([
    attr({ mode: 'boolean' })
], TooltipV2Component.prototype, "compact", void 0);
__decorate([
    attr({ mode: 'boolean' })
], TooltipV2Component.prototype, "inverted", void 0);
__decorate([
    attr({ attribute: 'min-width' })
], TooltipV2Component.prototype, "minWidth", void 0);
__decorate([
    attr({ attribute: 'max-width' })
], TooltipV2Component.prototype, "maxWidth", void 0);
__decorate([
    attr({ attribute: 'trigger-on' })
], TooltipV2Component.prototype, "triggerOn", void 0);
