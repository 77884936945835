import { __decorate } from "tslib";
import { attr } from '@microsoft/fast-element';
import { ButtonComponent } from '../Button';
import { ChameleonElementMixin } from '../common/mixin';
export class TileComponent extends ChameleonElementMixin(ButtonComponent) {
    constructor() {
        super(...arguments);
        this.variant = 'primary';
        this.selected = false;
        this.disabled = false;
    }
    attributeChangedCallback(name, oldValue, newValue) {
        super.attributeChangedCallback(name, oldValue, newValue);
        const isSecondaryNotSelected = this.variant === 'secondary' && !this.selected;
        let color = isSecondaryNotSelected ? 'type-color-default' : 'type-color-inverted';
        let labelColor = isSecondaryNotSelected ? 'type-color-secondary' : 'type-color-inverted';
        if (this.disabled) {
            color = 'type-color-disabled';
            labelColor = 'type-color-disabled';
        }
        this.changeTypographyColor([color, labelColor]);
        this.changeSvgColor(color);
    }
    changeTypographyColor(colors) {
        this.querySelectorAll('chameleon-typography').forEach((el, index) => {
            const nextColor = typeof colors[index] !== 'undefined' ? colors[index] : colors[0];
            el.setAttribute('color', nextColor);
        });
    }
    changeSvgColor(color) {
        this.querySelectorAll('chameleon-svg').forEach((el) => {
            el.style['--goto-icon-color'] = color;
        });
    }
}
__decorate([
    attr
], TileComponent.prototype, "variant", void 0);
__decorate([
    attr({ mode: 'boolean' })
], TileComponent.prototype, "selected", void 0);
__decorate([
    attr({ mode: 'boolean' })
], TileComponent.prototype, "disabled", void 0);
