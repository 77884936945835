import { __decorate } from "tslib";
import { attr, html } from '@microsoft/fast-element';
import { FoundationElement } from '@microsoft/fast-foundation';
import { ChameleonElementMixin } from '../../common/mixin';
export const templateDataTableCellV2 = html `<template role="cell"
  ><div class="inner"><slot></slot></div
></template>`;
export class DataTableCellV2Component extends ChameleonElementMixin(FoundationElement) {
    constructor() {
        super(...arguments);
        this.condensed = false;
        this.disabled = false;
        this.indented = false;
        this.handleHostClick = (e) => {
            if (this.disabled) {
                e.preventDefault();
                e.stopImmediatePropagation();
            }
        };
    }
    connectedCallback() {
        super.connectedCallback();
        this.addEventListener('click', this.handleHostClick);
    }
    disconnectedCallback() {
        this.removeEventListener('click', this.handleHostClick);
    }
}
__decorate([
    attr({ mode: 'boolean' })
], DataTableCellV2Component.prototype, "condensed", void 0);
__decorate([
    attr({ mode: 'boolean' })
], DataTableCellV2Component.prototype, "disabled", void 0);
__decorate([
    attr({ mode: 'boolean' })
], DataTableCellV2Component.prototype, "indented", void 0);
