import { __decorate } from "tslib";
import { SVG_SUBTRACT_OUTLINED, SVG_ADD_OUTLINED } from '@getgo/chameleon-icons';
import { attr, html } from '@microsoft/fast-element';
import { FoundationElement } from '@microsoft/fast-foundation';
import { ChameleonElementMixin } from '../common/mixin';
import { uniqueId } from '@microsoft/fast-web-utilities';
import { DEV } from 'esm-env';
export const inputStepperTemplate = html `<template>
  <chameleon-number-field
    disabled=${(x) => x.disabled}
    error=${(x) => x.error}
    :value=${(x) => x.value}
    @change=${(x, { event }) => x.handleChange(event)}
    fieldsize="${(x) => x.size}"
    :step="${(x) => x.step}"
    :max=${(x) => x.max}
    :min=${(x) => x.min}
    label-id=${(x) => { var _a; return (_a = x.labelId) !== null && _a !== void 0 ? _a : 'CHAMELEON_BYPASS_LABEL_ID'; }}
  >
    <chameleon-icon-button
      size="${(x) => x.size}"
      slot="start"
      aria-label=${(x) => x.decrementLabel}
      @click=${(x) => x.decrement()}
      ?disabled=${(x) => x.cantGoLower()}
    >
      <chameleon-svg>${SVG_SUBTRACT_OUTLINED}</chameleon-svg>
    </chameleon-icon-button>
    <chameleon-icon-button
      size="${(x) => x.size}"
      slot="end"
      aria-label=${(x) => x.incrementLabel}
      ?disabled=${(x) => x.cantGoHigher()}
      @click=${(x) => x.increment()}
    >
      <chameleon-svg>${SVG_ADD_OUTLINED}</chameleon-svg>
    </chameleon-icon-button>
    <span class="helper-text" slot="helpertext">${(x) => x.helpertext}</span>
    <slot></slot>
  </chameleon-number-field>
</template>`;
export class InputStepperComponent extends ChameleonElementMixin(FoundationElement) {
    constructor() {
        super(...arguments);
        this.size = 'medium';
        this.value = 0;
        this.step = 1;
        this.min = 0;
        this.max = 999;
        this.helpertext = '';
        this.incrementLabel = 'Increment number';
        this.decrementLabel = 'Decrement number';
    }
    sanitizeValue() {
        this.value = Number(this.value);
    }
    addIdForLabel() {
        var _a, _b, _c, _d, _e;
        const label = (_c = (_b = (_a = this.shadowRoot) === null || _a === void 0 ? void 0 : _a.querySelector('chameleon-number-field')) === null || _b === void 0 ? void 0 : _b.shadowRoot) === null || _c === void 0 ? void 0 : _c.querySelector('label');
        if (label) {
            label.id = uniqueId('label-');
            (_e = (_d = this.shadowRoot) === null || _d === void 0 ? void 0 : _d.querySelector('chameleon-number-field')) === null || _e === void 0 ? void 0 : _e.setAttribute('aria-labelledby', label.id);
        }
    }
    setAccessibleLabel() {
        var _a;
        if ((_a = this.textContent) === null || _a === void 0 ? void 0 : _a.trim()) {
            return;
        }
        if (this.labelId) {
            this.setAttribute('aria-labelledby', this.labelId);
            return;
        }
        if (DEV) {
            this.validateAccessibleLabel();
        }
    }
    validateAccessibleLabel() {
        var _a;
        if (!((_a = this.getAttribute('aria-labelledby')) === null || _a === void 0 ? void 0 : _a.length)) {
            console.error(`CHAMELEON-A11Y: InputStepper component must have an accessible label provided by either 
  1) text content 
  2) a visible label whose id is given as the attribute labelId
`);
        }
    }
    connectedCallback() {
        super.connectedCallback();
        this.addIdForLabel();
        this.setAccessibleLabel();
    }
    attributeChangedCallback(name, oldValue, newValue) {
        super.attributeChangedCallback(name, oldValue, newValue);
        if (name === 'readonly' && newValue) {
            this.setAttribute('aria-readonly', 'true');
        }
        else if (name === 'readonly') {
            this.removeAttribute('aria-readonly');
        }
    }
    cantGoLower() {
        if (!this.disabled) {
            return this.value <= Number(this.min);
        }
        else {
            return true;
        }
    }
    cantGoHigher() {
        if (!this.disabled) {
            return this.value >= Number(this.max);
        }
        else {
            return true;
        }
    }
    increment() {
        if (this.cantGoHigher()) {
            return;
        }
        this.sanitizeValue();
        this.value += Number(this.step);
        this.$emit('change', null, { bubbles: false });
    }
    decrement() {
        if (this.cantGoLower()) {
            return;
        }
        this.sanitizeValue();
        this.value -= Number(this.step);
        this.$emit('change', null, { bubbles: false });
    }
    handleChange(event) {
        this.sanitizeValue();
        this.value = event.target.value;
        this.$emit('change', null, { bubbles: false });
    }
}
__decorate([
    attr
], InputStepperComponent.prototype, "size", void 0);
__decorate([
    attr
], InputStepperComponent.prototype, "value", void 0);
__decorate([
    attr
], InputStepperComponent.prototype, "step", void 0);
__decorate([
    attr
], InputStepperComponent.prototype, "min", void 0);
__decorate([
    attr
], InputStepperComponent.prototype, "max", void 0);
__decorate([
    attr({ mode: 'boolean' })
], InputStepperComponent.prototype, "disabled", void 0);
__decorate([
    attr({ mode: 'boolean' })
], InputStepperComponent.prototype, "error", void 0);
__decorate([
    attr
], InputStepperComponent.prototype, "helpertext", void 0);
__decorate([
    attr({ attribute: 'increment-aria-label' })
], InputStepperComponent.prototype, "incrementLabel", void 0);
__decorate([
    attr({ attribute: 'decrement-aria-label' })
], InputStepperComponent.prototype, "decrementLabel", void 0);
__decorate([
    attr({ attribute: 'label-id' })
], InputStepperComponent.prototype, "labelId", void 0);
