import { __decorate } from "tslib";
import { attr } from '@microsoft/fast-element';
import { BaseProgress } from '@microsoft/fast-foundation';
import { ChameleonElementMixin } from '../common/mixin';
export class LinearProgressComponent extends ChameleonElementMixin(BaseProgress) {
    constructor() {
        super(...arguments);
        this.size = 'medium';
        this.inverse = false;
    }
}
__decorate([
    attr
], LinearProgressComponent.prototype, "size", void 0);
__decorate([
    attr({ mode: 'boolean' })
], LinearProgressComponent.prototype, "inverse", void 0);
