import { __decorate } from "tslib";
import { html, attr, slotted, observable } from '@microsoft/fast-element';
import { FoundationElement } from '@microsoft/fast-foundation';
import { uniqueId } from '@microsoft/fast-web-utilities';
import { ChameleonElementMixin } from '../common/mixin';
export const sidePanelActionsTemplate = html `<template
  data-orientation="${(x) => x.orientation}"
  id="${(x) => x.uniqueId}"
>
  <slot ${slotted('defaultSlot')}></slot>
</template>`;
export class SidePanelActionsComponent extends ChameleonElementMixin(FoundationElement) {
    constructor() {
        super(...arguments);
        this.uniqueId = uniqueId('side-panel-actions-');
        this.orientation = 'vertical';
        this.defaultSlot = [];
    }
    defaultSlotChanged() {
        if (this.defaultSlot.length > 3) {
            this.orientation = 'vertical';
        }
        else if (this.defaultSlot.length === 3 && this.orientation === 'horizontal') {
            this.orientation = 'mixed';
        }
    }
    connectedCallback() {
        super.connectedCallback();
    }
}
__decorate([
    attr
], SidePanelActionsComponent.prototype, "uniqueId", void 0);
__decorate([
    attr
], SidePanelActionsComponent.prototype, "orientation", void 0);
__decorate([
    observable
], SidePanelActionsComponent.prototype, "defaultSlot", void 0);
