import { createReactWrapper } from '../common/PrefixUtils';
import React, { useRef, forwardRef, useEffect, useState, useImperativeHandle, } from 'react';
import { LegacyPopoverV2 } from './LegacyPopoverV2.shell';
export const PopoverV2 = forwardRef((props, ref) => {
    const popoverV2Ref = useRef();
    const [isDialogVersion, setIsDialogVersion] = useState(undefined);
    useImperativeHandle(ref, () => popoverV2Ref.current, [popoverV2Ref.current]);
    useEffect(() => {
        const p = document.createElement('chameleon-popover-v2');
        setIsDialogVersion(p.isDialogVersion);
    }, []);
    const handleChange = (event) => {
        var _a;
        (_a = props.onChange) === null || _a === void 0 ? void 0 : _a.call(props, event);
    };
    useEffect(() => {
        var _a;
        console.log('change attach');
        (_a = popoverV2Ref.current) === null || _a === void 0 ? void 0 : _a.addEventListener('popoverchange', handleChange);
        return () => {
            var _a;
            (_a = popoverV2Ref.current) === null || _a === void 0 ? void 0 : _a.removeEventListener('popoverchange', handleChange);
        };
    }, [popoverV2Ref.current, props.onChange, isDialogVersion]);
    if (isDialogVersion === undefined) {
        return null;
    }
    else if (isDialogVersion) {
        return createReactWrapper({
            tagName: 'chameleon-popover-v2',
            props: Object.assign(Object.assign({}, props), { 'trigger-id': props.triggerId, 'is-open': props.isOpen, 'trigger-on': props.triggerOn, 'min-width': props.minWidth, 'max-width': props.maxWidth, 'z-index': props.zIndex, 'label-id': props.labelId, ref: popoverV2Ref }),
            children: props.children,
        });
    }
    else if (isDialogVersion === false) {
        return React.createElement(LegacyPopoverV2, Object.assign({ ref: popoverV2Ref }, props));
    }
});
