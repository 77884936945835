import { __decorate } from "tslib";
import { SVG_CHEVRON_DOWN, SVG_CHEVRON_UP } from '@getgo/chameleon-icons';
import { attr, html, observable, ref, slotted, when } from '@microsoft/fast-element';
import { FoundationElement } from '@microsoft/fast-foundation';
import { ChameleonElementMixin } from '../../common/mixin';
export const dataTableGroupRowV2Template = html `
  <template aria-expanded="${(x) => (x.isExpanded ? 'true' : 'false')}">
    <div class="inner">
      <div class="title" ${ref('titleEl')}>
        <div class="drag"><slot ${slotted('dragSlot')} name="drag"></slot></div>
        <div class="checkbox"><slot name="checkbox"></slot></div>
      </div>
      ${when((x) => x.state === 'collapsed', html `<chameleon-svg>${SVG_CHEVRON_UP}</chameleon-svg>`)}
      ${when((x) => x.state === 'expanded', html `<chameleon-svg>${SVG_CHEVRON_DOWN}</chameleon-svg>`)}
    </div>
  </template>
`;
export class DataTableGroupRowV2Component extends ChameleonElementMixin(FoundationElement) {
    constructor() {
        super(...arguments);
        this.state = 'collapsed';
        this.condensed = false;
        this.disabled = false;
        this.selected = false;
    }
    dragSlotChanged(_, next) {
        var _a, _b;
        if (!next || next.length === 0) {
            (_a = this.titleEl) === null || _a === void 0 ? void 0 : _a.classList.remove('has-drag');
        }
        else {
            (_b = this.titleEl) === null || _b === void 0 ? void 0 : _b.classList.add('has-drag');
        }
    }
    get isExpanded() {
        return this.state === 'expanded';
    }
}
__decorate([
    attr
], DataTableGroupRowV2Component.prototype, "state", void 0);
__decorate([
    attr({ mode: 'boolean' })
], DataTableGroupRowV2Component.prototype, "condensed", void 0);
__decorate([
    attr({ mode: 'boolean' })
], DataTableGroupRowV2Component.prototype, "disabled", void 0);
__decorate([
    attr({ mode: 'boolean' })
], DataTableGroupRowV2Component.prototype, "selected", void 0);
__decorate([
    observable
], DataTableGroupRowV2Component.prototype, "titleEl", void 0);
__decorate([
    observable
], DataTableGroupRowV2Component.prototype, "dragSlot", void 0);
