import React from 'react';
export const isRenderable = (value) => {
    return (value === null ||
        !(typeof value === 'object' ||
            typeof value === 'function' ||
            typeof value === 'symbol' ||
            typeof value === 'bigint'));
};
export const addExtraProps = (component, extraProps) => {
    return isRenderable(component) ? (React.createElement("span", Object.assign({}, extraProps), component)) : (React.createElement(component.type, Object.assign({}, component.props, extraProps)));
};
