import { __rest } from "tslib";
import React, { forwardRef, useEffect, useRef } from 'react';
import { handleForwardRef } from '../common/RefUtils';
import { ChangeSyntheticEvent } from '../common/SyntheticEvent';
import { createReactWrapper } from '../common/PrefixUtils';
import { addExtraProps } from '../common/utils/AddExtraProps';

export function generateInputFieldBase(tagName, _a, ref) {
    var { children, leadingIcon, trailingIcon, labelId, value = '', onKeyUp, onKeyDown, onChange, autoFocus, helperText, minLength, maxLength, hideCounter } = _a, otherProps = __rest(_a, ["children", "leadingIcon", "trailingIcon", "labelId", "value", "onKeyUp", "onKeyDown", "onChange", "autoFocus", "helperText", "minLength", "maxLength", "hideCounter"]);
    const inputFieldRef = useRef();
    const handledRef = handleForwardRef(inputFieldRef, ref);
    const handleKeyUp = (event) => {
        if (onKeyUp) {
            onKeyUp(event);
        }
    };
    const handleChange = (event) => {
        if (onChange) {
            onChange(new ChangeSyntheticEvent(event));
        }
    };
    const handleKeyDown = (event) => {
        if (onKeyDown) {
            onKeyDown(event);
        }
    };
    useEffect(() => {
        var _a, _b, _c;
        (_a = inputFieldRef.current) === null || _a === void 0 ? void 0 : _a.addEventListener('keyup', handleKeyUp);
        (_b = inputFieldRef.current) === null || _b === void 0 ? void 0 : _b.addEventListener('keydown', handleKeyDown);
        // React attaches the callback of the onChange prop to the `input` DOM event
        // so to be consistent with the React behaviour we do the same
        // see: https://github.com/facebook/react/issues/3964
        (_c = inputFieldRef.current) === null || _c === void 0 ? void 0 : _c.addEventListener('input', handleChange);
        return () => {
            var _a, _b, _c;
            (_a = inputFieldRef.current) === null || _a === void 0 ? void 0 : _a.removeEventListener('keyup', handleKeyUp);
            (_b = inputFieldRef.current) === null || _b === void 0 ? void 0 : _b.removeEventListener('keydown', handleKeyDown);
            (_c = inputFieldRef.current) === null || _c === void 0 ? void 0 : _c.removeEventListener('input', handleChange);
        };
    }, [ref, onChange, onKeyUp, onKeyDown]);
    useEffect(() => {
        if (inputFieldRef && inputFieldRef.current) {
            inputFieldRef.current.value = value;
        }
    }, [ref, value]);
    return createReactWrapper({
        tagName,
        props: Object.assign(Object.assign({}, otherProps), { 'hide-counter': hideCounter, 'label-id': labelId, minlength: minLength, maxlength: maxLength, autofocus: autoFocus, ref: handledRef }),
        children: (React.createElement(React.Fragment, null,
            leadingIcon && addExtraProps(leadingIcon, { slot: 'start' }),
            children,
            trailingIcon && addExtraProps(trailingIcon, { slot: 'end' }),
            helperText && addExtraProps(helperText, { slot: 'helpertext' }))),
    });
}
export const InputField = forwardRef((props, ref) => generateInputFieldBase('chameleon-input-field', props, ref));
