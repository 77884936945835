import { __decorate } from "tslib";
import { ChameleonElementMixin } from '../common/mixin';
import { DOM, attr, html, observable, repeat } from '@microsoft/fast-element';
import { FoundationElement } from '@microsoft/fast-foundation';
import { getDayOfWeek, getLocalTimeZone, getWeeksInMonth, isSameDay, isSameMonth, startOfMonth, startOfWeek, today, parseDate, } from '@internationalized/date';
import { SVG_CHEVRON_LEFT, SVG_CHEVRON_RIGHT } from '@getgo/chameleon-icons';
export const calendarTemplate = html `
  <chameleon-typography class="title" variant="heading-small">
    <slot name="header"></slot>
  </chameleon-typography>
  <div class="header">
    <chameleon-icon-button
      size="small"
      aria-label="${(x) => x.isLocaleValid(x.locale) && new Intl.RelativeTimeFormat(x.locale, { numeric: 'auto' }).format(-1, 'month')}"
      @click=${(x) => x.handlePreviousMonthClick()}
      ><chameleon-svg>${SVG_CHEVRON_LEFT}</chameleon-svg></chameleon-icon-button
    >
    <h2>${(x) => x.month}</h2>
    <chameleon-button size="small" class="today-button" variant="tertiary" @click=${(x) => x.handleTodayButtonClick()}
      >${(x) => x.isLocaleValid(x.locale) &&
    new Intl.RelativeTimeFormat(x.locale, { numeric: 'auto' }).format(0, 'day')}</chameleon-button
    >
    <chameleon-icon-button
      size="small"
      aria-label="${(x) => x.isLocaleValid(x.locale) && new Intl.RelativeTimeFormat(x.locale, { numeric: 'auto' }).format(1, 'month')}"
      @click=${(x) => x.handleNextMonthClick()}
      ><chameleon-svg>${SVG_CHEVRON_RIGHT}</chameleon-svg></chameleon-icon-button
    >
  </div>
  <table>
    <thead>
      <tr>
        ${repeat((x) => x.weekDays, html `<td>${(x) => x}</td>`)}
      </tr>
    </thead>

    <tbody>
      ${repeat((x) => x.weeks, html `
          <tr>
            ${repeat((x) => x, html `
                <td role="gridcell" class="cell">
                  <span
                    tabindex="${(x, c) => c.parentContext.parent.getTabIndexForDay(x)}"
                    role="button"
                    aria-label="${(x, c) => c.parentContext.parent.getAriaLabelForDay(x)}"
                    data-value="${(x) => x.toString()}"
                    class="day
                        ${(x, c) => c.parentContext.parent.date && isSameDay(x, c.parentContext.parent.date) ? 'selected' : ''}
                        ${(x, c) => (isSameDay(x, today(c.parentContext.parent.timezone)) ? 'today' : '')}
                        ${(x, c) => (c.parentContext.parent.isDayInactive(x) ? 'inactive' : '')}
                        "
                    @click=${(x, c) => c.parentContext.parent.handleDayClick(x)}
                    @keydown=${(x, c) => c.parentContext.parent.handleDayKeyDown(c.event, x)}
                  >
                    ${(x, c) => c.parentContext.parent.formatNumber(x.day)}
                  </span>
                </td>
              `)}
          </tr>
        `)}
    </tbody>
  </table>
`;
export class CalendarComponent extends ChameleonElementMixin(FoundationElement) {
    constructor() {
        super(...arguments);
        this.locale = navigator.language;
        this.timezone = getLocalTimeZone();
        this.weeks = [];
        this.weekDays = [];
        this.month = '';
        /**
         * Represents the currently focused or focusable date.
         * Scenarios where this can be different than `this.date`:
         * - When there's no selected date, this.date is undefined, but this is initialized to the current day
         * - When there's a selected date, but the user has gone to the next/previous month.
         * */
        this.focusedDate = this.date ? this.date : today(this.timezone);
    }
    connectedCallback() {
        super.connectedCallback();
        if (!this.value && this.isLocaleValid(this.locale) && this.isTimezoneValid(this.timezone)) {
            this.month = this.getMonth();
            this.weeks = this.getWeeks();
            this.weekDays = this.getWeekDays();
        }
    }
    valueChanged() {
        const date = parseDate(this.value);
        const isBeforeMin = !this.min ? false : parseDate(this.min).compare(date) > 0;
        const isAfterMax = !this.max ? false : parseDate(this.max).compare(date) < 0;
        if (isBeforeMin || isAfterMax) {
            return;
        }
        this.month = this.getMonth();
        this.weeks = this.getWeeks();
        this.weekDays = this.getWeekDays();
        this.date = parseDate(this.value);
        this.focusedDate = this.date;
    }
    localeChanged() {
        if (!this.isLocaleValid(this.locale)) {
            return;
        }
        this.weeks = this.getWeeks();
        this.weekDays = this.getWeekDays();
    }
    timezoneChanged() {
        if (!this.isTimezoneValid(this.timezone)) {
            return;
        }
        this.weeks = this.getWeeks();
        this.weekDays = this.getWeekDays();
    }
    getDatesInWeek(weekIndex, from) {
        let date = from.add({ weeks: weekIndex });
        const dates = [];
        date = startOfWeek(date, this.locale);
        // startOfWeek will clamp dates within the calendar system's valid range, which may
        // start in the middle of a week. In this case, add null placeholders.
        const dayOfWeek = getDayOfWeek(date, this.locale);
        for (let i = 0; i < dayOfWeek; i++) {
            dates.push(null);
        }
        while (dates.length < 7) {
            dates.push(date);
            const nextDate = date.add({ days: 1 });
            if (isSameDay(date, nextDate)) {
                // If the next day is the same, we have hit the end of the calendar system.
                break;
            }
            date = nextDate;
        }
        // Add null placeholders if at the end of the calendar system.
        while (dates.length < 7) {
            dates.push(null);
        }
        return dates;
    }
    getWeekDays() {
        const weekStart = startOfWeek(today(this.timezone), this.locale);
        return [...new Array(7).keys()].map((index) => {
            const date = weekStart.add({ days: index });
            const dateDay = date.toDate(this.timezone);
            return new Intl.DateTimeFormat(this.locale, { weekday: 'short' }).format(dateDay);
        });
    }
    getWeeks(base) {
        const n = base ? base : this.value ? parseDate(this.value) : today(this.timezone);
        const normalized = n.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
        return [...new Array(getWeeksInMonth(startOfMonth(normalized), this.locale)).keys()].map((i) => {
            const dates = this.getDatesInWeek(i, startOfMonth(normalized));
            return dates;
        });
    }
    getMonth(base) {
        const date = base ? base : this.value ? parseDate(this.value) : today(this.timezone);
        return new Intl.DateTimeFormat(this.locale, { month: 'long', year: 'numeric' }).format(date.toDate(this.timezone));
    }
    getTabIndexForDay(day) {
        return isSameDay(day, this.focusedDate) ? '0' : '-1';
    }
    handleDayClick(date) {
        this.selectDay(date);
    }
    handleNextMonthClick() {
        this.focusedDate = this.focusedDate.add({ months: 1 });
        this.refreshDaysInCalendar();
    }
    handlePreviousMonthClick() {
        this.focusedDate = this.focusedDate.subtract({ months: 1 });
        this.refreshDaysInCalendar();
    }
    handleTodayButtonClick() {
        const currentDay = today(this.timezone);
        const isBeforeMin = !this.min ? false : parseDate(this.min).compare(currentDay) > 0;
        const isAfterMax = !this.max ? false : parseDate(this.max).compare(currentDay) < 0;
        if (isBeforeMin || isAfterMax) {
            return;
        }
        this.selectDay(today(this.timezone));
        this.refreshDaysInCalendar();
    }
    handleDayKeyDown(e, day) {
        switch (e.key) {
            case 'ArrowUp':
                this.moveToPrevWeek(day);
                break;
            case 'ArrowDown':
                this.moveToNextWeek(day);
                break;
            case 'ArrowLeft':
                this.moveToPrevDay(day);
                break;
            case 'ArrowRight':
                this.moveToNextDay(day);
                break;
            case 'Enter':
                this.selectDay(day);
                break;
            default:
                break;
        }
        // We have to return true to prevent fast calling .preventDefault on the event
        // we need the default behaviour so the Tab keypress properly moves focus to the next element
        return true;
    }
    getAriaLabelForDay(day) {
        return new Intl.DateTimeFormat(this.locale, {
            weekday: 'long',
            month: 'long',
            day: 'numeric',
            year: 'numeric',
        }).format(day.toDate(this.timezone));
    }
    formatNumber(num) {
        return new Intl.NumberFormat(this.locale, { style: 'decimal' }).format(num);
    }
    isDayInactive(date) {
        const isBeforeMin = this.isBeforeMin(date);
        const isAfterMax = this.isAfterMax(date);
        // Check if the date is not in the same month as the focused date
        const isNotSameMonth = !isSameMonth(date, this.focusedDate);
        // A date is inactive if it's before min, after max, or not in the same month
        return isBeforeMin || isAfterMax || isNotSameMonth;
    }
    moveToNextWeek(currentDay) {
        const prev = this.focusedDate;
        const newDate = currentDay.add({ weeks: 1 });
        const isAfterMax = this.isAfterMax(newDate);
        if (isAfterMax) {
            return;
        }
        this.focusedDate = newDate;
        if (!isSameMonth(prev, this.focusedDate)) {
            this.refreshDaysInCalendar();
        }
        DOM.queueUpdate(() => {
            var _a;
            const nextDayEl = (_a = this.shadowRoot) === null || _a === void 0 ? void 0 : _a.querySelector(`[data-value="${this.focusedDate.toString()}"]`);
            nextDayEl.focus();
        });
    }
    moveToPrevWeek(currentDay) {
        const prev = this.focusedDate;
        const newDate = currentDay.subtract({ weeks: 1 });
        const isBeforeMin = this.isBeforeMin(newDate);
        if (isBeforeMin) {
            return;
        }
        this.focusedDate = newDate;
        if (!isSameMonth(prev, this.focusedDate)) {
            this.refreshDaysInCalendar();
        }
        DOM.queueUpdate(() => {
            var _a;
            const nextDayEl = (_a = this.shadowRoot) === null || _a === void 0 ? void 0 : _a.querySelector(`[data-value="${this.focusedDate.toString()}"]`);
            nextDayEl.focus();
        });
    }
    moveToPrevDay(currentDay) {
        const prev = this.focusedDate;
        const newDate = currentDay.subtract({ days: 1 });
        const isBeforeMin = this.isBeforeMin(newDate);
        if (isBeforeMin) {
            return;
        }
        this.focusedDate = newDate;
        if (!isSameMonth(prev, this.focusedDate)) {
            this.refreshDaysInCalendar();
        }
        DOM.queueUpdate(() => {
            var _a;
            const nextDayEl = (_a = this.shadowRoot) === null || _a === void 0 ? void 0 : _a.querySelector(`[data-value="${this.focusedDate.toString()}"]`);
            nextDayEl.focus();
        });
    }
    moveToNextDay(currentDay) {
        const prev = this.focusedDate;
        const newDate = currentDay.add({ days: 1 });
        const isAfterMax = this.isAfterMax(newDate);
        if (isAfterMax) {
            return;
        }
        this.focusedDate = newDate;
        if (!isSameMonth(prev, this.focusedDate)) {
            this.refreshDaysInCalendar();
        }
        DOM.queueUpdate(() => {
            var _a;
            const nextDayEl = (_a = this.shadowRoot) === null || _a === void 0 ? void 0 : _a.querySelector(`[data-value="${this.focusedDate.toString()}"]`);
            nextDayEl.focus();
        });
    }
    selectDay(day) {
        this.date = day;
        this.focusedDate = day;
        this.value = day.toString();
        this.$emit('ch-calendar-change', { date: day.toDate(this.timezone), customDate: day });
    }
    refreshDaysInCalendar() {
        this.weeks = this.getWeeks(this.focusedDate);
        this.month = this.getMonth(this.focusedDate);
    }
    isLocaleValid(locale) {
        try {
            // @ts-expect-error For some reason this is not included in typescript's type definitions
            Intl.getCanonicalLocales(locale);
            return true;
        }
        catch (e) {
            return false;
        }
        DOM.queueUpdate(() => {
            var _a;
            const nextDayEl = (_a = this.shadowRoot) === null || _a === void 0 ? void 0 : _a.querySelector(`[data-value="${this.focusedDate.toString()}"]`);
            nextDayEl.focus();
        });
    }
    isTimezoneValid(timezone) {
        try {
            Intl.DateTimeFormat(undefined, { timeZone: timezone });
            return true;
        }
        catch (e) {
            return false;
        }
    }
    isBeforeMin(date) {
        const isBeforeMin = this.min && parseDate(this.min).compare(date) > 0;
        return isBeforeMin;
    }
    isAfterMax(date) {
        // Check if the date is later than the maximum (if max is set)
        const isAfterMax = this.max && parseDate(this.max).compare(date) <= 0;
        return isAfterMax;
    }
}
__decorate([
    attr
], CalendarComponent.prototype, "locale", void 0);
__decorate([
    attr
], CalendarComponent.prototype, "timezone", void 0);
__decorate([
    attr
], CalendarComponent.prototype, "value", void 0);
__decorate([
    attr
], CalendarComponent.prototype, "min", void 0);
__decorate([
    attr
], CalendarComponent.prototype, "max", void 0);
__decorate([
    observable
], CalendarComponent.prototype, "weeks", void 0);
__decorate([
    observable
], CalendarComponent.prototype, "weekDays", void 0);
__decorate([
    observable
], CalendarComponent.prototype, "month", void 0);
__decorate([
    observable
], CalendarComponent.prototype, "date", void 0);
__decorate([
    observable
], CalendarComponent.prototype, "focusedDate", void 0);
