import { __decorate } from "tslib";
import { SVG_LINK_OUTLINED } from '@getgo/chameleon-icons';
import { attr, html, observable, slotted } from '@microsoft/fast-element';
import { FoundationElement } from '@microsoft/fast-foundation';
import { ChameleonElementMixin } from '../common/mixin';
export const linkThumbnailTemplate = html `<a
  href="${(x) => x.link}"
  target="${(x) => x.target}"
  class="container"
>
  <div class="preview">
    <slot ${slotted('imageSlot')} name="image"></slot>
  </div>
  <div class="description">
    <chameleon-typography
      id="trigger"
      class="name"
      variant="body-large-strong"
      color="type-color-default"
      nowrap="{true}"
    >
      <slot ${slotted('titleSlot')} name="name"></slot>
    </chameleon-typography>
    <chameleon-tooltip-v3 position="top-center" trigger-id="trigger" hidden="${(x) => x.hideTooltip}">
      <chameleon-typography variant="caption-medium">${(x) => x.text}</chameleon-typography>
    </chameleon-tooltip-v3>
    <chameleon-typography class="link-container" variant="caption-medium" color="type-color-secondary" nowrap="{true}">
      <div class="link">${(x) => x.hostname}</div>
    </chameleon-typography>
  </div>
</a>`;
export class LinkThumbnailComponent extends ChameleonElementMixin(FoundationElement) {
    constructor() {
        super(...arguments);
        this.link = '';
        this.target = '_blank';
        this.text = '';
        this.hideTooltip = true;
        this.hostname = '';
        this.icon = document.createElement('chameleon-svg');
    }
    connectedCallback() {
        super.connectedCallback();
        this.setNoImageState();
    }
    imageSlotChanged(_, newValue) {
        var _a, _b;
        if (newValue) {
            (_b = (_a = this.shadowRoot) === null || _a === void 0 ? void 0 : _a.querySelector('.link-icon')) === null || _b === void 0 ? void 0 : _b.remove();
        }
        else {
            this.setNoImageState();
        }
    }
    linkChanged() {
        if (this.link) {
            try {
                this.hostname = new URL(this.link).hostname;
            }
            catch (e) {
                console.warn('The link provided is wrong');
            }
        }
    }
    titleSlotChanged() {
        if (this.titleSlot && this.titleSlot.length > 0) {
            const titleNodes = this.titleSlot[0];
            if (titleNodes && titleNodes instanceof Element && titleNodes.textContent) {
                this.text = titleNodes.textContent;
                if (this.text && titleNodes.scrollWidth > titleNodes.clientWidth) {
                    this.hideTooltip = false;
                }
                else {
                    this.hideTooltip = true;
                }
            }
        }
    }
    setNoImageState() {
        var _a, _b;
        this.icon.innerHTML = SVG_LINK_OUTLINED;
        this.icon.setAttribute('class', 'link-icon');
        (_b = (_a = this.shadowRoot) === null || _a === void 0 ? void 0 : _a.querySelector('.preview')) === null || _b === void 0 ? void 0 : _b.appendChild(this.icon);
    }
}
__decorate([
    attr()
], LinkThumbnailComponent.prototype, "link", void 0);
__decorate([
    attr()
], LinkThumbnailComponent.prototype, "target", void 0);
__decorate([
    observable
], LinkThumbnailComponent.prototype, "text", void 0);
__decorate([
    observable
], LinkThumbnailComponent.prototype, "hideTooltip", void 0);
__decorate([
    observable
], LinkThumbnailComponent.prototype, "imageSlot", void 0);
__decorate([
    observable
], LinkThumbnailComponent.prototype, "titleSlot", void 0);
__decorate([
    observable
], LinkThumbnailComponent.prototype, "hostname", void 0);
