import { ListboxElement } from '@microsoft/fast-foundation';
import { ChameleonElementMixin } from '../common/mixin';
import { forwardKeyboardEvent } from './helpers';
export class ListboxComponent extends ChameleonElementMixin(ListboxElement) {
    constructor() {
        super(...arguments);
        this.handleKeyDown = (event) => {
            var _a;
            const listboxItems = this.querySelectorAll('chameleon-listbox-item');
            const selectedListboxItem = (_a = Array.from(listboxItems)) === null || _a === void 0 ? void 0 : _a.find((listboxItem) => listboxItem.getAttribute('aria-selected') === 'true');
            const { key } = event;
            if (selectedListboxItem &&
                (key === 'ArrowUp' ||
                    key === 'ArrowDown' ||
                    key === 'ArrowRight' ||
                    key === 'ArrowLeft' ||
                    key === 'Enter' ||
                    key === ' ')) {
                forwardKeyboardEvent(event, selectedListboxItem);
            }
        };
    }
    connectedCallback() {
        super.connectedCallback();
        this.addEventListener('keydown', this.handleKeyDown);
    }
    disconnectedCallback() {
        this.removeEventListener('keydown', this.handleKeyDown);
    }
}
