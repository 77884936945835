import { __decorate } from "tslib";
import { attr } from '@microsoft/fast-element';
import { Tabs } from '@microsoft/fast-foundation';
import { ChameleonElementMixin } from '../common/mixin';
import { DEV } from 'esm-env';
export class TabsComponent extends ChameleonElementMixin(Tabs) {
    constructor() {
        super(...arguments);
        /**
         * `orientation` is techincally supported in the library, but is an undesired pattern from a UX perspective.
         * If you find yourself reaching for it, reach out to our designers on the #chameleonux channel and they will help you
         * find a better alternative.
         */
        this.orientation = 'horizontal';
    }
    connectedCallback() {
        super.connectedCallback();
        this.setAccessibleLabel();
    }
    labelIdChanged() {
        this.setAccessibleLabel();
    }
    labelChanged() {
        this.setAccessibleLabel();
    }
    getTablistElement() {
        var _a;
        return (_a = this.shadowRoot) === null || _a === void 0 ? void 0 : _a.querySelector('[role="tablist"]');
    }
    setAccessibleLabel() {
        if (this.labelId) {
            this.setAttribute('aria-labelledby', this.labelId);
            return;
        }
        if (this.label) {
            this.setAttribute('aria-label', this.label);
            return;
        }
        if (DEV) {
            this.validateAccessibleLabel();
        }
    }
    validateAccessibleLabel() {
        var _a, _b;
        if (!((_a = this.getAttribute('aria-label')) === null || _a === void 0 ? void 0 : _a.length) && !((_b = this.getAttribute('aria-labelledby')) === null || _b === void 0 ? void 0 : _b.length)) {
            console.error(`CHAMELEON-A11Y: Tabs component must have an accessible label provided by either
  1) a visible label whose id is given as the attribute labelId
  2) a non-empty label attribute to be used as aria-label.
`);
        }
    }
    orientationChanged() {
        super.orientationChanged();
        // Orientation will need to be forced to horizontal in the future
        /*if (newValue === 'vertical') {
          this.orientation = 'horizontal';
        }*/
        requestAnimationFrame(() => { var _a; return (_a = this.getTablistElement()) === null || _a === void 0 ? void 0 : _a.setAttribute('aria-orientation', this.orientation); });
    }
    tabsChanged() {
        super.tabsChanged();
        if (!this.activeid && this.tabs.length !== 0) {
            requestAnimationFrame(() => { var _a, _b; return (this.activeid = ((_a = this.tabs[0]) === null || _a === void 0 ? void 0 : _a.id) !== '' ? (_b = this.tabs[0]) === null || _b === void 0 ? void 0 : _b.id : 'tab-1'); });
        }
    }
}
__decorate([
    attr({ mode: 'boolean' })
], TabsComponent.prototype, "fullwidth", void 0);
__decorate([
    attr({ mode: 'boolean' })
], TabsComponent.prototype, "noPadding", void 0);
__decorate([
    attr({ attribute: 'label-id' })
], TabsComponent.prototype, "labelId", void 0);
__decorate([
    attr
], TabsComponent.prototype, "orientation", void 0);
__decorate([
    attr
], TabsComponent.prototype, "label", void 0);
