import { assert } from './utils/assert';
import { redirect } from './utils/redirect';
import { stringify } from './utils/serialize';
import { getBase64UrlEncodedCodeVerifier } from './utils/codeVerifier';

export interface ILoginOptions {
  client_id: string;
  gateway?: boolean;
  onLoginRedirect?: (url: string) => void;
  redirect_url: string;
  scope?: string;
  state?: {
    [key: string]: any;
  };
  theme?: string;
  url: string;
  code_verifier?: string;
  minLoa?: number;
  [key: string]: any;
}

export const login = (options: ILoginOptions) => {
  assert(options, ['client_id', 'url', 'redirect_url']);

  const params = {
    client_id: options.client_id,
    gateway: options.gateway,
    login_theme: options.theme,
    redirect_uri: options.redirect_url,
    response_type: 'token',
    scope: options.scope,
    state: options.state,
    minLoa: options.minLoa,
  };
  const url = `${options.url}/oauth/authorize?${stringify(params)}`;
  const fn = options.onLoginRedirect || redirect;
  return fn(url);
};

export const loginWithPKCE = (options: ILoginOptions) => {
  assert(options, ['client_id', 'url', 'redirect_url', 'code_verifier']);

  const params = {
    client_id: options.client_id,
    gateway: options.gateway,
    login_theme: options.theme,
    redirect_uri: options.redirect_url,
    response_type: 'code',
    code_challenge: '',
    code_challenge_method: 'S256',
    scope: options.scope,
    state: options.state,
    minLoa: options.minLoa,
  };

  return getBase64UrlEncodedCodeVerifier(options.code_verifier).then((challenge) => {
    if (!challenge) {
      throw new Error('Code challenge is required');
    }
    params.code_challenge = challenge;
    const url = `${options.url}/oauth/authorize?${stringify(params)}`;
    const fn = options.onLoginRedirect || redirect;
    return fn(url);
  });
};

export const loginWithSSO = (options: ILoginOptions) => login({ ...options, gateway: true });
