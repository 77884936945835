import { PopoverTriggerOn } from '../model';
import { PopoverTriggerClickStrategy } from './PopoverTriggerClickStrategy';
import { PopoverTriggerHoverStrategy } from './PopoverTriggerHoverStrategy';
export const createPopoverTriggerStrategy = (popover, popoverContent) => {
    if (popover.triggerOn === PopoverTriggerOn.click) {
        return new PopoverTriggerClickStrategy(popover, popoverContent);
    }
    if (popover.triggerOn === PopoverTriggerOn.mouseover) {
        return new PopoverTriggerHoverStrategy(popover, popoverContent);
    }
    throw new Error(`PopoverTriggerStrategy does not exist for "${popover.triggerOn}"`);
};
