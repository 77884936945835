import { __rest } from "tslib";
import React, { forwardRef } from 'react';
import { createReactWrapper } from '../common/PrefixUtils';
import { addExtraProps } from '../common/utils/AddExtraProps';
export const Option = forwardRef((_a, ref) => {
    var { children, start, end } = _a, props = __rest(_a, ["children", "start", "end"]);
    return createReactWrapper({
        tagName: 'chameleon-option',
        props: Object.assign(Object.assign({}, props), { ref }),
        children: (React.createElement(React.Fragment, null,
            start && addExtraProps(start, { slot: 'start' }),
            children,
            end && addExtraProps(end, { slot: 'end' }))),
    });
});
