import { __decorate } from "tslib";
import { DOM, attr, html, nullableNumberConverter, observable, ref } from '@microsoft/fast-element';
import { PopoverV2TriggerOn } from '../PopoverV2';
import { ChameleonElementMixin } from '../common/mixin';
import { FoundationElement } from '@microsoft/fast-foundation';
import { inflateRectInDirection, isPointOverRect, traverseShadowRootsForElementWithId } from '../common/utils/DomUtil';
import { calculatePopoverPosition } from '../common/positionFloatingElement';
import { convertStylePropertyPixelsToNumber } from '@microsoft/fast-web-utilities';
const MIN_WIDTH = 30;
const MAX_WIDTH = 224;
const DEFAULT_POSITION = `bottom-center`;
const TRIGGER_MOUSE_MOVE_OFFSET = 20;
export const tooltipV3Template = html `
  <template popover>
    <div ${ref('contentEl')} class="content">
      <slot></slot>
    </div>
    <div class="arrow"></div>
  </template>
`;
class NativePopoverApi extends ChameleonElementMixin(FoundationElement) {
}
export class TooltipV3Component extends NativePopoverApi {
    constructor() {
        super(...arguments);
        this.position = DEFAULT_POSITION;
        this.inverted = true;
        this.minWidth = MIN_WIDTH;
        this.maxWidth = MAX_WIDTH;
        this.triggerOn = PopoverV2TriggerOn.mouseover;
        this.delay = 400;
        this.hidden = false;
        /**
         * @description The `id` of the element that triggers the popover
         * */
        this.triggerId = '';
        this.isOpen = false;
        /**
         * This is used for backward compatibility reasons in react wrappers. There we need to know if we're rendering
         * the version the uses the `popover` attribute internally or the older version in which case we render the old wrapper component.
         * */
        this.isNativePopoverVersion = true;
        this.triggerEl = null;
        this.handleTriggerMouseover = () => {
            this.isOpen = true;
        };
        this.handleTriggerMouseleave = () => {
            if (this.dataset.displayed !== 'true') {
                this.isOpen = false;
            }
        };
        this.handleTriggerFocus = () => {
            this.isOpen = true;
        };
        this.handleTriggerBlur = () => {
            this.isOpen = false;
        };
        this.handleDocumentMousemove = (event) => {
            var _a, _b, _c;
            const x = event.clientX;
            const y = event.clientY;
            const contentPosition = (_a = this.dataset.renderedPosition) !== null && _a !== void 0 ? _a : this.position;
            const position = contentPosition === null || contentPosition === void 0 ? void 0 : contentPosition.split('-')[0];
            const additionalOffsetX = (_b = convertStylePropertyPixelsToNumber(this.style, '--additional-offset-x')) !== null && _b !== void 0 ? _b : 0;
            const additionalOffsetY = (_c = convertStylePropertyPixelsToNumber(this.style, '--additional-offset-y')) !== null && _c !== void 0 ? _c : 0;
            const offsetX = Number.isNaN(additionalOffsetX) ? 0 : additionalOffsetX;
            const offsetY = Number.isNaN(additionalOffsetY) ? 0 : additionalOffsetY;
            const offset = position === 'top' || position === 'bottom'
                ? TRIGGER_MOUSE_MOVE_OFFSET + offsetY
                : TRIGGER_MOUSE_MOVE_OFFSET + offsetX;
            const triggerBounds = inflateRectInDirection(this.triggerEl.getBoundingClientRect(), offset, position);
            if (!(isPointOverRect(triggerBounds, { x, y }) || isPointOverRect(this.contentEl.getBoundingClientRect(), { x, y }))) {
                this.isOpen = false;
            }
        };
    }
    triggerIdChanged(_, next) {
        if (!next || next === '') {
            return;
        }
        // The first time this function is run, the elements might not be actually rendered into the DOM.
        // For this reason, we need to use the queueUpdate here to wait until the element and its triggers
        // are actually rendered into the DOM so we can find it and attach the listeners.
        DOM.queueUpdate(() => {
            this.triggerEl = traverseShadowRootsForElementWithId(next, this);
            if (!this.triggerEl) {
                return;
            }
            this.triggerEl.addEventListener('mouseover', this.handleTriggerMouseover);
            this.triggerEl.addEventListener('mouseleave', this.handleTriggerMouseleave);
            this.triggerEl.addEventListener('focus', this.handleTriggerFocus);
            this.triggerEl.addEventListener('blur', this.handleTriggerBlur);
        });
    }
    isOpenChanged(_, next) {
        if (!this.$fastController.isConnected || this.hidden || !this.triggerEl) {
            return;
        }
        if (next && this.hidden) {
            this.isOpen = false;
        }
        if (next) {
            if (this.delay) {
                this.timeoutId = setTimeout(() => {
                    this.openTooltip();
                }, this.delay);
            }
            else {
                this.openTooltip();
            }
        }
        else {
            this.closeTooltip();
        }
    }
    widthChanged(_, next) {
        if (this.isConnected && next > MIN_WIDTH) {
            this.style.setProperty('--width', next.toString() + 'px');
            this.positionTooltip();
        }
    }
    maxWidthChanged(_, next) {
        if (this.isConnected && next) {
            this.style.setProperty('--max-width', next.toString() + 'px');
            this.positionTooltip();
        }
    }
    minWidthChanged(_, next) {
        if (this.isConnected && next) {
            this.style.setProperty('--min-width', next.toString() + 'px');
            this.positionTooltip();
        }
    }
    connectedCallback() {
        super.connectedCallback();
        if (!this.isNativePopoverSupported()) {
            this.setAttribute('data-not-native', 'true');
        }
        // Apply the values now that we're connected
        if (this.maxWidth !== MAX_WIDTH) {
            this.style.setProperty('--max-width', this.maxWidth.toString() + 'px');
            this.positionTooltip();
        }
        if (this.minWidth !== MIN_WIDTH) {
            this.style.setProperty('--min-width', this.minWidth.toString() + 'px');
            this.positionTooltip();
        }
        if (this.width !== undefined && this.width > MIN_WIDTH) {
            this.style.setProperty('--width', this.width.toString() + 'px');
            this.positionTooltip();
        }
    }
    disconnectedCallback() {
        var _a;
        (_a = this.cleanupFn) === null || _a === void 0 ? void 0 : _a.call(this);
    }
    openTooltip() {
        this.isNativePopoverSupported() && this.togglePopover();
        this.positionTooltip();
        document.addEventListener('mousemove', this.handleDocumentMousemove);
        this.setAttribute('data-displayed', 'true');
    }
    closeTooltip() {
        var _a;
        if (this.timeoutId) {
            clearTimeout(this.timeoutId);
            this.timeoutId = undefined;
        }
        (_a = this.cleanupFn) === null || _a === void 0 ? void 0 : _a.call(this);
        this.isNativePopoverSupported() && this.hidePopover();
        document.removeEventListener('mousemove', this.handleDocumentMousemove);
        this.removeAttribute('data-displayed');
    }
    isNativePopoverSupported() {
        return 'popover' in this;
    }
    positionTooltip() {
        var _a;
        (_a = this.cleanupFn) === null || _a === void 0 ? void 0 : _a.call(this);
        if (this.triggerEl) {
            calculatePopoverPosition(this, this, this.triggerEl).then((cleanup) => {
                this.cleanupFn = cleanup;
            });
        }
    }
}
__decorate([
    attr()
], TooltipV3Component.prototype, "position", void 0);
__decorate([
    attr({ mode: 'boolean' })
], TooltipV3Component.prototype, "inverted", void 0);
__decorate([
    attr({ converter: nullableNumberConverter })
], TooltipV3Component.prototype, "width", void 0);
__decorate([
    attr({ attribute: 'min-width', converter: nullableNumberConverter })
], TooltipV3Component.prototype, "minWidth", void 0);
__decorate([
    attr({ attribute: 'max-width', converter: nullableNumberConverter })
], TooltipV3Component.prototype, "maxWidth", void 0);
__decorate([
    attr({ attribute: 'trigger-on' })
], TooltipV3Component.prototype, "triggerOn", void 0);
__decorate([
    attr({ attribute: 'z-index', converter: nullableNumberConverter })
], TooltipV3Component.prototype, "zIndex", void 0);
__decorate([
    attr({ converter: nullableNumberConverter })
], TooltipV3Component.prototype, "delay", void 0);
__decorate([
    attr({ mode: 'boolean' })
], TooltipV3Component.prototype, "hidden", void 0);
__decorate([
    attr({ attribute: 'trigger-id' })
], TooltipV3Component.prototype, "triggerId", void 0);
__decorate([
    attr({ mode: 'boolean', attribute: 'is-open' })
], TooltipV3Component.prototype, "isOpen", void 0);
__decorate([
    observable
], TooltipV3Component.prototype, "contentEl", void 0);
