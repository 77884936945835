import { Anchor, anchorTemplate as template } from '@microsoft/fast-foundation';
import { NavigationDrawerLinkComponent } from './NavigationDrawerLink';
import styles from './NavigationDrawerLink.styles.scss';
export const navigationDrawerLinkDefinition = {
    baseName: 'navigation-drawer-link',
    baseClass: Anchor,
    template: template,
    styles,
    shadowOptions: {
        delegatesFocus: true,
    },
};
export const chameleonNavigationDrawerLink = NavigationDrawerLinkComponent.compose(navigationDrawerLinkDefinition);
