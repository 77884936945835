import { assertSlotNonNull } from '../../common/asserts';
const NATIVE_TRIGGER_LIST = [
    'chameleon-button',
    'chameleon-round-button',
    'chameleon-icon-button',
    'chameleon-internal-navigation-rail-item',
    'button',
];
export class PopoverTriggerStrategy {
    constructor(popover, popoverContent) {
        this.popover = popover;
        this.popoverContent = popoverContent;
        this.hasBeenTriggeredBy = null;
        this.isCustomTrigger = () => {
            const tagName = this.trigger.tagName.toLowerCase();
            return NATIVE_TRIGGER_LIST.indexOf(tagName) === -1;
        };
        /***
         * Sets a tabindex to the trigger if it does not already have one.
         * Particularly useful when using an Avatar as popover trigger
         */
        this.handleTriggerTabIndex = () => {
            if (this.trigger.hasAttribute('disabled')) {
                this.trigger.removeAttribute('tabindex');
            }
            else if (!this.trigger.hasAttribute('tabindex')) {
                this.trigger.setAttribute('tabindex', '0');
            }
        };
        this.handleKeyDown = (event) => {
            if (event.code === 'Space') {
                if (this.isCustomTrigger()) {
                    // Prevents scrolling the page down
                    event.preventDefault();
                }
            }
            if (event.key === 'Enter' || event.code === 'Space') {
                // Chameleon buttons already have a keyboard event hooked up, no need to override them
                if (this.isCustomTrigger()) {
                    this.hasBeenTriggeredBy = 'keyboard';
                    this.popover.open();
                }
            }
        };
        this.triggerObserver = new MutationObserver(this.handleTriggerTabIndex);
    }
    init() {
        this.trigger = this.popover.querySelector('[slot="trigger"]');
        assertSlotNonNull(this.trigger, 'PopoverComponent', 'trigger');
        this.setTriggerAriaAttributes();
        this.handleTriggerTabIndex();
        this.triggerObserver.observe(this.trigger, {
            attributes: true,
        });
        this.trigger.addEventListener('keydown', this.handleKeyDown);
    }
    dispose() {
        this.triggerObserver.disconnect();
        this.trigger.removeEventListener('keydown', this.handleKeyDown);
    }
    onOpen() {
        this.trigger.setAttribute('aria-expanded', 'true');
        this.internalOnOpen();
    }
    onClose() {
        this.trigger.setAttribute('aria-expanded', 'false');
        this.internalOnClose();
    }
    setTriggerAriaAttributes() {
        this.trigger.setAttribute('role', 'button');
        this.trigger.setAttribute('aria-haspopup', 'true');
        this.trigger.setAttribute('aria-expanded', String(this.popover.isOpen));
    }
}
