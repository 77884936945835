import { __decorate } from "tslib";
import { attr, observable } from '@microsoft/fast-element';
import { SVG_MORE_MENU_HORIZONTAL_OUTLINED } from '@getgo/chameleon-icons';
import { NavigationRailHeightOverflowObserver } from '../Observers/FastElementHeightOverflowObserver';
import { DEV } from 'esm-env';
const ITEM_SELECTOR = 'chameleon-navigation-rail-item:not([part=toggle-button]), chameleon-navigation-rail-link, chameleon-internal-navigation-rail-item, .chameleon-custom-navigation-rail-item, .more-items-popover';
const TOGGLE_SELECTOR = 'chameleon-navigation-rail-item[part=toggle-button]';
const MORE_ITEM_HEIGHT = 80;
const MIN_SEPARATOR_HEIGHT = 20;
const ITEMS_SECONDARY_ITEMS_MARGIN = 24;
const PADDING = 8;
// @ts-expect-error itemsChanged is private in FAST, but we need to hook into this to add custom logic when slotted items change
export class NavigationRailComponent extends NavigationRailHeightOverflowObserver {
    constructor() {
        super(...arguments);
        this.moreLabel = 'More';
        this.collapseLabel = 'Collapse';
        this.expandLabel = 'Expand';
        this.shouldDisplaySecondaryItems = false;
        this.toggleLabel = this.expandLabel;
        this.secondaryItems = [];
        //TODO: change it to expanded after size is removed
        this.state = 'expanded';
        this.size = 'large';
        this.volatileItems = [];
        this.popoverElement = null;
        this.createPopover = () => {
            var _a;
            this.popoverElement = document.createElement('chameleon-popover');
            this.popoverElement.setAttribute('class', 'more-items-popover');
            this.popoverElement.setAttribute('role', 'menuitem');
            this.popoverElement.setAttribute('position', 'right-center');
            this.popoverElement.setAttribute('state', this.state);
            this.popoverElement.setAttribute('arrow', 'true');
            this.popoverElement.setAttribute('menu', 'true');
            this.popoverElement.setAttribute('z-index', '100');
            (_a = this.popoverElement) === null || _a === void 0 ? void 0 : _a.style.setProperty('--goto-icon-size', '24px');
            this.popoverElement.tabIndex = -1;
            this.popoverElement.innerHTML = `<chameleon-navigation-rail-item
      size=${this.size}
      state=${this.state}
      slot="trigger"
      aria-label="${this.moreLabel}"
      class="more-items-container">
        <chameleon-svg slot="start">${SVG_MORE_MENU_HORIZONTAL_OUTLINED}</chameleon-svg>
        ${this.moreLabel}
      </chameleon-navigation-rail-item>
      <div slot="content">
        <chameleon-menu style="border-radius: 8px" size=${this.size}></chameleon-menu>
      </div>`;
            this.popoverElement.addEventListener('popoverchange', (e) => this.handlePopoverChange(e));
            this.popoverElement.addEventListener('focus', (e) => this.handlePopoverFocus(e));
            return this.popoverElement;
        };
    }
    secondaryItemsChanged(_, changed) {
        this.shouldDisplaySecondaryItems = changed.length > 0;
        for (const element of changed) {
            // We get nodes that are not elements (text node, comment node), we need to filter them.
            if (!element || element.nodeType !== element.ELEMENT_NODE)
                continue;
            if (element.nodeName !== 'CHAMELEON-NAVIGATION-RAIL-ITEM' &&
                element.nodeName !== 'CHAMELEON-NAVIGATION-RAIL-LINK' &&
                !element.classList.contains('more-items-popover')) {
                element.classList.add('chameleon-custom-navigation-rail-item');
                element.setAttribute('role', 'menuitem');
            }
        }
    }
    labelChanged() {
        if (this.isConnected) {
            this.setAccessibleLabel();
        }
    }
    stateChanged(_, newValue) {
        const items = Array.from(this.querySelectorAll(ITEM_SELECTOR));
        if (newValue === 'collapsed') {
            this.size = 'small';
            this.toggleLabel = this.expandLabel;
        }
        else if (newValue === 'expanded') {
            this.size = 'large';
            this.toggleLabel = this.collapseLabel;
        }
        items.forEach((item) => {
            if (newValue === 'collapsed') {
                item.setAttribute('state', 'collapsed');
            }
            else if (newValue === 'expanded') {
                item.setAttribute('state', 'expanded');
            }
            else {
                item.removeAttribute('state');
            }
        });
    }
    sizeChanged(_, changed) {
        const items = Array.from(this.querySelectorAll(ITEM_SELECTOR));
        if (changed === 'small') {
            this.state = 'collapsed';
            this.toggleLabel = this.expandLabel;
        }
        else if (changed === 'large' || changed === 'medium') {
            this.state = 'expanded';
            this.toggleLabel = this.collapseLabel;
        }
        else {
            this.removeAttribute('size');
            this.removeAttribute('state');
        }
        items.forEach((item) => {
            if (changed === 'small') {
                item.setAttribute('size', 'small');
                item.setAttribute('state', 'collapsed');
            }
            else if (changed === 'large' || changed === 'medium') {
                item.setAttribute('size', 'large');
                item.setAttribute('state', 'expanded');
            }
            else {
                item.removeAttribute('size');
                item.removeAttribute('state');
            }
        });
    }
    itemsChanged(prev, next) {
        // @ts-expect-error itemsChanged is private in FAST, but we need to hook into this to add custom logic when slotted items change
        super.itemsChanged(prev, next);
        for (const element of next) {
            // We get nodes that are not elements (text node, comment node), we need to filter them.
            if (!element || element.nodeType !== element.ELEMENT_NODE)
                continue;
            if (element.nodeName !== 'CHAMELEON-NAVIGATION-RAIL-ITEM' &&
                element.nodeName !== 'CHAMELEON-NAVIGATION-RAIL-LINK' &&
                !element.classList.contains('more-items-popover')) {
                element.classList.add('chameleon-custom-navigation-rail-item');
                element.setAttribute('role', 'menuitem');
            }
        }
    }
    connectedCallback() {
        super.connectedCallback();
        this.setAccessibleLabel();
        if (this.size === 'medium') {
            this.size = 'large';
        }
        // The problem is that the addSvgDimension is not called when the SvgComponents are added as slot items, neither when the --goto-icon-size is changed
        for (const svg of this.querySelectorAll('chameleon-svg').values()) {
            svg.style.setProperty('--goto-icon-size', '24px');
        }
    }
    toggle() {
        if (this.size !== 'large' || this.state !== 'expanded') {
            this.size = 'large';
            this.state = 'expanded';
        }
        else {
            this.size = 'small';
            this.state = 'collapsed';
        }
        this.$emit('navrail-toggle', null, { bubbles: false });
    }
    validateOverflow(el) {
        var _a, _b;
        this.resetVolatileItems();
        this.removePopover();
        (_a = this.popoverElement) === null || _a === void 0 ? void 0 : _a.remove();
        const items = Array.from(this.querySelectorAll(ITEM_SELECTOR)).filter((item) => item.getAttribute('slot') !== 'secondary-items');
        const secondaryItems = Array.from(this.querySelectorAll(ITEM_SELECTOR)).filter((item) => item.getAttribute('slot') === 'secondary-items');
        const firstSecondaryItemRef = Array.from(this.querySelectorAll(ITEM_SELECTOR)).filter((item) => item.getAttribute('slot') === 'secondary-items')[0];
        const toggleButton = (_b = this.shadowRoot) === null || _b === void 0 ? void 0 : _b.querySelector(TOGGLE_SELECTOR);
        if (!el.clientHeight && !toggleButton)
            return;
        const itemsHeight = items.reduce((acc, item) => {
            return acc + item.offsetHeight;
        }, 0);
        const secondaryItemsHeight = secondaryItems.reduce((acc, item) => {
            return acc + item.offsetHeight;
        }, 0);
        const availableSpace = el.clientHeight -
            toggleButton.offsetHeight -
            secondaryItemsHeight -
            MIN_SEPARATOR_HEIGHT -
            ITEMS_SECONDARY_ITEMS_MARGIN * 2 -
            PADDING * 2;
        if (itemsHeight < availableSpace)
            return;
        const popover = this.createPopover();
        const popoverMenu = popover.querySelector('chameleon-menu');
        if (!popoverMenu)
            return;
        let heightUsed = 0;
        this.volatileItems = items.reduce((acc, item) => {
            heightUsed += item.offsetHeight;
            if (heightUsed > availableSpace - MORE_ITEM_HEIGHT) {
                return [...acc, item];
            }
            return acc;
        }, []);
        this.volatileItems.forEach((item) => {
            item.setAttribute('appearance', 'menuitem');
            item.setAttribute('size', 'large');
            const svg = item.querySelector('chameleon-svg');
            if (svg) {
                svg.style.setProperty('--goto-icon-size', '20px');
            }
            item.addEventListener('menu-item-click', this.handleMenuItemClick.bind(this));
            popoverMenu.appendChild(item);
        });
        this.insertBefore(popover, firstSecondaryItemRef);
        this.popoverElement = popover;
    }
    resetVolatileItems() {
        const firstSecondaryItemRef = Array.from(this.querySelectorAll(ITEM_SELECTOR)).filter((item) => item.getAttribute('slot') === 'secondary-items')[0];
        if (this.volatileItems.length) {
            this.volatileItems.forEach((item) => {
                item.setAttribute('appearance', 'navrailitem');
                item.setAttribute('size', this.size);
                item.setAttribute('state', this.state);
                const svg = item.querySelector('chameleon-svg');
                if (svg) {
                    svg.style.setProperty('--goto-icon-size', '24px');
                }
                item.removeEventListener('menu-item-click', this.handleMenuItemClick);
                this.insertBefore(item, firstSecondaryItemRef);
            });
        }
    }
    removePopover() {
        var _a, _b, _c;
        (_a = this.popoverElement) === null || _a === void 0 ? void 0 : _a.removeEventListener('popoverchange', (e) => this.handlePopoverChange(e));
        (_b = this.popoverElement) === null || _b === void 0 ? void 0 : _b.blur();
        (_c = this.popoverElement) === null || _c === void 0 ? void 0 : _c.remove();
    }
    handleMenuItemClick() {
        var _a;
        (_a = this.popoverElement) === null || _a === void 0 ? void 0 : _a.close();
    }
    handlePopoverFocus(e) {
        e.target.firstChild.focus();
    }
    handleCollapseButtonKeyup(e) {
        if (e.key === 'Enter' || e.key === ' ') {
            this.toggle();
        }
    }
    handlePopoverChange(e) {
        var _a, _b;
        const navRailItem = (_a = this.popoverElement) === null || _a === void 0 ? void 0 : _a.querySelector('chameleon-navigation-rail-item');
        const tooltip = (_b = navRailItem === null || navRailItem === void 0 ? void 0 : navRailItem.shadowRoot) === null || _b === void 0 ? void 0 : _b.querySelector('chameleon-tooltip-v2');
        tooltip === null || tooltip === void 0 ? void 0 : tooltip.setAttribute('hidden', `${e.detail.isOpen}`);
    }
    disconnectedCallback() {
        super.disconnectedCallback();
        document.body.style.removeProperty('--ADDITIONAL-ARROW-OFFSET-X');
    }
    setAccessibleLabel() {
        if (this.label) {
            this.setAttribute('aria-label', this.label);
            return;
        }
        if (DEV) {
            this.validateAccessibleLabel();
        }
    }
    validateAccessibleLabel() {
        var _a;
        if (!((_a = this.getAttribute('aria-label')) === null || _a === void 0 ? void 0 : _a.length)) {
            console.error(`CHAMELEON-A11Y: NavigationRail component must have an accessible label provided by a non-empty label attribute to be used as aria-label.`);
        }
    }
}
__decorate([
    attr({ attribute: 'more-label' })
], NavigationRailComponent.prototype, "moreLabel", void 0);
__decorate([
    attr({ attribute: 'collapse-label' })
], NavigationRailComponent.prototype, "collapseLabel", void 0);
__decorate([
    attr({ attribute: 'expand-label' })
], NavigationRailComponent.prototype, "expandLabel", void 0);
__decorate([
    observable
], NavigationRailComponent.prototype, "shouldDisplaySecondaryItems", void 0);
__decorate([
    observable
], NavigationRailComponent.prototype, "toggleLabel", void 0);
__decorate([
    observable
], NavigationRailComponent.prototype, "secondaryItems", void 0);
__decorate([
    attr
], NavigationRailComponent.prototype, "label", void 0);
__decorate([
    attr
], NavigationRailComponent.prototype, "state", void 0);
__decorate([
    attr
], NavigationRailComponent.prototype, "size", void 0);
