import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import {
  daoTransactionAddPaypalStep1Post,
  daoTransactionAddPaypalStep2Post,
  daoTransactionUsePaypalPayPost,
} from 'dao/transaction-paypal-dao';
import { Action } from 'types/action';
import { Thunk } from 'types/root';
import {
  AddPaypalStep1Payload,
  AddPaypalStep1Resp,
  AddPaypalStep2Payload,
  AddPaypalStep2Resp,
  TransactionsPaypalState,
  UsePaypalPayPayload,
  UsePaypalPayResp,
} from 'types/transaction-paypal';

export const postTransactionAddPaypalStep1 = createAsyncThunk<AddPaypalStep1Resp, AddPaypalStep1Payload, Thunk>(
  'transactions/addPaypalStep1',
  async (payload, thunkAPI) => {
    const state = thunkAPI.getState();
    const {
      globalWrapper: { accountKey, sessionId },
    } = state;
    const response = await daoTransactionAddPaypalStep1Post(accountKey, sessionId, payload);
    return response as AddPaypalStep1Resp;
  },
);

export const postTransactionAddPaypalStep2 = createAsyncThunk<AddPaypalStep2Resp, AddPaypalStep2Payload, Thunk>(
  'transactions/addPaypalStep2',
  async (payload, thunkAPI) => {
    const state = thunkAPI.getState();
    const {
      globalWrapper: { accountKey, sessionId },
    } = state;
    const response = await daoTransactionAddPaypalStep2Post(accountKey, sessionId, payload);
    return response as AddPaypalStep2Resp;
  },
);

export const postTransactionUsePaypalPay = createAsyncThunk<UsePaypalPayResp, UsePaypalPayPayload, Thunk>(
  'transactions/payPaypal',
  async (payload, thunkAPI) => {
    const state = thunkAPI.getState();
    const {
      globalWrapper: { accountKey, sessionId },
    } = state;
    const response = await daoTransactionUsePaypalPayPost(accountKey, sessionId, payload);
    return response as UsePaypalPayResp;
  },
);

const initialState: TransactionsPaypalState = {
  isLoading: false,
  addPaypalStep1Resp: {} as AddPaypalStep1Resp,
  addPaypalStep2Resp: {} as AddPaypalStep2Resp,
  usePaypalPayResp: {} as UsePaypalPayResp,
};

const transactionsPaypalSlice = createSlice({
  name: 'transactions-paypal',
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    builder
      .addCase(postTransactionAddPaypalStep1.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(postTransactionAddPaypalStep1.fulfilled, (state, action: Action) => {
        state.isLoading = false;
        state.addPaypalStep1Resp = action.payload;
      })
      .addCase(postTransactionAddPaypalStep1.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(postTransactionAddPaypalStep2.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(postTransactionAddPaypalStep2.fulfilled, (state, action: Action) => {
        state.isLoading = false;
        state.addPaypalStep2Resp = action.payload;
      })
      .addCase(postTransactionAddPaypalStep2.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(postTransactionUsePaypalPay.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(postTransactionUsePaypalPay.fulfilled, (state, action: Action) => {
        state.isLoading = false;
        state.usePaypalPayResp = action.payload;
      })
      .addCase(postTransactionUsePaypalPay.rejected, (state) => {
        state.isLoading = false;
      });
  },
});

export default transactionsPaypalSlice.reducer;
