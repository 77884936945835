import { provideChameleonDesignSystem } from '@getgo/chameleon-toolkit';
import { chameleonSvg } from '../Svg';
import { chameleonProgressStep } from './ProgressStep/ProgressStep.definition';
import { chameleonProgressStepPanel } from './ProgressStepPanel/ProgressStepPanel.definition';
import { chameleonProgressStepper } from './ProgressStepper.definition';
import { chameleonTooltipV2 } from '../TooltipV2';
import { register as registerTypography } from '@getgo/chameleon-typography/register';
export function registerCustom(prefix, node) {
    registerTypography({ prefix, node });
    return provideChameleonDesignSystem({ prefix, node }).register(chameleonProgressStepper(), chameleonProgressStep(), chameleonProgressStepPanel(), chameleonSvg(), chameleonTooltipV2());
}
