import { __decorate } from "tslib";
import { attr } from '@microsoft/fast-element';
import { LoadingButton } from '../common/LoadingButton';
import { DEV } from 'esm-env';
export class IconButtonComponent extends LoadingButton {
    constructor() {
        super(...arguments);
        this.size = 'medium';
        this.variant = 'primary';
        this.badge = false;
    }
    badgeChanged(_oldValue, newValue) {
        if (newValue) {
            requestAnimationFrame(() => {
                this.attachBadge();
            });
        }
        else
            this.detachBadge();
    }
    badgeLabelChanged() {
        if (this.badge) {
            requestAnimationFrame(() => {
                var _a, _b;
                const badgeElement = (_b = (_a = this.shadowRoot) === null || _a === void 0 ? void 0 : _a.querySelector('button')) === null || _b === void 0 ? void 0 : _b.querySelector('chameleon-badge-counter');
                this.setAccessibleLabelForBadge(badgeElement);
            });
        }
    }
    labelChanged() {
        this.setAccessibleLabel();
    }
    labelIdChanged() {
        this.setAccessibleLabel();
    }
    connectedCallback() {
        super.connectedCallback();
        this.setAccessibleLabel();
    }
    attachBadge() {
        var _a, _b, _c;
        const badgeElement = document.createElement('chameleon-badge-counter');
        badgeElement.setAttribute('label', 'CHAMELEON_BYPASS_LABEL');
        if (this.getAttribute('size') === 'medium' || this.getAttribute('size') === 'xsmall')
            badgeElement.setAttribute('size', 'small');
        else
            badgeElement.setAttribute('size', (_a = this.getAttribute('size')) !== null && _a !== void 0 ? _a : 'small');
        this.setAccessibleLabelForBadge(badgeElement);
        (_c = (_b = this.shadowRoot) === null || _b === void 0 ? void 0 : _b.querySelector('button')) === null || _c === void 0 ? void 0 : _c.appendChild(badgeElement);
    }
    detachBadge() {
        var _a, _b, _c;
        (_c = (_b = (_a = this.shadowRoot) === null || _a === void 0 ? void 0 : _a.querySelector('button')) === null || _b === void 0 ? void 0 : _b.querySelector('chameleon-badge-counter')) === null || _c === void 0 ? void 0 : _c.remove();
    }
    getInternalButtonElement() {
        var _a;
        return (_a = this.shadowRoot) === null || _a === void 0 ? void 0 : _a.querySelector('button');
    }
    disabledChanged(oldValue, newValue) {
        super.disabledChanged(oldValue, newValue);
        requestAnimationFrame(() => {
            var _a, _b;
            (_a = this.getInternalButtonElement()) === null || _a === void 0 ? void 0 : _a.removeAttribute('disabled');
            (_b = this.getInternalButtonElement()) === null || _b === void 0 ? void 0 : _b.setAttribute('aria-disabled', `${newValue}`);
        });
    }
    setAccessibleLabelForBadge(badge) {
        if (this.badgeLabel) {
            badge.setAttribute('aria-label', this.badgeLabel);
            return;
        }
        if (DEV) {
            this.validateAccessibleBadgeLabel(badge);
        }
    }
    setAccessibleLabel() {
        var _a;
        if (this.labelId) {
            this.setAttribute('aria-labelledby', this.labelId);
            return;
        }
        if (this.label) {
            (_a = this.getInternalButtonElement()) === null || _a === void 0 ? void 0 : _a.setAttribute('aria-label', this.label);
            return;
        }
        if (DEV) {
            this.validateAccessibleLabel();
        }
    }
    validateAccessibleBadgeLabel(badge) {
        var _a;
        if (!((_a = badge.getAttribute('aria-label')) === null || _a === void 0 ? void 0 : _a.length) || badge.getAttribute('aria-label') === 'CHAMELEON_BYPASS_LABEL') {
            console.error('CHAMELEON-A11Y: Icon Button component must have an accessible label for the badge indicator provided by a non-empty badgeLabel/badge-label attribute to be used as aria-label.');
        }
    }
    validateAccessibleLabel() {
        var _a, _b, _c;
        if (!((_b = (_a = this.getInternalButtonElement()) === null || _a === void 0 ? void 0 : _a.getAttribute('aria-label')) === null || _b === void 0 ? void 0 : _b.length) &&
            !((_c = this.getAttribute('aria-labelledby')) === null || _c === void 0 ? void 0 : _c.length)) {
            console.error(`CHAMELEON-A11Y: Icon Button component must have an accessible label provided by either 
  1) a visible label whose id is given as the attribute labelId 
  2) a non-empty label attribute to be used as aria-label.
`);
        }
    }
    _keypressHandler(event) {
        var _a;
        if (((_a = this.getInternalButtonElement()) === null || _a === void 0 ? void 0 : _a.getAttribute('aria-disabled')) === 'true') {
            event.preventDefault();
        }
    }
}
__decorate([
    attr
], IconButtonComponent.prototype, "size", void 0);
__decorate([
    attr
], IconButtonComponent.prototype, "variant", void 0);
__decorate([
    attr({ mode: 'boolean' })
], IconButtonComponent.prototype, "badge", void 0);
__decorate([
    attr({ attribute: 'label-id' })
], IconButtonComponent.prototype, "labelId", void 0);
__decorate([
    attr
], IconButtonComponent.prototype, "label", void 0);
__decorate([
    attr({ attribute: 'badge-label' })
], IconButtonComponent.prototype, "badgeLabel", void 0);
