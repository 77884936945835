import { __rest } from "tslib";
import React, { forwardRef } from 'react';
import { createReactWrapper } from '../common/PrefixUtils';
export const Snackbar = forwardRef((_a, ref) => {
    var { progress, actions, title, children } = _a, props = __rest(_a, ["progress", "actions", "title", "children"]);
    return createReactWrapper({
        tagName: 'chameleon-snackbar',
        props: Object.assign(Object.assign({}, props), { ref }),
        children: (React.createElement(React.Fragment, null,
            React.createElement("h2", { slot: "title" }, title),
            React.createElement("p", { slot: "message" }, children),
            progress !== undefined &&
                React.createElement('chameleon-linear-progress', { slot: 'progress', value: progress < 0 ? undefined : progress, inverse: true }, undefined),
            actions && React.createElement("div", { slot: "action" }, actions))),
    });
});
