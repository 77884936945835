import { __decorate } from "tslib";
import { attr, html } from '@microsoft/fast-element';
import { ChameleonElementMixin } from '../common/mixin';
import { FoundationElement } from '@microsoft/fast-foundation';
export const avatarGroupTemplate = html `<slot></slot>`;
const DEFAULT_MAX_AVATARS = 4;
const DEFAULT_SIZE = 'medium';
export class AvatarGroupComponent extends ChameleonElementMixin(FoundationElement) {
    constructor() {
        super(...arguments);
        this.max = DEFAULT_MAX_AVATARS;
        this.size = DEFAULT_SIZE;
    }
    connectedCallback() {
        super.connectedCallback();
        this.render();
    }
    attributeChangedCallback(name, oldValue, newValue) {
        super.attributeChangedCallback(name, oldValue, newValue);
        this.render();
    }
    getAvatars() {
        return this.querySelectorAll('chameleon-avatar-v2');
    }
    getOldAvatars() {
        return this.querySelectorAll('chameleon-avatar');
    }
    /**
     * HOTFIX : Migration path for Admin, use chameleon-tooltip-v3 instead.
     */
    getOldChildren() {
        return this.querySelectorAll(':scope > chameleon-avatar:not([hint]), :scope > chameleon-tooltip-v2');
    }
    render() {
        var _a, _b, _c, _d;
        (_a = this.querySelector('[hint]')) === null || _a === void 0 ? void 0 : _a.remove();
        const max = this.hasAttribute('max') ? +((_b = this.getAttribute('max')) !== null && _b !== void 0 ? _b : DEFAULT_MAX_AVATARS) : this.max;
        const size = this.hasAttribute('size') ? (_c = this.getAttribute('size')) !== null && _c !== void 0 ? _c : DEFAULT_SIZE : this.size;
        const avatars = this.getAvatars();
        const oldAvatars = this.getOldAvatars();
        avatars.forEach((avatar) => {
            avatar.setAttribute('size', size);
        });
        oldAvatars.forEach((avatar) => {
            avatar.setAttribute('size', size);
        });
        if ((avatars === null || avatars === void 0 ? void 0 : avatars.length) > max) {
            avatars.forEach((item) => (item.hidden = false));
            const diff = ((_d = this.getAvatars()) === null || _d === void 0 ? void 0 : _d.length) - max + 1;
            const itemsToRemove = [...avatars].slice(max - 1);
            itemsToRemove.forEach((item) => item.setAttribute('hidden', ''));
            if (diff > 0) {
                const avatar = document.createElement('chameleon-avatar-v2');
                avatar.setAttribute('size', size);
                avatar.setAttribute('hint', '');
                avatar.textContent = `+${diff}`;
                avatar.setAttribute('label', `Avatars`);
                this.appendChild(avatar);
            }
        }
        const oldChildren = this.getOldChildren();
        if ((oldChildren === null || oldChildren === void 0 ? void 0 : oldChildren.length) > max) {
            oldChildren.forEach((item) => item.style.removeProperty('display'));
            const diff = (oldChildren === null || oldChildren === void 0 ? void 0 : oldChildren.length) - max;
            const itemsToRemove = [...oldChildren].slice(diff > 0 ? max - 1 : max);
            itemsToRemove.forEach((item) => (item.style.display = 'none'));
            if (diff > 0) {
                const avatar = document.createElement('chameleon-avatar');
                avatar.setAttribute('hint', '');
                avatar.setAttribute('size', size);
                avatar.textContent = `+${diff + 1}`;
                this.appendChild(avatar);
            }
        }
    }
}
__decorate([
    attr
], AvatarGroupComponent.prototype, "max", void 0);
__decorate([
    attr
], AvatarGroupComponent.prototype, "size", void 0);
