import { PopoverTriggerStrategy } from './PopoverTriggerStrategy';
export class PopoverTriggerClickStrategy extends PopoverTriggerStrategy {
    constructor() {
        super(...arguments);
        this.handleTriggerClick = () => {
            if (this.popover.isOpen) {
                this.popover.close();
                return;
            }
            if (this.popover.shouldOpen) {
                this.popover.open();
                this.hasBeenTriggeredBy = 'click';
            }
        };
        this.handleGlobalClick = (e) => {
            const path = e.composedPath();
            const popoverTriggerElement = path.find((element) => {
                return (element === null || element === void 0 ? void 0 : element.id) === this.popover.triggerId;
            });
            if (popoverTriggerElement && (popoverTriggerElement === null || popoverTriggerElement === void 0 ? void 0 : popoverTriggerElement.id) === this.popover.triggerId) {
                return;
            }
            if (this.popover.isOpen && !this.popoverContent.contains(e.target)) {
                this.popover.close();
            }
        };
    }
    init() {
        super.init();
        this.trigger.addEventListener('click', this.handleTriggerClick);
    }
    dispose() {
        super.dispose();
        this.trigger.removeEventListener('click', this.handleTriggerClick);
        this.unbindGlobalListener();
    }
    internalOnOpen() {
        document.addEventListener('click', this.handleGlobalClick);
        document.addEventListener('split-button-trigger-click', (event) => this.handleGlobalClick(event));
    }
    internalOnClose() {
        this.unbindGlobalListener();
    }
    unbindGlobalListener() {
        document.removeEventListener('click', this.handleGlobalClick);
    }
}
