import { FC } from 'react';
import { useIntl } from 'react-intl';
import { BankAccountOutlinedIcon } from '@getgo/chameleon-icons/react';
import { Typography } from '@getgo/chameleon-web-react-wrapper';

import DefaultDeleteCTAs from 'components/default-delete-ctas';
import { DdDetails } from 'types/payment-method';
import { PAYMENT_METHODS_TYPES } from 'utils/constants';
import st from 'utils/shared-translations';

import './direct-debit-info.css';

interface DirectDebitInfoProps {
  directDebit: DdDetails;
}

const DirectDebitInfo: FC<DirectDebitInfoProps> = ({ directDebit }): JSX.Element => {
  const intl = useIntl();

  return (
    <>
      <section className="direct-debit-info">
        <div className="direct-debit-info__details">
          <BankAccountOutlinedIcon size="24px" />

          <section>
            <Typography tag="h3" variant="body-small" gutterBottom>
              {intl.formatMessage(st['dd.mandate.id'])}: {directDebit?.mandateId}
            </Typography>
            <Typography tag="h3" variant="body-small">
              {intl.formatMessage(st['dd.bank.name'])}: {directDebit?.bankName}
            </Typography>
          </section>
        </div>

        <DefaultDeleteCTAs
          isDefault={directDebit.isDefault}
          isDeletable={directDebit.isDeletable}
          paymentMethodKey={directDebit.paymentMethodKey}
          paymentType={PAYMENT_METHODS_TYPES.dd}
        />
      </section>
    </>
  );
};

export default DirectDebitInfo;
