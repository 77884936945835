import { __decorate } from "tslib";
import { attr, DOM, Observable } from '@microsoft/fast-element';
import { Select, SelectPosition } from '@microsoft/fast-foundation';
import { SVG_CHEVRON_DOWN_OUTLINED, SVG_CHECKMARK_OUTLINED, SVG_ALERT_OUTLINED } from '@getgo/chameleon-icons';
import { ChameleonElementMixin } from '../common/mixin';
import { findChild, findParent } from '../common/utils/DomUtil';
import { highRateFriendly } from '../common/utils/animationUtils';
import { DEV } from 'esm-env';
const helperTextDefaultColor = 'text-02';
export class SelectComponent extends ChameleonElementMixin(Select) {
    get displayValue() {
        var _a, _b;
        Observable.track(this, 'displayValue');
        return (_b = (_a = this.firstSelectedOption) === null || _a === void 0 ? void 0 : _a.label) !== null && _b !== void 0 ? _b : '';
    }
    get positionIsBelow() {
        return !!this.position && this.position === SelectPosition.below;
    }
    get positionIsAbove() {
        return !!this.position && this.position === SelectPosition.above;
    }
    get isInlineOrFitContent() {
        return !!(this.inline || this.fitContent);
    }
    get controlBoundingRect() {
        return this.control.getBoundingClientRect();
    }
    resizeListboxWhenFullWidth() {
        if (this.fullwidth) {
            if (this.controlBoundingRect.width > 460) {
                // checking 460px because boundingRect excludes the padding
                this.listbox.style.width = 'auto';
                this.listbox.style.maxWidth = '320px';
            }
            else {
                this.listbox.style.width = '100%';
                this.listbox.style.maxWidth = '100%';
            }
        }
    }
    resizeListboxWhenLabelAndHelperText() {
        const selectHeight = this.controlBoundingRect.height + (this.noBorder ? 0 : 2); // +2px because of the borders
        // Adjust listbox when there is a label on the left
        if (this.isInlineOrFitContent && this.label && this.labelWidth) {
            this.listbox.style.left = this.labelWidth + 4 + 'px';
        }
        // Adjust listbox when opens above and has helperText
        if (this.positionIsAbove && this.helperText && !this.isInlineOrFitContent) {
            this.listbox.style.bottom = `${selectHeight + 20}px`;
        }
        // Adjust listbox when opens below and has label
        if (this.positionIsBelow && this.labelHeight && !this.inDialog) {
            const extraHeight = this.isInlineOrFitContent && this.label ? 0 : this.labelHeight;
            this.listbox.style.top = `${selectHeight + extraHeight}px`;
        }
    }
    resizeListboxWhenNoScreenSpace() {
        const distanceRight = window.innerWidth - this.controlBoundingRect.left;
        const distanceToScreenBottom = window.innerHeight - this.controlBoundingRect.bottom;
        const distanceToScreenTop = this.controlBoundingRect.top;
        const listboxComputedMaxHeight = parseInt(getComputedStyle(this.listbox).maxHeight);
        const listboxIsBiggerThenBottom = listboxComputedMaxHeight > distanceToScreenBottom;
        const listboxIsBiggerThenTop = listboxComputedMaxHeight > distanceToScreenTop;
        // Adjust listbox position based on the distance to the top and bottom
        if (this.positionIsBelow) {
            this.listbox.style.maxHeight = listboxIsBiggerThenBottom
                ? `${distanceToScreenBottom - 2}px`
                : `${listboxComputedMaxHeight}px`;
        }
        if (this.positionIsAbove) {
            this.listbox.style.top = listboxIsBiggerThenTop ? `2px` : 'unset';
            this.listbox.style.maxHeight = listboxIsBiggerThenTop
                ? `${distanceToScreenTop - 2}px`
                : `${listboxComputedMaxHeight}px`;
        }
        // Adjust listbox position based on the distance to the right
        if (distanceRight < 330 || (this.inline && distanceRight < 260)) {
            this.listbox.style.left = 'unset';
            this.listbox.style.right = '0px';
        }
    }
    resizeListboxWhenLongTextOptions() {
        var _a;
        // Set max-width only when there are long text options
        if (this.optionList) {
            for (const option of this.optionList) {
                const text = (_a = option.shadowRoot) === null || _a === void 0 ? void 0 : _a.querySelector('.content');
                const hasLongTextOption = text &&
                    text.scrollWidth > text.clientWidth &&
                    !this.inline &&
                    this.controlBoundingRect.width &&
                    this.controlBoundingRect.width <= 460;
                if (hasLongTextOption) {
                    this.listbox.style.width = 'max-content';
                    this.listbox.style.maxWidth = '320px';
                    break; // no need to check other text options
                }
            }
        }
    }
    inDialogChanged(_oldValue, _newValue) {
        if (this.inDialog && !this.tearDownInDialog) {
            this.tearDownInDialog = this.setUpInDialog();
        }
        if (!this.inDialog && this.tearDownInDialog) {
            this.tearDownInDialog();
            this.tearDownInDialog = undefined;
        }
    }
    inlineChanged() {
        if (this.inline) {
            if (this.hasAttribute('size')) {
                this.removeAttribute('size');
                if (DEV) {
                    console.warn('Chameleon Select: Size attribute for Inline variants is deprecated. Inline only has 1 size.');
                }
            }
            this.setStateIcon(false);
        }
        else if (this.error) {
            this.setStateIcon(true, SVG_ALERT_OUTLINED);
        }
        else if (this.success) {
            this.setStateIcon(true, SVG_CHECKMARK_OUTLINED);
        }
    }
    sizeChanged() {
        if (this.getAttribute('size') === 'xsmall') {
            this.setAttribute('size', 'small');
            if (DEV) {
                console.warn('Chameleon Select: The size `xsmall` is deprecated. It will be set to `small` instead.');
            }
        }
    }
    selectedValueChanged(_oldValue, newValue) {
        this.value = newValue;
    }
    labelChanged(_oldValue, newValue) {
        this.labelHtmlElement.textContent = newValue;
    }
    helperTextChanged(_oldValue, newValue) {
        this.helperTextHtmlElement.textContent = newValue;
    }
    positionChanged(_oldValue, newValue) {
        super.positionChanged(_oldValue, newValue);
        if (this.inDialog) {
            this.positionListbox();
        }
    }
    successChanged(_oldValue, newValue) {
        if (newValue) {
            this.helperTextHtmlElement.setAttribute('color', 'text-success-01');
            if (!this.inline) {
                this.setStateIcon(true, SVG_CHECKMARK_OUTLINED);
            }
        }
        else {
            this.helperTextHtmlElement.removeAttribute('color');
            this.setStateIcon(false);
        }
    }
    errorChanged(_oldValue, newValue) {
        if (newValue) {
            this.helperTextHtmlElement.setAttribute('color', 'danger-01');
            if (!this.inline) {
                this.setStateIcon(true, SVG_ALERT_OUTLINED);
            }
        }
        else {
            this.helperTextHtmlElement.removeAttribute('color');
            this.setStateIcon(false);
        }
    }
    disabledChanged(_oldValue, newValue) {
        var _a, _b, _c, _d;
        super.disabledChanged(_oldValue, newValue);
        if (newValue) {
            (_a = this.helperTextHtmlElement) === null || _a === void 0 ? void 0 : _a.setAttribute('color', 'text-03');
            (_b = this.labelHtmlElement) === null || _b === void 0 ? void 0 : _b.setAttribute('color', 'text-03');
        }
        else {
            (_c = this.helperTextHtmlElement) === null || _c === void 0 ? void 0 : _c.setAttribute('color', helperTextDefaultColor);
            (_d = this.labelHtmlElement) === null || _d === void 0 ? void 0 : _d.setAttribute('color', 'text-01');
        }
    }
    constructor() {
        super();
        this.selectedValue = '';
        this.fullwidth = false;
        this.stateIcon = document.createElement('chameleon-svg');
        this.labelSize = new ResizeObserver((entries) => {
            const rect = entries[0].contentRect;
            this.labelWidth = rect.width;
            this.labelHeight = rect.height;
        });
        this.resizeListboxCallback = () => {
            this.resizeListboxWhenFullWidth();
            this.resizeListboxWhenLabelAndHelperText();
            this.resizeListboxWhenNoScreenSpace();
            this.resizeListboxWhenLongTextOptions();
        };
        this.resizeListbox = new ResizeObserver(this.resizeListboxCallback);
        this.positionListbox = highRateFriendly(() => {
            var _a;
            if (this.control && this.listbox) {
                this.listbox.style.position = 'fixed';
                this.listbox.style.width = `${this.controlBoundingRect.width}px`;
                if (this.positionIsAbove) {
                    if (this.listbox.style.top) {
                        this.listbox.style.removeProperty('top');
                    }
                    this.listbox.style.bottom = `${window.innerHeight - this.controlBoundingRect.y}px`;
                    this.listbox.style.left = `${this.controlBoundingRect.x}px`;
                }
                else {
                    if (this.listbox.style.bottom) {
                        this.listbox.style.removeProperty('bottom');
                    }
                    this.listbox.style.top = `${this.controlBoundingRect.y + this.controlBoundingRect.height}px`;
                    this.listbox.style.left = `${this.controlBoundingRect.x}px`;
                }
                const dialogBoundingRect = (_a = this.parentDialogContent) === null || _a === void 0 ? void 0 : _a.getBoundingClientRect();
                if (dialogBoundingRect &&
                    (dialogBoundingRect.bottom <= this.controlBoundingRect.bottom ||
                        dialogBoundingRect.top >= this.controlBoundingRect.top)) {
                    this.open = false;
                }
            }
        });
        this.labelHtmlElement = document.createElement('chameleon-typography');
        this.labelHtmlElement.setAttribute('variant', 'button-small');
        this.labelHtmlElement.classList.add('label');
        this.helperTextHtmlElement = document.createElement('chameleon-typography');
        this.helperTextHtmlElement.setAttribute('variant', 'caption-medium');
        this.helperTextHtmlElement.setAttribute('color', helperTextDefaultColor);
    }
    connectedCallback() {
        var _a, _b;
        super.connectedCallback();
        this.stateIcon.setAttribute('class', 'state-icon');
        (_b = (_a = this.shadowRoot) === null || _a === void 0 ? void 0 : _a.querySelector('[name="button-container"]')) === null || _b === void 0 ? void 0 : _b.appendChild(this.stateIcon);
        if (!this.querySelector('[slot="indicator"]')) {
            this.createIndicator();
        }
        this.insertLabel();
        this.insertHelperText();
        this.labelSize.observe(this.labelHtmlElement);
        this.setInitialSelectedValue();
        if (this.inDialog && !this.tearDownInDialog) {
            this.tearDownInDialog = this.setUpInDialog();
        }
        // for the inline select, there is only 1 size
        if (this.inline) {
            if (this.hasAttribute('size')) {
                this.removeAttribute('size');
            }
            this.setStateIcon(false);
        }
        this.optionList = this.querySelectorAll('chameleon-option');
        this.resizeListbox.observe(this.listbox);
    }
    disconnectedCallback() {
        super.disconnectedCallback();
        this.labelHtmlElement.remove();
        this.helperTextHtmlElement.remove();
        if (this.tearDownInDialog) {
            this.tearDownInDialog();
        }
    }
    setUpInDialog() {
        var _a;
        this.parentDialogContent = this.findParentDialogContent();
        if (this.parentDialogContent) {
            this.positionListbox();
            (_a = this.parentDialogContent) === null || _a === void 0 ? void 0 : _a.addEventListener('scroll', this.positionListbox);
            window.addEventListener('resize', this.positionListbox);
            return () => {
                var _a;
                this.resetListbox();
                (_a = this.parentDialogContent) === null || _a === void 0 ? void 0 : _a.removeEventListener('scroll', this.positionListbox);
                window.removeEventListener('resize', this.positionListbox);
            };
        }
    }
    resetListbox() {
        this.listbox.style.removeProperty('position');
        this.listbox.style.removeProperty('width');
        this.listbox.style.removeProperty('top');
        this.listbox.style.removeProperty('left');
    }
    createIndicator() {
        const indicator = document.createElement('chameleon-svg');
        indicator.setAttribute('slot', 'indicator');
        indicator.innerHTML = SVG_CHEVRON_DOWN_OUTLINED;
        this.appendChild(indicator);
    }
    setStateIcon(enabled, icon) {
        if (enabled && icon) {
            this.stateIcon.innerHTML = icon;
        }
        else {
            this.stateIcon.remove();
        }
    }
    insertLabel() {
        var _a;
        if (this.label) {
            (_a = this.shadowRoot) === null || _a === void 0 ? void 0 : _a.prepend(this.labelHtmlElement);
        }
    }
    insertHelperText() {
        var _a;
        if (this.helperText) {
            (_a = this.shadowRoot) === null || _a === void 0 ? void 0 : _a.append(this.helperTextHtmlElement);
        }
    }
    setInitialSelectedValue() {
        const selectedValue = this.getAttribute('selected-value');
        if (selectedValue !== null && selectedValue !== '') {
            // because fast wait a frame before querying options
            DOM.queueUpdate(() => {
                this.value = selectedValue;
            });
        }
        else {
            this.selectedValue = this.value;
        }
    }
    findParentDialogContent() {
        const dialog = findParent(this, (parent) => parent.tagName === 'CHAMELEON-DIALOG');
        const dialogContent = (dialog === null || dialog === void 0 ? void 0 : dialog.shadowRoot) && findChild(dialog === null || dialog === void 0 ? void 0 : dialog.shadowRoot, (child) => child.part.contains('body'));
        return dialogContent;
    }
}
__decorate([
    attr({ attribute: 'selected-value' })
], SelectComponent.prototype, "selectedValue", void 0);
__decorate([
    attr({ mode: 'boolean' })
], SelectComponent.prototype, "success", void 0);
__decorate([
    attr({ mode: 'boolean' })
], SelectComponent.prototype, "error", void 0);
__decorate([
    attr({ mode: 'boolean' })
], SelectComponent.prototype, "inline", void 0);
__decorate([
    attr
], SelectComponent.prototype, "label", void 0);
__decorate([
    attr({ attribute: 'helper-text' })
], SelectComponent.prototype, "helperText", void 0);
__decorate([
    attr({ mode: 'boolean' })
], SelectComponent.prototype, "fullwidth", void 0);
__decorate([
    attr({ attribute: 'no-border', mode: 'boolean' })
], SelectComponent.prototype, "noBorder", void 0);
__decorate([
    attr({ attribute: 'in-dialog', mode: 'boolean' })
], SelectComponent.prototype, "inDialog", void 0);
__decorate([
    attr({ attribute: 'fit-content', mode: 'boolean' })
], SelectComponent.prototype, "fitContent", void 0);
