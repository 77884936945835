import { css } from '@microsoft/fast-element';
import { TooltipV3Component, tooltipV3Template } from './TooltipV3';
import tooltipStyles from './TooltipV3.styles.scss';
export const tooltipV3Definition = {
    baseName: 'tooltip-v3',
    styles: css `
    ${tooltipStyles}
  `,
    template: tooltipV3Template,
};
export const chameleonTooltipV3 = TooltipV3Component.compose(tooltipV3Definition);
