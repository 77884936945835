import { Anchor, anchorTemplate as template } from '@microsoft/fast-foundation';
import styles from './BaseNavigationRailLink.styles.scss';
import { BaseNavigationRailLinkComponent } from './BaseNavigationRailLink';
export const baseNavigationRailLinkDefinition = {
    baseName: 'base-navigation-rail-link',
    baseClass: Anchor,
    template: template,
    styles,
};
export const chameleonBaseNavigationRailLink = BaseNavigationRailLinkComponent.compose(baseNavigationRailLinkDefinition);
