import { useRef, useEffect } from 'react';
export function useEventListener(eventName, handler, elementRef) {
    const savedHandler = useRef();
    useEffect(() => {
        savedHandler.current = handler;
    }, [handler]);
    useEffect(() => {
        const element = elementRef.current;
        const isSupported = element && element.addEventListener;
        if (!isSupported)
            return;
        const eventListener = (event) => savedHandler.current && savedHandler.current(event);
        element.addEventListener(eventName, eventListener);
        return () => {
            element.removeEventListener(eventName, eventListener);
        };
    }, [eventName, elementRef]);
}
