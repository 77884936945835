import { __decorate } from "tslib";
import { SVG_ADD_FILLED } from '@getgo/chameleon-icons';
import { attr, html, observable, slotted, volatile, when } from '@microsoft/fast-element';
import { FoundationElement } from '@microsoft/fast-foundation';
import { ChameleonElementMixin } from '../../common/mixin';
export const dataTableFilterBarV2Template = html `
  <div class="filters">
    <slot name="filters" ${slotted('filters')}></slot>
    ${when((x) => x.showAddButton, html `
        <chameleon-button
          variant="tertiary"
          size="${(x) => (x.condensed ? 'small' : 'medium')}"
          @click="${(x) => x.handleFilterButtonClick()}"
        >
          <chameleon-svg slot="start">${SVG_ADD_FILLED}</chameleon-svg>
          ${(x) => x.addFiltersText}
        </chameleon-button>
      `)}
    ${when((x) => x.showOpenButton, html `
        <chameleon-button
          variant="tertiary"
          size="${(x) => (x.condensed ? 'small' : 'medium')}"
          @click="${(x) => x.handleFilterButtonClick()}"
        >
          ${(x) => x.openFiltersText}
        </chameleon-button>
      `)}
  </div>
  <div class="actions">
    <slot ${slotted('favorite')} name="favorite"></slot>
    <slot ${slotted('clear')} name="clear"></slot>
  </div>
  <slot name="filter-panel"></slot>
`;
export class DataTableFilterBarV2Component extends ChameleonElementMixin(FoundationElement) {
    constructor() {
        super(...arguments);
        this.addFiltersText = '';
        this.openFiltersText = '';
        this.condensed = false;
    }
    get showAddButton() {
        return this.filters === undefined || this.filters.length === 0;
    }
    get showOpenButton() {
        return this.filters !== undefined && this.filters.length > 0;
    }
    get allSlottedElements() {
        const combined = [];
        if (this.filters) {
            combined.push(...this.filters);
        }
        if (this.clear) {
            combined.push(...this.clear);
        }
        if (this.favorite) {
            combined.push(...this.favorite);
        }
        return combined;
    }
    favoriteChanged(_, next) {
        if (!next) {
            return;
        }
        for (const el of next) {
            if (this.condensed) {
                el.setAttribute('size', 'small');
            }
            else {
                el.setAttribute('size', 'medium');
            }
        }
    }
    clearChanged(_, next) {
        if (!next) {
            return;
        }
        for (const el of next) {
            if (this.condensed) {
                el.setAttribute('size', 'small');
            }
            else {
                el.setAttribute('size', 'medium');
            }
        }
    }
    filtersChanged(_, next) {
        if (!next) {
            return;
        }
        for (const el of next) {
            if (this.condensed) {
                el.setAttribute('size', 'small');
            }
            else {
                el.setAttribute('size', 'medium');
            }
        }
    }
    condensedChanged(_, next) {
        for (const el of this.allSlottedElements) {
            if (next === true) {
                el.setAttribute('size', 'small');
            }
            else {
                el.setAttribute('size', 'medium');
            }
        }
    }
    handleFilterButtonClick() {
        this.$emit('filter-button-click');
    }
}
__decorate([
    attr({ attribute: 'add-filters-text' })
], DataTableFilterBarV2Component.prototype, "addFiltersText", void 0);
__decorate([
    attr({ attribute: 'open-filters-text' })
], DataTableFilterBarV2Component.prototype, "openFiltersText", void 0);
__decorate([
    attr({ mode: 'boolean' })
], DataTableFilterBarV2Component.prototype, "condensed", void 0);
__decorate([
    observable
], DataTableFilterBarV2Component.prototype, "filters", void 0);
__decorate([
    observable
], DataTableFilterBarV2Component.prototype, "favorite", void 0);
__decorate([
    observable
], DataTableFilterBarV2Component.prototype, "clear", void 0);
__decorate([
    volatile
], DataTableFilterBarV2Component.prototype, "showAddButton", null);
__decorate([
    volatile
], DataTableFilterBarV2Component.prototype, "showOpenButton", null);
