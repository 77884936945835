import { __decorate } from "tslib";
import { Radio } from '@microsoft/fast-foundation';
import { attr } from '@microsoft/fast-element';
import { ChameleonElementMixin } from '../common/mixin';
import { DEV } from 'esm-env';
import { SVG_ALERT_OUTLINED } from '@getgo/chameleon-icons';
export class RadioButtonComponent extends ChameleonElementMixin(Radio) {
    constructor() {
        super(...arguments);
        this.erIcon = document.createElement('chameleon-svg');
        this.msg = document.createElement('chameleon-typography');
        this.partError = document.createElement('div');
    }
    connectedCallback() {
        super.connectedCallback();
        this.setAccessibleLabel();
        if (this.errorText) {
            this.appendErrorState();
        }
    }
    labelChanged() {
        this.setAccessibleLabel();
    }
    labelIdChanged() {
        this.setAccessibleLabel();
    }
    setAccessibleLabel() {
        var _a;
        if ((_a = this.textContent) === null || _a === void 0 ? void 0 : _a.length) {
            return;
        }
        if (this.labelId) {
            this.setAttribute('aria-labelledby', this.labelId);
            return;
        }
        if (this.label) {
            this.setAttribute('aria-label', this.label);
            return;
        }
        if (DEV) {
            this.validateAccessibleLabel();
        }
    }
    validateAccessibleLabel() {
        var _a, _b;
        if (!((_a = this.getAttribute('aria-label')) === null || _a === void 0 ? void 0 : _a.length) && !((_b = this.getAttribute('aria-labelledby')) === null || _b === void 0 ? void 0 : _b.length)) {
            console.error(`CHAMELEON-A11Y: Each Radio Button component must have an accessible label provided by either 
  1) text content 
  2) a visible label whose id is given as the attribute labelId 
  3) a non-empty label attribute to be used as aria-label.
`);
        }
    }
    errorTextChanged(oldValue, newValue) {
        if (newValue && oldValue != newValue) {
            this.appendErrorState();
        }
        else if (this.partError && oldValue != newValue) {
            this.removeErrorState();
        }
    }
    appendErrorState() {
        var _a, _b;
        if (!this.errorText) {
            return;
        }
        this.classList.add('has-error');
        this.partError.setAttribute('part', 'error');
        this.erIcon.classList.add('error-icon');
        this.erIcon.innerHTML = SVG_ALERT_OUTLINED;
        this.msg.classList.add('error-text');
        this.msg.setAttribute('color', 'type-color-danger');
        this.msg.setAttribute('variant', 'caption-medium');
        this.msg.textContent = this.errorText;
        this.setAttribute('aria-invalid', 'true');
        (_a = this.shadowRoot) === null || _a === void 0 ? void 0 : _a.appendChild(this.erIcon);
        (_b = this.shadowRoot) === null || _b === void 0 ? void 0 : _b.appendChild(this.msg);
    }
    removeErrorState() {
        this.classList.remove('has-error');
        this.erIcon.remove();
        this.msg.remove();
        this.removeAttribute('aria-invalid');
    }
}
__decorate([
    attr({ attribute: 'label-id' })
], RadioButtonComponent.prototype, "labelId", void 0);
__decorate([
    attr
], RadioButtonComponent.prototype, "label", void 0);
__decorate([
    attr({ attribute: 'error-text' })
], RadioButtonComponent.prototype, "errorText", void 0);
