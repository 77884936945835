import { __decorate } from "tslib";
import { InputFieldComponent } from '../InputField';
import { attr } from '@microsoft/fast-element';
import { DEV } from 'esm-env';
export class TextFieldComponent extends InputFieldComponent {
    constructor() {
        super(...arguments);
        this.hidecounter = false;
        this.counterEl = document.createElement('span');
        this.handleHelperTextSlotChange = () => {
            this.render();
            const helperText = this.helperTextEl.assignedElements()[0];
            if (!helperText || !helperText.textContent) {
                return;
            }
            this.control.setAttribute('aria-describedby', this.querySelector('[slot="helpertext"]').id);
        };
        this.handleInput = (e) => {
            const { value } = e === null || e === void 0 ? void 0 : e.target;
            if (this.maxlength && !this.hidecounter) {
                this.counterEl.textContent = `${value.length}/${this.maxlength}`;
            }
        };
    }
    connectedCallback() {
        super.connectedCallback();
        this.control.addEventListener('input', this.handleInput);
        this.render();
    }
    disconnectedCallback() {
        super.disconnectedCallback();
        this.control.removeEventListener('input', this.handleInput);
    }
    attributeChangedCallback(name, oldValue, newValue) {
        super.attributeChangedCallback(name, oldValue, newValue);
        this.render();
    }
    hasHelperText() {
        const helperText = this.querySelector('[slot="helpertext"]');
        return helperText !== null;
    }
    render() {
        if (this.maxlength && !this.hidecounter) {
            this.renderCounter();
        }
        else {
            this.removeCounter();
        }
    }
    renderCounter() {
        var _a;
        if (!this.hasHelperText()) {
            this.infoContainer.style.justifyContent = 'flex-end';
        }
        else {
            this.infoContainer.style.justifyContent = 'space-between';
        }
        this.counterEl.classList.add('counter');
        this.counterEl.textContent = `${this.value.length}/${this.maxlength}`;
        (_a = this.infoContainer) === null || _a === void 0 ? void 0 : _a.appendChild(this.counterEl);
    }
    removeCounter() {
        var _a;
        if ((_a = this.counterEl) === null || _a === void 0 ? void 0 : _a.isConnected) {
            this.infoContainer.style.justifyContent = '';
            this.counterEl.remove();
        }
    }
    setAccessibleLabel() {
        var _a, _b, _c;
        if (((_a = this.textContent) === null || _a === void 0 ? void 0 : _a.trim()) && ((_b = this.helperTextEl.assignedElements()[0]) === null || _b === void 0 ? void 0 : _b.textContent) !== ((_c = this.textContent) === null || _c === void 0 ? void 0 : _c.trim())) {
            return;
        }
        if (this.labelId) {
            this.setAttribute('aria-labelledby', this.labelId);
            return;
        }
        if (DEV) {
            this.validateAccessibleLabel();
        }
    }
    validateAccessibleLabel() {
        var _a;
        if (!((_a = this.getAttribute('aria-labelledby')) === null || _a === void 0 ? void 0 : _a.length)) {
            console.error(`CHAMELEON-A11Y: TextField component must have an accessible label provided by either 
  1) text content 
  2) a visible label whose id is given as the attribute labelId
`);
        }
    }
}
__decorate([
    attr({ mode: 'boolean', attribute: 'hide-counter' })
], TextFieldComponent.prototype, "hidecounter", void 0);
