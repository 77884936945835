import { SVG_CHECKBOX_LINE, SVG_CHECKBOX_CHECK } from '@getgo/chameleon-icons';
import { Checkbox, checkboxTemplate as template } from '@microsoft/fast-foundation';
import { CheckboxComponent } from './Checkbox';
import styles from './Checkbox.styles.scss';
export const checkboxDefinition = {
    baseName: 'checkbox',
    baseClass: Checkbox,
    template: template,
    styles,
    checkedIndicator: `<chameleon-svg class="checked-indicator">${SVG_CHECKBOX_CHECK}</chameleon-svg>`,
    indeterminateIndicator: `<chameleon-svg class="indeterminate-indicator">${SVG_CHECKBOX_LINE}</chameleon-svg>`,
};
export const chameleonCheckbox = CheckboxComponent.compose(checkboxDefinition);
