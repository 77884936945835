import { css, html } from '@microsoft/fast-element';
import { accordionItemStyles } from '@microsoft/fast-components';
import { AccordionItem, accordionItemTemplate as template } from '@microsoft/fast-foundation';
import { AccordionItemComponent } from './AccordionItem';
import styles from './AccordionItem.styles.scss';
import { SVG_CHEVRON_DOWN_OUTLINED, SVG_CHEVRON_RIGHT_OUTLINED, SVG_CHEVRON_UP_OUTLINED } from '@getgo/chameleon-icons';
export const chameleonAccordionItem = AccordionItemComponent.compose({
    baseName: 'accordion-item',
    baseClass: AccordionItem,
    template: template,
    styles: (ctx, def) => css `
    ${accordionItemStyles(ctx, def)}
    ${styles}
  `,
    collapsedIcon: html `${(x) => x.chevronPosition === 'left'
        ? html `<chameleon-svg>${SVG_CHEVRON_RIGHT_OUTLINED}</chameleon-svg>`
        : html `<chameleon-svg>${SVG_CHEVRON_DOWN_OUTLINED}</chameleon-svg>`}`,
    expandedIcon: html `${(x) => x.chevronPosition === 'left'
        ? html `<chameleon-svg>${SVG_CHEVRON_DOWN_OUTLINED}</chameleon-svg>`
        : html `<chameleon-svg>${SVG_CHEVRON_UP_OUTLINED}</chameleon-svg>`}`,
});
