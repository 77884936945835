import { SwatchRGB } from "../swatch.js";
export function baseLayerLuminanceSwatch(luminance) {
    return SwatchRGB.create(luminance, luminance, luminance);
}
/**
 * Recommended values for light and dark mode for {@link @microsoft/fast-components#baseLayerLuminance}.
 *
 * @public
 */
export const StandardLuminance = {
    LightMode: 1,
    DarkMode: 0.23,
};
