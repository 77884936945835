import { __decorate } from "tslib";
import { SVG_CHEVRON_DOWN_OUTLINED } from '@getgo/chameleon-icons';
import { attr, html, ref } from '@microsoft/fast-element';
import { FoundationElement } from '@microsoft/fast-foundation';
import { ChameleonElementMixin } from '../common/mixin';
import { getHostChildrenFromEvent } from '../common/utils/DomUtil';
import { DEV } from 'esm-env';
export const splitButtonTemplate = html `<div class="container" @click=${(x, { event }) => x.clickHandler(event)}>
  <chameleon-button is-loading="${(x) => x.isLoading}" part="button" class="action" variant="${(x) => x.variant}" size="${(x) => x.size}"
  disabled=${(x) => x.disabled}>
    <span slot="start">
      <slot name="start"></slot>
    </span>
    <slot></slot>
  </chameleon-button>
  <chameleon-button id="menu-button" @click="${(x) => x.handleTriggerClick()}" variant="${(x) => x.variant}" size="${(x) => x.size}"
  disabled=${(x) => x.disabled || x.isLoading}>
    <chameleon-svg>${SVG_CHEVRON_DOWN_OUTLINED}<chameleon-svg>
  </chameleon-button>
  <chameleon-popover-v2 label="popover" trigger-id="menu-button" part="popover" z-index="${(x) => x.popoverZIndex}" position="${(x) => x.position}" menu width="${(x) => x.popoverWidth}">
    <slot ${ref('actionsSlot')} name="actions"></slot>
  </chameleon-popover-v2>
  </div>`;
export class SplitButtonComponent extends ChameleonElementMixin(FoundationElement) {
    constructor() {
        super(...arguments);
        this.size = 'medium';
        this.variant = 'primary';
        this.position = 'bottom-end';
        this.disabled = false;
        this.fullWidth = false;
        this.popoverWidth = undefined;
        this.popoverZIndex = undefined;
        this.getActionButton = () => { var _a; return (_a = this.shadowRoot) === null || _a === void 0 ? void 0 : _a.querySelector('.action'); };
        this.getPopover = () => { var _a; return (_a = this.shadowRoot) === null || _a === void 0 ? void 0 : _a.querySelector('chameleon-popover'); };
        this.popoverChangeHandler = (e) => this.$emit('popoverchange', e.detail, { bubbles: false });
    }
    menuLabelChanged() {
        requestAnimationFrame(() => {
            this.setAccessibleLabel();
        });
    }
    connectedCallback() {
        var _a;
        super.connectedCallback();
        this.setAccessibleLabel();
        (_a = this.getPopover()) === null || _a === void 0 ? void 0 : _a.addEventListener('popoverchange', this.popoverChangeHandler);
    }
    disconnectedCallback() {
        var _a;
        super.disconnectedCallback();
        (_a = this.getPopover()) === null || _a === void 0 ? void 0 : _a.removeEventListener('popoverchange', this.popoverChangeHandler);
    }
    clickHandler(e) {
        const actionButton = this.getActionButton();
        if (actionButton) {
            const children = getHostChildrenFromEvent(e, actionButton);
            if (!children.length) {
                e.stopImmediatePropagation();
            }
        }
    }
    handleTriggerClick() {
        this.$emit('split-button-trigger-click');
    }
    getInternalMenuButtonElement() {
        var _a, _b, _c;
        return (_c = (_b = (_a = this.shadowRoot) === null || _a === void 0 ? void 0 : _a.getElementById('menu-button')) === null || _b === void 0 ? void 0 : _b.shadowRoot) === null || _c === void 0 ? void 0 : _c.querySelector('button');
    }
    validateMenuLabel() {
        var _a, _b;
        if (!this.menuLabel && !((_b = (_a = this.getInternalMenuButtonElement()) === null || _a === void 0 ? void 0 : _a.getAttribute('aria-label')) === null || _b === void 0 ? void 0 : _b.length)) {
            console.error(`CHAMELEON-A11Y: Split Button component must have an accessible label for the chevron button provided by a non-empty menuLabel/menu-label attribute to be used as aria-label.`);
        }
    }
    setAccessibleLabel() {
        const menuButton = this.getInternalMenuButtonElement();
        if (menuButton && this.menuLabel) {
            menuButton.setAttribute('aria-label', this.menuLabel);
            return;
        }
        if (DEV) {
            this.validateMenuLabel();
        }
    }
}
__decorate([
    attr
], SplitButtonComponent.prototype, "size", void 0);
__decorate([
    attr
], SplitButtonComponent.prototype, "variant", void 0);
__decorate([
    attr
], SplitButtonComponent.prototype, "position", void 0);
__decorate([
    attr({ mode: 'boolean' })
], SplitButtonComponent.prototype, "disabled", void 0);
__decorate([
    attr({ mode: 'boolean', attribute: 'full-width' })
], SplitButtonComponent.prototype, "fullWidth", void 0);
__decorate([
    attr({ attribute: 'popover-width' })
], SplitButtonComponent.prototype, "popoverWidth", void 0);
__decorate([
    attr({ attribute: 'popover-z-index' })
], SplitButtonComponent.prototype, "popoverZIndex", void 0);
__decorate([
    attr({ attribute: 'menu-label' })
], SplitButtonComponent.prototype, "menuLabel", void 0);
__decorate([
    attr({ attribute: 'is-loading' })
], SplitButtonComponent.prototype, "isLoading", void 0);
