import { __decorate } from "tslib";
import { attr, html, observable, slotted, when } from '@microsoft/fast-element';
import { ChameleonElementMixin } from '../common/mixin';
import { FoundationElement } from '@microsoft/fast-foundation';
import { SVG_CLOSE_OUTLINED, SVG_SUBTRACT_OUTLINED, SVG_STRETCH_EXPAND_OUTLINED, SVG_PIP_MAXIMIZE_OUTLINED, } from '@getgo/chameleon-icons';
export const topBarTemplate = html ` <div class="container">
  ${when((x) => x.platform == 'macOS', html `<div class="traffic-light-container">
      <button class="close-button traffic-light" id="traffic-light-close">${SVG_CLOSE_OUTLINED}</button>
      <button class="minimize-button traffic-light" id="traffic-light-minimize">${SVG_SUBTRACT_OUTLINED}</button>
      <button class="maximize-button traffic-light" id="traffic-light-maximize">${SVG_STRETCH_EXPAND_OUTLINED}</button>
    </div>`)}
  <div class="logo">
    <slot name="logo" ${slotted('logoNodes')}></slot>
  </div>
  <div class="submenu">
    <slot name="submenu" ${slotted('submenuNodes')}></slot>
  </div>
  <div class="search-container">
    <slot name="search-field"></slot>
  </div>
  <div class="extra-element-container">
    <slot name="extra-element-container"></slot>
  </div>
  <div class="button-container">
    <slot name="button-container"></slot>
  </div>
  <div class="sign-in-container">
    <slot name="sign-in-button"></slot>
  </div>
  ${when((x) => x.platform == 'windows', html `<div class="windows-container">
      <chameleon-icon-button class="minimize-button" id="windows-button-minimize"
        ><chameleon-svg>${SVG_SUBTRACT_OUTLINED}</chameleon-svg></chameleon-icon-button
      >
      <chameleon-icon-button class="maximize-button" id="windows-button-maximize"
        ><chameleon-svg>${SVG_PIP_MAXIMIZE_OUTLINED}</chameleon-svg></chameleon-icon-button
      >
      <chameleon-icon-button class="close-button" id="windows-button-close"
        ><chameleon-svg>${SVG_CLOSE_OUTLINED}</chameleon-svg></chameleon-icon-button
      >
    </div>`)}
</div>`;
export class TopBarComponent extends ChameleonElementMixin(FoundationElement) {
    constructor() {
        super(...arguments);
        this.platform = 'web';
        this.onMinimize = () => {
            this.$emit('ch-top-bar-minimize', null, { bubbles: false });
        };
        this.onMaximize = () => {
            this.$emit('ch-top-bar-maximize', null, { bubbles: false });
        };
        this.onClose = () => {
            this.$emit('ch-top-bar-close', null, { bubbles: false });
        };
    }
    connectedCallback() {
        var _a, _b, _c;
        super.connectedCallback();
        this.setAttribute('state', 'focus');
        if (this.platform == 'macOS') {
            this.setTrafficLights();
            this.setSeparator();
        }
        if (this.platform == 'windows') {
            this.setWindowsButtons();
        }
        (_a = this.closeBtn) === null || _a === void 0 ? void 0 : _a.addEventListener('click', this.onClose);
        (_b = this.maximizeBtn) === null || _b === void 0 ? void 0 : _b.addEventListener('click', this.onMaximize);
        (_c = this.minimizeBtn) === null || _c === void 0 ? void 0 : _c.addEventListener('click', this.onMinimize);
    }
    logoNodesChanged() {
        var _a, _b;
        if (this.logoNodes && this.logoNodes.length > 0) {
            const logoNode = this.logoNodes[0];
            if (this.submenuNodes.length > 0 &&
                logoNode &&
                logoNode instanceof Element &&
                !((_a = this.shadowRoot) === null || _a === void 0 ? void 0 : _a.querySelector('.separator'))) {
                this.setSeparator();
            }
        }
        else if ((_b = this.shadowRoot) === null || _b === void 0 ? void 0 : _b.querySelector('.separator')) {
            this.removeSeparator();
        }
    }
    submenuNodesChanged() {
        var _a, _b;
        if (this.submenuNodes && this.submenuNodes.length > 0) {
            const submenuNode = this.submenuNodes[0];
            if (this.logoNodes.length > 0 &&
                submenuNode &&
                submenuNode instanceof Element &&
                !((_a = this.shadowRoot) === null || _a === void 0 ? void 0 : _a.querySelector('.separator'))) {
                this.setSeparator();
            }
        }
        else if ((_b = this.shadowRoot) === null || _b === void 0 ? void 0 : _b.querySelector('.separator')) {
            this.removeSeparator();
        }
    }
    setSeparator() {
        var _a, _b;
        const separator = document.createElement('div');
        separator.setAttribute('class', 'separator');
        (_b = (_a = this.shadowRoot) === null || _a === void 0 ? void 0 : _a.querySelector('.logo')) === null || _b === void 0 ? void 0 : _b.appendChild(separator);
    }
    removeSeparator() {
        var _a, _b;
        (_b = (_a = this.shadowRoot) === null || _a === void 0 ? void 0 : _a.querySelector('.separator')) === null || _b === void 0 ? void 0 : _b.remove();
    }
    setTrafficLights() {
        var _a, _b, _c;
        this.closeBtn = (_a = this.shadowRoot) === null || _a === void 0 ? void 0 : _a.querySelector('.close-button');
        this.minimizeBtn = (_b = this.shadowRoot) === null || _b === void 0 ? void 0 : _b.querySelector('.minimize-button');
        this.maximizeBtn = (_c = this.shadowRoot) === null || _c === void 0 ? void 0 : _c.querySelector('.maximize-button');
        window.addEventListener('blur', () => {
            this.setAttribute('state', 'blur');
        });
        window.addEventListener('focus', () => {
            this.setAttribute('state', 'focus');
        });
    }
    setWindowsButtons() {
        var _a, _b, _c;
        this.closeBtn = (_a = this.shadowRoot) === null || _a === void 0 ? void 0 : _a.querySelector('.close-button');
        this.maximizeBtn = (_b = this.shadowRoot) === null || _b === void 0 ? void 0 : _b.querySelector('.maximize-button');
        this.minimizeBtn = (_c = this.shadowRoot) === null || _c === void 0 ? void 0 : _c.querySelector('.minimize-button');
    }
}
__decorate([
    attr
], TopBarComponent.prototype, "platform", void 0);
__decorate([
    observable
], TopBarComponent.prototype, "logoNodes", void 0);
__decorate([
    observable
], TopBarComponent.prototype, "submenuNodes", void 0);
