import { breadcrumbItemTemplate as template } from '@microsoft/fast-foundation';
import { BreadcrumbItem } from '@microsoft/fast-components';
import { BreadcrumbItemComponent } from './BreadcrumbItem';
import styles from './BreadcrumbItem.styles.scss';
export const breadcrumbItemDefinition = {
    baseName: 'breadcrumb-item',
    baseClass: BreadcrumbItem,
    template: template,
    styles,
    shadowOptions: {
        delegatesFocus: true,
    },
    separator: '/',
};
export const chameleonBreadcrumbItem = BreadcrumbItemComponent.compose(breadcrumbItemDefinition);
