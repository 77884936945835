import { __decorate } from "tslib";
import { SVG_CLOSE_OUTLINED, SVG_INFO_OUTLINED, SVG_ALERT_OUTLINED, SVG_CHECKMARK_OUTLINED, SVG_BLOCK_OUTLINED, } from '@getgo/chameleon-icons';
import { html, observable, slotted, when } from '@microsoft/fast-element';
import { AlertBase, DEFAULT_CLOSE_ARIA_LABEL } from '../common/AlertBase';
export const alertTemplate = html `
  <template role="alert" variant="${(x) => { var _a; return (_a = x.variant) !== null && _a !== void 0 ? _a : 'info'; }}">
    <slot name="icon"></slot>
    <div class="alert-content">
      <slot></slot>
      <slot name="action"></slot>
    </div>
    ${when((x) => x.closable, html `<chameleon-icon-button
        size="small"
        aria-label="${(x) => { var _a; return (_a = x.closeLabel) !== null && _a !== void 0 ? _a : DEFAULT_CLOSE_ARIA_LABEL; }}"
        @click=${(x) => x.close()}
      >
        <chameleon-svg>${SVG_CLOSE_OUTLINED}</chameleon-svg>
      </chameleon-icon-button>`)}
  </template>
`;
export const alertV2Template = html `
  <template role="alert" variant="${(x) => { var _a; return (_a = x.variant) !== null && _a !== void 0 ? _a : 'info'; }}">
    <slot name="icon" ${slotted('icon')}
      >${html `<chameleon-svg class="alert-leading-icon">
        ${when((x) => x.variant === 'info', html `${SVG_INFO_OUTLINED}`)}
        ${when((x) => x.variant === 'success', html `${SVG_CHECKMARK_OUTLINED}`)}
        ${when((x) => x.variant === 'danger', html `${SVG_BLOCK_OUTLINED}`)}
        ${when((x) => x.variant === 'warning', html `${SVG_ALERT_OUTLINED}`)}
      </chameleon-svg>`}
    </slot>
    <div class="alert-content">
      <slot></slot>
      <slot name="action"></slot>
    </div>
    ${when((x) => x.closable, html `
        <chameleon-icon-button
          part="close-icon"
          size="xsmall"
          aria-label="${(x) => { var _a; return (_a = x.closeLabel) !== null && _a !== void 0 ? _a : DEFAULT_CLOSE_ARIA_LABEL; }}"
          @click=${(x) => x.close()}
        >
          <chameleon-svg> ${SVG_CLOSE_OUTLINED} </chameleon-svg>
        </chameleon-icon-button>
      `)}
  </template>
`;
export class AlertComponent extends AlertBase {
}
__decorate([
    observable
], AlertComponent.prototype, "icon", void 0);
