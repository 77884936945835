import { __decorate } from "tslib";
import { attr } from '@microsoft/fast-element';
import { FoundationElement } from '@microsoft/fast-foundation';
import { Direction } from '@microsoft/fast-web-utilities';
export class ThemeProviderComponent extends FoundationElement {
    constructor() {
        super(...arguments);
        this.direction = Direction.ltr;
        this.theme = 'light';
        this.skin = 'gotoadmin';
        this.automatic = false;
        this.rebranding2021 = true;
    }
    connectedCallback() {
        super.connectedCallback();
        this.setRebrandingFlagOnBody(this.rebranding2021);
    }
    updateStyles(newTheme, isAutomatic) {
        if (newTheme === undefined || isAutomatic === undefined) {
            return;
        }
        const isForcedDarkTheme = isAutomatic === false && newTheme === 'dark';
        this.classList.toggle('enable-darkmode', isAutomatic);
        this.classList.toggle('theme-dark', isForcedDarkTheme);
        if (this.isGlobalThemeProvider()) {
            document.body.classList.toggle('theme-dark', isForcedDarkTheme);
            document.body.classList.toggle('enable-darkmode', isAutomatic);
        }
    }
    themeChanged(_oldValue, newTheme) {
        this.updateStyles(newTheme, this.automatic);
    }
    automaticChanged(_oldValue, isAutomatic) {
        this.updateStyles(this.theme, isAutomatic);
    }
    isGlobalThemeProvider() {
        const themeProvider = this.getGlobalThemeProvider();
        // The current theme provider is in shadow dom and is not found by the document.querySelector call.
        // Then it is most likely to be the only one in the page.
        // If there's another bug regarding this consider adding an extra attribute to the theme provider.
        return themeProvider === null || themeProvider === this;
    }
    getGlobalThemeProvider() {
        return document.querySelector('chameleon-theme-provider');
    }
    setRebrandingFlagOnBody(flagValue) {
        if (this.isGlobalThemeProvider()) {
            document.body.toggleAttribute('rebranding2021', flagValue);
        }
    }
}
__decorate([
    attr
], ThemeProviderComponent.prototype, "theme", void 0);
__decorate([
    attr
], ThemeProviderComponent.prototype, "skin", void 0);
__decorate([
    attr({ mode: 'boolean' })
], ThemeProviderComponent.prototype, "automatic", void 0);
__decorate([
    attr({ mode: 'boolean' })
], ThemeProviderComponent.prototype, "rebranding2021", void 0);
