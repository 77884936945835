import { RadioGroup, radioGroupTemplate as template } from '@microsoft/fast-foundation';
import { RadioGroupComponent } from './RadioGroup';
import styles from './RadioGroup.styles.scss';
export const radioGroupDefinition = {
    baseName: 'radio-group',
    baseClass: RadioGroup,
    template: template,
    styles,
};
export const chameleonRadioGroup = RadioGroupComponent.compose(radioGroupDefinition);
