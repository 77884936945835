export function handleForwardRef(currentRef, forwardedRef) {
    return function (node) {
        currentRef.current = node;
        if (typeof forwardedRef === 'function') {
            forwardedRef(node);
        }
        else if (forwardedRef) {
            forwardedRef.current = node;
        }
    };
}
